import { Box } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import Header from "./Header";

const HeaderBar = ({ onLoginChange }) => {
  const headerRef = useRef(null);

  const [headerHeightInVh, setHeaderHeightInVh] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const header = headerRef.current;
      const newHeaderHeightInVh = (
        (header.offsetHeight / window.innerHeight) *
        100
      ).toFixed(2);
      setHeaderHeightInVh(newHeaderHeightInVh);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("wheel", handleResize);

    // Initial calculation
    if (headerRef.current) {
      handleResize();
    }

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("wheel", handleResize);
    };
  }, []);

  return (
    <Box
      ref={headerRef}
      sx={{
        flex: 1,
        height: "100%",
      }}
    >
      <Header onLoginChange={onLoginChange} />
    </Box>
  );
};

export default HeaderBar;
