import { useState, useEffect, useCallback, useRef } from "react";
import dayjs from "dayjs";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
  ListItemIcon,
  CircularProgress,
  Box,
} from "@mui/material";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Toast from "../../components/Utils/Toast";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";

import InputNew from "../../components/Content/FormElements/InputNew";

import "./OptionConfig.css";
import { toast } from "react-toastify";
import update from "immutability-helper";

const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

const removeSymmetryArray = (array) => {
  let res = [];

  for (let i = 0; i < array.length; i++) {
    if (!isSymmetryArrayIncludes(res, array[i])) {
      res = [...res, array[i]];
    } else {
      res = res.filter(
        (item) => item[0] !== array[i][1] || item[1] !== array[i][0]
      );
    }
  }
  return res;

  function isSymmetryArrayIncludes(a, b) {
    for (let i = 0; i < a.length; i++) {
      if (a[i][0] === b[1] && a[i][1] === b[0]) {
        return true;
      }
    }
    return false;
  }
};

function DraggableListItem({
  id,
  index,
  option,
  moveCard,
  deleteOptionHandler,
}) {
  const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    type: "option",
    item: { type: "option", id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: "option",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  drag(drop(ref));
  return (
    <ListItem ref={ref} disablePadding style={{ opacity: isDragging ? 0 : 1 }}>
      <ListItemIcon>
        <ArrowForwardIosIcon />
      </ListItemIcon>
      <ListItemText primary={option} />
      <IconButton
        onClick={(event) => {
          deleteOptionHandler(event, option);
        }}
      >
        <DeleteIcon color="error" />
      </IconButton>
    </ListItem>
  );
}

const OptionConfig = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm({}, false);
  const [configData, setConfigData] = useState([]);
  const [selectionData, setSelectionData] = useState();
  const [optionData, setOptionData] = useState([]);
  const [optionSelected, setOptionSelected] = useState("");
  const [selectedIndex, setSelectedIndex] = useState("");
  const [changedData, setChangedData] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [selectionFiltered, setSelectionFiltered] = useState("");

  const handleSelectionClick = (event, index) => {
    setOptionSelected(index);
    setSelectedIndex(index);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/config/select`
        );

        setConfigData(response);
        setSelectionData(Object.keys(response));
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, isChanged]);

  useEffect(() => {
    configData[optionSelected] &&
      setOptionData(configData?.[optionSelected]?.values);
  }, [optionSelected, configData]);

  useEffect(() => {
    formState.inputs.filter_option &&
      setSelectionFiltered(formState.inputs.filter_option.value);
  }, [formState.inputs.filter_option]);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      setOptionData((prevCards) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex]],
          ],
        })
      );
    },
    [optionData]
  );

  const addNewOptionHandler = (e) => {
    if (
      formState.inputs.new_option.value &&
      formState.inputs.new_option.value.length > 0
    ) {
      if (optionData.includes(formState.inputs.new_option.value)) {
        Toast("warning", "This option already exists.");
        return;
      } else {
        setOptionData([...optionData, formState.inputs.new_option.value]);
        setChangedData([
          ...changedData,
          ["", formState.inputs.new_option.value],
        ]);
      }
    }
  };

  const deleteOptionHandler = (event, index) => {
    setOptionData((prev) => prev.filter((item) => item !== index));
  };

  const newOptionSubmitHandler = async (e) => {
    e.preventDefault();
    if (!optionSelected || optionSelected === "") {
      return;
    }
    try {
      const body = {
        fe_label: optionSelected,
        values: optionData,
        update_time: currentDate,
        user_id: "",
      };
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/config/select/save`,
        "POST",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );

      if (res.error) {
        toast.error(res.error, {
          autoClose: true,
          position: "top-center",
        });
        return;
      }

      toast.success("Option Updated", {
        autoClose: true,
        position: "top-center",
      });
      setIsChanged((prev) => !prev);
    } catch (err) {}
  };

  const renderCard = useCallback((option, i) => {
    return (
      <DraggableListItem
        key={option}
        id={option}
        index={i}
        option={option}
        moveCard={moveCard}
        deleteOptionHandler={deleteOptionHandler}
      />
    );
  }, []);

  if (!selectionData)
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>Option Configuration</h4>
      </div>
      <hr />
      <div className="option-config" style={{ margin: "2rem 9rem 2rem 9rem" }}>
        <div className="user_user_group">
          <div
            style={{
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            System Parameters - Select
          </div>
          <div className="selection">
            <List
              sx={{
                width: "100%",
                position: "relative",
                overflow: "auto",
                height: 500,
                padding: "0 12px 12px 12px",
                "& ul": { padding: 0 },
              }}
            >
              <div
                style={{
                  position: "sticky",
                  zIndex: 999,
                  marginBottom: "0.5rem",
                  top: 0,
                }}
              >
                <InputNew
                  id="filter_option"
                  element="input"
                  validators={[]}
                  onInput={inputHandler}
                  placeholder="Search"
                />
              </div>
              {selectionData &&
                selectionData.map((selection, i) => {
                  if (selectionFiltered && selectionFiltered.length > 0) {
                    if (
                      selection
                        .toLowerCase()
                        .includes(selectionFiltered.toLowerCase())
                    ) {
                      return (
                        <ListItem disablePadding key={i}>
                          <ListItemButton
                            selected={selectedIndex === selection}
                            onClick={(event) => {
                              handleSelectionClick(event, selection);
                            }}
                          >
                            <ListItemText primary={selection} key={i} />
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                  } else {
                    return (
                      <ListItem disablePadding key={i}>
                        <ListItemButton
                          selected={selectedIndex === selection}
                          onClick={(event) => {
                            handleSelectionClick(event, selection);
                          }}
                        >
                          <ListItemText primary={selection} key={i} />
                        </ListItemButton>
                      </ListItem>
                    );
                  }
                })}
            </List>
          </div>
        </div>
        <div className="user_attribute">
          <div
            style={{
              fontSize: "16px",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            System Values - Options
          </div>
          <div className="selection">
            <List
              sx={{
                width: "100%",
                position: "relative",
                overflow: "auto",
                height: 430,
                "& ul": { padding: 0 },
              }}
            >
              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}

              {!isLoading && selectionData && (
                <DndProvider backend={HTML5Backend}>
                  {optionData &&
                    optionData.map((option, i) => renderCard(option, i))}
                </DndProvider>
              )}
            </List>

            <div className="new-option">
              <div className="form-control-config">
                <InputNew
                  id="new_option"
                  label=""
                  element="input"
                  validators={[]}
                  onInput={inputHandler}
                  onBlur
                />
              </div>
              <Button
                variant="outlined"
                onClick={addNewOptionHandler}
                className="editButton"
              >
                Add New
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="div4-init">
        <Button
          variant="outlined"
          className="editButton"
          onClick={newOptionSubmitHandler}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default OptionConfig;
