import { useState, useEffect, useContext } from "react";
import { read, writeFile, utils } from "xlsx";
import { Button, CircularProgress } from "@mui/material";
import Toast from "../../components/Utils/Toast";
import dayjs from "dayjs";

import { UserContext } from "../../context/user-context";
import { useForm } from "../../components/Utils/hooks/form-hook";
import InputNew from "../../components/Content/FormElements/InputNew";
import BatchTable from "./BatchTable";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";

export default function App({ tag, type }) {
  const { user, tabNameOutToInHandler, tabNameInToOutHandler } =
    useContext(UserContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [batchData, setBatchData] = useState([]);
  const [uploadTypeSelected, setUploadTypeSelected] = useState("");
  const [uploadModuleSelected, setUploadModuleSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formState, inputHandler] = useForm(
    {
      module_upload: {
        value: "",
        isValid: true,
      },
      type_upload: {
        value: "",
        isValid: true,
      },
      module_download: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  const formattedDate = dayjs().format("YYYYMMDD");

  const { display_name } = user || {};

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  let moduleOption;
  if (
    tag &&
    tag.filter(
      (item) => item.db_tablename === "batch" && item.fe_id === "batch_file"
    )[0]
  ) {
    moduleOption = tag
      .filter(
        (item) => item.db_tablename === "batch" && item.fe_id === "batch_file"
      )[0]
      .group.split(",")
      .map((item) => tabNameOutToInHandler(item));
  }

  const readUploadFile = (e) => {
    e.preventDefault();
    try {
      if (e.target.files) {
        const file = e.target.files?.[0];

        if (!file) return;

        const fileType = file?.name?.split(".")?.pop();

        if (fileType !== "csv" && fileType !== "xlsx" && fileType !== "xls") {
          Toast("error", "Invalid file type");
          return;
        }

        const reader = new FileReader();

        reader.onerror = (err) => {
          Toast("error", "Error reading the file");
        };

        reader.onload = (e) => {
          const data = e.target.result;

          const workbook = read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = utils.sheet_to_json(worksheet, {
            defval: "",
            raw: false,
            header: 1,
            blankrows: false,
          });
          setBatchData(json);
        };

        if (file instanceof Blob) {
          reader.readAsArrayBuffer(file);
        }
      }
    } catch (e) {
      Toast("error", `An error occurred: ${e}`);
    }
  };

  useEffect(() => {
    setUploadModuleSelected(
      tabNameInToOutHandler(formState.inputs?.module_upload?.value)
    );
  }, [formState.inputs?.module_upload?.value]);

  useEffect(() => {
    setUploadTypeSelected(formState.inputs.type_upload.value);
    if (formState.inputs.type_upload.value === "Overwrite") {
      alert(
        "Caution: Please select “Append” if intend to append data, otherwise “Overwrite” will replace all current data!"
      );
    }
  }, [formState.inputs.type_upload?.value]);

  const templateClickHandler = async () => {
    if (!uploadModuleSelected || uploadModuleSelected === "") {
      Toast("info", "Please select a module first.");
    }
    const downloadURL = `${process.env.REACT_APP_BACKEND_URL}/batch/download?form=${uploadModuleSelected}&remove_html=true`;
    try {
      const res = await sendRequest(downloadURL);
      let obj = {};
      res.order.map((item, i) => {
        obj[item] = "";
      });

      const worksheet = utils.json_to_sheet([obj]);
      const workbook = utils.book_new();
      utils.book_append_sheet(workbook, worksheet, "Sheet1");
      writeFile(
        workbook,
        `${tabNameOutToInHandler(uploadModuleSelected)} Template.xlsx`
      );
    } catch (err) {}
  };

  const headers = batchData[0];
  const tableBody = [];
  for (let i = 1; i < batchData.length; i++) {
    const row = {};
    for (let j = 0; j < batchData[i].length; j++) {
      row[headers[j]] = batchData[i][j];
    }
    tableBody.push(row);
  }

  const batchUploadHandler = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (tableBody.length === 0) {
      setIsLoading(false);
      Toast("error", "Please select a file.");
      return;
    }
    const uploadBody = tableBody;
    const uploadURL = `${process.env.REACT_APP_BACKEND_URL}/batch/upload?form=${uploadModuleSelected}&mode=${uploadTypeSelected}&user_id=${display_name}&update_time=${currentDate}`;
    try {
      const res = await sendRequest(
        uploadURL,
        "POST",
        JSON.stringify(uploadBody),
        {
          "Content-Type": "application/json",
        }
      );
      setIsLoading(false);
      if (res.state === "success") {
        Toast("success", "Upload Success.");
      } else {
        Toast("error", res, "Please check the upload file.");
      }
      // alert(res.state ? res.state : res.split(' ').length > 30 ? "Please check upload file" : res);
    } catch (err) {
      Toast("error", err);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>Batch Upload</h4>
      </div>
      <hr />
      <div style={{ margin: "2rem 9rem 2rem 9rem" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "5rem" }}>
          <InputNew
            id="module_upload"
            type="text"
            label="Batch Upload"
            element="select"
            onInput={inputHandler}
            initialValue=""
            validators={[]}
            onRecord={() => {}}
            clean_options={moduleOption}
          />
          <InputNew
            id="type_upload"
            type="text"
            label="Upload Type"
            element="select"
            onInput={inputHandler}
            initialValue=""
            validators={[]}
            onRecord={() => {}}
            origin_options={
              tag.filter(
                (item) =>
                  item.db_tablename === "batch" && item.fe_id === "batch_type"
              )[0]
            }
          />
          <div style={{ paddingTop: "35px" }}>
            <Button
              variant="outlined"
              className="editButton"
              onClick={templateClickHandler}
            >
              Template
            </Button>
          </div>
          <div
            style={{
              paddingTop: "35px",
              paddingRight: "3rem",
              whiteSpace: "nowrap",
            }}
          >
            <Button variant="outlined" className="editButton" component="label">
              Choose File
              <input
                type="file"
                name="upload"
                id="upload"
                onChange={readUploadFile}
                hidden
              />
            </Button>
          </div>
        </div>

        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50%",
            }}
          >
            <CircularProgress />
          </div>
        )}

        {!isLoading && (
          <BatchTable
            tableData={tableBody}
            head={headers || []}
            fileName={`${formState.inputs?.module_upload?.value}_${formattedDate}`}
          />
        )}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            type="button"
            onClick={batchUploadHandler}
            style={{
              marginBottom: 16,
            }}
            variant="outlined"
            className="editButton"
          >
            Upload
          </Button>
        </div>
      </div>
    </div>
  );
}
