import { useState, useEffect, useContext } from "react";
import { CircularProgress, Button } from "@mui/material";
import dayjs from "dayjs";

import AssignmentTableNew from "./AssignmentTableNew";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { UserContext } from "../../context/user-context";
import Toast from "../../components/Utils/Toast";

const Assignment = (props) => {
  const { user } = useContext(UserContext);
  const [assignment, setAssignment] = useState([]);
  const [assignmentData, setAssignmentData] = useState([]);
  const [userData, setUserData] = useState([]);
  const [submitData, setSubmitData] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const { isLoading, sendRequest } = useHttpClient();
  const [isAssignmentLoading, setIsAssignmentLoading] = useState(false);

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const buttonAccess = user["menu access"] || {};
  const indicator = props.record.split("+")[1];

  let form;
  if (props.record.split("+")[0] === "Model") {
    form = "Model";
  } else if (props.record.split("+")[0] === "Policy Exception") {
    form = "Policy";
  } else if (props.record.split("+")[0] === "Attestation") {
    form = "InUse";
  } else if (props.record.split("+")[0] === "Model Change Memo") {
    form = "ModelChange";
  }

  const access = Object.values(buttonAccess)[0]
    .filter((item) => item[0] === "Assignment")
    .map((access) => access[1]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsAssignmentLoading(true);
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/assignment`
        );
        setIsAssignmentLoading(false);
        setAssignment(response.assignment);
        setUserData(response.users);
        setEmailData(response.emails);
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, isChanged]);

  useEffect(() => {
    assignment &&
      setAssignmentData(
        assignment.filter(
          (data) => data.form === form && data.indicator === indicator
        )
      );
    assignment &&
      setSubmitData(
        assignment.filter(
          (data) => data.form === form && data.indicator === indicator
        )
      );
  }, [props.record, assignment]);

  const assignmentChangedHandler = (data) => {
    setSubmitData(
      data.map((i) => ({
        ...i,
        email: userData.find((user) => user[2] === i.assignee)
          ? userData.find((user) => user[2] === i.assignee)[1]
          : "",
        assignee: userData.find((user) => user[2] === i.assignee)
          ? userData.find((user) => user[2] === i.assignee)[0]
          : "",
      }))
    );
  };
  const submitAssignmentDataHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/assignment/submit/${form}/${indicator}`,
        "POST",
        JSON.stringify({
          assignment: submitData.map((item) => ({
            assignee: item.assignee,
            comment: item.comment,
            email: item.email,
            form: form,
            indicator: indicator,
            update_time: currentDate,
            user_group: item.user_group,
          })),
        }),
        {
          "Content-Type": "application/json",
        }
      );
      if (res.assignment) {
        Toast("success", "Assignment Updated.");
      }
      setIsChanged(!isChanged);
    } catch (err) {}
  };

  if (
    !form ||
    !indicator ||
    form === "undefined" ||
    indicator === "undefined"
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
          paddingTop: "10rem",
        }}
      >
        <h2 style={{ color: "#444791" }}>Please select a model</h2>
      </div>
    );
  }

  if (!assignment || isLoading || isAssignmentLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ margin: "1.5rem 8rem 2rem 8rem", minHeight: "8vh" }}>
      <AssignmentTableNew
        assignmentData={assignmentData}
        indicator={props.record.split("+")[0]}
        userData={userData}
        emailData={emailData}
        assignmentDataChanged={assignmentChangedHandler}
      />
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: "5rem" }}
      >
        <Button
          onClick={submitAssignmentDataHandler}
          variant="outlined"
          className="editButton"
          disabled={access.includes("Update") ? false : true}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Assignment;
