import React, { useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import classes from "./FileUploader.module.css";
import uploadImg from "../../assets/cloud-upload-regular-240.png";

import { UserContext } from "../../context/user-context";
import Toast from "../../components/Utils/Toast";

const FileUpload = (props) => {
  const { fileType } = useContext(UserContext);
  const wrapperRef = useRef(null);

  const [fileList, setFileList] = useState([]);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    let type;
    if (newFile) {
      type = newFile.name.split(".").at(-1);

      if (!fileType.includes(type)) {
        Toast("error", "File Type not supported.");
        return;
      }

      props.onFileChange(newFile);
    }
    // const updatedList = [...fileList, newFile];
    // setFileList(updatedList);
  };

  const fileRemove = (file) => {
    const updatedList = [...fileList];
    updatedList.splice(fileList.indexOf(file), 1);
    setFileList(updatedList);
    props.onFileChange(updatedList);
  };

  return (
    <div
      ref={wrapperRef}
      className={classes["drop-file-input"]}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      <div className={classes["drop-file-input__label"]}>
        <img src={uploadImg} alt="" style={{ width: "20%" }} />
        <p style={{ fontSize: "1rem" }}>Drag & Drop File Here</p>
      </div>
      <input
        type="file"
        accept={fileType}
        value=""
        onChange={onFileDrop}
        disabled={props.disabled}
      />
    </div>
  );
};

FileUpload.propTypes = {
  onFileChange: PropTypes.func,
};

export default FileUpload;
