import { useEffect, useState } from "react";

import HistoryTable from "./HistoryTable";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { CircularProgress } from "@mui/material";
import dayjs from "dayjs";

const History = () => {
  const [historyData, setHistoryData] = useState([]);
  const { sendRequest } = useHttpClient();
  const [isLoading, setIsLoading] = useState(true);

  const formattedDate = dayjs().format("YYYYMMDD");

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/history`
        );
        setIsLoading(false);
        setHistoryData(response);
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10%",
          minHeight: "85vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!historyData || historyData.length === 0) {
    return (
      <div style={{ marginTop: "1rem", minHeight: "89vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "85vh",
          }}
        >
          <h4>No History Log</h4>
        </div>
      </div>
    );
  }

  return (
    <div>
    <div style={{ marginTop: "1rem" }}>
      {historyData.length !== 0 && (
        <div>
          <div
            style={{ display: "flex", gap: "15rem", justifyContent: "center", marginTop: "2rem" }}
          >
            <h4>History Log</h4>
          </div>
          <hr />  
          <div style={{ margin: "4rem 8rem 1rem 8rem" }}>
            <HistoryTable
              tableData={historyData}
              isCreateNotShow={false}
              fileName={`History Log_${formattedDate}`}
            />
          </div>
        </div>
      )}
    </div>
    </div>
  );
};
export default History;
