import React from 'react'

import { Box } from '@mui/material'
// DEVELOPMENT
import ReactSpeedometer from "react-d3-speedometer"
// PRODUCTION: switch to dist for checking production version
// import ReactSpeedometer from "../../dist/index"

import { ColorTransfer } from "../../../components/Content/FormElements/OpmMetric2";

const CustomSegmentLabels = (props) => {

  if(!props.gaugeData) {
    return (
      <Box
        sx={{textAlign: 'center', width: '100%'}}
      >
        <ReactSpeedometer
          width={500}
          segments={3}
          segmentColors={['#00B050', '#FFBF00', '#FF0000']}
          minValue={0}
          maxValue={1}
          customSegmentStops={[0, 0.5, 0.7, 1]}
          value={0}
          needleColor="steelblue"
        />
      </Box>
    )
  }

  const colorList = props.gaugeData?.map((item) => ColorTransfer(item?.color));

  let segmentLabels = [];
  
  if(!Array.isArray(segmentLabels) || segmentLabels?.filter(i => typeof Number(i) !== "number")?.length > 0) {
    return <div>Invalid segment labels</div>
  };
 
  if(Array.isArray(props.gaugeData)) {
    segmentLabels = props?.gaugeData?.map((item, i) => +item?.lower_bound);
  }

  segmentLabels = [...segmentLabels, +props.gaugeData[props.gaugeData.length - 1]?.upper_bound];
 
  if(typeof segmentLabels[0] !== "number" || isNaN(segmentLabels[0])) {
    return <div>Invalid segment labels</div>
  }

  return (
  <Box
  sx={{textAlign: 'center', width: '100%'}}
>
  <ReactSpeedometer
    width={500}
    segments={3}
    segmentColors={colorList}
    minValue={typeof +segmentLabels[0] === "number" && !isNaN(segmentLabels[0]) ? +segmentLabels[0] : 0}
    maxValue={typeof +segmentLabels[segmentLabels.length - 1] === "number" && !isNaN(segmentLabels[segmentLabels.length - 1]) ? +segmentLabels[segmentLabels.length - 1] : 100}
    customSegmentStops={segmentLabels}
    value={(typeof +props.value === "number" && !isNaN(+props.value))? props.value : 0}
    needleColor="steelblue"
  />
</Box>)
  }
export default CustomSegmentLabels