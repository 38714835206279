import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import InputNew from "../../components/Content/FormElements/InputNew";
import { UserContext } from "../../context/user-context";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import {
  Button,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  CircularProgress,
  Modal,
  Backdrop,
  Fade,
} from "@mui/material";
import Toast from "../../components/Utils/Toast";

import "./TaskConfig.css";
import _ from "lodash";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "70%",
  bgcolor: "background.paper",
  border: "1px solid rgba(224, 224, 224, 1)",
  borderRadius: "5px",
  boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  p: 4,
  overflow: "auto",
};

const TaskConfig = (props) => {
  const { error, sendRequest, clearError } = useHttpClient();
  const { user, tabNameOutToInHandler, tabNameInToOutHandler } =
    useContext(UserContext);
  const [formState, inputHandler] = useForm({}, false);
  const [options, setOptions] = useState({});
  const [statusOption, setStatusOption] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [task, setTask] = useState([]);
  const [taskSelected, setTaskSelected] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState({
    "due descriptor": [],
    operation: [],
  });
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { display_name } = user || {};

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/task`
        );
        setIsLoading(false);
        setTask(response.task);
        setOptions(response.option);
      } catch (err) {
        console.warn(err);
      }
    };
    fetchData();
  }, [sendRequest, isChanged]);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleSelectionClick = (event, index) => {
    setSelectedIndex(index);

    setTaskSelected(
      task.filter(
        (item) =>
          item.form === index.split(" -- ")[0] &&
          item.operation === index.split(" -- ")[1] &&
          item.user_group === index.split(" -- ")[2]
      )[0]
    );
  };

  useEffect(() => {
    options &&
      setCurrentForm(
        options[
          `${tabNameInToOutHandler(formState.inputs?.form_task_config?.value)}`
        ] || {
          "due descriptor": [],
          operation: [],
        }
      );
  }, [formState.inputs?.form_task_config?.value, options, taskSelected]);

  useEffect(() => {
    options &&
      setCurrentForm(
        options[
          `${tabNameInToOutHandler(
            formState.inputs?.form_task_config_new?.value
          )}`
        ] || {
          "due descriptor": [],
          operation: [],
        }
      );
  }, [formState.inputs?.form_task_config_new?.value, options]);

  const addNewTaskHandler = async (e) => {
    e.preventDefault();
    if (
      !formState.inputs?.form_task_config_new?.value ||
      formState.inputs?.form_task_config_new?.value === "" ||
      !formState.inputs?.user_group_task_config_new?.value ||
      formState.inputs?.user_group_task_config_new?.value === "" ||
      !formState.inputs?.status_task_config_new?.value ||
      formState.inputs?.status_task_config_new?.value === ""
    ) {
      Toast("error", "Please fill in User Group, Task and Operation.");
      return;
    }
    try {
      const body = {
        form:
          tabNameInToOutHandler(
            formState.inputs?.form_task_config_new?.value
          ) || "",
        user_group: formState.inputs?.user_group_task_config_new?.value || "",
        due_descriptor:
          currentForm["due descriptor"] &&
          formState.inputs?.due_task_config_new.value !== "NULL" &&
          currentForm["due descriptor"].filter(
            (i) => i[0] === formState.inputs?.due_task_config_new?.value
          )[0]
            ? currentForm["due descriptor"].filter(
                (i) => i[0] === formState.inputs?.due_task_config_new?.value
              )[0][1]
            : "",
        notification_day:
          formState.inputs?.notification_day_config_new?.value || 0,
        operation: formState.inputs?.status_task_config_new?.value || "",
        update_time: currentDate || "",
        user_id: display_name || "",
      };
      setIsLoading(true);
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/user/task/create`,
        "POST",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      setIsLoading(false);
      if (!res.task) {
        alert(res, 3);
        return;
      } else {
        alert("Update success.", 3);
        setModalOpen(false);
      }
      //alert("Table Created.", 3);
      setIsChanged((prev) => !prev);
    } catch (err) {
      alert(err, 3);
    }
  };

  const updateTaskHandler = async (e) => {
    e.preventDefault();
    if (_.isEmpty(taskSelected)) {
      Toast("error", "Please select a Task.");
      return;
    }
    try {
      const body = {
        form:
          tabNameInToOutHandler(formState.inputs.form_task_config.value) || "",
        user_group: formState.inputs.user_group_task_config.value || "",
        due_descriptor:
          currentForm["due descriptor"] &&
          formState.inputs.due_task_config.value !== "NULL" &&
          currentForm["due descriptor"].filter(
            (i) => i[0] === formState.inputs.due_task_config.value
          )[0]
            ? currentForm["due descriptor"].filter(
                (i) => i[0] === formState.inputs.due_task_config.value
              )[0][1]
            : "",
        notification_day: formState.inputs.notification_day_config.value || "",
        operation: formState.inputs.status_task_config.value || "",
        update_time: currentDate || "",
        user_id: display_name || "",
      };
      setIsLoading(true);
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/user/task/update`,
        "PUT",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      setIsLoading(false);
      if (!res.task) {
        alert(res, 3);
        return;
      } else {
        alert("Update success.", 3);
        setModalOpen(false);
      }
      setSelectedIndex("");
      setTaskSelected({});
      setIsChanged((prev) => !prev);
    } catch (err) {
      alert(err, 3);
    }
  };

  const deleteTaskHandler = async (e) => {
    e.preventDefault();
    if (_.isEmpty(taskSelected)) {
      Toast("error", "Please select a Task.");
      return;
    }
    try {
      const body = {
        user_group: taskSelected.user_group,
        form: tabNameInToOutHandler(taskSelected.form),
        operation: taskSelected.operation,
      };
      setIsLoading(true);
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/user/task/delete`,
        "DELETE",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      setIsLoading(false);
      if (!res.task) {
        alert(res, 3);
        return;
      } else {
        alert("Delete success.", 3);
        setModalOpen(false);
      }
      setSelectedIndex("");
      setTaskSelected({});
      setIsChanged((prev) => !prev);
    } catch (err) {
      alert(err, 3);
    }
  };

  if (!taskSelected) {
    setTaskSelected({});
  }

  let element = taskSelected ? (
    <div>
      <div className="task_config-1">
        <div style={{ gap: "3rem", width: "220%" }}>
          <InputNew
            id="user_group_task_config"
            label="User Group:"
            element="select"
            validators={[]}
            onInput={inputHandler}
            initialValue={taskSelected.user_group}
            onBlur
            clean_options={options ? options.user_group : []}
            disabled
          />
          <InputNew
            id="form_task_config"
            label="Task:"
            element="select"
            validators={[]}
            onInput={inputHandler}
            initialValue={taskSelected.form}
            onBlur
            clean_options={
              options
                ? Object.keys(options).filter((i) => i !== "user_group")
                : []
            }
            disabled
          />
          <InputNew
            id="status_task_config"
            label="Operation:"
            element="select"
            initialValue={
              taskSelected && tabNameOutToInHandler(taskSelected.operation)
            }
            validators={[]}
            onInput={inputHandler}
            onBlur
            clean_options={currentForm && currentForm.operation}
            disabled
          />
          <InputNew
            id="due_task_config"
            label="Due Reference: "
            element="select"
            validators={[]}
            onInput={inputHandler}
            initialValue={
              taskSelected.due_descriptor !== ""
                ? taskSelected.due_descriptor &&
                  currentForm["due descriptor"].filter(
                    (i) => i[1] === taskSelected.due_descriptor
                  )[0] &&
                  currentForm["due descriptor"].filter(
                    (i) => i[1] === taskSelected.due_descriptor
                  )[0][0]
                : "NULL"
            }
            onBlur
            clean_options={
              currentForm && currentForm["due descriptor"][0]
                ? [...currentForm["due descriptor"].map((i) => i[0]), "NULL"]
                : []
            }
          />
          <InputNew
            id="notification_day_config"
            type="number"
            label="Notification start before due date (days):"
            element="input"
            initialValue={
              (formState.inputs?.due_task_config
                ? formState.inputs?.due_task_config?.value
                : "") === "NULL"
                ? ""
                : taskSelected.notification_day
            }
            validators={[]}
            onInput={inputHandler}
            onBlur
            disabled={
              (formState.inputs?.due_task_config
                ? formState.inputs.due_task_config?.value
                : "") === "NULL"
            }
          />
        </div>
      </div>
    </div>
  ) : (
    ""
  );

  if (isLoading || !task || !options) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>Task Configuration</h4>
      </div>
      <hr />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={modalStyle}>
            <div>
              <form>
                <div>
                  <div className="task_config-1">
                    <div
                      style={{
                        gap: "3rem",
                        width: "220%",
                        marginTop: "1rem",
                      }}
                    >
                      <InputNew
                        id="user_group_task_config_new"
                        label="User Group:"
                        element="select"
                        validators={[]}
                        onInput={inputHandler}
                        onBlur
                        clean_options={options ? options.user_group : []}
                      />
                      <InputNew
                        id="form_task_config_new"
                        label="Task:"
                        element="select"
                        validators={[]}
                        onInput={inputHandler}
                        onBlur
                        clean_options={
                          options
                            ? Object.keys(options)
                                .filter((i) => i !== "user_group")
                                .map((opt) => tabNameOutToInHandler(opt))
                            : []
                        }
                      />
                      <InputNew
                        id="status_task_config_new"
                        label="Operation:"
                        element="select"
                        validators={[]}
                        onInput={inputHandler}
                        onBlur
                        clean_options={currentForm && currentForm.operation}
                      />
                      <InputNew
                        id="due_task_config_new"
                        label="Due Reference: "
                        element="select"
                        validators={[]}
                        onInput={inputHandler}
                        onBlur
                        clean_options={
                          currentForm && currentForm["due descriptor"][0]
                            ? [
                                ...currentForm["due descriptor"].map(
                                  (i) => i[0]
                                ),
                                "NULL",
                              ]
                            : []
                        }
                      />
                      <InputNew
                        id="notification_day_config_new"
                        type="number"
                        label="Notification start before due date(days):"
                        element="input"
                        validators={[]}
                        onInput={inputHandler}
                        onBlur
                        disabled={
                          (formState.inputs?.due_task_config_new
                            ? formState.inputs?.due_task_config_new?.value
                            : "") === "NULL"
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="task_config-button">
                  <Button
                    variant="outlined"
                    className="editButton"
                    onClick={addNewTaskHandler}
                  >
                    Add New Task
                  </Button>
                  <Button
                    variant="outlined"
                    className="editButton"
                    onClick={handleClose}
                  >
                    Exit
                  </Button>
                </div>
              </form>
            </div>
          </Box>
        </Fade>
      </Modal>

      <div
        className="task_config-layout"
        style={{ margin: "2rem 9rem 2rem 9rem" }}
      >
        <div className="task_config-option">
          {task.map((item, i) => (
            <ListItem disablePadding key={i}>
              <ListItemButton
                selected={
                  selectedIndex ===
                  `${item.form} -- ${item.operation} -- ${item.user_group}`
                }
                onClick={(event) => {
                  handleSelectionClick(
                    event,
                    `${item.form} -- ${item.operation} -- ${item.user_group}`
                  );
                }}
              >
                <ListItemText
                  primary={`${item.form} -- ${item.operation} -- ${item.user_group}`}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </div>
        <form className="task_config-container">
          <Button
            variant="outlined"
            className="editButton"
            onClick={handleOpen}
          >
            New
          </Button>
          {element}
          <div className="task_config-button">
            <Button
              variant="outlined"
              className="editButton"
              onClick={updateTaskHandler}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              className="editButton"
              onClick={deleteTaskHandler}
            >
              Delete
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TaskConfig;
