import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";

import dayjs from "dayjs";

// chart options


// ==============================|| MONTHLY BAR CHART ||============================== //

const DonutChart = (props) => {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const info = theme.palette.info.light;

  const [series, setSeries] = useState([80, 95, 70, 42, 65, 55, 78]);

  const formattedDate = dayjs().format("YYYYMMDD");

  useEffect(() => {
    setSeries(props.chartData);
  }, [props.chartData]);

  const options = {
    chart: {
      width: 380,
      type: "donut",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: `${props.chartTitle}_${formattedDate}`,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: `${props.chartTitle}_${formattedDate}`,
          },
          png: {
            filename: `${props.chartTitle}_${formattedDate}`,
          },
        },
        autoSelected: "zoom",
      },
    },
    labels: props.chartLabels,
    tooltip: { theme: "light" },
    legend: { position: "bottom", show: true },
    dataLabels: {
      enable: true,
      formatter: function (val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name, val.toFixed(1) + "%"];
      },
      textAnchor: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "11px",
        colors: ["black"],
      },
      dropShadow: {
        enabled: false,
        top: 1,
        left: 1,
        blur: 1,
        color: "#000",
        opacity: 0.45,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 300,
          },
          legend: {
            position: "bottom",
            show: false,
          },
        },
      },
    ],
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        width="100%"
        height={props.chartHeight}
      />
    </div>
  );
};

export default DonutChart;
