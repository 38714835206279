import { useState, useEffect, useContext } from "react";
import { writeFile, utils } from "xlsx";
import {
  Button,
  CircularProgress,
  Switch,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import dayjs from "dayjs";

import { useForm } from "../../components/Utils/hooks/form-hook";
import InputNew from "../../components/Content/FormElements/InputNew";
import BatchTable from "./BatchTable";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { UserContext } from "../../context/user-context";

export default function BatchDownload({ tag, type }) {
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const { user, tabNameOutToInHandler, tabNameInToOutHandler } =
    useContext(UserContext);
  const [batchData, setBatchData] = useState([]);
  const [downModuleModuleSelected, setDownloadModuleSelected] = useState("");
  const [order, setOrder] = useState([]);
  const [formState, inputHandler] = useForm(
    {
      module_upload: {
        value: "",
        isValid: true,
      },
      type_upload: {
        value: "",
        isValid: true,
      },
      module_download: {
        value: "",
        isValid: true,
      },
    },
    false
  );
  const [checked, setChecked] = useState(false);

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  const formattedDate = dayjs().format("YYYYMMDD");

  let moduleOption;
  if (
    tag &&
    tag.filter(
      (item) => item.db_tablename === "batch" && item.fe_id === "view_name"
    )[0]
  ) {
    moduleOption = tag
      .filter(
        (item) => item.db_tablename === "batch" && item.fe_id === "view_name"
      )[0]
      ?.group?.split(",")
      ?.map((item) => tabNameOutToInHandler(item));
  }

  const downloadExcel = (batchData) => {
    const worksheet = utils.json_to_sheet(batchData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    writeFile(workbook, "DataSheet.xlsx");
  };

  useEffect(() => {
    const fetchData = async () => {
      const downloadURL = `${
        process.env.REACT_APP_BACKEND_URL
      }/batch/download?form=${downModuleModuleSelected}&remove_html=${!checked}`;
      try {
        const res = await sendRequest(downloadURL);
        res.data && Array.isArray(res.data) && setBatchData(res.data);
        res && res.order && setOrder(res.order);
      } catch (err) {}
    };
    if (downModuleModuleSelected) {
      fetchData();
    }
  }, [sendRequest, downModuleModuleSelected, checked]);

  useEffect(() => {
    setDownloadModuleSelected(
      tabNameInToOutHandler(formState.inputs?.module_download?.value)
    );
  }, [formState.inputs?.module_download.value]);

  let downloadData = [];
  batchData.map((batch) => {
    let obj = {};
    order.map((key, index) => (obj[key] = batch[key]));
    downloadData.push(obj);
  });

  const batchDownloadHandler = async (e) => {
    e.preventDefault();
    downloadExcel(downloadData);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>Batch Download</h4>
      </div>
      <hr />
      <div style={{ margin: "2rem 9rem 2rem 9rem" }}>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
            gap: "3rem",
            width: "50% ",
          }}
        >
          <InputNew
            id="module_download"
            type="text"
            label="Batch Download"
            element="select"
            onInput={inputHandler}
            initialValue=""
            validators={[]}
            onRecord={() => {}}
            clean_options={moduleOption}
          />
          <FormGroup aria-label="position" row sx={{ width: "100%" }}>
            <FormControlLabel
              value="top"
              control={
                <Switch
                  color="primary"
                  checked={checked}
                  onChange={handleSwitchChange}
                />
              }
              label="HTML Format"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "1rem",
                  fontWeight: "bold",
                },
                "& .MuiSwitch-root": {
                  mt: 1,
                },
              }}
              labelPlacement="top"
            />
          </FormGroup>
        </div>

        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5rem",
              height: "50%",
            }}
          >
            <CircularProgress />
          </div>
        )}

        <BatchTable
          tableData={Array.isArray(batchData) ? batchData : []}
          head={order.length > 0 ? order : []}
          fileName={`${formState.inputs?.module_download?.value}_${formattedDate}`}
        />
      </div>
    </div>
  );
}
