import React, { Fragment, useState, useEffect } from "react";
import { gridClasses } from "@mui/x-data-grid";
import { Menu, MenuItem, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

import WrapTextTable, {
  renderCellExpand,
} from "../../../components/CustomComponents/WrapTextTable";

const PopUpTable = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);

  useEffect(() => {
    setRows(props.tableData);
    setIsLoading(false);
  }, [props.tableData]);

  const columns = props.isNew
    ? [
        {
          field: "attribute",
          headerName: "Attribute",
          flex: 1,
          headerAlign: "center",
          align: "left",
          renderCell: renderCellExpand,
        },
        {
          field: "current",
          headerName: "Value",
          flex: 1,
          headerAlign: "center",
          align: "center",
          renderCell: renderCellExpand,
        },
      ]
    : [
        {
          field: "id",
          headerName: "ID",
          width: 50,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "attribute",
          headerName: "Attribute",
          width: 200,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "previous",
          headerName: "Previous",
          width: 360,
          headerAlign: "center",
          align: "center",
        },
        {
          field: "current",
          headerName: "Current",
          width: 360,
          headerAlign: "center",
          align: "center",
        },
      ];

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertToUppercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toUpperCase(),
          email: row.email.toUpperCase(),
          title: row.title.toUpperCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const convertToLowercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toLowerCase(),
          email: row.email.toLowerCase(),
          title: row.title.toLowerCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };
  // different color in even line and hover effect
  const datagridSx = {
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "rgb(250,250,250)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgb(221,225,255)",
      },
    },
  };
  return (
    <Fragment>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <div>
          <h4 style={{ textAlign: "center", marginBottom: "1.5rem" }}>
            Data Verification and Submission
          </h4>
          <div style={{ width: "100%" }}>
            <WrapTextTable
              autoHeight
              rowHeight={30}
              sx={datagridSx}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              columns={columns}
              rows={rows}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              rowsPerPageOptions={[]}
            />

            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
              componentsProps={{
                root: {
                  onContextMenu: (e) => {
                    e.preventDefault();
                    handleClose();
                  },
                },
              }}
            >
              <MenuItem onClick={convertToUppercase}>UPPERCASE</MenuItem>
              <MenuItem onClick={convertToLowercase}>lowercase</MenuItem>
            </Menu>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default PopUpTable;
