import { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Box,
  Autocomplete,
  TextField,
  Popper,
  Paper,
  InputBase,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridCellModes,
  GridActionsCellItem,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";
import { UserContext } from "../../../context/user-context";
import uniq from "lodash/uniq";

import WrapTextTable, {
  renderCellExpand,
} from "../../CustomComponents/WrapTextTable";

const columnTypeHandler = (type) => {
  if (type === "select") {
    return "singleSelect";
  }

  if (type === "textArea") {
    return "string";
  }

  return type;
};

const EditTextarea = (props) => {
  const { id, field, value, colDef, rowHeightHandler, hasFocus } = props;
  const [valueState, setValueState] = useState(value);
  const [anchorEl, setAnchorEl] = useState();
  const apiRef = useGridApiContext();

  const handleRef = useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const inputRef = useCallback((el) => {
    const element = document.getElementById("textareaCell");
    if (element && element !== null) {
      try {
        rowHeightHandler([id, element.scrollHeight]);
      } catch (err) {
        console.warning(err);
        rowHeightHandler([id, element.scrollHeight]);
      }
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById("textareaCell");
    if (element && element !== null) {
      rowHeightHandler([id, element.scrollHeight]);
    }
  }, [valueState, field]);

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    },
    [apiRef, field, id]
  );

  useEffect(() => {
    if (!hasFocus) {
      rowHeightHandler([id, 30]);
    }
  }, [hasFocus]);

  const handleKeyDown = useCallback(
    (event) => {
      if (
        event.key === "Escape" ||
        (event.key === "Enter" &&
          !event.shiftKey &&
          (event.ctrlKey || event.metaKey))
      ) {
        const params = apiRef.current.getCellParams(id, field);
        apiRef.current.publishEvent("cellKeyDown", params, event);
      }
    },
    [apiRef, id, field]
  );

  return (
    <InputBase
      multiline
      value={valueState}
      sx={{ width: "100%", padding: 0 }}
      onChange={handleChange}
      autoFocus
      inputRef={inputRef}
      id="textareaCell"
    />
  );
};

const EditAutocomplete = (props) => {
  const {
    id,
    field,
    value,
    colDef,
    userGroup,
    modelIdGroup,
    tableId,
    attendanceValueOptions,
    fe_id,
    group,
    rowHeightHandler,
    hasFocus,
  } = props;
  const [valueState, setValueState] = useState(value);
  const [anchorEl, setAnchorEl] = useState();
  const apiRef = useGridApiContext();

  const handleRef = useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const inputRef = useCallback((el) => {
    if (el === null) return;
    const element = document.getElementById("autoCompleteCell");
    if (element && element !== null) {
      try {
        rowHeightHandler([id, element.scrollHeight]);
      } catch (err) {
        console.warning(err);
        rowHeightHandler([id, element.scrollHeight]);
      }
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById("autoCompleteCell");
    if (element && element !== null) {
      rowHeightHandler([id, element.scrollHeight]);
    }
  }, [valueState, field]);

  useEffect(() => {
    if (!hasFocus) {
      rowHeightHandler([id, 30]);
    }
  }, [hasFocus]);

  const handleChange = useCallback(
    (event, value) => {
      const newValue = value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    },
    [apiRef, field, id]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (
        event.key === "Escape" ||
        (event.key === "Enter" &&
          !event.shiftKey &&
          (event.ctrlKey || event.metaKey))
      ) {
        const params = apiRef.current.getCellParams(id, field);
        apiRef.current.publishEvent("cellKeyDown", params, event);
      }
    },
    [apiRef, id, field]
  );

  return (
    <div style={{ position: "relative", alignSelf: "flex-start" }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper
            elevation={5}
            sx={{ p: 0, minWidth: colDef.computedWidth }}
            id="autoCompleteCell"
          >
            {tableId !== "attendance" && (
              <Autocomplete
                multiple={tableId === "attendance" ? false : true}
                id="tags-filled"
                options={
                  tableId === "mac_attendance"
                    ? uniq(userGroup)
                    : fe_id === "code"
                    ? modelIdGroup
                    : group
                }
                disableCloseOnSelect
                value={valueState}
                sx={{ width: colDef.computedWidth, p: 0, m: 0 }}
                freeSolo={props.field === "attendee"}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" size="small" />
                )}
                ref={inputRef}
              />
            )}
            {tableId === "attendance" && (
              <Autocomplete
                multiple={tableId === "attendance" ? false : true}
                id="tags-filled"
                options={
                  tableId === "mac_attendance"
                    ? userGroup
                    : tableId === "memo"
                    ? modelIdGroup
                    : attendanceValueOptions
                }
                disableCloseOnSelect
                inputValue={valueState}
                sx={{ width: colDef.computedWidth, p: 0, m: 0 }}
                freeSolo={props.field === "attendee"}
                onInputChange={handleChange}
                onKeyDown={handleKeyDown}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" size="small" />
                )}
                ref={inputRef}
              />
            )}
          </Paper>
        </Popper>
      )}
    </div>
  );
};

function updateRowPosition(initialIndex, newIndex, rows) {
  return new Promise((resolve) => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

export default function MacTable(props) {
  const { tag } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [cellModesModel, setCellModesModel] = useState({});
  const [count, setCount] = useState(
    rows.length > 0 ? Math.max(...rows.map((i) => i.key)) + 1 : 1
  );
  const [rowHeight, setRowHeight] = useState(["id", 30]);
  const [isLoading, setLoading] = useState(false);

  const [tableHeight, setTableHeight] = useState("100px");

  useEffect(() => {
    if (rows.length === 0) {
      setTableHeight("100px");
    } else {
      setTableHeight("auto");
    }
  }, [rows]);

  const rowHeightHandler = (height) => {
    setRowHeight(height);
  };

  const memoTag = tag.filter(
    (i) => i.db_tablename === "mac memo" && i.fe_isrender === true
  );
  const stateTag = tag.filter(
    (i) => i.db_tablename === "mac state" && i.fe_isrender === true
  );
  const attendanceTag = tag.filter(
    (i) => i.db_tablename === "mac role" && i.fe_isrender === true
  );
  const mac_attendanceTag = tag.filter(
    (i) => i.db_tablename === "mac attendance" && i.fe_isrender === true
  );

  const autoCompleteColumn = (id, attendanceValueOptions, fe_id, group) => {
    return {
      type: "string",
      renderEditCell: (params) => (
        <EditAutocomplete
          {...params}
          modelIdGroup={props.modelIdGroup}
          userGroup={props.userGroup}
          tableId={id}
          attendanceValueOptions={attendanceValueOptions}
          fe_id={fe_id}
          group={group}
          rowHeightHandler={rowHeightHandler}
        />
      ),
    };
  };

  const multilineColumn = {
    type: "string",
    renderEditCell: (params) => (
      <EditTextarea {...params} rowHeightHandler={rowHeightHandler} />
    ),
  };

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        isNew: true,
      },
    ]);

    // setCellModesModel((oldModel) => ({
    //   ...oldModel,
    //   [id]: {
    //     [props.id === "memo" ? "" : ""]: { mode: GridCellModes.Edit },
    //   },
    // }));
  };

  useEffect(() => {
    props.tableData &&
      setRows(
        props.tableData.map((data, i) => {
          return {
            ...data,
            id: data.id ? data.id : randomId(),
            __reorder__: Object.values(data) ? Object.values(data)[0] : i + 1,
            isNew: false,
          };
        })
      );
    setRowHeight(["id", 30]);
  }, [props.tableData]);

  const handleCellClick = useCallback((params) => {
    if (!params.isEditable) {
      return;
    }
    if (params.field === "actions") {
      return;
    }
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    props.tableDataChanged(rows.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    props.tableDataChanged(
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const handleRowOrderChange = async (params) => {
    setLoading(true);
    const newRows = await updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );

    setRows(newRows);
    props.tableDataChanged(newRows);
    setLoading(false);
  };

  let columns = [];
  if (props.id === "memo") {
    columns = memoTag
      .sort((a, b) => a.fe_order - b.fe_order)
      .map((tag) => {
        if (tag.fe_element === "autoComplete") {
          return {
            field: tag.fe_id,
            headerName: tag.fe_label,
            headerAlign: "center",
            flex: tag.fe_grid,
            editable: true,
            ...autoCompleteColumn(
              props.id,
              undefined,
              tag.fe_id,
              tag.group.split(",")
            ),
            renderCell: renderCellExpand,
          };
        } else {
          return {
            field: tag.fe_id,
            headerName: tag.fe_label,
            type: columnTypeHandler(tag.fe_element),
            headerAlign: "center",
            flex: tag.fe_grid,
            editable: true,
            valueOptions: tag.group.split(","),
          };
        }
      });
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 5,
        headerAlign: "center",
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      },
    ];
  } else if (props.id === "state") {
    columns = stateTag
      .sort((a, b) => a.fe_order - b.fe_order)
      .map((tag) => {
        if (tag.fe_element === "textarea") {
          return {
            field: tag.fe_id,
            headerName: tag.fe_label,
            type: columnTypeHandler(tag.fe_element),
            headerAlign: "center",
            flex: tag.fe_grid,
            editable: true,
            renderCell: renderCellExpand,
            ...multilineColumn,
          };
        } else {
          return {
            field: tag.fe_id,
            headerName: tag.fe_label,
            type: columnTypeHandler(tag.fe_element),
            headerAlign: "center",
            flex: tag.fe_grid,
            editable: true,
            valueOptions: tag.group.split(","),
            renderCell:
              tag.fe_element === "date" ? undefined : renderCellExpand,
          };
        }
      });
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 3,
        headerAlign: "center",
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      },
    ];
  } else if (props.id === "mac_attendance") {
    columns = mac_attendanceTag
      .sort((a, b) => a.fe_order - b.fe_order)
      .map((tag) => {
        if (tag.fe_element === "autoFill") {
          return {
            field: tag.fe_id,
            headerName: tag.fe_label,
            headerAlign: "center",
            flex: tag.fe_grid,
            editable: true,
            ...autoCompleteColumn(props.id),
            renderCell: (params) => {
              return (
                <div>
                  {Array.isArray(params.value)
                    ? params.value.join("; ")
                    : params.value}
                </div>
              );
            },
          };
        } else {
          return {
            field: tag.fe_id,
            headerName: tag.fe_label,
            type: columnTypeHandler(tag.fe_element),
            headerAlign: "center",
            flex: tag.fe_grid,
            editable: true,
            valueOptions: tag.group.split(","),
          };
        }
      });
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 3,
        headerAlign: "center",
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      },
    ];
  } else if (props.id === "attendance") {
    columns = attendanceTag
      .sort((a, b) => a.fe_order - b.fe_order)
      .map((tag) => {
        if (tag.fe_element === "autoFill") {
          const attendanceValueOptions = props?.mac_attendanceData?.map(
            (i) => i?.attendee
          )
            ? props?.mac_attendanceData?.map((i) => i?.attendee)
            : [];
          return {
            field: tag.fe_id,
            headerName: tag.fe_label,
            headerAlign: "center",
            flex: tag.fe_grid,
            editable: true,
            ...autoCompleteColumn(props.id, attendanceValueOptions),
            renderCell: (params) => {
              return (
                <div>
                  {Array.isArray(params.value)
                    ? params.value.join("; ")
                    : params.value}
                </div>
              );
            },
          };
        } else {
          return {
            field: tag.fe_id,
            headerName: tag.fe_label,
            type: columnTypeHandler(tag.fe_element),
            headerAlign: "center",
            flex: tag.fe_grid,
            editable: true,
            valueOptions: tag.group.split(","),
          };
        }
      });
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 3,
        headerAlign: "center",
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      },
    ];
  }

  return (
    <Box>
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            fontWeight: "bold",
            color: "#444791",
            marginBottom: "-3rem",
          }}
        >
          {props.label}
        </div>
      </div>

      <Button
        variant="outlined"
        className="editButton"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Add record
      </Button>

      <WrapTextTable
        headerHeight={40}
        getRowHeight={(params) => {
          if (params.id === rowHeight[0]) {
            return rowHeight[1] + 6;
          } else {
            return 30;
          }
        }}
        autoHeight
        rows={rows}
        columns={columns}
        isCellEditable={(params) => !props.disabled}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        hideFooter={true}
        showCellRightBorder={true}
        sx={{
          boxShadow: "none",
        }}
        onCellKeyDown={(params, event) => {
          if (event.key === "Enter") {
            event.stopPropagation();
          }
        }}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        isLoading={isLoading}
        style={{ height: tableHeight }}
      />
    </Box>
  );
}
