import { useState, useContext, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";

import Toast from "../../components/Utils/Toast";

import InputNew from "../../components/Content/FormElements/InputNew";
import FileLoad from "../../components/CustomComponents/FileLoad";
import {
  CircularProgress,
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import PopUpTable from "../IssuePage/NewIssuePage/PopUpTable";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { UserContext } from "../../context/user-context";
import { isTwoJsonValueEqual } from "../../components/CustomComponents/GeneralPageTemplate";

function checkIfDuplicateExists(arr) {
  return new Set(arr).size !== arr.length;
}

function checkIfEmptyExists(arr) {
  for (const i of arr) {
    if (!i || i.trim() === "") {
      return true;
    }
  }
}

const NewMac = ({
  tag,
  isTreeChanged,
  modelData,
  setScrollTop,
  pageElement,
}) => {
  const {
    user,
    token,
    userGroup,
    macFileTypeInToOutHandler,
    macFileType,
    isPageDataChangedHandler,
    isPageDataNotChangedHandler,
  } = useContext(UserContext);
  const { isLoading, sendRequest } = useHttpClient();
  const { name } = useParams();
  const [files, setFiles] = useState([]);
  const [newDocument, setNewDocument] = useState([]);
  const [open, setOpen] = useState(false);
  const [formDataObj, setFormDataObj] = useState({});
  const [tableData, setTableData] = useState([]);
  const [buttonOperation, setButtonOperation] = useState("");
  const [attendanceData, setAttendanceData] = useState([]);
  const [initialFormData, setInitialFormData] = useState({});

  const [formState, inputHandler] = useForm(
    {
      issue_id: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const { display_name } = user || {};
  const status_option = Object.values(user["group access"]) || [];

  const modelIdGroup = modelData?.map((item) => item.code);

  let tag2, fileOption;
  if (tag) {
    tag2 = tag.filter((item) => item.db_tablename === "mac");
    fileOption = tag.filter(
      (item) => item.db_tablename === "documents" && item.fe_id === "file_type"
    );
  } else {
    tag2 = [];
    fileOption = [];
  }

  if (tag) {
    tag = tag.filter(
      (item) => item.db_tablename === "mac" && item.fe_isrender === true
    );
  } else {
    tag = [];
  }

  const history = useHistory();

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const buttonAccess = user["menu access"] || {};
  const access = Object.values(buttonAccess)[0]
    .filter((item) => item[0] === "mac")
    .map((access) => access[1]);

  useEffect(() => {
    const fetchMacAttendanceOption = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/mac?mac_name=${name}`
        );
        response?.["attendance option"] &&
          setAttendanceData(response?.["attendance option"]);
      } catch (err) {}
    };
    fetchMacAttendanceOption();
  }, [sendRequest, name]);

  useEffect(() => {
    setInitialFormData(formState.inputs);
  }, []);

  const fileTypeCheck = () => {
    if (Array.from(newDocument, (x) => x.file_type).includes("")) {
      Toast("error", "Please specify the File Type.");
      return false;
    }
    return true;
  };

  const macInputCheck = () => {
    if (formState.inputs?.mac_date?.value === "") {
      Toast("error", "Please enter the MAC Date.");
      return false;
    }
    if (Array.isArray(formState.inputs?.state?.value)) {
      if (
        checkIfDuplicateExists(
          Array.from(formState.inputs?.state?.value, (x) => x.action_id)
        )
      ) {
        Toast("error", "Please do not input duplicate Item ID.");
        return false;
      }
      if (
        checkIfEmptyExists(
          Array.from(formState.inputs?.state?.value, (x) => x.action_id)
        )
      ) {
        Toast("error", "Please do not input the empty Item ID.");
        return false;
      }
    }

    return true;
  };

  const handleClickOpen = (operation) => {
    if (fileTypeCheck() === false) {
      return;
    }

    if (macInputCheck() === false) {
      return;
    }

    setOpen(true);

    const formData = new FormData();

    formData.append("user_id", display_name);

    tag.forEach((item) => {
      if (item.fe_id !== "update_time") {
        formData.append(
          item.fe_id,
          formState.inputs[item.fe_id]?.value
            ? formState.inputs[item.fe_id]?.value
            : ""
        );
      }
    });

    let object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    setFormDataObj(formData);
    let arr = [];
    let i = 1;
    for (const [key, value] of Object.entries(object)) {
      arr.push({ id: i, attribute: key, current: value });
      i++;
    }
    setTableData(arr);
    setButtonOperation(operation);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const macSubmitHandler = async (event) => {
    event.preventDefault();

    const memoBody = Array.isArray(formState.inputs?.memo?.value)
      ? formState.inputs?.memo?.value.map((item, i) => ({
          mac_name: name || "",
          mac_date: formState.inputs?.mac_date?.value || "",
          memo_id: i + 1,
          mac_purpose: item.mac_purpose || "",
          mac_classification: item.mac_classification || "",
          mac_form: item.mac_form || "",
          mac_decision: item.mac_decision || "",
          code: item.code || "",
          placeholder1: "",
          placeholder2: "",
          placeholder3: "",
          placeholder4: "",
          placeholder5: "",
        }))
      : [];

    const stateBody = Array.isArray(formState.inputs?.state?.value)
      ? formState.inputs?.state?.value?.map((item, i) => ({
          mac_name: name || "",
          mac_date: formState.inputs?.mac_date?.value || "",
          action_id: item.action_id || "",
          mac_action: item.mac_action || "",
          target_date: item.target_date || "",
          mac_result: item.mac_result || "",
          related_model: item.related_model || "",
          comment: item.comment || "",
          close_date: item.close_date || "",
          placeholder1: "",
          placeholder2: "",
          placeholder3: "",
          placeholder4: "",
          placeholder5: "",
        }))
      : [];

    const attendanceBody = Array.isArray(formState.inputs?.attendance?.value)
      ? formState.inputs?.attendance?.value.map((item, i) => ({
          mac_name: name || "",
          mac_date: formState.inputs?.mac_date?.value || "",
          attend_id: i + 1,
          attendee: item.attendee || "",
          stakeholder:
            attendanceData.find(({ attendee }) => attendee === item.attendee)
              ?.stakeholder || item.stakeholder,
          business_unit:
            attendanceData.find(({ attendee }) => attendee === item.attendee)
              ?.business_unit || item.business_unit,
          placeholder1: "",
          placeholder2: "",
          placeholder3: "",
          placeholder4: "",
          placeholder5: "",
        }))
      : [];

    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);

    try {
      setOpen(false);
      const formData = new FormData();
      formData.append("user_id", display_name);
      formData.append("update_time", currentDate);
      formData.append("mac_name", name);
      newDocument.length > 0 &&
        formData.append(
          "file_description",
          JSON.stringify(Array.from(newDocument, (x) => x.file_description))
        );
      newDocument.length > 0 &&
        formData.append(
          "file_type",
          JSON.stringify(
            Array.from(newDocument, (x) =>
              macFileTypeInToOutHandler(x.file_type)
            )
          )
        );

      tag2.forEach((item) => {
        if (
          item.fe_element !== "autoFill" &&
          item.fe_id !== "update_time" &&
          item.fe_id !== "user_id" &&
          item.fe_id !== "sequence" &&
          item.fe_id !== "business_unit" &&
          item.fe_id !== "mac_name"
        ) {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id]?.value
              ? formState.inputs[item.fe_id]?.value
              : ""
          );
        }
      });

      tag2.forEach((item) => {
        if (item.fe_id === "business_unit") {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id]?.value
              ? JSON.stringify(formState.inputs[item.fe_id]?.value)
              : JSON.stringify([])
          );
        }
      });

      formData.append("memo", JSON.stringify(memoBody));
      formData.append("state", JSON.stringify(stateBody));
      formData.append("attendance", JSON.stringify(attendanceBody));

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append(`file${i + 1}`, files[i]);
        }
      }

      let inventoryValue = true;

      if (buttonOperation === "save") {
        inventoryValue = false;
      }

      const res = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/mac?inventory=${inventoryValue}`,
        "POST",
        formData,
        headers
      );

      if (res.error) {
        Toast("error", res);
        return;
      }

      if (res.state === "success") {
        Toast("success", "MAC is created successfully.");
        isTreeChanged(true);
      }
    } catch (err) {
      Toast("error", err);
    }
  };

  const fileChangeHandler = (id, value) => {
    setNewDocument(
      newDocument.map((doc, i) => {
        if ("file_" + doc.filename + "_type_" + (i + 1) === id) {
          doc.file_type = value;
        }
        if ("file_description_" + doc.filename === id) {
          doc.file_description = value;
        }
        return doc;
      })
    );
  };
  const uploadFileHandler = (file) => {
    if (Array.isArray(files) && files.map((i) => i.name).includes(file.name)) {
      Toast("error", "File already added.");
      return;
    }

    setFiles([...files, file]);
    setNewDocument([
      ...newDocument,
      {
        file_description: "",
        filename: file.name,
        update_time: new Date(),
        file_owner: display_name,
        files_status: "active",
        file_type: "",
      },
    ]);
  };

  const removeFileHandler = (file) => {
    setFiles(files.filter((doc) => doc.name !== file));
    setNewDocument(newDocument.filter((doc) => doc.filename !== file));
  };

  const PopUpModalData = tableData
    .filter(
      (item) =>
        item.attribute !== "update_time" &&
        item.attribute !== "user_id" &&
        item.attribute !== "access_log"
    )
    .map((data) => {
      const label = tag2.find(
        ({ fe_id }) => fe_id === data.attribute
      )?.fe_label;
      return { ...data, attribute: label };
    });

  const isDataChanged = () => {
    for (let i = 0; i < tag.length; i++) {
      if (!isTwoJsonValueEqual("", formState.inputs?.[tag[i].fe_id]?.value)) {
        // console.log("data changed!");
        // console.log("label:", tag[i].fe_label);
        // console.log("initial:", "");
        // console.log("input:", formState.inputs?.[tag[i].fe_id]?.value);
        return true;
      }
    }
    const macTag = [
      { id: "attendance", label: "Attendances" },
      { id: "memo", label: "MAC Purpose" },
      { id: "state", label: "Actions" },
    ];
    for (let i = 0; i < macTag.length; i++) {
      if (macTag[i].id === "attendance") {
        if (
          !isTwoJsonValueEqual(
            attendanceData,
            formState.inputs?.[macTag[i].id]?.value,
            macTag[i].id
          )
        ) {
          // console.log("mac data changed");
          // console.log("Label:", macTag[i].label);
          return true;
        }
      } else {
        if (
          !isTwoJsonValueEqual(
            [],
            formState.inputs?.[macTag[i].id]?.value,
            macTag[i].id
          )
        ) {
          // console.log("mac data changed");
          // console.log("Label:", macTag[i].label);
          return true;
        }
      }
    }

    if (newDocument?.length > 0) {
      return true;
    }

    return false;
  };

  // console.log(attendanceData)
  useEffect(() => {
    if (isDataChanged()) {
      isPageDataChangedHandler("New", 0);
    } else {
      isPageDataNotChangedHandler("New", 0);
    }
  }, [formState.inputs]);

  let element;
  element = (
    <div style={{ marginTop: "1.5rem" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "650px",
            maxWidth: "900px",
            height: "100%",
            padding: "1rem 3rem 1rem 3rem",
          },
        }}
      >
        <DialogTitle>
          <PopUpTable tableData={PopUpModalData} isNew={true} />
        </DialogTitle>
        <DialogActions>
          <Button onClick={macSubmitHandler}>Confirm</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <form style={{ margin: "0 8rem 2rem 8rem" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={0.5} columnSpacing={5}>
            {tag
              .filter((i) => i.fe_id !== "mac_comment")
              .sort((a, b) => a.fe_order - b.fe_order)
              .map((tag, i) => (
                <Grid
                  item
                  xs={12}
                  md={tag.fe_grid === 12 ? 12 : 6}
                  lg={tag.fe_grid}
                  key={i}
                >
                  <InputNew
                    id={tag.fe_id}
                    type={tag.fe_type}
                    label={tag.fe_label}
                    element={tag.fe_element}
                    onInput={inputHandler}
                    initialValid={true}
                    initialValue=""
                    origin_options={tag}
                    onRecord={() => {}}
                    options={
                      userGroup
                        ? userGroup.map((user) => ({
                            label: user,
                            value: user,
                          }))
                        : []
                    }
                    setScrollTop={setScrollTop}
                    pageElement={pageElement}
                  />
                </Grid>
              ))}

            <Grid item xs={12}>
              <div style={{ padding: "0.8rem", margin: "1.5rem 0 1rem 0" }}>
                <InputNew
                  id="attendance"
                  label="Attendances"
                  element="macTable"
                  onInput={inputHandler}
                  initialValue={attendanceData}
                  modelIdGroup={modelIdGroup}
                  userGroup={userGroup}
                  mac_attendance={attendanceData || []}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div style={{ padding: "0.8rem", marginBottom: "1rem" }}>
                <InputNew
                  id="memo"
                  label="MAC Purpose"
                  element="macTable"
                  onInput={inputHandler}
                  initialValue=""
                  modelIdGroup={modelIdGroup}
                  userGroup={userGroup}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div style={{ padding: "0.8rem", marginBottom: "1rem" }}>
                <InputNew
                  id="state"
                  label="Actions"
                  element="macTable"
                  onInput={inputHandler}
                  initialValue=""
                  modelIdGroup={modelIdGroup}
                  userGroup={userGroup}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <InputNew
                id="mac_comment"
                label="Comment"
                element="textEditor"
                onInput={inputHandler}
                initialValid={true}
                initialValue=""
                onRecord={() => {}}
                setScrollTop={setScrollTop}
                pageElement={pageElement}
              />
            </Grid>

            <Grid item xs={12}>
              <div style={{ padding: "0.8rem" }}>
                <FileLoad
                  uploadHandler={uploadFileHandler}
                  removeHandler={removeFileHandler}
                  newDocument={newDocument}
                  form="model"
                  onInput={inputHandler}
                  fileChangeHandler={fileChangeHandler}
                  macFileType={macFileType}
                />
              </div>
            </Grid>
            {(!tag || isLoading) && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                <Button
                  onClick={() => handleClickOpen("publish")}
                  variant="outlined"
                  className="editButton"
                  // disabled={access.includes("Update") ? false : true}
                  disabled={user?.user_group?.[0] !== "Admin"}
                >
                  Submit
                </Button>

                {
                  <Button
                    onClick={() => handleClickOpen("save")}
                    variant="outlined"
                    className="editButton"
                    // disabled={access.includes("Update") ? false : true}
                    disabled={user?.user_group?.[0] !== "Admin"}
                  >
                    Save
                  </Button>
                }
              </div>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );

  return <div>{element}</div>;
};

export default NewMac;
