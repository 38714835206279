import React, { Fragment } from "react";
import Navigation from "./Navigation/Navigation";
import "./TopBar.css";

import HeaderBar from "./HeaderBar";

const Header = ({onLoginChange}) => {
  //get system date to display in header
  return (
    <Fragment>
      <header className="header-layout">
        <HeaderBar onLoginChange={onLoginChange}/>
        {/*<ModelStatus />*/}
        {/*<Navigation />*/}
      </header>
    </Fragment>
  );
};

export default Header;
