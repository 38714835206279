import { useState, useContext, useEffect, Fragment } from "react";
import {
  CircularProgress,
  Box,
  Grid,
  Divider,
  Modal,
  Fade,
  Backdrop,
  Button,
} from "@mui/material";
import InputNew from "../../components/Content/FormElements/InputNew";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import OpmTable from "./OpmTable/OpmTable";
import Toast from "../../components/Utils/Toast";
import { UserContext } from "../../context/user-context";
import DashboardLayout from "../DashboardPage/DashboardLayout";

import "./Opm.css";
import { set } from "lodash";

const modalStyle1 = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",

  bgcolor: "background.paper",
  border: "1px solid rgba(224, 224, 224, 1)",
  borderRadius: "5px",
  boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  p: 3,
  overflowY: "auto",
  overflowX: "hidden",
};

const modalStyle2 = {
  position: "absolute",

  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  border: "1px solid rgba(224, 224, 224, 1)",
  borderRadius: "5px",
  boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  p: 3,
  overflowY: "auto",
  overflowX: "hidden",
};

const Opm = (props) => {
  const { tag } = props;
  const { user, dataChangedArr } = useContext(UserContext);
  const [formState, inputHandler] = useForm({}, false);
  const [tableData, setTableData] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [thresholdModalOpen, setThresholdModalOpen] = useState(false);
  const [thresholdModalOpen2, setThresholdModalOpen2] = useState(false);
  const [staticMetricData, setStaticMetricData] = useState([]);
  const [dynamicMetricData, setDynamicMetricData] = useState([]);
  const [monitorData, setMonitorData] = useState([]);
  const [isOpmChanged, setIsOpmChanged] = useState(false);
  const [schemaData, setSchemaData] = useState([]);
  const [weightSchema, setWeightSchema] = useState([]);
  const [performanceMeasurement, setPerformanceMeasurement] = useState([]);
  const [attestation, setAttestation] = useState([]);
  const [scoreSchema, setScoreSchema] = useState([]);
  const [frameworkSelected, setFrameworkSelected] = useState("");
  const [gaugeData, setGaugeData] = useState([]);
  const [isOpmLoading, setIsOpmLoading] = useState(false);
  const [layoutData, setLayoutData] = useState([]);
  const [isFrameworkLoading, setIsFrameworkLoading] = useState(false);

  const { display_name } = user || {};

  const colorList = tag
    ?.filter(
      (item) => item.db_tablename === "score schema" && item.fe_id === "color"
    )[0]
    ?.group?.split(",");

  const frequency_option = tag.filter(
    (item) => item.db_tablename === "opm" && item.fe_id === "opm_frequency"
  )[0];

  const framework_option = tag.filter(
    (item) =>
      item.db_tablename === "static metric" && item.fe_id === "framework"
  )[0];

  const monitor_level_option = tag.filter(
    (item) => item.db_tablename === "opm" && item.fe_id === "monitor_level"
  )[0];

  const fetchFrameworkDataHandler = async (e) => {
    setThresholdModalOpen(true);
    setIsFrameworkLoading(true);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/opm/schema`
      );
      setTimeout(() => {}, 1000);
      setIsFrameworkLoading(false);

      setSchemaData(response);
    } catch (err) {}
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsOpmLoading(true);
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/opm/${
            props.record ? props.record.split("+")[0] : ""
          }`
        );
        setTableData(response["opm data"]);
        setSummaryData(response["opm data"]?.[0] || {});
        let obj = {};
        Array.isArray(response["monitor metric"]) &&
          response["monitor metric"].forEach((data) => {
            obj[data.attribute] = data.value;
          });
        setMonitorData(obj);
        setStaticMetricData(response["static metric"]);
        setDynamicMetricData(response["dynamic metric"]);
        setGaugeData(response["score schema"]);
        if (Array.isArray(response?.summary?.grid)) {
          setLayoutData(response?.summary);
        } else {
          setLayoutData([]);
        }
        setIsOpmLoading(false);
      } catch (err) {
        console.warn(err);
      }
    };
    if (props?.record && props?.record.split("+")[0] !== "undefined") {
      fetchData();
    }
  }, [props.record, sendRequest, isOpmChanged]);

  useEffect(() => {
    if (
      formState.inputs.framework_new &&
      formState.inputs.framework_new.value
    ) {
      setFrameworkSelected(formState.inputs.framework_new?.value);

      setWeightSchema(
        schemaData["weight schema"]?.filter(
          (item) => item.framework === formState.inputs.framework_new.value
        )
      );
      setPerformanceMeasurement(
        schemaData["static metric"]?.filter(
          (item) =>
            item.framework === formState.inputs.framework_new.value &&
            item.statistics_type === "Quantitative"
        )
      );
      setAttestation(
        schemaData["static metric"]?.filter(
          (item) =>
            item.framework === formState.inputs.framework_new.value &&
            item.statistics_type === "Qualitative"
        )
      );
      setScoreSchema(
        schemaData["score schema"]?.filter(
          (item) => item.framework === formState.inputs.framework_new.value
        )
      );
    } else {
      setFrameworkSelected("");
      setWeightSchema([]);
      setPerformanceMeasurement([]);
      setAttestation([]);
      setScoreSchema([]);
    }
  }, [formState.inputs?.framework_new?.value, schemaData]);

  useEffect(() => {
    if (!thresholdModalOpen) {
      setFrameworkSelected("");
      setWeightSchema([]);
      setPerformanceMeasurement([]);
      setAttestation([]);
      setScoreSchema([]);
    }
  }, [thresholdModalOpen]);

  if (!(props?.record && props?.record.split("+")[0] !== "undefined")) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
          paddingTop: "10rem",
        }}
      >
        <h2 style={{ color: "#444791" }}>Please select a model</h2>
      </div>
    );
  }

  if (!tableData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const updateThresholdHandler = async (e) => {
    e.preventDefault();

    if (formState.inputs?.framework?.value === "") {
      Toast("error", "Please select a framework.");
      return;
    }

    try {
      const monitorMetricBody = [
        {
          attribute: "framework",
          form: "OPM",
          indicator: props.record ? props.record.split("+")[0] : "",
          value: formState.inputs.framework.value || "",
        },
        {
          attribute: "monitor_level",
          form: "OPM",
          indicator: props.record ? props.record.split("+")[0] : "",
          value: formState.inputs.monitor_level.value || "",
        },
        {
          attribute: "opm_frequency",
          form: "OPM",
          indicator: props.record ? props.record.split("+")[0] : "",
          value: formState.inputs.opm_frequency.value || "",
        },
        {
          attribute: "start_date",
          form: "OPM",
          indicator: props.record ? props.record.split("+")[0] : "",
          value: formState.inputs.start_date.value || "",
        },
      ];
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/opm/monitor/update`,
        "PUT",
        JSON.stringify(monitorMetricBody),
        {
          "Content-Type": "application/json",
        }
      );
      if (response["monitor metric"]) {
        Toast("success", "Threshold Updated.");
        setThresholdModalOpen2(false);
        setIsOpmChanged((prev) => !prev);
      }
    } catch (err) {}
  };

  if (isOpmLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "10rem",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div style={{ margin: "2rem 8rem 2rem 8rem", height: "30rem" }}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={thresholdModalOpen}
        onClose={() => setThresholdModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Fade in={thresholdModalOpen}>
          <Box sx={modalStyle2}>
            <form>
              <Box
                sx={{ flexGrow: 1 }}
                style={{ margin: "1rem 1rem 1rem 1rem" }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <InputNew
                      id="framework_new"
                      type="text"
                      label="View Framework"
                      element="select"
                      onInput={inputHandler}
                      initialValue={
                        framework_option?.group?.split(",")[0] || ""
                      }
                      onBlur
                      origin_options={framework_option}
                    />
                  </div>
                </div>

                <div>
                  <div style={{ width: "100%", margin: "1rem auto 2rem auto" }}>
                    <InputNew
                      id="weight_schema"
                      label="Weighting Schema"
                      element="opmTable"
                      onInput={inputHandler}
                      initialValue={weightSchema}
                      isShowOnly
                      disabled
                    />
                  </div>
                  <div style={{ width: "100%", margin: "1rem auto 2rem auto" }}>
                    <InputNew
                      id="performance_measurement"
                      label="Performance Measurement (Quantitative)"
                      element="opmTable"
                      onInput={inputHandler}
                      initialValue={performanceMeasurement}
                      colorList={colorList}
                      isShowOnly
                      disabled
                    />
                  </div>
                  <div style={{ width: "100%", margin: "1rem auto 2rem auto" }}>
                    <InputNew
                      id="attestation"
                      label="Assessment (Qualitative)"
                      element="opmTable"
                      onInput={inputHandler}
                      initialValue={attestation}
                      colorList={colorList}
                      isShowOnly
                      disabled
                    />
                  </div>
                  <div style={{ width: "100%", margin: "1rem auto 2rem auto" }}>
                    <InputNew
                      id="score_schema"
                      label="Score Schema"
                      element="opmTable"
                      onInput={inputHandler}
                      initialValue={scoreSchema}
                      colorList={colorList}
                      isShowOnly
                      disabled
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  ></div>
                </div>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={thresholdModalOpen2}
        onClose={() => setThresholdModalOpen2(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={thresholdModalOpen2}>
          <Box sx={modalStyle1}>
            <form>
              <Box sx={{ flexGrow: 1, ml: 1, mr: 1 }}>
                <Box
                  sx={{
                    m: 1,
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: 500,
                    fontSize: 24,
                  }}
                >
                  Threshold
                </Box>
                <Grid container rowSpacing={2} columnSpacing={5}>
                  <Grid item xs={6}>
                    <InputNew
                      id="framework"
                      type="text"
                      label="Framework(*)"
                      element="select"
                      onInput={inputHandler}
                      initialValue={monitorData ? monitorData.framework : ""}
                      onBlur
                      origin_options={framework_option}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputNew
                      id="monitor_level"
                      label="Monitor Level"
                      element="select"
                      onInput={inputHandler}
                      initialValue={
                        monitorData ? monitorData.monitor_level : ""
                      }
                      origin_options={monitor_level_option}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputNew
                      id="opm_frequency"
                      label="OPM Frequency"
                      element="select"
                      onInput={inputHandler}
                      initialValue={
                        monitorData ? monitorData.opm_frequency : ""
                      }
                      origin_options={frequency_option}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <InputNew
                      id="start_date"
                      label="OPM Starting Date"
                      element="date"
                      onInput={inputHandler}
                      initialValue={monitorData ? monitorData.start_date : ""}
                      onBlur
                    />
                  </Grid>
                </Grid>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    onClick={updateThresholdHandler}
                    variant="outlined"
                    className="editButton"
                  >
                    Save Criteria
                  </Button>
                </div>
              </Box>
            </form>
          </Box>
        </Fade>
      </Modal>
      <div style={{ marginTop: "3rem", marginBottom: "1rem" }}>
        <DashboardLayout layoutData={layoutData} />
      </div>
      <Grid container rowSpacing={0.5} columnSpacing={1}>
        <Grid item xs={12} lg={5}>
          <InputNew
            id="framework_show"
            type="text"
            label="Framework"
            element="input"
            onInput={inputHandler}
            initialValue={monitorData ? monitorData.framework : ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <InputNew
            id="monitor_level"
            label="Monitor Level"
            element="input"
            onInput={inputHandler}
            initialValue={monitorData ? monitorData.monitor_level : ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            onClick={() => setThresholdModalOpen2(true)}
            variant="outlined"
            className="editButton-2"
            style={{ width: "6rem", marginTop: "2rem", marginLeft: "3rem" }}
          >
            Threshold
          </Button>
        </Grid>
        <Grid item xs={12} lg={5}>
          <InputNew
            id="opm_frequency"
            label="OPM Frequency"
            element="input"
            onInput={inputHandler}
            initialValue={monitorData ? monitorData.opm_frequency : ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={5}>
          <InputNew
            id="start_date"
            label="OPM Starting Date"
            element="date"
            onInput={inputHandler}
            initialValue={monitorData ? monitorData.start_date : ""}
            disabled
          />
        </Grid>
        <Grid item xs={12} lg={2}>
          <Button
            onClick={fetchFrameworkDataHandler}
            variant="outlined"
            className="editButton-2"
            style={{ width: "6rem", marginTop: "2rem", marginLeft: "3rem" }}
          >
            Framework
          </Button>
        </Grid>
      </Grid>

      <div style={{ width: "100%", marginTop: "3rem" }}>
        <OpmTable
          tableData={tableData}
          opmId={props.record}
          dynamicMetricData={dynamicMetricData}
          monitorData={monitorData}
          colorReference={staticMetricData}
          gaugeData={gaugeData}
        />
      </div>
    </div>
  );
};

export default Opm;
