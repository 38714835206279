import dayjs from "dayjs";

// third-party
import ReactApexChart from "react-apexcharts";



export const VerticalBarChart = (props) => {
  const formattedDate = dayjs().format("YYYYMMDD");

  let series = [{ name: "", data: [] }];

  let options = {};

  if (props?.axis?.length === 2) {
    series = [
      {
        name: props.chartAxisLabel,
        data: props.chartData,
      },
    ];

    options = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: `${props.chartTitle}_${formattedDate}`,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: `${props.chartTitle}_${formattedDate}`,
            },
            png: {
              filename: `${props.chartTitle}_${formattedDate}`,
            },
          },
          autoSelected: "zoom",
        },
        // type: "bar",
        // height: 350,
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          horizontal: false,
          dataLabels: {
            // textAnchor: "end",
            position: "top",
          },
        },
      },
      dataLabels: {
        enabled: true,
        // textAnchor: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "11px",
          colors: ["black"],
        },
        background: {
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45,
          },
        },
      },

      grid: {
        show: true,
        xaxis: { lines: { show: false } },
        yaxis: { lines: { show: true } },
      },
      yaxis: {
        title: {
          text: props.chartAxisLabel,
          align: "medium",
        },
      },
      xaxis: {
        tickPlacement: "on",
        categories: props.chartLabels,
      },
    };
  } else {
    series = props.chartData;

    options = {
      chart: {
        //   type: "bar",
        //   height: 350,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: [],
          },
          export: {
            csv: {
              filename: `${props.chartTitle}_${formattedDate}`,
              columnDelimiter: ",",
              headerCategory: "category",
              headerValue: "value",
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: `${props.chartTitle}_${formattedDate}`,
            },
            png: {
              filename: `${props.chartTitle}_${formattedDate}`,
            },
          },
          autoSelected: "zoom",
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
          dataLabels: {
            // textAnchor: "end",
            position: "end",
          },
        },
      },
      dataLabels: {
        enabled: true,
        //   textAnchor: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: "11px",
          colors: ["black"],
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },

      fill: {
        opacity: 1,
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return val;
      //     },
      //   },
      // },
      xaxis: {
        categories: props.chartLabels,
        tickPlacement: "on",
      },
      yaxis: {
        show: true,
        showAlways: true,
        labels: {
          align: "left",
          show: true,
        },
        title: {
          text: props.chartAxisLabel,
        },
      },
    };
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={props.chartHeight}
      />
    </div>
  );
};
