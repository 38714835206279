import Toast from "../../../components/Utils/Toast";

export const fileTypeCheck = (newDocument) => {
  if (Array.from(newDocument, (x) => x.file_type).includes("")) {
    Toast("error", "Please specify the File Type.");
    return false;
  }
  return true;
};

export const emptyFieldCheck = (input, text) => {
  if (!input || input === "") {
    Toast("error", `Please specify the ${text}.`);
    return false;
  }
  return true;
};
