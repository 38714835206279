import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import LoginMock from "../components/CustomComponents/LoginMock";

const UnAuthRoute = () => {
  return (
    <Switch>
      <Route path="/" exact>
        <LoginMock />
      </Route>
      <Route path="/login" exact>
        <LoginMock />
      </Route>
      <Redirect to="/login" />
    </Switch>
  );
};

export default UnAuthRoute;
