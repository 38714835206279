import React, { useEffect } from "react";
import "./Tree.css";
import TreeItem from "@mui/lab/TreeItem";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InputNew from "./InputNew";
import { useForm } from "../../Utils/hooks/form-hook";
import { useLocation } from "react-router-dom";

import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";

import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";

const Tree = (props) => {
  const { pathname } = useLocation();
  const [formState, inputHandler] = useForm(
    { searchBar: { value: "" } },
    false
  );
  let scrollToId;
  const getFirst2andLast = (pathname) => {
    let arr = pathname?.split("/")?.[2]?.split("+");
    if (arr) {
      const f1 = arr?.shift();
      const f2 = arr?.shift();
      const l1 = arr?.pop();
      let nodeIds = [`Model+${f1}`, `Model+${f1}+${f2}+${l1}`];
      scrollToId = nodeIds[0];
      return nodeIds;
    }
    return [""];
  };
  useEffect(() => {
    if (scrollToId) {
      const element = document.getElementById(scrollToId);
      element && element.scrollIntoView();
      let stickyElement = document.querySelector("#sticky-search-bar"); // Replace #myStickyElement with the selector for your sticky element
      let stickyElementHeight = stickyElement.offsetHeight;

      let scrollableParent = document.getElementById("tree-parent");

      if (scrollableParent?.scrollTop > stickyElementHeight) {
        scrollableParent.scrollBy(0, -stickyElementHeight);
      }
    }
  }, []);

  return (
    <div style={{ marginBottom: "3rem" }}>
      <div
        style={{
          background: "white",
          width: "100%",
          position: "sticky",
          top: "-0.8vh",
          zIndex: 10,
        }}
        id="sticky-search-bar"
      >
        <div style={{ display: "flex", alignItems: "flex-end" }}>
          <InputNew
            id="searchBar"
            type="text"
            label=""
            element="input"
            initialValue=""
            onInput={inputHandler}
            onBlur
            placeholder="Search"
          />
        </div>
      </div>
      <TreeView // aria-label="file system navigator"
        defaultCollapseIcon={<IndeterminateCheckBoxOutlinedIcon />}
        defaultExpandIcon={<AddBoxOutlinedIcon />}
        defaultEndIcon={<LabelOutlinedIcon />}
        // sx={{ flexGrow: 1, fontWeight: 600 }}
        onNodeSelect={props.onClick}
        multiSelect={true}
        defaultExpanded={
          pathname !== "/model" && pathname !== "/temp"
            ? [
                "Model",
                `Model+${pathname
                  ?.split("/")[2]
                  ?.split("+")
                  ?.slice(0, 1)
                  ?.join("+")}`,
              ]
            : ["Model", `Model+${props.data?.[0]?.Model?.[0]?.code}`]
        }
        defaultSelected={
          pathname !== "/model"
            ? getFirst2andLast(pathname)
            : [
                `Model+${props.data?.[0]?.Model?.[0]?.code}+${props.data?.[0]?.Model?.[0]?.version?.[0]?.[0]}+${props.data?.[0]?.Model[0]?.version?.[0]?.[1]}`,
              ]
        }
        selected={props.selected}
        expanded={props.expanded}
        onNodeToggle={props.nodeExpandHandler}
      >
        {props.data.map((node, i) => {
          return (
            <TreeItem
              label={Object.keys(node)[0]}
              nodeId={Object.keys(node)[0]}
              key={i}
              sx={{
                "& .Mui-selected": {
                  backgroundColor: "#FF9100 !important",
                  color: "white",
                  fontWeight: "bold !important",
                },
                "& .Mui-focused": {
                  backgroundColor: "#FF9100 !important",
                  color: "white",
                  fontWeight: "bold !important",
                },
              }}
            >
              {(formState.inputs.searchBar.value?.length === 0
                ? Object.values(node)[0]
                : Object.values(node)[0].filter((item) => {
                    if (!item.mac_name) {
                      return (
                        item.code
                          ?.toLowerCase()
                          .includes(
                            formState.inputs.searchBar.value.toLowerCase()
                          ) ||
                        item.name
                          ?.toLowerCase()
                          .includes(
                            formState.inputs.searchBar.value.toLowerCase()
                          ) ||
                        (!item.code
                          ? item
                              .toLowerCase()
                              .includes(
                                formState.inputs.searchBar.value.toLowerCase()
                              )
                          : false)
                      );
                    } else {
                      return (
                        item.mac_name
                          ?.toLowerCase()
                          .includes(
                            formState.inputs.searchBar.value.toLowerCase()
                          ) ||
                        item.mac_date
                          .toString()
                          ?.includes(
                            formState.inputs.searchBar.value.toLowerCase()
                          )
                      );
                    }
                  })
              ).map((item, i) => {
                if (!item.mac_name) {
                  return (
                    <TreeItem
                      key={i}
                      nodeId={
                        item.name !== undefined
                          ? Object.keys(node)[0] + "+" + item.code
                          : Object.keys(node)[0] + "+" + item
                      }
                      id={
                        item.name !== undefined
                          ? Object.keys(node)[0] + "+" + item.code
                          : Object.keys(node)[0] + "+" + item
                      }
                      label={
                        item.name !== undefined
                          ? item.code + " | " + item.name
                          : item
                      }
                      //onClick={props.onClick}
                      style={{
                        color:
                          item.version &&
                          item.version.map((ver) => ver[1]).includes(0)
                            ? "#CB3694"
                            : "black",
                      }}
                      sx={{
                        "& .Mui-selected": {
                          backgroundColor: "#FF9100 !important",
                        },
                        "& .Mui-focused": {
                          backgroundColor: "#FF9100 !important",
                        },
                      }}
                    >
                      {item.version &&
                        item.version.map((version, j) => {
                          return (
                            <TreeItem
                              sx={{
                                color: version[1] === 0 ? "#CB3694" : "black",
                                backgroundColor:
                                  version[1] === 0 ? "white" : "white",
                                "& > .Mui-selected": {
                                  border: "solid 1px",
                                  borderRadius: "5px",
                                  borderColor: "#444444",
                                  backgroundColor: "#FF9100 !important",
                                  " & .MuiTreeItem-label": {
                                    fontWeight: "bold",
                                  },
                                },
                                "& > .Mui-focused": {
                                  border: "solid 1px",
                                  borderRadius: "5px",
                                  borderColor: "#444444",
                                  backgroundColor: "#FF9100 !important",
                                },
                              }}
                              label={version[0]}
                              key={j}
                              nodeId={
                                Object.keys(node)[0] +
                                "+" +
                                item.code +
                                "+" +
                                version[0] +
                                `${version[1] === 0 ? "+" + version[1] : ""}`
                              }
                              id={
                                Object.keys(node)[0] +
                                "+" +
                                item.code +
                                "+" +
                                version[0] +
                                `${version[1] === 0 ? "+" + version[1] : ""}`
                              }
                            />
                          );
                        })}
                    </TreeItem>
                  );
                } else {
                  return item?.mac_date?.map((date, j) => {
                    return (
                      <TreeItem
                        key={j}
                        nodeId={"MAC+" + item.mac_name + "+" + date?.[0]}
                        id={"MAC+" + item.mac_name + "+" + date?.[0]}
                        label={date?.[0]}
                        //onClick={props.onClick}
                        sx={{
                          color: date && date.includes(0) ? "#CB3694" : "black",
                          "& .Mui-selected": {
                            border: "solid 1px",
                            borderRadius: "5px",
                            borderColor: "#444444",
                            backgroundColor: "#FF9100 !important",
                            " & .MuiTreeItem-label": {
                              fontWeight: "bold",
                            },
                          },
                        }}
                      />
                    );
                  });
                }
              })}
              {/*<TreeItem
              key={i}
              nodeId={Object.values(node)[0].name + i}
              label={Object.values(node)[0].name}
              //onClick={props.onClick}
            >
              {versionId.map((id, i) => {
                return (
                  <TreeItem
                    // className={classes.pdate}
                    id={mrm + "+" + id}
                    nodeId={mrm + "+" + id}
                    key={id + "|" + i}
                    // onClick={props.onClick}
                    label={id}
                  />
                );
              })}
            </TreeItem>*/}
            </TreeItem>
          );
        })}
      </TreeView>
    </div>
  );
};

export default Tree;
