import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import { useEffect, useRef, useState } from "react";

import Model from "../pages/ModelPage/Model-dynamic";
import Change from "../pages/ChangePage/ChangePage";
import Validation from "../pages/ValidationPage/Validation";
import NewValidation from "../pages/ValidationPage/NewValidationPage/NewValidation";
import ValidationDetails from "../pages/ValidationPage/ValidationDetailsPage/ValidationDetails";
import Opm from "../pages/OpmPage/Opm";
import Issues from "../pages/IssuePage/Issues";
import NewModel2 from "../pages/ModelPage/NewModelPage/NewModel2";
import NewIssue from "../pages/IssuePage/NewIssuePage/NewIssue";
import IssueDetails from "../pages/IssuePage/IssueDetailsPage/IssueDetails";
import OpmDetails from "../pages/OpmPage/OpmDetails/OpmDetails";
import NewOpm from "../pages/OpmPage/NewOpmPage/NewOpm";
import Document from "../pages/DocumentPage/Document";
import NewDocument from "../pages/DocumentPage/NewDocument";
import SystemInit from "../pages/SystemInitPage/SystemInit";
import OptionConfig from "../pages/OptionConfigPage/OptionConfig";
import User from "../pages/UserPage/User";
import Notification from "../pages/NotificationPage/Notification";
import History from "../pages/HistoryPage/History";
import HistoryDetails from "../pages/HistoryPage/HistoryDetails";
import Task from "../pages/TaskPage/Task";
import TaskConfig from "../pages/TaskConfigPage/TaskConfig";
import Assignment from "../pages/AssignmentPage/Assignment";
import AccessConfig from "../pages/AccessConfigPage/AccessConfig";
import BatchDownload from "../pages/BatchPage/BatchDownload";
import BatchUpload from "../pages/BatchPage/BatchUpload";
import SuperUser from "../pages/SuperUser/index";
import Report from "../pages/ReportPage/Report";
import ReportFilter from "../pages/ReportPage/ReportFilter";
import GlobalSchema from "../pages/GlobalSchemaPage/GlobalSchema";
import MacDetails from "../pages/MacPage/MacDetails";
import NewMac from "../pages/MacPage/NewMac";
import MacAttendance from "../pages/MacAttendancePage/MacAttendance";
import UserListTable from "../pages/AllUserListPage/UserListTable";
import Dashboard from "../pages/DashboardPage/Dashboard";
import DashboardConfig from "../pages/DashboardConfigPage/DashboardConfig";
import Query from "../pages/QueryPage/Query";
import Executor from "../pages/ExecutorPage/Executor";

import Tree from "../components/Content/FormElements/Tree";
import Split from "react-split";

const Routes = ({
  pathname,
  modelAccess,
  modelRecord,
  documents,
  options,
  tag,
  tree,
  treeClickHandler,
  isTreeChangedHandler,
  isUserChangedHandler,
  record,
  user,
  allModelVersion,
  tempAccess,
  allModelData,
  isTaskPageEnable,
  nodeSelected,
  isSaveExecuted,
  expanded,
  nodeExpandHandler,
  treeHeight,
}) => {
  const latestVersion = allModelVersion
    ?.filter((item) => item.code === record.split("+")[1])[0]
    ?.version?.at(-1);
  const inventory_model = allModelData ? allModelData : [];

  let formList = [];
  if (user) {
    formList = Object.values(user?.["menu access"])?.[0]?.map(
      (form) => form[0]
    );
  }

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const plainPageDiv = document.querySelector(".right");
    const plainPageDiv2 = document.querySelector(".plain-page");

    if (plainPageDiv && plainPageDiv !== null) {
      const handleScroll = (e) => {
        if (scrollTop !== null) {
          e.preventDefault();
          plainPageDiv.scrollTop = scrollTop; // Set scroll position to saved value
          setScrollTop(null);
        }
      };

      plainPageDiv.addEventListener("scroll", handleScroll, { passive: false });

      return () => {
        plainPageDiv.removeEventListener("scroll", handleScroll);
      };
    }

    if (plainPageDiv2 && plainPageDiv2 !== null) {
      const handleScroll = (e) => {
        if (scrollTop !== null) {
          e.preventDefault();
          plainPageDiv2.scrollTop = scrollTop; // Set scroll position to saved value
          setScrollTop(null);
        }
      };

      plainPageDiv2.addEventListener("scroll", handleScroll, {
        passive: false,
      });

      return () => {
        plainPageDiv2.removeEventListener("scroll", handleScroll);
      };
    }
  }, [scrollTop]); // The effect will rerun whenever scrollTop changes

  if (
    pathname === "/executor" ||
    pathname === "/user/root" ||
    pathname === "/init"
  ) {
    return (
      <Switch>
        <Route path="/executor" exact>
          <Executor />
        </Route>
        <Route path="/user/root" exact>
          <SuperUser />
        </Route>
        <Route path="/init" exact>
          <SystemInit />
        </Route>
      </Switch>
    );
  }

  return (
    <div className="main-content">
      {!isTaskPageEnable && pathname === "/" && (
        <Split className="split" sizes={[25, 75]} gutterSize={3}>
          <div
            className="left"
            id="tree-parent"
            style={{ height: `${treeHeight}vh` }}
          >
            <Tree
              data={tree}
              onClick={treeClickHandler}
              record={record}
              selected={nodeSelected}
              expanded={expanded}
              nodeExpandHandler={nodeExpandHandler}
            />
          </div>
          <div className="right" style={{ height: `${treeHeight}vh` }}>
            <Switch>
              <Route path="/" exact>
                <Redirect
                  to={formList.includes("Model") ? "/model" : "/report"}
                />
              </Route>
              <Route path="/model" exact>
                <Model
                  record={modelRecord}
                  documents={documents}
                  options={options}
                  tag={tag}
                  modelData={inventory_model}
                  isTreeChanged={isTreeChangedHandler}
                />
              </Route>
              <Route path="/model/:record2" exact>
                <Model
                  record={modelRecord}
                  documents={documents}
                  options={options}
                  tag={tag}
                  modelData={inventory_model}
                  isTreeChanged={isTreeChangedHandler}
                />
              </Route>
              <Redirect to="/report" />
            </Switch>
          </div>
        </Split>
      )}

      {((isTaskPageEnable && pathname === "/") ||
        pathname === "/task" ||
        pathname === "/query" ||
        pathname === "/user/root" ||
        pathname === "/executor" ||
        pathname === "/report" ||
        pathname.includes("/report") ||
        pathname === "/dashboard" ||
        pathname === "/dashboard/config" ||
        pathname === "/userlist" ||
        pathname === "/attendance" ||
        pathname === "/init" ||
        pathname === "/config" ||
        pathname === "/user" ||
        pathname === "/notification" ||
        pathname === "/history" ||
        pathname.includes("/history") ||
        pathname === "/task/config" ||
        pathname === "/access" ||
        pathname === "/batch/upload" ||
        pathname === "/batch/download" ||
        pathname === "/schema") && (
        <div className="plain-page" style={{ height: `${treeHeight}vh` }}>
          <Switch>
            <Route path="/" render={() => <Redirect to="/model" />} exact>
              <Task />
            </Route>

            <Route path="/task" exact>
              <Task />
            </Route>

            <Route path="/query" exact>
              <Query setScrollTop={setScrollTop} pageElement=".plain-page" />
            </Route>

            <Route path="/user/root" exact>
              <SuperUser />
            </Route>

            <Route path="/executor" exact>
              <Executor />
            </Route>

            <Route path="/report" exact>
              <Report />
            </Route>

            <Route path="/report/:entity" exact>
              <ReportFilter />
            </Route>

            <Route path="/dashboard" exact>
              <Dashboard />
            </Route>

            <Route path="/dashboard/config" exact>
              <DashboardConfig />
            </Route>

            <Route path="/init" exact>
              <SystemInit
                record={modelRecord}
                tag={tag}
                isTreeChanged={isTreeChangedHandler}
              />
            </Route>

            <Route path="/config" exact>
              <OptionConfig
                record={modelRecord}
                tag={tag}
                isTreeChanged={isTreeChangedHandler}
              />
            </Route>

            <Route path="/user" exact>
              <User record={modelRecord} tag={tag} />
            </Route>

            <Route path="/notification" exact>
              <Notification
                record={modelRecord}
                tag={tag}
                setScrollTop={setScrollTop}
                pageElement=".plain-page"
              />
            </Route>

            <Route path="/history" exact>
              <History />
            </Route>

            <Route path="/history/:id" exact>
              <HistoryDetails tag={tag} />
            </Route>

            <Route path="/task/config" exact>
              <TaskConfig />
            </Route>

            <Route path="/access" exact>
              <AccessConfig isUserChanged={isUserChangedHandler} tag={tag} />
            </Route>

            <Route path="/batch/upload" exact>
              <BatchUpload tag={tag} type="upload" />
            </Route>

            <Route path="/batch/download" exact>
              <BatchDownload tag={tag} type="download" />
            </Route>

            <Route path="/schema" exact>
              <GlobalSchema tag={tag} />
            </Route>

            <Route path="/userlist" exact>
              <UserListTable />
            </Route>

            <Route path="/attendance" exact>
              <MacAttendance tag={tag} />
            </Route>
          </Switch>
        </div>
      )}
      {pathname !== "/" &&
        pathname !== "/task" &&
        pathname !== "/query" &&
        pathname !== "/user/root" &&
        pathname !== "/executor" &&
        pathname !== "/report" &&
        !pathname.includes("/report") &&
        pathname !== "/dashboard" &&
        pathname !== "/dashboard/config" &&
        pathname !== "/init" &&
        pathname !== "/config" &&
        pathname !== "/user" &&
        pathname !== "/notification" &&
        pathname !== "/history" &&
        !pathname.includes("/history") &&
        pathname !== "/task/config" &&
        pathname !== "/access" &&
        pathname !== "/batch/upload" &&
        pathname !== "/batch/download" &&
        pathname !== "/schema" &&
        pathname !== "/userlist" &&
        pathname !== "/attendance" && (
          <Split className="split" sizes={[25, 75]} gutterSize={3}>
            <div
              className="left"
              id="tree-parent"
              style={{ height: `${treeHeight}vh` }}
            >
              <Tree
                data={tree}
                onClick={treeClickHandler}
                record={record}
                selected={nodeSelected}
                expanded={expanded}
                nodeExpandHandler={nodeExpandHandler}
              />
            </div>
            <div className="right" style={{ height: `${treeHeight}vh` }}>
              <Switch>
                <Route path={modelAccess ? "/model" : "/"} exact>
                  <Model
                    record={modelRecord}
                    documents={documents}
                    options={options}
                    tag={tag}
                    modelData={inventory_model}
                    isTreeChanged={isTreeChangedHandler}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/model/new" exact>
                  <NewModel2
                    options={options}
                    tag={tag}
                    modelData={inventory_model}
                    isTreeChanged={isTreeChangedHandler}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/model/:record2" exact>
                  <Model
                    record={modelRecord}
                    documents={documents}
                    options={options}
                    tag={tag}
                    modelData={inventory_model}
                    isTreeChanged={isTreeChangedHandler}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path={tempAccess ? "/temp" : "/"} exact>
                  <Model
                    record={modelRecord}
                    documents={documents}
                    options={options}
                    tag={tag}
                    modelData={inventory_model}
                    isTreeChanged={isTreeChangedHandler}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path={tempAccess ? "/temp/:record2" : "/"} exact>
                  <Model
                    record={modelRecord}
                    documents={documents}
                    options={options}
                    tag={tag}
                    modelData={inventory_model}
                    isTreeChanged={isTreeChangedHandler}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/change" exact>
                  <Change
                    record={modelRecord}
                    documents={documents}
                    options={options}
                    tag={tag}
                    modelData={inventory_model}
                    isTreeChanged={isTreeChangedHandler}
                    latestVersion={latestVersion}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/validation" exact>
                  <Validation
                    record={modelRecord}
                    documents={documents}
                    options={options}
                    tag={tag}
                  />
                </Route>

                <Route path="/validation/:validationId/new" exact>
                  <NewValidation
                    tag={tag}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/validation/:validationId" exact>
                  <ValidationDetails
                    record={modelRecord}
                    documents={documents}
                    options={options}
                    tag={tag}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/opm" exact>
                  <Opm
                    record={modelRecord}
                    documents={documents}
                    options={options}
                    tag={tag}
                  />
                </Route>
                <Route path="/opm/:opmId/new" exact>
                  <NewOpm
                    isTreeChanged={isTreeChangedHandler}
                    tag={tag}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/opm/:opmId" exact>
                  <OpmDetails
                    isTreeChanged={isTreeChangedHandler}
                    tag={tag}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/issue" exact>
                  <Issues
                    record={modelRecord}
                    documents={documents}
                    options={options}
                    tag={tag}
                  />
                </Route>
                <Route path="/issue/:issueId/new" exact>
                  <NewIssue
                    tag={tag}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/issue/:issueId" exact>
                  <IssueDetails
                    tag={tag}
                    record={modelRecord}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>

                <Route path="/documents" exact>
                  <Document
                    record={record}
                    documents={documents}
                    options={options}
                    tag={tag}
                  />
                </Route>
                <Route path="/documents/:record/new" exact>
                  <NewDocument record={modelRecord} tag={tag} />
                </Route>

                <Route path="/init" exact>
                  <SystemInit
                    record={modelRecord}
                    tag={tag}
                    isTreeChanged={isTreeChangedHandler}
                  />
                </Route>

                <Route path="/config" exact>
                  <OptionConfig
                    record={modelRecord}
                    tag={tag}
                    isTreeChanged={isTreeChangedHandler}
                  />
                </Route>

                <Route path="/user" exact>
                  <User record={modelRecord} tag={tag} />
                </Route>

                <Route path="/notification" exact>
                  <Notification record={modelRecord} tag={tag} />
                </Route>

                <Route path="/history" exact>
                  <History />
                </Route>

                <Route path="/history/:id" exact>
                  <HistoryDetails tag={tag} />
                </Route>

                <Route path="/task/config" exact>
                  <TaskConfig />
                </Route>

                <Route path="/assignment" exact>
                  <Assignment record={record} />
                </Route>

                <Route path="/access" exact>
                  <AccessConfig
                    isUserChanged={isUserChangedHandler}
                    tag={tag}
                  />
                </Route>

                <Route path="/batch/upload" exact>
                  <BatchUpload tag={tag} type="upload" />
                </Route>

                <Route path="/batch/download" exact>
                  <BatchDownload tag={tag} type="download" />
                </Route>

                <Route path="/schema" exact>
                  <GlobalSchema tag={tag} />
                </Route>

                <Route path="/mac" exact>
                  <GlobalSchema tag={tag} />
                </Route>
                <Route path="/mac/:name/new" exact>
                  <NewMac
                    tag={tag}
                    modelData={inventory_model}
                    isTreeChanged={isTreeChangedHandler}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>
                <Route path="/mac/:record" exact>
                  <MacDetails
                    tag={tag}
                    modelData={inventory_model}
                    macDate={record}
                    isTreeChanged={isTreeChangedHandler}
                    setScrollTop={setScrollTop}
                    pageElement=".right"
                  />
                </Route>
                <Route path="/attendance" exact>
                  <MacAttendance tag={tag} />
                </Route>

                <Route path="/userlist" exact>
                  <UserListTable />
                </Route>

                <Redirect to="/" />
              </Switch>
            </div>
          </Split>
        )}
    </div>
  );
};

export default Routes;
