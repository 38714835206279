export const optionException = (tag, modelName, status_option) => {
  if (tag && Array.isArray(status_option) && status_option.length > 0) {
    if (tag.fe_id === "upstream_name" || tag.fe_id === "downstream_name") {
      return modelName;
    } else if (tag.fe_id === "model_status") {
      return status_option?.[0]
        ?.filter((item) => item[0] === "New")
        ?.map((item) => item[1]);
    } else {
      return undefined;
    }
  }
  return undefined;
};

export const conditionalDisabledHandler = (tag, formState) => {
  if (
    tag.fe_id === "vendor_developer" &&
    formState.inputs.vendor &&
    formState.inputs.vendor.value === "No"
  ) {
    return true;
  } else if (
    tag.fe_id === "expire_date" &&
    formState.inputs.approver &&
    formState.inputs.approver.value === "CRO Exception Approval"
  ) {
    return true;
  } else if (
    tag.fe_id === "upstream_code" ||
    tag.fe_id === "downstream_code" ||
    tag.fe_id === "risk_rating" ||
    tag.fe_id === "net_rating" ||
    tag.fe_id === "approve_purpose" ||
    tag.fe_id === "certification_date" ||
    tag.fe_id === "expire_date" ||
    tag.fe_id === "approver"
  ) {
    return true;
  } else if (
    tag.fe_id === "other" &&
    formState.inputs.purpose &&
    formState.inputs.purpose.value !== "Others"
  ) {
    return true;
  } else {
    return false;
  }
};

export const formInitialValueHandler = (
  tag,
  formState,
  modelData,
  status_option,
  storedData
) => {
  // if (
  //   tag.fe_element === "select" &&
  //   (!storedData[tag.fe_id] || storedData[tag.fe_id] === "") &&
  //   tag.group.split(",") &&
  //   tag.fe_id !== "risk_rating" &&
  //   tag.fe_id !== "net_rating" &&
  //   tag.fe_id !== "approver" &&
  //   tag.fe_id !== "model_status"
  // ) {
  //   return tag.group.split(",")[0];
  // }

  if (
    tag.fe_id === "model_status" &&
    (!storedData["model_status"] || storedData["model_status"] === "")
  ) {
    if (
      status_option[0]
        .filter((item) => item[0] === "New")
        .map((item) => item[1])
    ) {
      return status_option[0]
        .filter((item) => item[0] === "New")
        .map((item) => item[1])[0];
    }
  }

  if (
    tag.fe_id === "vendor_developer" &&
    formState.inputs.vendor &&
    formState.inputs.vendor.value === "No"
  ) {
    return "";
  }
  if (
    tag.fe_id === "other" &&
    formState.inputs.purpose &&
    formState.inputs.purpose.value !== "Others"
  ) {
    return "";
  }

  if (
    tag.fe_id === "expire_date" &&
    formState.inputs.approver &&
    formState.inputs.approver.value === "CRO Exception Approval"
  ) {
    return "";
  }

  if (tag.fe_id === "data_source") {
    if (storedData["data_source"] === "") {
      return "Refer to Validation Report Section # and Model Document Section #";
    }
  }

  if (tag.fe_id === "analysis_documentation") {
    if (storedData["analysis_documentation"] === "") {
      return "Refer to Validation Report Section # and Model Document Section #";
    }
  }

  if (tag.fe_id === "upstream_code") {
    if (formState.inputs.upstream_name?.value) {
      return formState.inputs.upstream_name.value
        .map((model_name) => {
          if (modelData.filter((model) => model.name === model_name)[0]) {
            return modelData.filter((model) => model.name === model_name)[0]
              .code;
          } else return [];
        })
        .toString();
    }
  }

  if (tag.fe_id === "downstream_code") {
    if (formState.inputs.downstream_name?.value) {
      return formState.inputs.downstream_name.value
        .map((model_name) => {
          if (modelData.filter((model) => model.name === model_name)[0]) {
            return modelData.filter((model) => model.name === model_name)[0]
              .code;
          }
          return [];
        })
        .toString();
    }
  }

  if (storedData && storedData[tag.fe_id]) {
    return storedData[tag.fe_id];
  }
};

export const newModelIdCheck = (formState, Toast) => {
  if (
    formState.inputs.model_code.value &&
    formState.inputs.model_version.value
  ) {
    if (
      formState.inputs.model_code.value.slice(-2) !==
      formState.inputs.model_version.value.slice(1, 3)
    ) {
      Toast("error", "Model ID and Full Version ID do not match.");
      return false;
    }
  }
  return true;
};

export const newModelNameCheck = (formState, Toast) => {
  if (
    !formState.inputs.name ||
    !formState.inputs.name.value ||
    formState.inputs.name.value.trim() === ""
  ) {
    Toast("error", "Model Name is required.");
    return false;
  }
  return true;
};

export const modelProposePurposeCheck = (formState, Toast) => {
  if (
    !formState.inputs.propose.value ||
    !formState.inputs.propose.value.trim() === ""
  ) {
    Toast("error", "Please input Model Develop Intended Purpose.");
    return false;
  }
  return true;
};

export const modelShortDescription = (formState, Toast) => {
  if (
    !formState.inputs.short_description.value ||
    !formState.inputs.short_description.value.trim() === ""
  ) {
    Toast("error", "Please input Model Short Description.");
    return false;
  }
  return true;
};

export const fileTypeCheck = (newDocument, Toast) => {
  if (Array.from(newDocument, (x) => x.file_type).includes("")) {
    Toast("error", "Please specify the File Type.");
    return false;
  }
  return true;
};
