import dayjs from "dayjs";

// material-ui
import { useTheme } from "@mui/material/styles";

// third-party
import ReactApexChart from "react-apexcharts";

// ==============================|| INCOME AREA CHART ||============================== //

const IncomeAreaChart = (props) => {
  const theme = useTheme();

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;
  const formattedDate = dayjs().format("YYYYMMDD");

  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: `${props.chartTitle}_${formattedDate}`,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: `${props.chartTitle}_${formattedDate}`,
          },
          png: {
            filename: `${props.chartTitle}_${formattedDate}`,
          },
        },
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "11px",
        
      },
     
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
    title: {
      text: "",
      align: "medium",
    },
    // grid: {
    //     row: {
    //       colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
    //       opacity: 0.5,
    //     },
    // },
    xaxis: {
      categories: props.chartLabels,

      axisBorder: {
        show: true,
        color: line,
      },
      tickAmount: 7,
    },
    yaxis: {
      title: {
        text: props.chartAxisLabel,
      },
    },
    stroke: {
      curve: "smooth",
    },
  };

  const series = [
    {
      name: props.chartAxisLabel,
      data: props.chartData,
    },
  ];

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={props.chartHeight}
    />
  );
};

export default IncomeAreaChart;
