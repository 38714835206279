import { useEffect, useState } from "react";
import { writeFile, utils } from "xlsx";
import { useParams } from "react-router-dom";

import HistoryTable from "./HistoryTable";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { CircularProgress, Button } from "@mui/material";
import dayjs from 'dayjs';

const HistoryDetails = ({ tag }) => {
  const { id } = useParams();
  const [currentDetails, setCurrentDetails] = useState({});
  const [previousDetails, setPreviousDetails] = useState([]);
  const [historyDetails, setHistoryDetails] = useState([]);
  const [order, setOrder] = useState({});
  const { isLoading, sendRequest } = useHttpClient();

  const formattedDate = dayjs().format('YYYYMMDD');

  let param;
  if (id) {
    param = id.split("+");
  }

  let url = '';
  if(param?.[0] === "Issue") {
    url = `${process.env.REACT_APP_BACKEND_URL}/history/detail?form=${param[0]}&indicator=${param[1]}&version=${param[2]}&issue_id=${param[3]}&sequence=${param[4]}`;
  } else if (param?.[0] === "Validation") {
    url = `${process.env.REACT_APP_BACKEND_URL}/history/detail?form=${param[0]}&indicator=${param[1]}&version=${param[2]}&validation_id=${param[3]}&sequence=${param[4]}`;
  } else {
    url = `${process.env.REACT_APP_BACKEND_URL}/history/detail?form=${param[0]}&indicator=${param[1]}&version=${param[2]}&sequence=${param[4]}`;
  }

  let downloadFileName = '';
  if(param?.[0] === "Issue" || param?.[0] === "Validation") {
    downloadFileName = `${param[0]}_${param[1]}_${param[2]}_${param[3]}_${param[4]}_${formattedDate}`;
  } else {
    downloadFileName = `${param[0]}_${param[1]}_${param[2]}_${param[4]}_${formattedDate}`;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await sendRequest(url);
        if(Object.keys(response.current).length === 0) {
          setCurrentDetails();
          return;
        }
        setCurrentDetails(response.current);
        setPreviousDetails(response.previous);
        setOrder(response.order);
      } catch (err) {}
    };
    if(param) {
      fetchData();
    }
    
  }, [sendRequest]);

  let arr = [];
  if(currentDetails) {
      Object.keys(currentDetails).map((item) =>
    arr.push(...tag.filter((i) => i.db_tablename === item.toLowerCase()))
  );
  }


  if (!currentDetails) {
    return <h4 style={{textAlign: 'center', marginTop: "3rem"}}>No data</h4>;
  }

  let historyData = [];

  for (const [currentKey, currentValue] of Object.entries(currentDetails)) {
    for (const [key, value] of Object.entries(currentValue)) {
      if (!previousDetails[currentKey]) {
        historyData.push({
          attribute: key,
          previous: "",
          current: value,
          table: currentKey,
        });
      } else {
        historyData.push({
          attribute: key,
          previous: previousDetails[currentKey][key],
          current: value,
          table: currentKey,
        });
      }
    }
  }

  const historyDetailsData = historyData
    .map((data) => {
      const dataOrder = order[data.table][data.attribute];
      const label = arr.find(({ fe_id }) => fe_id === data.attribute)?.fe_label;
      return { ...data, attribute: label, order: dataOrder };
    })
    .sort((a, b) => a.order - b.order)
    .map((item, i) => ({ ...item, id: i + 1 }));

  const finalHistoryData = historyDetailsData.filter(
    (data) => !data.attribute?.includes("PL_BBD")
  );

  const downloadExcel = (finalHistoryData) => {
    const worksheet = utils.json_to_sheet(finalHistoryData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    writeFile(workbook, "DataSheet.xlsx");
  };

  const batchDownloadHandler = async (e) => {
    e.preventDefault();
    downloadExcel(
      finalHistoryData.map((item) => ({
        Table: item.table,
        Attribute: item.attribute,
        Previous: Array.isArray(item.previous)
          ? item.previous.toString()
          : item.previous,
        Current: Array.isArray(item.current)
          ? item.current.toString()
          : item.current,
      }))
    );
  };

  return (
    <div style={{ marginTop: "1rem", minHeight: "89vh" }}>
      {(!historyData || historyData.length === 0) && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      )}

      {historyData.length !== 0 && (
        <div>
          <div
            style={{ display: "flex", gap: "15rem", justifyContent: "center" }}
          >
            <h4>History Log</h4>
          </div>
          <div style={{ margin: "2rem 8rem 1rem 8rem" }}>
            <HistoryTable
              tableData={finalHistoryData}
              isCreateNotShow={false}
              isDetails={true}
              fileName={downloadFileName}
            />
          </div>
        </div>
      )}
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="button"
          onClick={batchDownloadHandler}
          style={{
            marginBottom: 16,
          }}
          variant="outlined"
          className="editButton"
        >
          Download
        </Button>
      </div>
    </div>
  );
};
export default HistoryDetails;
