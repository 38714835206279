import PropTypes from "prop-types";

// material-ui
import { Box, Chip, Grid, Stack, Typography } from "@mui/material";

// project import
import MainCard from "./MainCard";

// assets
import { RiseOutlined, FallOutlined } from "@ant-design/icons";

// ==============================|| STATISTICS - ECOMMERCE CARD  ||============================== //

const CardChart = ({
  color,
  title,
  count,
  percentage,
  isLoss,
  extra,
  status,
  axis,
}) => (
  <MainCard>
    <Stack spacing={0.5}>
      <Typography variant="h5" color="black">
        {axis?.[0]}
      </Typography>
      <Grid container alignItems="center">
        <Grid item>
          <Typography variant="h4" color="inherit">
            {count}
          </Typography>
        </Grid>
        {percentage && (
          <Grid item>
            <Chip
              variant="combined"
              color={color}
              icon={
                <>
                  {/*!isLoss && <RiseOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />*/}
                  {/*isLoss && <FallOutlined style={{ fontSize: '0.75rem', color: 'inherit' }} />*/}
                </>
              }
              label={
                typeof percentage === "number"
                  ? `${Number(percentage).toFixed(2)}%`
                  : `${percentage}`
              }
              sx={{ ml: 1.25, pl: 0 }}
              size="small"
            />
          </Grid>
        )}
      </Grid>
    </Stack>
    <Box sx={{ pt: 2.25 }}>
      <Typography color="black">{status}</Typography>
    </Box>
  </MainCard>
);

CardChart.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  count: PropTypes.string,
  isLoss: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

CardChart.defaultProps = {
  color: "primary",
};

export default CardChart;
