import * as React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Paper, Popper } from "@mui/material";
import { LicenseInfo } from "@mui/x-license-pro";

import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarColumnsButton,
  GridExcelExportOptions,
} from "@mui/x-data-grid-premium";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_TABLE_KEY);

export function CustomToolbar(name) {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton sx={{ color: "#444791" }} />
      <GridToolbarDensitySelector sx={{ color: "#444791" }} />
      <GridToolbarColumnsButton sx={{ color: "#444791" }} />
      <GridToolbarExport
        sx={{
          color: "#444791",
        }}
        printOptions={{ disableToolbarButton: true }}
        csvOptions={{ disableToolbarButton: false, fileName: name }}
        excelOptions={{ fileName: name }}
      />
    </GridToolbarContainer>
  );
}

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = React.memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = React.useRef(null);
  const cellDiv = React.useRef(null);
  const cellValue = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showFullCell, setShowFullCell] = React.useState(false);
  const [showPopper, setShowPopper] = React.useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  React.useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      // IE11, Edge (prior to using Bink?) use 'Esc'
      if (nativeEvent.key === "Escape" || nativeEvent.key === "Esc") {
        setShowFullCell(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: "center",
        lineHeight: "24px",
        width: "100%",
        height: "100%",
        position: "relative",
        display: "flex",
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: "100%",
          width,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      <Box
        ref={cellValue}
        sx={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ marginLeft: -7, zIndex: 99999999 }}
        >
          <Paper
            elevation={1}
            style={{
              minHeight: wrapper.current.offsetHeight - 3,
              maxWidth: wrapper.current.offsetWidth,
            }}
          >
            <Typography
              variant="body2"
              style={{ padding: 8, wordBreak: "break-word" }}
            >
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

GridCellExpand.propTypes = {
  value: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export function renderCellExpand(params) {
  return (
    <GridCellExpand
      value={
        Array.isArray(params.value) ? params.value.join("; ") : params.value
      }
      width={params.colDef.computedWidth}
    />
  );
}

renderCellExpand.propTypes = {
  /**
   * The column of the row that the current cell belongs to.
   */
  colDef: PropTypes.object.isRequired,
  /**
   * The cell value.
   * If the column has `valueGetter`, use `params.row` to directly access the fields.
   */
  value: PropTypes.string,
};

export default function WrapTextTable({ sx: sxProp, ...props }) {
  return (
    <div>
      <DataGridPremium
        {...props}
        showCellRightBorder={true}
        sx={{
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          "& .MuiDataGrid-footerContainer": {
            borderTop: "solid 1px #dfdddd",
          },

          ...sxProp,
        }}
      />
    </div>
  );
}
