import Dexie from "dexie";

const db = new Dexie("mgs");

db.version(1).stores({
  testData: "datakey",
});

export { db };

export async function saveDataInIndexDB(data, form) {
  if (data) {
    if (db.testData) db.testData.delete(form);
    db.testData.add({ datakey: form, data }).then(() => {});
  }
}

export async function getDataFromIndexDB(form) {
  const testData = await db.testData.where("datakey").equals(form).toArray();
  if (testData && testData.length > 0) {
    return testData[0];
  }
  return null;
}

export async function deleteDataFromIndexDB(form) {
  await db.testData.delete(form);
}

export async function clearIndexDB() {
  await db.testData.clear();
}

export const getDataFromDB = async (form) => {
  let data = await getDataFromIndexDB(form);
};
