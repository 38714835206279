import { useContext } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";

import { UserContext } from "../../context/user-context";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DocumentTable from "./DocumentTable";

const fileAmount = (obj) => {
  if(!obj || obj === null || Array.isArray(obj) || Object.keys(obj).length <= 0) {
    return "0";
  } else {
    return Object.values(obj)?.flat()?.length;
  }
}

const DocumentAccordion = ({ data, updatePageHandler }) => {
  const { tabNameOutToInHandler } =
    useContext(UserContext);

  let element = data.map((item, key) => {
    if (Array.isArray(Object.values(item)?.[0])) {
      return (
        <Accordion
          key={key}
          sx={{
            color: "inherit",

            borderRadius: "0 !important",

            borderTop: "none",

            borderRight: "none",

            borderLeft: "none",

            borderBottom: "none",
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="document-management"
            id="document-management"
            sx={{
              width: "380px",
              "& .MuiAccordionSummary-content": {
                justifyContent: "space-between",
              },
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
              {tabNameOutToInHandler(Object.keys(item)?.[0])}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: 16,
                ml: 10,
                color: "#CB3694",
              }}
            >
              ({Object.entries(Object.values(item)?.[0])?.length})
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box>
              <DocumentTable
                documentRecord={Object.values(item)?.[0]}
                isDocumentChanged={updatePageHandler}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      if (Object.keys(item)?.[0] !== "Model") {
        return (
          <Accordion
            key={key}
            sx={{
              color: "inherit",

              borderRadius: "0 !important",

              borderTop: "none",

              borderRight: "none",

              borderLeft: "none",

              borderBottom: "none",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="document-management"
              id="document-management"
              sx={{
                width: "380px",
                "& .MuiAccordionSummary-content": {
                  justifyContent: "space-between",
                },
              }}
            >
              <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
                {tabNameOutToInHandler(Object.keys(item)?.[0])}
              </Typography>
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: 16,
                  ml: 10,
                  color: "#CB3694",
                }}
              >
                ({fileAmount(Object.values(item)?.[0])})
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                {Object.entries(Object.values(item)?.[0]).map(
                  ([key, value]) => {
                    return (
                      <Accordion
                        sx={{
                          color: "inherit",
                          borderRadius: "0 !important",
                          borderTop: "none",
                          borderRight: "none",
                          borderLeft: "none",
                          borderBottom: "none",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="document-management"
                          id="document-management"
                          sx={{
                            minWidth: "50%",
                            p: 0,
                            pl: 1,
                            "& .MuiAccordionSummary-content": {
                              justifyContent: "space-between",
                            },
                          }}
                        >
                          <Typography sx={{ fontWeight: 500 }}>
                            {key}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                              fontSize: 16,
                              ml: 10,
                              color: "#CB3694",
                            }}
                          >
                            ({value.length})
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            <DocumentTable
                              documentRecord={value}
                              isDocumentChanged={updatePageHandler}
                            />
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    );
                  }
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        );
      }
    }
  });
  return <div>{element}</div>;
};

export default DocumentAccordion;
