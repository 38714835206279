import { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";

import {
  List,
  Button,
  CircularProgress,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Drawer,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import FilterListIcon from "@mui/icons-material/FilterList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";

import InputNew from "../../components/Content/FormElements/InputNew";
import DashboardLayout from "../DashboardPage/DashboardLayout";
import Toast from "../../components/Utils/Toast";

const isEmptyObject = (obj) => {
  return (
    typeof obj === "object" &&
    obj instanceof Object &&
    Object.keys(obj).length === 0
  );
};

const parseJSON = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return str;
  }
  return JSON.parse(str);
};

const Report = () => {
  const { pathname, data, filter } = useLocation();
  const { entity } = useParams();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm({}, false);
  const [filteredData, setFilteredData] = useState({});
  const [dateRange, setDateRange] = useState({
    start_date: "",
    end_date: "",
  });

  const [open, setOpen] = useState(false);

  const [checked, setChecked] = useState({});

  useEffect(() => {
    setDateRange({
      start_date: formState.inputs?.start_date?.value || "",
      end_date: formState.inputs?.end_date?.value || "",
    });
  }, [formState.inputs?.start_date?.value, formState.inputs?.end_date?.value]);

  const handleCheckboxChange = (mainKey, key, value) => (event) => {
    const isChecked = event.target.checked;
    setChecked((prevChecked) => {
      const updatedChecked = { ...prevChecked };
      if (isChecked) {
        // If the checkbox is being checked, add the value to the state.
        return {
          ...updatedChecked,
          [mainKey]: {
            ...updatedChecked[mainKey],
            [key]: [...(updatedChecked[mainKey]?.[key] || []), value],
          },
        };
      } else {
        // If the checkbox is being unchecked, remove the value from the state.
        const filteredValues = updatedChecked[mainKey][key].filter(
          (v) => v !== value
        );

        const filteredKeys = {
          ...updatedChecked[mainKey],
          [key]: filteredValues,
        };

        const filteredMain = Object.keys(filteredKeys)
          .filter((k) => filteredKeys[k].length > 0)
          .reduce((res, key) => ((res[key] = filteredKeys[key]), res), {});

        const finalChecked = {
          ...updatedChecked,
          [mainKey]: { ...filteredMain },
        };

        if (Object.keys(finalChecked[mainKey]).length === 0) {
          const { [mainKey]: _, ...rest } = finalChecked; // This removes the mainKey from the object if it's empty
          return rest;
        }

        return finalChecked;
      }
    });
  };

  const toggleDrawer = (bool) => {
    setOpen(bool);
  };

  const applyFilterHandler = async () => {

    if((formState.inputs?.start_date?.value === "" && formState.inputs?.end_date?.value !== "") ||
    (formState.inputs?.start_date?.value !== "" && formState.inputs?.end_date?.value === "")
    ) {
      Toast("error", "Please fill in the correct date.");
      return;
    }

    if(new Date(formState.inputs?.end_date?.value) < new Date(formState.inputs?.start_date?.value)) {
      Toast("error", "End date cannot be before start date.");
      return;
    }

    try {
      const filterBody = {
        ...checked,
        "start date": formState.inputs?.start_date?.value || "",
        "end date": formState.inputs?.end_date?.value || "",
      };

      for (let key in filterBody) {
        if (filterBody[key] === "") delete filterBody[key];
      }

      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/summary/overall?entity=${entity}`,
        "POST",
        JSON.stringify(filterBody),
        {
          "Content-Type": "application/json",
        }
      );
      setFilteredData(response);
    } catch (err) {
      console.warning(err);
    }
  };

  const clearFilterHandler = () => {
    setChecked({});
    setDateRange({
      start_date: "",
      end_date: "",
    });
  };

  let layoutData = [];
  if (data && parseJSON(data)) {
    layoutData = parseJSON(data);
  }

  let filterTemplate = [];
  if (filter && parseJSON(filter)) {
    filterTemplate = parseJSON(filter);
  }

  const list = (
    <Box sx={{ width: 500, overflowY: "overlay" }} role="presentation">
      <List>
        <ListItem disablePadding>
          <ListItemText
            primary="Filter"
            primaryTypographyProps={{ fontWeight: "bold" }}
          />
          <FilterListIcon />
        </ListItem>
      </List>

      <Divider
        sx={{ background: "black", ml: 0, mr: 0, mt: 1, mb: 1 }}
        variant="middle"
      />

      <div style={{ display: "flex", alignItems: "flex-end" }}>
        <InputNew
          id="searchBar"
          type="text"
          label=""
          element="input"
          initialValue=""
          onInput={inputHandler}
          onBlur
          placeholder="Search"
        />
      </div>

      <List sx={{ p: 0, mt: 1 }}>
        <ListItem disablePadding>
          <div
            style={{
              fontSize: "1rem",
              fontWeight: 400,
              fontFamily: "Inter",
              paddingLeft: "0.8rem",
            }}
          >
            From:
          </div>
          <InputNew
            id="start_date"
            type="text"
            label=""
            element="date"
            initialValue={dateRange?.start_date}
            onInput={inputHandler}
            onBlur="dashboard"
            placeholder="Search"
          />
          <div
            style={{ fontSize: "1rem", fontWeight: 400, fontFamily: "Inter" }}
          >
            To:
          </div>
          <InputNew
            id="end_date"
            type="text"
            label=""
            element="date"
            initialValue={dateRange?.end_date}
            onInput={inputHandler}
            onBlur="dashboard"
            placeholder="Search"
          />
        </ListItem>
      </List>

      <Box sx={{ mt: 2 }}>
        {typeof filterTemplate === "object" &&
          Object.entries(filterTemplate)
            .filter(([mainKey, mainValue]) =>
              Object.values(mainValue).some(
                (values) =>
                  Array.isArray(values) &&
                  values.some((value) =>
                    typeof value === "string" &&
                    typeof formState.inputs?.searchBar?.value === "string"
                      ? value
                          .toLowerCase()
                          .includes(
                            formState.inputs?.searchBar?.value.toLowerCase()
                          )
                      : false
                  )
              )
            )
            .map(([mainKey, mainValue]) =>
              Object.entries(mainValue)
                .filter(
                  ([key, values]) =>
                    Array.isArray(values) &&
                    values.some((value) =>
                      typeof value === "string" &&
                      typeof formState.inputs?.searchBar?.value === "string"
                        ? value
                            .toLowerCase()
                            .includes(
                              formState.inputs?.searchBar?.value.toLowerCase()
                            )
                        : false
                    )
                )
                .map(([key, values]) => (
                  <Accordion
                    key={mainKey + "-" + key}
                    sx={{ borderRadius: "0 !important" }}
                  >
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      {key}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box display="flex" flexDirection="column">
                        {Array.isArray(values) &&
                          values
                            .filter((value) =>
                              typeof value === "string" &&
                              typeof formState.inputs?.searchBar?.value ===
                                "string"
                                ? value
                                    .toLowerCase()
                                    .includes(
                                      formState.inputs?.searchBar?.value.toLowerCase()
                                    )
                                : false
                            )
                            .map((value) => (
                              <FormControlLabel
                                key={value}
                                control={
                                  <Checkbox
                                    checked={
                                      checked[mainKey] &&
                                      checked[mainKey][key] &&
                                      Array.isArray(checked[mainKey][key]) &&
                                      checked[mainKey][key].includes(value)
                                        ? true
                                        : false
                                    }
                                    onChange={handleCheckboxChange(
                                      mainKey,
                                      key,
                                      value
                                    )}
                                  />
                                }
                                label={value}
                              />
                            ))}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))
            )}

        <Box
          sx={{
            bgcolor: "white", // sets background color
            position: "sticky", // makes the box sticky
            bottom: 0, // sticks the box to the bottom
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            variant="outlined"
            style={{
              margin: "0.5rem",
            }}
            onClick={applyFilterHandler}
          >
            Apply
          </Button>
          <Button
            variant="outlined"
            style={{
              margin: "0.5rem",
            }}
            onClick={clearFilterHandler}
          >
            Clear
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    <div style={{ margin: "4rem 4rem 3rem 4rem" }}>
      <h4 style={{ display: "flex", justifyContent: "center" }}>
        {layoutData?.description || ""}
      </h4>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "1rem",
        }}
      >
        <Button variant="outlined" onClick={() => toggleDrawer(true)}>
          Filter
        </Button>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            ml: 1,
            color: "#444791",
          }}
        >
          {/*isEmptyObject(filteredData) ? (
            <FilterAltOffOutlinedIcon color="inherit" />
          ) : (
            <FilterAltOutlinedIcon color="inherit" />
          )*/}
          <FilterAltOutlinedIcon color="inherit" />
        </Box>
        <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
          {list}
        </Drawer>
      </div>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      )}
      {!isLoading && (
        <DashboardLayout
          layoutData={isEmptyObject(filteredData) ? layoutData : filteredData}
        />
      )}
    </div>
  );
};
export default Report;
