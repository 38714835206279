import React, { Fragment, useState, useEffect, useContext } from "react";
// import { gridClasses } from "@mui/x-data-grid";
import {
  Menu,
  MenuItem,
  // Button,
  CircularProgress,
  Box,
  // Popper,
  // Paper,
  // Typography,
} from "@mui/material";
// import { useHistory } from "react-router-dom";
import WrapTextTable, {
  renderCellExpand,
} from "../../components/CustomComponents/WrapTextTable";
import { Popconfirm } from "antd";
import { GridActionsCellItem } from "@mui/x-data-grid-pro";
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";

import { UserContext } from "../../context/user-context";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";

const UserListTable = (props) => {
  // const history = useHistory();
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { user, token, userGroup, fileTypeInToOutHandler, socket } =
    useContext(UserContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [isUserChanged, setIsUserChanged] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/state`
        );
        setIsLoading(false);
        setRows(
          responseData.state.map((user, i) => ({
            id: i,
            name: user[0],
            form: user[1],
            code: user[2],
            version: user[3],
            issueId: user[4] || "",
          }))
        );
      } catch (err) {}
    };
    fetchUsers();
  }, [isUserChanged, sendRequest]);

  const columns = [
    {
      field: "name",
      headerName: "User Name",
      minWidth: 200,
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 2,
    },
    {
      field: "form",
      headerName: "Module",
      width: 100,
      align: "center",
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      field: "code",
      headerName: "Model ID",
      align: "center",
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      field: "version",
      headerName: "Full Version ID",
      align: "center",
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      field: "issueId",
      headerName: "Model Issue ID",
      align: "center",
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      field: "edit",
      headerName: "Action",
      align: "center",
      headerAlign: "center",
      renderCell: (cellValues) => {
        return (
          <Fragment>
            <Popconfirm
              title="Are you sure to suspend the user?"
              onConfirm={() => {
                socket.emit(
                  "message",
                  JSON.stringify({
                    operation: "logout",
                    data: cellValues.row.name,
                  })
                );
                setIsUserChanged((prev) => !prev);
              }}
              okText="Yes"
              cancelText="No"
            >
              <GridActionsCellItem
                icon={<DesktopAccessDisabledIcon />}
                label="Delete"
                color="error"
                disabled={user?.user_group?.[0] === "Admin" ? false : true}
              />
            </Popconfirm>
          </Fragment>
        );
      },
    },
  ];

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertToUppercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toUpperCase(),
          email: row.email.toUpperCase(),
          title: row.title.toUpperCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const convertToLowercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toLowerCase(),
          email: row.email.toLowerCase(),
          title: row.title.toLowerCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };
  // different color in even line and hover effect
  return (
    <div style={{ margin: "0 8rem 2rem 8rem" }}>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <div>
          <div style={{ width: "100%", marginTop: "3rem" }}>
            <WrapTextTable
              autoHeight
              rowHeight={30}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              columns={columns}
              rows={rows}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              components={{
                Footer: () => (
                  <div
                    className="MuiDataGrid-footerContainer css-17jjc08-MuiDataGrid-footerContainer"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box sx={{ p: 1, display: "flex", justifyContent: "end" }}>
                      Total Users: {rows.length}
                    </Box>
                  </div>
                ),
              }}
              showCellRightBorder={true}
            />

            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
              componentsProps={{
                root: {
                  onContextMenu: (e) => {
                    e.preventDefault();
                    handleClose();
                  },
                },
              }}
            >
              <MenuItem onClick={convertToUppercase}>UPPERCASE</MenuItem>
              <MenuItem onClick={convertToLowercase}>lowercase</MenuItem>
            </Menu>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserListTable;
