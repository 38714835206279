import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "./styles/globals.css";
import "antd/dist/antd.min.css";
//import 'antd/dist/reset.css';
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(document.getElementById("root"));

const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  <StrictMode>
    <MsalProvider instance={msalInstance}>
      <ToastContainer />
      <App />
    </MsalProvider>
  </StrictMode>
);
