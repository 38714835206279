import { useState, useEffect, Fragment, useContext } from "react";
import dayjs from "dayjs";
import InputNew from "../../components/Content/FormElements/InputNew";
import { UserContext } from "../../context/user-context";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import {
  Button,
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Backdrop,
  Fade,
  CircularProgress,
  Grid,
} from "@mui/material";

import "./Query.css";
import Toast from "../../components/Utils/Toast";
import _ from "lodash";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  border: "1px solid rgba(224, 224, 224, 1)",
  borderRadius: "5px",
  boxShadow:
    "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
  p: 4,
  overflow: "auto",
};

const Query = (props) => {
  const { error, sendRequest, clearError } = useHttpClient();
  const { user, tabNameOutToInHandler, tabNameInToOutHandler } =
    useContext(UserContext);
  const [formState, inputHandler] = useForm(
    { form_query: { value: "" }, form_query_new: { value: "" } },
    false
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [queryList, setQueryList] = useState([]);
  const [querySelected, setQuerySelected] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [currentForm, setCurrentForm] = useState({
    "due descriptor": [],
    operation: [],
  });
  const [isChanged, setIsChanged] = useState(false);
  const [height, setHeight] = useState(0);

  const pageHeight = (
    (document.querySelector(".plain-page")?.offsetHeight * 100) /
      window.innerHeight -
    18
  ).toFixed(2);

  const containerHeight = (
    (document.querySelector(".query-box")?.offsetHeight * 100) /
    window.innerHeight
  ).toFixed(2);

  useEffect(() => {
    const element = document.querySelector(".query-box");

    if (element) {
      // Initialize the ResizeObserver and set the callback
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setHeight(entry.contentRect.height);
        }
      });

      // Start observing the target element
      resizeObserver.observe(element);

      return () => {
        // Clean up the observer on component unmount
        resizeObserver.disconnect();
      };
    }
  }, []);

  const { display_name } = user || {};

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/query`
        );
        setIsLoading(false);
        setQueryList(response.data);
      } catch (err) {
        console.warn(err);
      }
    };
    fetchData();
  }, [sendRequest, isChanged]);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleSelectionClick = async (event, index) => {
    setSelectedIndex(index);
    setQuerySelected(queryList.find((item) => item.title === index));
  };

  const addNewQueryHandler = async (e) => {
    e.preventDefault();
    if (
      !formState.inputs?.query_title_new?.value ||
      formState.inputs?.query_title_new?.value === ""
    ) {
      Toast("error", "Please enter title.");
      return;
    }
    if (
      queryList.find(
        (item) => item.title === formState.inputs?.query_title_new?.value
      )
    ) {
      Toast("error", "This query already exists.");
      return;
    }

    try {
      const body = {
        title: formState.inputs?.query_title_new?.value || "",
        description: formState.inputs?.query_description_new?.value || "",
        statement: formState.inputs?.query_statement_new?.value || "",
        user_id: display_name || "",
        update_time: currentDate || "",
      };

      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/query?operation=insert`,
        "POST",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      if (!res.title) {
        Toast("error", res);
        return;
      } else {
        Toast("success", "Add success.");
        setModalOpen(false);
      }
      setIsChanged((prev) => !prev);
    } catch (err) {
      alert(err, 3);
    }
  };

  const updateQueryHandler = async (e) => {
    e.preventDefault();
    if (_.isEmpty(querySelected)) {
      Toast("error", "Please select a query.");
      return;
    }
    try {
      const body = {
        title: formState.inputs?.query_title?.value || "",
        description: formState.inputs?.query_description?.value || "",
        statement: formState.inputs?.query_statement?.value || "",
        user_id: display_name || "",
        update_time: currentDate || "",
      };
      setIsLoading(true);
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/query?operation=update`,
        "POST",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      if (!res.title) {
        Toast("error", res);
        return;
      } else {
        Toast("success", "Update success.");
        setQuerySelected(res);
      }
      setIsLoading(false);
      setIsChanged((prev) => !prev);
    } catch (err) {
      alert(err, 3);
    }
  };

  const deleteQueryHandler = async (e) => {
    e.preventDefault();
    if (_.isEmpty(querySelected)) {
      Toast("error", "Please select a query.");
      return;
    }
    try {
      setIsLoading(true);
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/query?title=${selectedIndex}`,
        "DELETE",
        null,
        { "Content-Type": "application/json" }
      );
      if (!res.data) {
        Toast("error", res);
        return;
      } else {
        Toast("success", "Delete success.");
      }
      setSelectedIndex(res?.data?.[0]?.title || "");
      setQuerySelected(res?.data?.[0] || "");
      setIsLoading(false);
      setQueryList(res?.data);
      setIsChanged((prev) => !prev);
    } catch (err) {
      alert(err, 3);
    }
  };

  if (!querySelected) {
    setQuerySelected({});
  }

  let element = (
    <div style={{ margin: "0 3rem 0 3rem" }} className="query-box">
      <Button variant="outlined" className="editButton" onClick={handleOpen}>
        New
      </Button>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container rowSpacing={0.5} columnSpacing={5}>
          <Grid item xs={12}>
            <InputNew
              id="query_title"
              label="Title"
              element="input"
              validators={[]}
              onInput={inputHandler}
              initialValue={querySelected?.title}
              onBlur
              disabled
            />
          </Grid>

          <Grid item xs={12}>
            <InputNew
              id="query_description"
              label="Description"
              element="textEditor"
              initialValue={querySelected?.description}
              validators={[]}
              rows={5}
              onInput={inputHandler}
              onBlur
              setScrollTop={props.setScrollTop}
              pageElement={props.pageElement}
              isAutoSize
            />
          </Grid>

          <Grid item xs={12}>
            <InputNew
              id="query_statement"
              label="Statement"
              element="textEditor"
              initialValue={querySelected?.statement}
              validators={[]}
              rows={8}
              onInput={inputHandler}
              onBlur
              setScrollTop={props.setScrollTop}
              pageElement={props.pageElement}
              isAutoSize
            />
          </Grid>
        </Grid>
        <div className="query-button">
          <Button
            variant="outlined"
            className="editButton"
            onClick={updateQueryHandler}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            className="editButton"
            onClick={deleteQueryHandler}
          >
            Delete
          </Button>
          <div>{isLoading && <CircularProgress />}</div>
        </div>
      </Box>
    </div>
  );

  if (!queryList) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 1000,
        }}
      >
        <Fade in={modalOpen}>
          <Box sx={modalStyle}>
            <Grid container rowSpacing={0} columnSpacing={5}>
              <Grid item xs={12}>
                <InputNew
                  id="query_title_new"
                  label="Title"
                  element="input"
                  validators={[]}
                  onInput={inputHandler}
                  onBlur
                />
              </Grid>

              <Grid item xs={12}>
                <InputNew
                  id="query_description_new"
                  label="Description"
                  element="textEditor"
                  validators={[]}
                  initialValue=""
                  rows={10}
                  onInput={inputHandler}
                  onBlur
                  setScrollTop={props.setScrollTop}
                  pageElement={props.pageElement}
                  isAutoSize
                />
              </Grid>
              <Grid item xs={12}>
                <InputNew
                  id="query_statement_new"
                  label="Statement"
                  element="textEditor"
                  validators={[]}
                  initialValue="
                  <p><code>Please code here</code></p>
                      "
                  rows={10}
                  onInput={inputHandler}
                  setScrollTop={props.setScrollTop}
                  pageElement={props.pageElement}
                  onBlur
                  isAutoSize
                />
              </Grid>
            </Grid>

            <div className="query-button">
              <Button
                variant="outlined"
                className="editButton"
                onClick={addNewQueryHandler}
              >
                Add New Query
              </Button>
              <Button
                variant="outlined"
                className="editButton"
                onClick={handleClose}
              >
                Exit
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>Query List</h4>
      </div>
      <hr />
      <div className="query-layout" style={{ margin: "2rem 9rem 2rem 9rem" }}>
        <div
          className="query-option"
          style={{ height: `${containerHeight}vh` }}
        >
          {queryList.map((item, i) => (
            <ListItem disablePadding key={i}>
              <ListItemButton
                selected={selectedIndex === `${item.title}`}
                onClick={(event) => {
                  handleSelectionClick(event, `${item.title}`);
                }}
              >
                <ListItemText primary={`${item.title}`} />
              </ListItemButton>
            </ListItem>
          ))}
        </div>
        <form className="query-container">{element}</form>
      </div>
    </div>
  );
};

export default Query;
