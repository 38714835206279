import { useState, useEffect, useContext, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import { GridActionsCellItem, GridCellModes } from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";
import WrapTextTable from "../../components/CustomComponents/WrapTextTable";

import { UserContext } from "../../context/user-context";

export default function FullFeaturedCrudGrid(props) {
  const { tag } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [cellModesModel, setCellModesModel] = useState({});

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        assignee: "",
        user_group: "",
        email: "",
        comment: "",
        isNew: true,
      },
    ]);

    setCellModesModel((oldModel) => ({
      ...oldModel,
    }));
  };

  useEffect(() => {
    props.assignmentData &&
      setRows(
        props.assignmentData.map((data, i) => ({
          ...data,
          id: i,
          isNew: false,
          assignee: props?.userData?.find((i) => i[1] === data.email)?.[2],
        }))
      );
  }, [props.assignmentData]);

  const handleCellClick = useCallback((params) => {
    if (params.field === "actions") return;
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    props.assignmentDataChanged(rows.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    props.assignmentDataChanged(
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const columns = [
    {
      field: "assignee",
      headerName: "Name",
      type: "singleSelect",
      valueOptions: props?.userData?.map((i) => ({
        value: i[2],
        label: i[0],
      })),
      flex: 1,
      editable: true,
      headerAlign: "center",
      renderCell: (params) =>
        props?.userData.find((data) => data[2] === params.value)?.[0] ||
        params.value,
    },

    {
      field: "user_group",
      headerName: "Role",
      type: "singleSelect",
      headerAlign: "center",
      valueOptions: tag
        ?.filter(
          (i) => i.db_tablename === "assignment" && i.fe_id === "user_group"
        )[0]
        ?.group.split(","),
      flex: 1,
      editable: true,
    },

    {
      field: "email",
      headerName: "Email",
      type: "string",
      headerAlign: "center",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return props.userData.find((user) => user[2] === params.row.assignee)
          ? props.userData.find((user) => user[2] === params.row.assignee)[1]
          : "";
      },
      renderEditCell: (params) => {
        return props.userData.find((user) => user[2] === params.row.assignee)
          ? props.userData.find((user) => user[2] === params.row.assignee)[1]
          : "";
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      type: "string",
      headerAlign: "center",
      flex: 1,
      editable: true,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.5,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  return (
    <Box>
      <Button
        variant="outlined"
        className="editButton"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Add Assignee
      </Button>
      <WrapTextTable
        headerHeight={40}
        rowHeight={40}
        autoHeight
        rows={rows}
        columns={columns}
        isCellEditable={(params) => true}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        hideFooter={true}
        showCellRightBorder={true}
        sx={{
          boxShadow: "none",
        }}
      />
    </Box>
  );
}
