import { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Backdrop,
  Box,
  Modal,
  Fade,
} from "@mui/material";

import Toast from "../../components/Utils/Toast";

import InputNew from "../../components/Content/FormElements/InputNew";

import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";

import "./SystemInit.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "90%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SystemInit = (props) => {
  const { isLoading, error, sendRequest } = useHttpClient();
  const [initData, setInitData] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [optionTable, setOptionTable] = useState([]);
  const [open, setOpen] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [formState, inputHandler] = useForm(
    {
      db_tablename: {
        isValid: true,
      },
      attributes: {
        isValid: true,
      },
      class_name: {
        isValid: true,
      },
      source_column: {
        isValid: true,
      },
      db_column: {
        isValid: true,
      },
      data_type: {
        isValid: true,
      },
      db_selectlist: {
        isValid: true,
      },
      nullable: {
        isValid: true,
      },
      primary_key: {
        isValid: true,
      },
      fe_element: {
        isValid: true,
      },
      fe_label: {
        isValid: true,
      },
      fe_type: {
        isValid: true,
      },
      fe_isrender: {
        isValid: true,
      },
      fe_id: {
        isValid: true,
      },
      fe_order: {
        isValid: true,
      },
    },
    false
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/config/table`
        );
        setInitData(response.attribute || []);
        setModelList(
          [...new Set(response.class_name)].filter(
            (data) =>
              data !== "AccessControl" &&
              data !== "DocumentAccess" &&
              data !== "IssueAccess" &&
              data !== "ModelAccess" &&
              data !== "OPMAccess" &&
              data !== "ValidationAccess" &&
              data !== "Select"
          )
        );
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, isChanged]);

  useEffect(() => {
    initData &&
      setOptionTable(
        initData.filter(
          (data) => data.class_name === formState.inputs.class_name.value
        )
      );
  }, [initData, formState.inputs.class_name.value]);

  useEffect(() => {
    optionTable &&
      setColumnList(
        optionTable.filter(
          (option) => option.fe_label === formState.inputs.attributes.value
        )
      );
  }, [optionTable, formState.inputs.attributes.value]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const newAttributeHandler = async (e) => {
    e.preventDefault();
    try {
      const body = {
        class_name: formState.inputs.class_name_new.value || "",
        db_tablename: formState.inputs.db_tablename_new.value || "",
        source_column: formState.inputs.source_column_new.value || "",
        db_column: formState.inputs.db_column_new.value || "",
        db_selectlist: formState.inputs.db_selectlist_new.value || "",
        fe_label: formState.inputs.fe_label_new.value || "",
        fe_element: formState.inputs.fe_element_new.value || "",
        fe_type: formState.inputs.fe_type_new.value || "",
        fe_isrender: (formState.inputs.fe_isrender_new.value || "") === "true",
        fe_id: formState.inputs.fe_id_new.value || "",
        fe_order: Number(formState.inputs.fe_order_new.value) || "",
      };
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/config/table/insert`,
        "POST",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      await sendRequest(process.env.REACT_APP_BACKEND_URL + "/restart");
      alert("Table Created.", 3);
      //setIsChanged((prev) => !prev);
      props.isTreeChanged();
    } catch (err) {}
  };

  const deleteAttributeHandler = async (e) => {
    e.preventDefault();
    try {
      const body = {
        class_name: columnList[0].class_name,
        db_tablename: columnList[0].db_tablename,
        db_column: columnList[0].db_column,
        fe_label: columnList[0].fe_label,
      };
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/config/table/delete`,
        "DELETE",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      alert("Table Deleted.", 3);
      await sendRequest(process.env.REACT_APP_BACKEND_URL + "/restart");
      setIsChanged((prev) => !prev);
      props.isTreeChanged();
    } catch (err) {}
  };
  const saveAttributeHandler = async (e) => {
    e.preventDefault();
    if (!columnList[0]) {
      alert("Please check data received.", 3);
      return;
    }
    try {
      const body = {
        class_name: columnList[0].class_name,
        db_tablename: columnList[0].db_tablename,
        db_column: columnList[0].db_column,
        field: {
          class_name: columnList[0].class_name,
          db_tablename: columnList[0].db_tablename,
          section: columnList[0].section,
          source_column: columnList[0].source_column,
          db_column: columnList[0].db_column,
          db_datatype: columnList[0].db_datatype,
          db_nullable: columnList[0].db_nullable,
          db_key: columnList[0].db_key,
          db_selectlist: formState.inputs.db_selectlist.value,
          fe_label: formState.inputs.fe_label.value,
          fe_element: formState.inputs.fe_element.value,
          fe_type: formState.inputs.fe_type.value,
          fe_isrender: formState.inputs.fe_isrender.value === "true",
          fe_id: formState.inputs.fe_id.value,
          fe_order: formState.inputs.fe_order.value,
          fe_check: formState.inputs.fe_check.value,
          fe_grid: formState.inputs.fe_grid.value,
        },
      };
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/config/table/update`,
        "PUT",
        JSON.stringify(body),
        { "Content-Type": "application/json" }
      );
      if (!res.attribute) {
        Toast("error", res, "Something wrong happened.");
        return;
      } else {
        Toast("success", "Table config updated.");
        setIsChanged((prev) => !prev);
        props.isTreeChanged();
      }
    } catch (err) {
      Toast("error", err);
    }
  };

  if (isLoading || !initData || !modelList || !optionTable || !columnList) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20rem",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const formElement = columnList[0] && (
    <div>
      <InputNew
        id="class_name"
        type="text"
        label="class_name"
        element="input"
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        initialValue={columnList[0].class_name}
        onBlur
        disabled
      />
      <InputNew
        id="db_tablename"
        type="text"
        label="db_tablename"
        element="input"
        initialValue={columnList[0].db_tablename}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
        disabled
      />
      <InputNew
        id="section"
        type="text"
        label="section"
        element="input"
        initialValue={columnList[0].section}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
        disabled
      />
      <InputNew
        id="source_column"
        type="text"
        label="source_column"
        element="input"
        initialValue={columnList[0].source_column}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
        disabled
      />
      <InputNew
        id="db_column"
        type="text"
        label="db_column"
        element="input"
        initialValue={columnList[0].db_column}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
        disabled
      />
      <InputNew
        id="db_datatype"
        type="text"
        label="db_datatype"
        element="select"
        initialValue={columnList[0].db_datatype}
        errorText="Please enter a valid Code"
        clean_options={["string", "integer", "boolean", "date", "datetime"]}
        validators={[]}
        onInput={inputHandler}
        onBlur
        disabled
      />
      <InputNew
        id="db_nullable"
        type="text"
        label="db_nullable"
        element="input"
        initialValue={columnList[0].db_nullable}
        clean_options={["true", "false"]}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
        disabled
      />
      <InputNew
        id="db_key"
        type="text"
        label="db_key"
        element="select"
        initialValue={columnList[0].db_key}
        clean_options={["true", "false"]}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
        disabled
      />

      <InputNew
        id="db_selectlist"
        type="text"
        label="db_selectlist"
        element="input"
        initialValue={columnList[0].db_selectlist}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
      />
      <InputNew
        id="fe_label"
        type="text"
        label="fe_label"
        element="input"
        initialValue={columnList[0].fe_label}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
      />
      <InputNew
        id="fe_element"
        type="text"
        label="fe_element"
        element="select"
        initialValue={columnList[0].fe_element}
        errorText="Please enter a valid Code"
        clean_options={[
          "input",
          "select",
          "date",
          "textEditor",
          "textarea",
          "time",
          "metricTable",
        ]}
        validators={[]}
        onInput={inputHandler}
        onBlur
      />
      <InputNew
        id="fe_type"
        type="text"
        label="fe_type"
        element="select"
        initialValue={columnList[0].fe_type}
        clean_options={["string", "date", "number", "datetime", "time"]}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
      />
      <InputNew
        id="fe_isrender"
        type="text"
        label="fe_isrender"
        element="select"
        initialValue={columnList[0].fe_isrender ? "true" : "false"}
        clean_options={["true", "false"]}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
      />
      <InputNew
        id="fe_id"
        type="text"
        label="fe_id"
        element="input"
        initialValue={columnList[0].fe_id}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
      />
      <InputNew
        id="fe_order"
        type="number"
        label="fe_order"
        element="input"
        initialValue={columnList[0].fe_order}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
      />
      <InputNew
        id="fe_check"
        type="text"
        label="fe_check"
        element="input"
        initialValue={columnList[0].fe_check}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
      />

      <InputNew
        id="fe_grid"
        type="number"
        label="fe_grid"
        element="input"
        initialValue={columnList[0].fe_grid}
        errorText="Please enter a valid Code"
        validators={[]}
        onInput={inputHandler}
        onBlur
      />
    </div>
  );

  return (
    <div style={{ height: "100vh", overflowY: "auto" }}>
      {(isLoading || !initData) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <div className="parent-init">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 1000,
          }}
        >
          <Fade in={open}>
            <Box sx={modalStyle}>
              <form>
                <div className="modal-input">
                  <InputNew
                    id="class_name_new"
                    type="text"
                    label="class_name"
                    element="select"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    clean_options={modelList}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="db_column_new"
                    type="text"
                    label="db_column"
                    element="input"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="db_datatype_new"
                    type="text"
                    label="db_datatype"
                    element="select"
                    errorText="Please enter a valid Code"
                    clean_options={[
                      "string",
                      "integer",
                      "boolean",
                      "date",
                      "datetime",
                    ]}
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="db_nullable_new"
                    type="text"
                    label="db_nullable"
                    element="input"
                    clean_options={["true", "false"]}
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="primary_key_new"
                    type="text"
                    label="db_key"
                    element="input"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="db_selectlist_new"
                    type="text"
                    label="db_selectlist"
                    element="input"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="db_tablename_new"
                    type="text"
                    label="db_tablename"
                    element="input"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="fe_element_new"
                    type="text"
                    label="fe_element"
                    element="select"
                    errorText="Please enter a valid Code"
                    clean_options={[
                      "input",
                      "select",
                      "textarea",
                      "radio",
                      "date",
                    ]}
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="fe_id_new"
                    type="text"
                    label="fe_id"
                    element="input"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="fe_isrender_new"
                    type="text"
                    label="fe_isrender"
                    element="select"
                    clean_options={["true", "false"]}
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="fe_label_new"
                    type="text"
                    label="fe_label"
                    element="input"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="fe_order_new"
                    type="number"
                    label="fe_order"
                    element="input"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="fe_type_new"
                    type="text"
                    label="fe_type"
                    element="input"
                    clean_options={["string", "number"]}
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="section_new"
                    type="text"
                    label="section"
                    element="input"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                  <InputNew
                    id="source_column_new"
                    type="text"
                    label="source_column"
                    element="input"
                    errorText="Please enter a valid Code"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                  />
                </div>
                <div className="modal-button">
                  <Button className="editButton" onClick={newAttributeHandler}>
                    Save
                  </Button>
                  <Button className="editButton" onClick={handleClose}>
                    Exit
                  </Button>
                </div>
              </form>
            </Box>
          </Fade>
        </Modal>
        <div className="div1-init">
          <InputNew
            id="class_name"
            label="Table Name"
            element="select"
            validators={[]}
            onInput={inputHandler}
            clean_options={modelList}
            onBlur
          />
        </div>
        <div className="div2-init">
          <InputNew
            id="attributes"
            label="Model Attribute"
            element="radio"
            onInput={inputHandler}
            radioOptions={optionTable}
            initialValue=""
            onBlur
          />
        </div>
        {columnList[0] && <div className="div3-init">{formElement}</div>}
        <div className="div4-init">
          <Button
            variant="outlined"
            className="editButton"
            onClick={handleOpen}
            disabled
          >
            New
          </Button>
          <Button
            variant="outlined"
            className="editButton"
            onClick={deleteAttributeHandler}
            color="error"
            disabled
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            className="editButton"
            onClick={saveAttributeHandler}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SystemInit;
