import { useState, useEffect } from "react";
import IssuesTable from "./IssuesTable/IssuesTable";
import { CircularProgress } from "@mui/material";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import Toast from "../../components/Utils/Toast";
import DashboardLayout from "../DashboardPage/DashboardLayout";
import "./Issues.css";

const Issues = (props) => {
  const [issues, setIssues] = useState([]);
  const [mrm, setMrm] = useState(props.record.split("+")[0]);
  const [version, setVersion] = useState(props.record.split("+")[1]);
  const [isIssueChanged, setIsIssueChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [maxIssueId, setMaxIssueId] = useState("");
  const [layoutData, setLayoutData] = useState([]);
  const { error, sendRequest, clearError } = useHttpClient();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/issue/${mrm}+${version}`
        );

        setIssues(response["issue data"]);
        setMaxIssueId(response?.["max issue"]);
        if (Array.isArray(response?.summary?.grid)) {
          setLayoutData(response?.summary);
        } else {
          setLayoutData([]);
        }
        setIsLoading(false);
      } catch (err) {
        console.warn(err);
      }
    };
    if (mrm && version && version !== "undefined" && mrm !== "undefined") {
      fetchData();
    }
  }, [mrm, version, sendRequest, isIssueChanged]);

  const issueChangedHandler = () => {
    setIsIssueChanged((prev) => !prev);
  };

  useEffect(() => {
    setMrm(props.record.split("+")[0]);
    setVersion(props.record.split("+")[1]);
  }, [props.record]);

  let element;

  if (isLoading) {
    element = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
          paddingTop: "20rem",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    element = (
      <div style={{ height: "120%" }}>
        <IssuesTable
          issue={issues ? issues : []}
          id={`${mrm}+${version}`}
          maxIssueId={maxIssueId}
          layoutData={layoutData || []}
        />
      </div>
    );
  }

  if (!(mrm && version && version !== "undefined" && mrm !== "undefined")) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
          paddingTop: "10rem",
        }}
      >
        <h2 style={{ color: "#444791" }}>Please select a model</h2>
      </div>
    );
  }

  return <div>{element}</div>;
};

export default Issues;
