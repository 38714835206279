import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/user-context";

import ReportTree from "./ReportTree";
import { CircularProgress, Box } from "@mui/material";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import Toast from "../../components/Utils/Toast";

const Report = () => {
  const { error, sendRequest, clearError } = useHttpClient();

  const [allSummaryData, setAllSummaryData] = useState([]);

  const [allFilterData, setAllFilterData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const [treeClick, setTreeClick] = useState("");

  const { reportDataChangedHandler, reportData } = useContext(UserContext);

  const stringifyJSON = (str) => {
    try {
      JSON.stringify(str);
    } catch (e) {
      return str;
    }
    return JSON.stringify(str);
  };

  const setTreeHandler = (node) => {

    setTreeClick(node);

    history.push({
      pathname: `/report/${node}`,
      data: Array.isArray(allSummaryData)
        ? stringifyJSON(allSummaryData.find((i) => i?.entity === node))
        : [],
      filter: typeof allFilterData === "object" ? allFilterData : {},
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/summary/overall`
        );
        setIsLoading(false);
        if (response.error) {
          Toast("error", response.error);
          return;
        } else {
          setAllSummaryData(response?.summary);
          setAllFilterData(response?.filter);
          reportDataChangedHandler(response);
        }
      } catch (err) {}
    };
    if (!reportData || reportData.length === 0) {
      fetchData();
    } else {
      setAllSummaryData(reportData?.summary || []);
      setAllFilterData(reportData?.filter || {});
    }
  }, [sendRequest]);

  if (isLoading || !allSummaryData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "15rem",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <Box
      sx={{
        mt: 10,
        ml: 40,
        mr: 40,
        mb: 10,
        backgroundColor: "#f2f4fa",
      }}
    >
      <div style={{ padding: "1rem", border: "1px solid #444444", borderRadius: "5px" }}>
        <ReportTree
          treeData={
            Array.isArray(allSummaryData)
              ? allSummaryData.map((i) => i?.entity)
              : []
          }
          setTreeHandler={setTreeHandler}
        />
      </div>
    </Box>
  );
};

export default Report;
