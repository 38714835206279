import React, { Fragment, useState, useEffect, useContext } from "react";
import { gridClasses } from "@mui/x-data-grid";
import dayjs from "dayjs";
import { Box, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import SaveIcon from "@mui/icons-material/Save";
import WrapTextTable from "../../components/CustomComponents/WrapTextTable";

import InputNew from "../../components/Content/FormElements/InputNew";
import Download from "../../components/CustomComponents/Download";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { UserContext } from "../../context/user-context";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";

const TaskTable = (props) => {
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [pageSize, setPageSize] = useState(3);
  const [formState, inputHandler] = useForm({}, false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const tagList = useContext(UserContext);

  const tags =
    tagList && tagList.tag
      ? tagList.tag.filter((item) => item.db_tablename === "documents")
      : [];

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  useEffect(() => {
    props.documentRecord &&
      Array.isArray(props.documentRecord) &&
      setRows(
        props.documentRecord.map((row, index) => ({ ...row, id: index + 1 }))
      );
  }, [props.documentRecord]);

  const updateDocumentHandler = async (
    event,
    series,
    code,
    version,
    issue_id,
    validation_id,
    form,
    file_type,
    doc_description,
    update_time,
    filename,
    file_description,
    user_id,
    index
  ) => {
    event.preventDefault();
    try {
      const body = {
        series: series,
        code: code,
        version: version,
        issue_id: issue_id,
        validation_id: validation_id,
        form: form,
        file_type,
        doc_description: doc_description,
        update_time: currentDate,
        filename: filename,
        file_description: file_description,
        file_status: formState.inputs[`file_status${index}`].value,
        //file_comment: formState.inputs[`file_comment${index}`].value,
        user_id: user_id,
      };
      await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/documents/save",
        "PUT",
        JSON.stringify(body),
        {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      );
      setRows([]);
      props.isDocumentChanged();
    } catch (err) {}
  };

  const columns = [
    {
      field: "series",
      headerName: "ID",
      flex: 0.5,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "filename",
      headerName: "File Name",
      flex: 1,
      headerAlign: "center",
      renderCell: (params) => (
        <Download
          page="DocumentPage"
          code={params.row.code}
          version={params.row.version}
          form={params.row.form}
          issue_id={params.row.issue_id}
          validation_id={params.row.validation_id}
          fileName={params.row.filename}
          isIconShow
        />
      ),
    },
    {
      field: "file_description",
      headerName: "File Description",
      flex: 1,
      headerAlign: "center",
    },
    {
      field: "file_status",
      headerName: "File Status",
      flex: 1,
      autoHeight: true,
      headerAlign: "center",
      renderCell: (params) => (
        <InputNew
          initialValue={params.row.file_status}
          onInput={inputHandler}
          id={`file_status${params.row.id}`}
          element="select"
          traceList={params.row.access_log.sort(
            (b, a) => new Date(b[8]) - new Date(a[8])
          )}
          origin_options={
            tags.filter((item) => item.fe_id === "file_status")[0]
          }
          isDocumentTable
        />
      ),
    },
    {
      field: "edit",
      headerName: "Actions",
      align: "center",
      flex: 0.3,
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Fragment>
            <IconButton
              variant="outlined"
              onClick={(event) =>
                updateDocumentHandler(
                  event,
                  params.row.series,
                  params.row.code,
                  params.row.version,
                  params.row.issue_id,
                  params.row.validation_id,
                  params.row.form,
                  params.row.file_type,
                  params.row.doc_description,
                  params.row.update_time,
                  params.row.filename,
                  params.row.file_description,
                  params.row.user_id,
                  params.row.id
                )
              }
              style={{ border: "none", color: "#444791" }}
            >
              <SaveIcon />
            </IconButton>
            <Download
              page="DocumentPage"
              code={params.row.code}
              version={params.row.version}
              form={params.row.form}
              issue_id={params.row.issue_id}
              validation_id={params.row.validation_id}
              fileName={params.row.filename}
              isIconShow={false}
              isDownloadNotShow
            />
          </Fragment>
        );
      },
    },
  ];

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertToUppercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toUpperCase(),
          email: row.email.toUpperCase(),
          title: row.title.toUpperCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const convertToLowercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toLowerCase(),
          email: row.email.toLowerCase(),
          title: row.title.toLowerCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const datagridSx = {
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "rgb(250,250,250)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgb(221,225,255)",
      },
    },
  };

  // if(isLoading) {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         height: "50%",
  //       }}
  //     >
  //       <CircularProgress />
  //     </div>
  //   );
  // }

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        <WrapTextTable
          autoHeight
          getRowHeight={() => "auto"}
          sx={datagridSx}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          columns={columns}
          rows={rows}
          pageSize={pageSize}
          rowsPerPageOptions={[3, 5, 10]}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            row: {
              onContextMenu: handleContextMenu,
              style: { cursor: "context-menu" },
            },
          }}
          components={{
            Footer: () => (
              <div
                className="MuiDataGrid-footerContainer css-17jjc08-MuiDataGrid-footerContainer"
                style={{ justifyContent: "flex-end" }}
              >
                <Box sx={{ p: 1, display: "flex", justifyContent: "end" }}>
                  Total Documents: {rows.length}
                </Box>
              </div>
            ),
          }}
        />
      </div>
    </Fragment>
  );
};

export default TaskTable;
