import { useContext, Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Popconfirm } from "antd";

import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import FileUpload from "../Utils/FileUpload";
import classes from "../Content/ModelForm.module.css";
import InputNew from "../Content/FormElements/InputNew";
import Download from "./Download";

import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { UserContext } from "../../context/user-context";

const FileLoad = (props) => {
  const { isLoading, sendRequest } = useHttpClient();
  const [newDocument, setNewDocument] = useState([]);

  const { fileTypeOutToInHandler, macFileTypeOutToInHandler, user } =
    useContext(UserContext);

  const location = useLocation();

  let documentPermission;
  if (user) {
    documentPermission = user["document permission"];
  }

  let file_type_option = [];
  if (props.fileOption && props.fileOption[0]) {
    file_type_option = props.fileOption[0].group
      .split(",")
      .map((opt) => fileTypeOutToInHandler(opt));
  } else if (props.macFileType) {
    file_type_option = props.macFileType;
  }

  useEffect(() => {
    setNewDocument(props.newDocument);
  }, [props.newDocument]);

  const deleteFileHandler = async (e, doc) => {
    e.preventDefault();
    const headers = new Headers();
    const bearer = props.token;
    headers.append("Authorization", bearer);
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/delete/file/${doc.series}`,
        "DELETE",
        null,
        headers
      );
      if (response.state === "success") {
        props.removeHandler(doc.filename);
      }
    } catch (err) {}
  };
  const fileTag = (doc, i, isNew, fileDescriptionArr) => {
    return (
      <TableRow className={classes.fileOperations} key={i}>
        <TableCell
          component="th"
          scope="row"
          className={classes.fileName}
          style={{ borderBottom: "none", width: "30rem" }}
        >
          <InputNew
            id={"upload_file_" + doc.filename}
            type="text"
            element="input"
            onInput={props.onInput}
            initialValue={doc.filename}
            initialValid={true}
            validators={[]}
            disabled={true}
            fileLoad={true}
            fileChangeHandler={props.fileChangeHandler}
          />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ borderBottom: "none", width: "20rem" }}
        >
          <InputNew
            id={"file_" + doc.filename + "_type_" + (i + 1)}
            element="select"
            onInput={props.onInput}
            initialValue={
              props.macFileType
                ? macFileTypeOutToInHandler(doc.file_type)
                : fileTypeOutToInHandler(doc.file_type)
            }
            disabled={!isNew ? true : false}
            height={{ height: "36.2px" }}
            initialValid={true}
            clean_options={file_type_option}
            validators={[]}
            fileLoad={true}
            fileChangeHandler={props.fileChangeHandler}
          />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          className={classes.fileName}
          style={{ borderBottom: "none", width: "20rem" }}
        >
          <InputNew
            id={"file_description_" + doc.filename}
            type="text"
            element="input"
            onInput={props.onInput}
            initialValue={doc.file_description}
            disabled={!isNew ? true : false}
            initialValid={true}
            validators={[]}
            onBlur="fileInput"
            fileChangeHandler2={props.fileChangeHandler}
          />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ borderBottom: "none", width: "8rem" }}
        >
          <InputNew
            id="file_status"
            type="text"
            element="input"
            onInput={props.onInput}
            initialValue={doc.file_status ? doc.file_status : "Active"}
            disable={doc.file_status ? false : true}
            initialValid={true}
            validators={[]}
            disabled={true}
            fileLoad={true}
            fileChangeHandler={props.fileChangeHandler}
          />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ borderBottom: "none", width: "10rem" }}
        >
          <InputNew
            id="update_time"
            type="text"
            element="input"
            onInput={props.onInput}
            initialValue={doc.update_time}
            initialValid={true}
            validators={[]}
            disabled={true}
            fileLoad={true}
            fileChangeHandler={props.fileChangeHandler}
          />
        </TableCell>
        <TableCell
          component="th"
          scope="row"
          style={{ borderBottom: "none", width: "8rem" }}
        >
          <InputNew
            id="file_owner"
            type="text"
            element="input"
            onInput={props.onInput}
            initialValue={doc.user_id}
            initialValid={true}
            validators={[]}
            disabled={true}
            fileLoad={true}
            fileChangeHandler={props.fileChangeHandler}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          <div style={{ display: "flex", justifyContent: "center" }}>
     
            {!isNew && !props.isPolicy && !props.isModelChange && (
              <Download
                code={props.record.split("+")[0]}
                version={props.record.split("+")[1]}
                form={props.form}
                issue_id={
                  props.form === "issue" ||
                  (props.page === "DocumentPage" && props.issueId)
                    ? props.issueId.split("+")[2]
                    : 0
                }
                validation_id={
                  props.form === "validation" ||
                  (props.page === "DocumentPage" && props.validationId)
                    ? props.validationId
                    : 0
                }
                fileName={doc.filename}
              />
            )}
            {!isNew && (props.isPolicy || props.isModelChange) && (
              <Download
                code={props.topic}
                version={0}
                form={props.form}
                issueId={0}
                fileName={doc.filename}
              />
            )}

            {!isNew && location?.pathname?.split("/")?.[1] === "temp" && (
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={(e) => {
                  deleteFileHandler(e, doc);
                }}
                okText="Yes"
                cancelText="No"
              >
                <IconButton color="error" size="small">
                  <DeleteIcon />
                </IconButton>
              </Popconfirm>
            )}

            {isNew && (
              <Popconfirm
                title="Are you sure to delete?"
                onConfirm={() => {
                  props.removeHandler(doc.filename);
                }}
                okText="Yes"
                cancelText="No"
              >
                <IconButton color="error" size="small">
                  <DeleteIcon />
                </IconButton>
              </Popconfirm>
            )}
          </div>
        </TableCell>
      </TableRow>
    );
  };

  if (!documentPermission) {
    return <Fragment></Fragment>;
  }

  return (
    <div
      style={{
        border:
          Array.isArray(newDocument) && newDocument.length > 0
            ? "1px solid #CB3694"
            : "1px solid rgba(224, 224, 224, 1)",
        borderRadius: "5px",
      }}
    >
      <div className={classes.files}>
        <FileUpload
          onFileChange={props.uploadHandler}
          disabled={props.disabled}
        />
      </div>
      <div style={{ padding: " 0 1rem 0 1rem" }}>
        <hr />
      </div>

      <div style={{ padding: "0 2rem 1rem 1rem" }}>
        {isLoading && (
          <div style={{ textAlign: "center" }}>
            <CircularProgress />
          </div>
        )}
        {!isLoading && (
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    style={{ border: "none", padding: "0.5rem 0 0.5rem 0px" }}
                  >
                    File Name
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "none", padding: "0.5rem 0 0.5rem 0px" }}
                  >
                    File Type
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "none", padding: "0.5rem 0 0.5rem 0px" }}
                  >
                    File Description
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "none", padding: "0.5rem 0 0.5rem 0px" }}
                  >
                    Status
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "none", padding: "0.5rem 0 0.5rem 0px" }}
                  >
                    Update Time
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "none", padding: "0.5rem 0 0.5rem 0px" }}
                  >
                    User ID
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ border: "none", padding: "0.5rem 0 0.5rem 0px" }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {props.oldDocument &&
                  props.oldDocument
                    .flat()
                    .sort(
                      (b, a) =>
                        new Date(a.update_time) - new Date(b.update_time)
                    )
                    .map((doc, i) => fileTag(doc, i, false))}

                {Array.isArray(newDocument) &&
                  newDocument.map((doc, i) =>
                    fileTag(doc, i, true, props.fileDescriptionArr)
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
    </div>
  );
};

export default FileLoad;
