import { useState, useEffect, useContext, Fragment } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import dayjs from "dayjs";
import {
  saveDataInIndexDB,
  getDataFromDB,
  deleteDataFromIndexDB,
  db,
} from "../../../components/CustomComponents/IndexedDBTemplate";
import "./NewModel.css";

import InputNew from "../../../components/Content/FormElements/InputNew";
import FileLoad from "../../../components/CustomComponents/FileLoad";
import {
  CircularProgress,
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { toast } from "react-toastify";
import Toast from "../../../components/Utils/Toast";
import PopUpTable from "./PopUpTable";

import {
  VALIDATOR_CODE,
  VALIDATOR_MODEL_VERSION_ID,
} from "../../../components/Utils/Validators";

import { useForm } from "../../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../../components/Utils/hooks/http-hook";
import { UserContext } from "../../../context/user-context";
import {
  optionException,
  conditionalDisabledHandler,
  formInitialValueHandler,
  newModelIdCheck,
  newModelNameCheck,
  modelProposePurposeCheck,
  fileTypeCheck,
  modelShortDescription,
} from "../ModelFieldCheck/NewModelFieldCheck";
import { isTwoJsonValueEqual } from "../../../components/CustomComponents/GeneralPageTemplate";

const NewModel2 = ({
  isTreeChanged,
  tag,
  modelData,
  setScrollTop,
  pageElement,
}) => {
  const history = useHistory();
  const { isLoading, sendRequest } = useHttpClient();
  const {
    user,
    token,
    userGroup,
    fileTypeInToOutHandler,
    isPageDataChangedHandler,
    isPageDataNotChangedHandler,
    setNodeClickedHandler,
    setRecordHandler,
    nodeExpandHandler,
  } = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [newDocument, setNewDocument] = useState([]);
  const [versionId, setVersionId] = useState("");
  const [osfiId, setOsfiId] = useState("");
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [formDataObj, setFormDataObj] = useState({});
  const [storedData, setStoredData] = useState({});
  const [formData, setFormData] = useState({});
  const [formState, inputHandler] = useForm(
    {
      model_code: {
        value: "",
        isValid: false,
      },
      model_version: {
        value: "",
        isValid: false,
      },
      model_name: {
        value: "",
        isValid: false,
      },
      model_osfi: {
        value: "",
        isValid: false,
      },
      upstream_name: {
        value: [""],
        isValid: false,
      },
    },
    false
  );

  const { display_name } = user || {};
  const buttonAccess = user["menu access"] || {};
  const modelName = modelData.map((data) => data.name);
  const status_option = Object.values(user["group access"]) || [];

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  let tag2 = [];
  let fileOption = [];
  if (tag) {
    tag2 = tag.filter((item) => item.db_tablename === "new");
    fileOption = tag.filter(
      (item) => item.db_tablename === "documents" && item.fe_id === "file_type"
    );
  } else {
    tag2 = [];
    fileOption = [];
  }

  if (tag) {
    tag = tag.filter(
      (item) => item.db_tablename === "new" && item.fe_isrender === true
    );
  } else {
    tag = [];
  }

  const groupAccess = Object.values(buttonAccess)[0]
    .filter((item) => item[0] === "New")
    .map((access) => access[1]);

  useEffect((e) => {
    nodeExpandHandler(e, ["Model"]);
  }, []);

  useEffect(() => {
    db.testData
      .where("datakey")
      .equals("New")
      .toArray()
      .then((data) => {
        setStoredData(data?.[0]?.data || []);
      });
  }, []);

  useEffect(() => {
    if (!_.isEqual(formData, formState.inputs)) {
      setFormData(formState.inputs);
    }
  }, [formState.inputs]);

  useEffect(() => {
    storedData.file && setFiles(storedData.file);
    storedData.file && setNewDocument(storedData.newDocument);
    storedData.version && setVersionId(storedData.version);
  }, [storedData]);

  useEffect(() => {
    formState.inputs.model_code.value &&
      setVersionId(`${formState.inputs.model_code.value.slice(-3)}CH000`);
    formState.inputs.model_code.value &&
      setOsfiId(formState.inputs.model_code.value.replace(/[^0-9]/g, ""));
  }, [formState.inputs.model_code.value]);

  const handleClickOpen = () => {
    // socket.emit("message", { user: "b", msg: "a" });

    if (newModelNameCheck(formState, Toast) === false) {
      return;
    }

    if (
      !formState.inputs.model_code.value ||
      !/[A-Z][A-Z][A-Z]+\.\d\.\d\d\.\d\d\.V\d\d/.test(
        formState.inputs.model_code.value
      ) ||
      formState.inputs.model_code.value.length !== 15
    ) {
      Toast("error", "Model ID has an invalid format.");
      return;
    }

    if (
      !formState.inputs.model_version.value ||
      !/V\d\dCH\d\d\d/.test(formState.inputs.model_version.value) ||
      formState.inputs.model_version.value.length !== 8
    ) {
      Toast("error", "Full Version ID has an invalid format.");
      return;
    }

    if (formState.inputs.vendor && formState.inputs.vendor.value === "No") {
      if (
        formState.inputs.vendor_developer &&
        formState.inputs.vendor_developer.value !== ""
      ) {
        toast.error("Please check Vendor Model and Vendor Name.", {
          autoClose: false,
          position: "top-center",
          toastId: "new model vendor",
        });
        return;
      }
    }

    if (newModelIdCheck(formState, Toast) === false) {
      return;
    }

    if (modelProposePurposeCheck(formState, Toast) === false) {
      return;
    }

    if (modelShortDescription(formState, Toast) === false) {
      return;
    }

    if (fileTypeCheck(newDocument, Toast) === false) {
      return;
    }

    setOpen(true);

    const formData = new FormData();
    formData.append("code", formState.inputs.model_code.value || "");
    formData.append("version", formState.inputs.model_version.value || "");
    formData.append("osfi", formState.inputs.model_osfi.value || "");

    tag.forEach((item) => {
      if (
        item.fe_id !== "update_time" &&
        item.fe_id !== "retire_date" &&
        item.fe_id !== "change_log" &&
        item.fe_id !== "upstream_name" &&
        item.fe_id !== "downstream_name" &&
        item.fe_id !== "upstream_code" &&
        item.fe_id !== "downstream_code" &&
        formState.inputs[item.fe_id]
      ) {
        formData.append(
          item.fe_id,
          formState.inputs[item.fe_id].value
            ? formState.inputs[item.fe_id].value
            : ""
        );
      }
    });

    if (
      formState.inputs.upstream_name &&
      formState.inputs.upstream_name.value &&
      formState.inputs.upstream_name.value !== ""
    ) {
      formData.append(
        "upstream_name",
        formState.inputs.upstream_name.value
          ? JSON.stringify(formState.inputs.upstream_name.value)
          : ""
      );
      formData.append(
        "upstream_code",
        JSON.stringify(
          formState.inputs.upstream_name.value.map((item) =>
            modelData.filter((model) => model.name === item)[0]
              ? modelData.filter((model) => model.name === item)[0].code
              : []
          )
        )
      );
    }
    if (
      formState.inputs.downstream_name &&
      formState.inputs.downstream_name.value &&
      formState.inputs.downstream_name.value !== ""
    ) {
      formData.append(
        "downstream_name",
        formState.inputs.downstream_name.value
          ? JSON.stringify(formState.inputs.downstream_name.value)
          : ""
      );
      formData.append(
        "downstream_code",
        JSON.stringify(
          formState.inputs.downstream_name.value.map((item) =>
            modelData.filter((model) => model.name === item)[0]
              ? modelData.filter((model) => model.name === item)[0].code
              : []
          )
        )
      );
    }

    let object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    setFormDataObj(formData);
    let arr = [];
    let i = 1;
    for (const [key, value] of Object.entries(object)) {
      arr.push({ id: i, attribute: key, current: value });
      i++;
    }
    setTableData(arr);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uploadFileHandler = (file) => {
    if (Array.isArray(files) && files.map((i) => i.name).includes(file.name)) {
      Toast("error", "File already added.");
      return;
    }

    setFiles([...files, file]);
    setNewDocument([
      ...newDocument,
      {
        file_description: "",
        filename: file.name,
        update_time: new Date(),
        user_id: display_name,
        files_status: "",
        file_type: "",
      },
    ]);
  };

  const removeFileHandler = (file) => {
    setFiles(files.filter((doc) => doc.name !== file));
    setNewDocument(newDocument.filter((doc) => doc.filename !== file));
  };

  const fileChangeHandler = (id, value) => {
    setNewDocument(
      newDocument.map((doc, i) => {
        if ("file_" + doc.filename + "_type_" + (i + 1) === id) {
          doc.file_type = value;
        }
        if ("file_description_" + doc.filename === id) {
          doc.file_description = value;
        }
        return doc;
      })
    );
  };

  const modelSubmitHandler = async (event) => {
    event.preventDefault();
    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);
    const traceList = [
      [
        1,
        formState.inputs.model_code.value,
        formState.inputs.model_version.value,
        formState.inputs.model_status.value,
        display_name,
        currentDate,
      ],
    ];
    if (
      !formState.inputs.model_code.value ||
      !/[A-Z][A-Z][A-Z]+\.\d\.\d\d\.\d\d\.V\d\d/.test(
        formState.inputs.model_code.value
      )
    ) {
      alert("Model ID has an invalid format.");
      setOpen(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("code", formState.inputs.model_code.value || "");
      formData.append("version", formState.inputs.model_version.value || "");
      formData.append("osfi", formState.inputs.model_osfi.value || "");
      formData.append("access_log", JSON.stringify(traceList));
      formData.append("user_id", display_name);
      newDocument.length > 0 &&
        formData.append(
          "file_description",
          JSON.stringify(Array.from(newDocument, (x) => x.file_description))
        );

      newDocument.length > 0 &&
        formData.append(
          "file_type",
          JSON.stringify(
            Array.from(newDocument, (x) => fileTypeInToOutHandler(x.file_type))
          )
        );

      formData.append("update_time", currentDate);
      tag.forEach((item) => {
        if (
          item.fe_element !== "autoFill" &&
          item.fe_element !== "autoComplete" &&
          item.fe_id !== "update_time" &&
          item.fe_id !== "retire_date" &&
          item.fe_id !== "change_log" &&
          item.fe_id !== "upstream_name" &&
          item.fe_id !== "downstream_name" &&
          item.fe_id !== "upstream_code" &&
          item.fe_id !== "downstream_code" &&
          formState.inputs[item.fe_id]
        ) {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id]
              ? formState.inputs[item.fe_id].value
                ? formState.inputs[item.fe_id].value
                : ""
              : ""
          );
        }
      });

      tag.forEach((item) => {
        if (item.fe_element === "autoFill") {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id].value
              ? JSON.stringify(formState.inputs[item.fe_id].value)
              : JSON.stringify([])
          );
        }
      });

      tag.forEach((item) => {
        if (
          item.fe_element === "autoComplete" &&
          item.fe_id !== "upstream_code" &&
          item.fe_id !== "downstream_code"
        ) {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id].value
              ? JSON.stringify(formState.inputs[item.fe_id].value)
              : JSON.stringify([])
          );
        }
      });

      if (
        formState.inputs.upstream_name &&
        formState.inputs.upstream_name.value &&
        formState.inputs.upstream_name.value !== ""
      ) {
        formData.append(
          "upstream_name",
          formState.inputs.upstream_name.value
            ? JSON.stringify(formState.inputs.upstream_name.value)
            : JSON.stringify([])
        );
        formData.append(
          "upstream_code",
          JSON.stringify(
            formState.inputs.upstream_name.value.map((item) =>
              modelData.filter((model) => model.name === item)[0]
                ? modelData.filter((model) => model.name === item)[0].code
                : []
            )
          )
        );
      }
      if (
        formState.inputs.downstream_name &&
        formState.inputs.downstream_name.value &&
        formState.inputs.downstream_name.value !== ""
      ) {
        formData.append(
          "downstream_name",
          formState.inputs.downstream_name.value
            ? JSON.stringify(formState.inputs.downstream_name.value)
            : JSON.stringify([])
        );
        formData.append(
          "downstream_code",
          JSON.stringify(
            formState.inputs.downstream_name.value.map((item) =>
              modelData.filter((model) => model.name === item)[0]
                ? modelData.filter((model) => model.name === item)[0].code
                : []
            )
          )
        );
      }

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append(`file${i + 1}`, files[i]);
        }
      }

      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/model/new",
        "POST",
        formData,
        headers
      );

      setOpen(false);
      if (!res.state || res.state !== "success") {
        Toast("error", res, "Something wrong happened.");
        return;
      }
      deleteDataFromIndexDB("New");
      Toast("success", "Model is created successfully.");
      //Redirect the user to a different page
      isTreeChanged();
      setNodeClickedHandler("");
      setRecordHandler("");
    } catch (err) {
      toast.error(err, { autoClose: false, position: "top-center" });
    }
  };

  const PopUpModalData = tableData
    .filter(
      (item) =>
        item.attribute !== "update_time" &&
        item.attribute !== "user_id" &&
        item.attribute !== "access_log"
    )
    .map((data) => {
      const label = tag2.find(
        ({ fe_id }) => fe_id === data.attribute
      )?.fe_label;
      return { ...data, attribute: label };
    });

  const isDataChanged = () => {
    for (let i = 0; i < tag.length; i++) {
      if (
        !isTwoJsonValueEqual(
          formInitialValueHandler(
            tag[i],
            formState,
            modelData,
            status_option,
            storedData
          ),
          formState.inputs?.[tag[i].fe_id]?.value
        )
      ) {
        // console.log("data changed!");
        // console.log("label:", tag[i].fe_label);
        // console.log(
        //   "initial:",
        //   formInitialValueHandler(
        //     tag[i],
        //     formState,
        //     modelData,
        //     status_option,
        //     storedData
        //   )
        // );
        // console.log("input:", formState.inputs?.[tag[i].fe_id]?.value);
        return true;
      }
    }
    if (
      formState.inputs?.model_code?.value &&
      formState.inputs?.model_code?.value !== ""
    ) {
      // console.log("data changed!");
      // console.log("label: Model ID");
      // console.log(formState.inputs?.model_code?.value);
      return true;
    }

    if (
      formState.inputs?.model_version?.value &&
      formState.inputs?.model_version?.value !== ""
    ) {
      // console.log("data changed!");
      // console.log("label: Full Version ID");
      // console.log(formState.inputs?.model_version?.value);
      return true;
    }

    if (newDocument?.length > 0) {
      //console.log("files exist");

      return true;
    }

    return false;
  };

  useEffect(() => {
    setRecordHandler("");
    if (isDataChanged()) {
      isPageDataChangedHandler("New", 0);
    } else {
      isPageDataNotChangedHandler("New", 0);
    }
  }, [formData]);

  let element;
  if (!tag) {
    element = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    element = (
      <div>
        {/*<PopUpModal data={formState.inputs} tag={tag}/>*/}
        <div style={{ marginTop: "1.5rem" }}>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: {
                width: "650px",
                maxWidth: "900px",
                height: "100%",
                padding: "1rem 3rem 1rem 3rem",
              },
            }}
          >
            <DialogTitle>
              <PopUpTable tableData={PopUpModalData} isNew={true} />
            </DialogTitle>
            <DialogActions>
              <Button onClick={modelSubmitHandler}>Confirm</Button>
              <Button onClick={handleClose} autoFocus>
                Cancel
              </Button>
              {isLoading && <CircularProgress />}
            </DialogActions>
          </Dialog>
        </div>
        <form style={{ margin: "0 8rem 2rem 8rem" }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={0.5} columnSpacing={5}>
              <Grid item xs={12} md={6} lg={4}>
                <InputNew
                  id="model_code"
                  type="text"
                  label="Model ID(*)"
                  element="input"
                  onInput={inputHandler}
                  onBlur
                  placeholder="eg. ERM.0.01.01.V02"
                  errorText="Model ID should be in the format of ERM.0.01.01.V02"
                  validators={[VALIDATOR_CODE()]}
                  initialValue={storedData?.code}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <InputNew
                  id="model_version"
                  type="text"
                  label="Full Version ID(*)"
                  element="input"
                  initialValue={versionId}
                  validators={[VALIDATOR_MODEL_VERSION_ID()]}
                  errorText="Full Version ID should be in the format of V01CH000"
                  placeholder="eg. V01CH000"
                  onBlur
                  onInput={inputHandler}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <InputNew
                  id="model_osfi"
                  type="text"
                  label="OSFI ID(*)"
                  element="input"
                  initialValue={osfiId}
                  onInput={inputHandler}
                  disabled
                />
              </Grid>
              {tag
                .filter(
                  (i) =>
                    i.fe_id !== "retired_date" &&
                    i.fe_id !== "change_log" &&
                    i.fe_id !== "approver" &&
                    i.fe_id !== "certification_date" &&
                    i.fe_id !== "expire_date" &&
                    i.fe_id !== "risk_rating" &&
                    i.fe_id !== "net_rating" &&
                    i.fe_id !== "approve_purpose"
                )
                .sort((a, b) => a.fe_order - b.fe_order)
                .map((tag, index) => (
                  <Grid
                    item
                    xs={12}
                    md={tag.fe_grid === 12 ? 12 : 6}
                    lg={tag.fe_grid}
                    key={index}
                  >
                    <InputNew
                      isNewModel={true}
                      id={tag.fe_id}
                      type={tag.fe_type}
                      label={tag.fe_label}
                      element={tag.fe_element}
                      onInput={inputHandler}
                      initialValid={true}
                      initialValue={formInitialValueHandler(
                        tag,
                        formState,
                        modelData,
                        status_option,
                        storedData
                      )}
                      clean_options={optionException(
                        tag,
                        modelName,
                        status_option
                      )}
                      origin_options={tag}
                      onRecord={() => {}}
                      disabled={conditionalDisabledHandler(tag, formState)}
                      options={
                        userGroup
                          ? userGroup.map((user) => ({
                              label: user,
                              value: user,
                            }))
                          : []
                      }
                      setScrollTop={setScrollTop}
                      pageElement={pageElement}
                    />
                  </Grid>
                ))}

              <Grid item xs={12}>
                <div style={{ padding: "0.8rem" }}>
                  <FileLoad
                    uploadHandler={uploadFileHandler}
                    removeHandler={removeFileHandler}
                    newDocument={newDocument}
                    form="model"
                    onInput={inputHandler}
                    fileChangeHandler={fileChangeHandler}
                    fileOption={fileOption}
                  />
                </div>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <Button
                  onClick={handleClickOpen}
                  variant="outlined"
                  className="editButton"
                  disabled={groupAccess.includes("Update") ? false : true}
                >
                  Add new model
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </div>
    );
  }

  return <Fragment>{element}</Fragment>;
};

export default NewModel2;
