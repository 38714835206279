import { useEffect, useState, useContext } from "react";
import dayjs from "dayjs";
import _ from "lodash";
// material-ui
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
  Modal,
  Fade,
  Backdrop,
  CircularProgress,
  Switch,
  FormGroup,
  FormControlLabel,
} from "@mui/material";

import InputNew from "../../components/Content/FormElements/InputNew";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { UserContext } from "../../context/user-context";
import Toast from "../../components/Utils/Toast";
import MainCard from "../DashboardPage/MainCard";
import AddImg from "../../assets/AddIcon.png";

const modalStyle = {
  position: "absolute",
  top: "10%",
  left: "20%",
  width: "60%",
  height: "35%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
};

const filterChartData = (data) => {
  if (!data) return [];
  // find the first 'card' item
  if (Array.isArray(data)) {
    const result = data.reduce((accumulator, current) => {
      if (accumulator.every((item) => item.order !== current.order)) {
        accumulator.push(current);
      }

      return accumulator;
    }, []);

    return result;
  }
};

const DashboardConfig = () => {
  const [formState, inputHandler] = useForm({}, false);
  const { user, tag, tabNameInToOutHandler, tabNameOutToInHandler } =
    useContext(UserContext);
  const [dashboardList, setDashboardList] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [entitySelected, setEntitySelected] = useState("");
  const [summaryList, setSummaryList] = useState([]);
  const [currentSummary, setCurrentSummary] = useState([]);
  const [currentGrid, setCurrentGrid] = useState([]);
  const [isDashboardChanged, setIsDashboardChanged] = useState(false);
  const { error, sendRequest, clearError } = useHttpClient();
  const [isDashboardLoading, setIsDashboardLoading] = useState(false);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [gridOrder, setGridOrder] = useState(0);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const displayTag = tag.find(
    (item) => item.db_tablename === "canvas" && item.fe_id === "display"
  );

  const titleTag = tag.find(
    (item) => item.db_tablename === "canvas" && item.fe_id === "entity"
  );

  const descriptionTag = tag.find(
    (item) => item.db_tablename === "canvas" && item.fe_id === "description"
  );

  const gridConfig = tag.filter(
    (item) => item.db_tablename === "grid" && item.fe_isrender === true
  );

  const getGridLabelHandler = (id) => {
    const result = gridConfig.find((item) => item.fe_id === id)?.fe_label;
    return result;
  };

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/summary`
        );
        setIsLoading(false);
        setDashboardList(response);
        setSummaryList(response?.summary);
      } catch (error) {}
    };
    fetchData();
  }, [sendRequest, isDashboardChanged]);

  // console.log(formState.inputs)
  // console.log(currentSummary)

  useEffect(() => {
    setEntitySelected(formState.inputs?.dashboard_entity_select?.value || "");
    setIsDashboardLoading(true);
    const asyncFunc = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1));
      setIsDashboardLoading(false);
      Array.isArray(summaryList) &&
        setCurrentSummary(
          summaryList?.find(
            (item) =>
              item?.entity === formState.inputs?.dashboard_entity_select?.value
          )
        );
      Array.isArray(summaryList) &&
        setCurrentGrid(
          filterChartData(
            summaryList?.find(
              (item) =>
                item?.entity ===
                formState.inputs?.dashboard_entity_select?.value
            )?.grid
          )
        );

      Array.isArray(summaryList) &&
        setGridOrder(
          Math.max(
            ...(summaryList
              ?.find(
                (item) =>
                  item?.entity ===
                  formState.inputs?.dashboard_entity_select?.value
              )
              ?.grid?.map((item) => item.order) || [0])
          )
        );
    };
    if (Array.isArray(summaryList)) {
      asyncFunc();
    }
  }, [
    formState.inputs?.dashboard_entity_select?.value,
    dashboardList,
    summaryList,
  ]);

  const addNewChartHandler = (e) => {
    if (
      currentSummary &&
      currentSummary?.grid &&
      Array.isArray(currentSummary?.grid)
    ) {
      setCurrentSummary({
        ...currentSummary,
        grid: [
          ...currentSummary?.grid,
          {
            entity: entitySelected,
            order: isFinite(gridOrder) ? gridOrder + 1 : 20,
            height: 500,
            subtitle: "",
            chart_type:
              dashboardList?.chart_type?.[
                dashboardList?.chart_type?.length - 1
              ],
            title: "",
            width: 4,
            query_title: currentSummary?.grid?.[0]?.query_title || "",
            label: "",
          },
        ],
      });
      setGridOrder(isFinite(gridOrder) ? gridOrder + 1 : 21);
    }
  };

  const deleteGridHandler = async (e, gridOrder) => {
    e.preventDefault();
    try {
      const newGridBody = currentSummary?.grid?.filter(
        (item) => item.order !== gridOrder
      );
      setCurrentSummary({
        ...currentSummary,
        grid: newGridBody,
      });
    } catch (error) {}
  };

  useEffect(() => {
    const newGrid = filterChartData(currentSummary?.grid)?.map((item, i) => ({
      chart_type:
        formState.inputs?.[`dashboard_chart_type+${item.order}`]?.value,
      entity: item.entity,
      height: formState.inputs?.[`dashboard_height+${item.order}`]?.value,
      order: item.order,
      query_title:
        formState.inputs?.[`dashboard_query_title+${item.order}`]?.value,
      subtitle: formState.inputs?.[`dashboard_subtitle+${item.order}`]?.value,
      title: formState.inputs?.[`dashboard_title+${item.order}`]?.value,
      width: formState.inputs?.[`dashboard_width+${item.order}`]?.value,
      label: formState.inputs?.[`dashboard_label+${item.order}`]?.value,
    }));

    setCurrentSummary({
      ...currentSummary,
      grid: newGrid,
    });
  }, [formData]);

  useEffect(() => {
    if (!_.isEqual(formData, formState.inputs)) {
      setFormData(formState.inputs);
    }
  }, [formState.inputs]);

  const updateChartHandler = async (e) => {
    e.preventDefault();
    const dashboardBody = filterChartData(currentSummary?.grid)?.map(
      (item, i) => ({
        ...item,
        order: i + 1,
      })
    );

    try {
      setIsDashboardLoading(true);
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/summary?table=all`,
        "PUT",
        JSON.stringify({
          entity: entitySelected,
          description: formState.inputs?.dashboard_description?.value,
          display:
            tabNameInToOutHandler(
              formState.inputs?.[displayTag.fe_id]?.value
            ) || "",
          user_id: user?.display_name,
          update_time: currentDate,
          grid: dashboardBody,
        }),
        {
          "Content-Type": "application/json",
        }
      );

      Toast("success", "Update Success.");
      setIsDashboardChanged(!isDashboardChanged);
    } catch (error) {}
  };

  const generateLayoutHandler = async (e) => {
    e.preventDefault();
    setModalOpen(false);
    if (
      formState.inputs?.dashboard_name_new?.value &&
      formState.inputs?.dashboard_description_new?.value &&
      !isNaN(formState.inputs?.dashboard_row_new?.value) &&
      !isNaN(formState.inputs?.dashboard_col_new?.value) &&
      formState.inputs?.dashboard_row_new?.value > 0 &&
      formState.inputs?.dashboard_col_new?.value > 0
    ) {
      const newGrid = [];
      for (
        let i = 0;
        i <
        formState.inputs?.dashboard_row_new?.value *
          formState.inputs?.dashboard_col_new?.value;
        i++
      ) {
        newGrid.push({
          entity: formState.inputs?.dashboard_name_new?.value,
          order: i + 1,
          height: 400,
          subtitle: "",
          chart_type:
            dashboardList?.chart_type?.[dashboardList?.chart_type?.length - 1],
          title: "",
          width: 12 / formState.inputs?.dashboard_row_new?.value,
          query_title: "",
          label: "",
        });
      }
      const newSummary = {
        entity: formState.inputs?.dashboard_name_new?.value,
        description: formState.inputs?.dashboard_description_new?.value,
        display:
          typeof displayTag?.group === "string"
            ? displayTag?.group?.split(",")?.[0]
            : [],
        user_id: user?.display_name,
        update_time: currentDate,
        grid: newGrid,
      };
      setIsLoading(true);
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/summary`,
        "POST",
        JSON.stringify(newSummary),
        {
          "Content-Type": "application/json",
        }
      );
      setIsLoading(false);
      setSummaryList(response);
      if (response) {
        Toast("success", "Generate Success.");
      }
    } else {
      Toast("error", "Please check input value.");
    }
  };

  const deleteSummaryHandler = async (e) => {
    e.preventDefault();
    try {
      setIsDashboardLoading(true);
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/summary?entity=${entitySelected}`,
        "DELETE",
        {
          "Content-Type": "application/json",
        }
      );
      Toast("success", "Delete Success.");
      setIsDashboardChanged(!isDashboardChanged);
    } catch (error) {}
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4 align="center">Dashboard Configuration </h4>
      </div>
      <hr />
      <Box sx={{ flexGrow: 2, mt: 2, ml: 20, mr: 20, mb: 2 }}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={modalOpen}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 1000,
          }}
        >
          <Fade in={modalOpen}>
            <Box sx={modalStyle}>
              <div style={{ padding: " 0 2rem" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h5 style={{ whiteSpace: "nowrap" }}>Please input Name:</h5>
                  <InputNew
                    id="dashboard_name_new"
                    type="text"
                    label=""
                    element="input"
                    onInput={inputHandler}
                    initialValue=""
                    validators={[]}
                    onRecord={() => {}}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <h5 style={{ whiteSpace: "nowrap" }}>
                    Please input Description:
                  </h5>
                  <InputNew
                    id="dashboard_description_new"
                    type="text"
                    label=""
                    element="input"
                    onInput={inputHandler}
                    initialValue=""
                    validators={[]}
                    onRecord={() => {}}
                  />
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "3rem",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h5 style={{ whiteSpace: "nowrap" }}>
                      Please input row and column:
                    </h5>
                    <InputNew
                      id="dashboard_row_new"
                      type="number"
                      label=""
                      element="input"
                      onInput={inputHandler}
                      initialValue=""
                      validators={[]}
                      onRecord={() => {}}
                    />
                    <div>X</div>
                    <InputNew
                      id="dashboard_col_new"
                      type="number"
                      label=""
                      element="input"
                      onInput={inputHandler}
                      initialValue=""
                      validators={[]}
                      onRecord={() => {}}
                    />
                  </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="outlined"
                    className="editButton"
                    style={{ fontSize: "10px !important" }}
                    onClick={generateLayoutHandler}
                  >
                    Generate
                  </Button>
                </div>
              </div>
            </Box>
          </Fade>
        </Modal>

        <div>
          <Button
            variant="outlined"
            className="editButton"
            onClick={handleOpen}
          >
            New
          </Button>
        </div>
        <Grid container sx={{ pl: 24, pr: 24 }}>
          <Grid item lg={6} md={12} sm={12}>
            <Box sx={{ width: "100%" }}>
              <div>
                <InputNew
                  id="dashboard_entity_select"
                  type="text"
                  label={titleTag.fe_label}
                  element="select"
                  onInput={inputHandler}
                  initialValue=""
                  validators={[]}
                  onRecord={() => {}}
                  clean_options={
                    Array.isArray(summaryList)
                      ? summaryList?.map((i) => i?.entity)
                      : []
                  }
                />
              </div>
              <div style={{ marginTop: "0.9rem" }}>
                <InputNew
                  id={displayTag.fe_id}
                  type="text"
                  label={displayTag.fe_label}
                  element={displayTag.fe_element}
                  onInput={inputHandler}
                  initialValue={tabNameOutToInHandler(currentSummary?.display)}
                  validators={[]}
                  onRecord={() => {}}
                  clean_options={
                    typeof displayTag?.group === "string"
                      ? displayTag?.group?.split(",")
                      : []
                  }
                />
              </div>
            </Box>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <Box sx={{ width: "100%" }}>
              <InputNew
                id="dashboard_description"
                type="text"
                label={descriptionTag.fe_label}
                element="textarea"
                onInput={inputHandler}
                initialValue={currentSummary?.description}
                validators={[]}
                onRecord={() => {}}
                rows={5}
              />
            </Box>
          </Grid>
          <Grid item lg={12} sx={{ display: "flex", justifyContent: "center" }}>
            {(isDashboardLoading || isLoading) && <CircularProgress />}
          </Grid>
        </Grid>

        <Grid
          container
          rowSpacing={3}
          columnSpacing={3}
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "4rem",
          }}
        >
          {!isDashboardLoading &&
            !isLoading &&
            filterChartData(currentSummary?.grid)
              ?.sort((a, b) => a.order - b.order)
              ?.map((chart, i) => (
                <Grid
                  item
                  xs={12}
                  md={5}
                  lg={chart.chart_type === "card" ? 12 : Number(chart.width)}
                  key={i}
                >
                  <Grid>
                    <Grid item>
                      <Typography
                        variant="h5"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div>{getGridLabelHandler("title")}</div>
                        <div style={{ width: "50%" }}>
                          <InputNew
                            id={`dashboard_title+${chart.order}`}
                            type="text"
                            label=""
                            element="input"
                            onInput={inputHandler}
                            onBlur="dashboard"
                            initialValue={chart.title}
                            validators={[]}
                            onRecord={() => {}}
                          />
                        </div>
                      </Typography>
                    </Grid>
                  </Grid>
                  <MainCard
                    sx={{ mt: 1.75 }}
                    height="300px"
                    isDeleteShow={(e, gridOrder) =>
                      deleteGridHandler(e, chart.order)
                    }
                  >
                    <Box sx={{ p: 0, pb: 0 }}>
                      <Stack spacing={1.5}>
                        <Typography variant="h6" color="textSecondary">
                          <Grid container spacing={0}>
                            <Grid item xs={12} md={4} lg={6}>
                              <InputNew
                                id={`dashboard_subtitle+${chart.order}`}
                                type="text"
                                label={getGridLabelHandler("subtitle")}
                                element="input"
                                onInput={inputHandler}
                                onBlur="dashboard"
                                initialValue={chart.subtitle}
                                validators={[]}
                                onRecord={() => {}}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={6}>
                              <InputNew
                                id={`dashboard_query_title+${chart.order}`}
                                type="text"
                                label={getGridLabelHandler("query_title")}
                                element="select"
                                onInput={inputHandler}
                                initialValue={chart.query_title}
                                validators={[]}
                                onRecord={() => {}}
                                clean_options={dashboardList?.query_title}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={6}>
                              <InputNew
                                id={`dashboard_chart_type+${chart.order}`}
                                type=""
                                label={getGridLabelHandler("chart_type")}
                                element="select"
                                onInput={inputHandler}
                                initialValue={chart.chart_type}
                                validators={[]}
                                onRecord={() => {}}
                                clean_options={dashboardList?.chart_type}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={6}>
                              <InputNew
                                id={`dashboard_label+${chart.order}`}
                                type="text"
                                label={getGridLabelHandler("label")}
                                element="input"
                                onInput={inputHandler}
                                onBlur="dashboard"
                                initialValue={chart.label}
                                validators={[]}
                                onRecord={() => {}}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={6}>
                              <InputNew
                                id={`dashboard_width+${chart.order}`}
                                type="number"
                                label={getGridLabelHandler("width")}
                                element="input"
                                onInput={inputHandler}
                                onBlur="dashboard"
                                initialValue={chart.width}
                                validators={[]}
                                onRecord={() => {}}
                              />
                            </Grid>
                            <Grid item xs={12} md={4} lg={6}>
                              <InputNew
                                id={`dashboard_height+${chart.order}`}
                                type="number"
                                label={getGridLabelHandler("height")}
                                element="input"
                                onInput={inputHandler}
                                onBlur="dashboard"
                                initialValue={chart.height}
                                validators={[]}
                                onRecord={() => {}}
                              />
                            </Grid>
                          </Grid>
                        </Typography>
                        {chart.chartType === "stack-bar" && (
                          <Typography variant="h3">{chart.total}</Typography>
                        )}
                      </Stack>
                    </Box>
                  </MainCard>
                </Grid>
              ))}

          <Grid item xs={12} md={5} lg={3}>
            <MainCard sx={{ mt: 7.75 }} height="300px">
              <div>
                <Button onClick={addNewChartHandler}>
                  <img src={AddImg} alt="" style={{ width: "50%" }} />
                </Button>
              </div>
            </MainCard>
          </Grid>
        </Grid>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant="outlined"
            className="editButton"
            onClick={updateChartHandler}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            className="editButton"
            onClick={deleteSummaryHandler}
          >
            Delete
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default DashboardConfig;
