import { useState, Fragment, useContext, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  saveDataInIndexDB,
  getDataFromDB,
  deleteDataFromIndexDB,
  db,
} from "../../../components/CustomComponents/IndexedDBTemplate";

import { toast } from "react-toastify";
import Toast from "../../../components/Utils/Toast";

import "./NewValidation.css";

import InputNew from "../../../components/Content/FormElements/InputNew";
import FileLoad from "../../../components/CustomComponents/FileLoad";
import {
  CircularProgress,
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import PopUpTable from "./PopUpTable";
import { useForm } from "../../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../../components/Utils/hooks/http-hook";
import { UserContext } from "../../../context/user-context";
import { isTwoJsonValueEqual } from "../../../components/CustomComponents/GeneralPageTemplate";

function incrementString(str) {
  const regex = /(\D+)(\d*)/; // Regular expression to match letters (\D+) and numbers (\d*)
  const match = str.match(regex); // Match the regular expression against the input string
  if (!match) {
    return str; // If no match is found, return the original string
  }
  const letters = match[1]; // Extract the letters from the match result
  const number = parseInt(match[2] || "0", 10) + 1; // Extract the number, convert to integer, and increment by one
  const numberStr = String(number).padStart(match[2].length, "0"); // Convert the number back to string and pad with leading zeros
  return letters + numberStr; // Concatenate the letters and the incremented number
}

const NewValidation = ({ tag, isTreeChanged, setScrollTop, pageElement }) => {
  const { pathname, maxValidationId } = useLocation();
  const { validationId } = useParams();
  const {
    user,
    token,
    userGroup,
    fileTypeInToOutHandler,
    isPageDataChangedHandler,
    isPageDataNotChangedHandler,
  } = useContext(UserContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [files, setFiles] = useState([]);
  const [newDocument, setNewDocument] = useState([]);
  const [open, setOpen] = useState(false);
  const [formDataObj, setFormDataObj] = useState({});
  const [tableData, setTableData] = useState([]);
  const [storedData, setStoredData] = useState({});
  const [buttonOperation, setButtonOperation] = useState("");
  const [data, setData] = useState([]);
  const [formState, inputHandler] = useForm(
    {
      validation_id: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const { display_name } = user || {};
  const status_option = Object.values(user["group access"]) || [];

  let tag2, fileOption;
  if (tag) {
    tag2 = tag.filter((item) => item.db_tablename === "validation");
    fileOption = tag.filter(
      (item) => item.db_tablename === "documents" && item.fe_id === "file_type"
    );
  } else {
    tag2 = [];
    fileOption = [];
  }

  let tag3;
  if (tag) {
    tag3 = tag
      .filter((item) => item.db_tablename === "validation assumption")
      .sort((a, b) => a.fe_order - b.fe_order);
  }

  if (tag) {
    tag = tag.filter(
      (item) => item.db_tablename === "validation" && item.fe_isrender === true
    );
  } else {
    tag = [];
  }

  const history = useHistory();

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const buttonAccess = user["menu access"] || {};
  const access = Object.values(buttonAccess)[0]
    .filter((item) => item[0] === "Validation")
    .map((access) => access[1]);

  const ratingOption = { High: 3, Medium: 2, Low: 1 };

  const ValidationActivityIdCheck = () => {
    if (
      !/ATV\d\d\d\d/.test(formState.inputs.validation_id.value) ||
      formState.inputs.validation_id.value.length !== 7
    ) {
      Toast("error", "MRM Activity ID has an invalid format.");
      return false;
    }

    return true;
  };

  const validationNetRiskingCheck = () => {
    if (
      formState.inputs.net_rating &&
      formState.inputs.risk_rating &&
      formState.inputs.net_rating.value &&
      formState.inputs.risk_rating.value &&
      ratingOption[formState.inputs.net_rating.value] >
        ratingOption[formState.inputs.risk_rating.value]
    ) {
      Toast(
        "error",
        "Net Risk Rating should be lower than or equal to Inherent Risk Rating."
      );
      return false;
    }
    return true;
  };

  const validationMrmActivityIdCheck = () => {
    if (
      !formState.inputs.validation_id ||
      !formState.inputs.validation_id.value ||
      formState.inputs.validation_id.value === "" ||
      !/ATV\d\d\d\d/.test(formState.inputs.validation_id.value)
    ) {
      Toast("error", "MRM Activity ID has an invalid format.");
      return false;
    }
    return true;
  };

  const validationStatusCheck = () => {
    if (
      !formState.inputs.validation_status ||
      !formState.inputs.validation_status.value ||
      formState.inputs.validation_status.value === ""
    ) {
      Toast("error", "Please input correct Validation status.");
      return false;
    }
    return true;
  };

  const validationExpiryDateCheck = () => {
    if (
      formState.inputs.approver &&
      formState.inputs.approver.value !== "CRO Exception Approval"
    ) {
      if (
        formState.inputs.expire_date &&
        formState.inputs.expire_date.value !== ""
      ) {
        Toast(
          "error",
          "Please check Model Approver and CRO Exception Expiry Date."
        );
        return false;
      }
    }
    return true;
  };

  useEffect(() => {
    validationNetRiskingCheck();
  }, [
    formState.inputs?.net_rating?.value,
    formState.inputs.risk_rating?.value,
  ]);

  const newValidationInitialValueHandler = (tag) => {
    if (tag.fe_id === "next_date") {
      if (
        formState.inputs.certification_date &&
        formState.inputs.risk_rating &&
        formState.inputs.certification_date.value &&
        formState.inputs.risk_rating.value
      ) {
        const date = new Date(formState.inputs.certification_date.value);
        switch (formState.inputs.risk_rating.value) {
          case "High":
            return new Date(date.setDate(date.getDate() + 365 * 3 + 1))
              .toISOString()
              .split("T")[0];
          case "Medium":
            return new Date(date.setDate(date.getDate() + 365 * 4 + 1))
              .toISOString()
              .split("T")[0];
          case "Low":
            return new Date(date.setDate(date.getDate() + 365 * 5 + 1))
              .toISOString()
              .split("T")[0];
          default:
            return "";
        }
      }
    }

    if (
      tag.fe_id === "vendor_developer" &&
      formState.inputs.vendor &&
      formState.inputs.vendor.value === "No"
    ) {
      return "";
    }

    if (
      tag.fe_id === "other" &&
      formState.inputs.purpose &&
      formState.inputs.purpose.value !== "Others"
    ) {
      return "";
    }

    if (
      tag.fe_id === "expire_date" &&
      formState.inputs.approver &&
      formState.inputs.approver.value !== "CRO Exception Approval"
    ) {
      return "";
    }

    if (storedData && storedData[tag.fe_id]) {
      return storedData[tag.fe_id];
    }

    if (tag.fe_id === "validation_id") {
      if (maxValidationId) {
        return incrementString(maxValidationId);
      } else {
        return "ATV0001";
      }
    }
  };

  const conditionalDisabledHandler = (tag) => {
    if (
      tag.fe_id === "expire_date" &&
      formState.inputs.approver &&
      formState.inputs.approver.value !== "CRO Exception Approval"
    ) {
      return true;
    }

    if (tag.fe_id === "next_date" || tag.fe_id === "validation_id") {
      return true;
    }

    return false;
  };

  const validationDateCheck = () => {
    if (
      formState.inputs.certification_date &&
      formState.inputs.certification_date.value &&
      formState.inputs.certification_date.value !== ""
    ) {
      if (
        !(
          formState.inputs.end_date &&
          formState.inputs.start_date &&
          formState.inputs.end_date.value &&
          formState.inputs.start_date.value &&
          formState.inputs.start_date.value <=
            formState.inputs.end_date.value &&
          formState.inputs.end_date.value <=
            formState.inputs.certification_date.value
        )
      ) {
        Toast(
          "error",
          "Activity End Date should be later than Activity Start Date and earlier than Approval Date."
        );
        return false;
      }
    } else if (formState.inputs.end_date && formState.inputs.end_date.value) {
      if (
        !(
          formState.inputs.start_date &&
          formState.inputs.start_date.value &&
          formState.inputs.start_date.value <= formState.inputs.end_date.value
        )
      ) {
        Toast(
          "error",
          "Activity End Date should be later than Activity Start Date."
        );
        return false;
      }
    }

    return true;
  };

  const validationReportDateCheck = () => {
    if (
      formState.inputs.report_date.value &&
      formState.inputs.report_date.value !== ""
    ) {
      if (
        !formState.inputs.start_date.value ||
        formState.inputs.start_date.value === "" ||
        (formState.inputs.start_date.value &&
          formState.inputs.start_date.value !== "" &&
          formState.inputs.start_date.value >
            formState.inputs.report_date.value)
      ) {
        Toast("error", "Report Date should be later than Activity Start Date.");
        return false;
      }
    }
    return true;
  };

  const fileTypeCheck = () => {
    if (Array.from(newDocument, (x) => x.file_type).includes("")) {
      Toast("error", "Please specify the File Type.");
      return false;
    }
    return true;
  };

  const validationApprovalRatingCheck = () => {
    if (
      formState.inputs.rating &&
      formState.inputs.rating.value &&
      formState.inputs.validation_status &&
      formState.inputs.validation_status
    ) {
      if (
        formState.inputs.validation_status?.value !==
          "Report MD Reviewed and Agreed" &&
        (formState.inputs.rating.value === "Approval" ||
          formState.inputs.rating.value === "Approved With Condition(s)")
      ) {
        toast.error(
          "Validation Status 'Report MD Reviewed and Agreed' is required.",
          {
            autoClose: false,
            position: "top-center",
            toastId: "validationStatusError",
          }
        );
        return false;
      }
    }
    return true;
  };

  const handleClickOpen = (operation) => {
    if (ValidationActivityIdCheck() === false) {
      return;
    }

    if (validationNetRiskingCheck() === false) {
      return;
    }

    if (validationMrmActivityIdCheck() === false) {
      return;
    }

    if (validationDateCheck() === false) {
      return;
    }

    if (validationStatusCheck() === false) {
      return;
    }

    if (validationExpiryDateCheck() === false) {
      return;
    }

    if (validationReportDateCheck() === false) {
      return;
    }

    if (fileTypeCheck() === false) {
      return;
    }

    if (validationApprovalRatingCheck() === false) {
      return;
    }

    setOpen(true);

    const formData = new FormData();
    formData.append("code", validationId.split("+")[0]);
    formData.append("version", validationId.split("+")[1]);
    formData.append("user_id", display_name);

    tag.forEach((item) => {
      if (item.fe_id !== "update_time") {
        formData.append(
          item.fe_id,
          formState.inputs[item.fe_id].value
            ? formState.inputs[item.fe_id].value
            : ""
        );
      }
    });

    let object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    setFormDataObj(formData);
    let arr = [];
    for (const [key, value] of Object.entries(object)) {
      const order = tag2.find((item) => item.fe_id === key)?.fe_order;
      if (!order) {
        continue;
      }
      arr.push({ id: order, attribute: key, current: value });
    }
    setTableData(arr.sort((a, b) => a.id - b.id));
    setButtonOperation(operation);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validationSubmitHandler = async (event) => {
    event.preventDefault();
    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);
    const traceList = [
      [
        1,
        validationId.split("+")[0],
        validationId.split("+")[1],
        formState.inputs.validation_id.value,
        formState.inputs.validation_status.value,
        display_name,
        currentDate,
      ],
    ];

    try {
      setOpen(false);
      const formData = new FormData();
      formData.append("code", validationId.split("+")[0]);
      formData.append("version", validationId.split("+")[1]);
      formData.append("access_log", JSON.stringify(traceList));
      formData.append("form", "Validation");
      formData.append("user_id", display_name);
      formData.append("update_time", currentDate);
      formData.append("sequence", 0);
      newDocument.length > 0 &&
        formData.append(
          "file_description",
          JSON.stringify(Array.from(newDocument, (x) => x.file_description))
        );
      newDocument.length > 0 &&
        formData.append(
          "file_type",
          JSON.stringify(
            Array.from(newDocument, (x) => fileTypeInToOutHandler(x.file_type))
          )
        );

      tag.forEach((item) => {
        if (
          item.fe_element !== "autoFill" &&
          item.fe_element !== "autoComplete" &&
          item.fe_type !== "number" &&
          item.fe_id !== "user_id" &&
          item.fe_id !== "version" &&
          item.fe_id !== "user_id" &&
          item.fe_id !== "code" &&
          item.fe_id !== "update_time"
        ) {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id]?.value
              ? formState.inputs[item.fe_id]?.value
              : ""
          );
        }
      });

      tag.forEach((item) => {
        if (item.fe_element === "autoFill") {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id].value
              ? JSON.stringify(formState.inputs[item.fe_id]?.value)
              : JSON.stringify([])
          );
        }
      });

      tag.forEach((item) => {
        if (item.fe_type === "number") {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id]
              ? formState.inputs[item.fe_id].value
                ? formState.inputs[item.fe_id].value
                : 0
              : 0
          );
        }
      });

      tag3.forEach((item) => {
        formData.append(
          item.fe_id,
          !formState.inputs[item.fe_id] ||
            formState.inputs[item.fe_id].length === 0 ||
            !formState.inputs[item.fe_id].value
            ? JSON.stringify([])
            : JSON.stringify(
                formState.inputs[item.fe_id].value
                  .map((i) => ({
                    ...i,
                    validation_id: formState.inputs.validation_id.value,
                  }))
                  .filter((i) => i.condition !== "")
              )
        );
      });

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append(`file${i + 1}`, files[i]);
        }
      }

      let inventoryValue = true;

      if (buttonOperation === "save") {
        inventoryValue = false;
      }

      const res = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/validation/submit?inventory=${inventoryValue}`,
        "PUT",
        formData,
        headers
      );

      if (res.error) {
        Toast("error", res);
        return;
      }

      Toast("success", "New Validation has been created successfully.");
      deleteDataFromIndexDB("New Validation");
      isPageDataNotChangedHandler("New", 0);
      //Redirect the user to a different page
      history.push("/validation");
    } catch (err) {
      toast(err, {
        autoClose: false,
        position: "top-center",
      });
    }
  };

  const fileChangeHandler = (id, value) => {
    setNewDocument(
      newDocument.map((doc, i) => {
        if ("file_" + doc.filename + "_type_" + (i + 1) === id) {
          doc.file_type = value;
        }
        if ("file_description_" + doc.filename === id) {
          doc.file_description = value;
        }
        return doc;
      })
    );
  };
  const uploadFileHandler = (file) => {
    if (Array.isArray(files) && files.map((i) => i.name).includes(file.name)) {
      Toast("error", "File already added.");
      return;
    }

    setFiles([...files, file]);
    setNewDocument([
      ...newDocument,
      {
        file_description: "",
        filename: file.name,
        update_time: new Date(),
        file_owner: display_name,
        files_status: "active",
        file_type: "",
      },
    ]);
  };

  const removeFileHandler = (file) => {
    setFiles(files.filter((doc) => doc.name !== file));
    setNewDocument(newDocument.filter((doc) => doc.filename !== file));
  };

  const optionException = (tag) => {
    if (tag.fe_id === "validation_status") {
      return status_option[0]
        .filter((item) => item[0] === "Validation")
        .map((item) => item[1]);
    } else {
      return undefined;
    }
  };

  const formOnBlurHandler = (tag) => {
    if (
      tag.fe_id === "validation_id" ||
      tag.fe_id === "risk_rating" ||
      tag.fe_id === "net_rating" ||
      tag.fe_id === "issue_id"
    ) {
      return true;
    } else {
      return false;
    }
  };

  const validationValidatorHandler = (tag) => {
    return [];
  };

  const validationErrorTextHandler = (tag) => {
    if (
      (tag.fe_id === "risk_rating" || tag.fe_id === "net_rating") &&
      formState.inputs.net_rating &&
      formState.inputs.risk_rating &&
      ratingOption[formState.inputs.net_rating.value] >=
        ratingOption[formState.inputs.risk_rating.value]
    ) {
      return "Net rating should be lower than or equal to risk rating";
    }

    if (tag.fe_id === "validation_id") {
      return "MRM Validation ID should be in the format of ATV0001";
    }

    return "";
  };

  const PopUpModalData = tableData
    .filter(
      (item) =>
        item.attribute !== "update_time" &&
        item.attribute !== "user_id" &&
        item.attribute !== "access_log"
    )
    .map((data) => {
      const label = tag2.find(
        ({ fe_id }) => fe_id === data.attribute
      )?.fe_label;
      return { ...data, attribute: label };
    });

  const isDataChanged = () => {
    let newTag = tag.filter((i) => i.fe_id !== "validation_id");
    for (let i = 0; i < newTag.length; i++) {
      if (
        !isTwoJsonValueEqual(
          newValidationInitialValueHandler(tag),
          formState.inputs?.[newTag[i].fe_id]?.value
        )
      ) {
        // console.log("data changed!");
        // console.log("label:", newTag[i].fe_label);
        // console.log("initial:", newValidationInitialValueHandler(tag));
        // console.log("input:", formState.inputs?.[newTag[i].fe_id]?.value);
        return true;
      }
    }

    for (let i = 0; i < tag3.length; i++) {
      if (
        !isTwoJsonValueEqual(
          newValidationInitialValueHandler(tag),
          formState.inputs?.[tag3[i].fe_id]?.value
        )
      ) {
        // console.log("data changed!");
        // console.log("label:", tag3[i].fe_label);
        // console.log("initial:", newValidationInitialValueHandler(tag));
        // console.log("input:", formState.inputs?.[tag3[i].fe_id]?.value);
        return true;
      }
    }

    if (newDocument?.length > 0) {
      // console.log("files exist");

      return true;
    }

    return false;
  };

  useEffect(() => {
    if (isDataChanged()) {
      isPageDataChangedHandler("New", 0);
    } else {
      isPageDataNotChangedHandler("New", 0);
    }
  }, [formState.inputs]);

  let element;
  element = (
    <div style={{ marginTop: "1.5rem" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "650px",
            maxWidth: "900px",
            height: "100%",
            padding: "1rem 3rem 1rem 3rem",
          },
        }}
      >
        <DialogTitle>
          <PopUpTable tableData={PopUpModalData} isNew={true} />
        </DialogTitle>
        <DialogActions>
          <Button onClick={validationSubmitHandler}>Confirm</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <form style={{ margin: "0 8rem 2rem 8rem" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={0.5} columnSpacing={5}>
            {tag
              .sort((a, b) => a.fe_order - b.fe_order)
              .map((tag, i) => (
                <Grid
                  item
                  xs={12}
                  md={tag.fe_grid === 12 ? 12 : 6}
                  lg={tag.fe_grid}
                  key={i}
                >
                  <InputNew
                    id={tag.fe_id}
                    type={tag.fe_type}
                    label={tag.fe_label}
                    element={tag.fe_element}
                    onInput={inputHandler}
                    initialValid={true}
                    initialValue={newValidationInitialValueHandler(tag)}
                    validators={validationValidatorHandler(tag)}
                    errorText={validationErrorTextHandler(tag)}
                    onBlur={formOnBlurHandler(tag)}
                    origin_options={tag}
                    onRecord={() => {}}
                    clean_options={optionException(tag)}
                    disabled={conditionalDisabledHandler(tag)}
                    options={
                      userGroup
                        ? userGroup.map((user) => ({
                            label: user,
                            value: user,
                          }))
                        : []
                    }
                    setScrollTop={setScrollTop}
                    pageElement={pageElement}
                  />
                </Grid>
              ))}

            {tag3.map((tag, i) => (
              <Grid
                item
                xs={12}
                md={tag.fe_grid === 12 ? 12 : 6}
                lg={tag.fe_grid}
                key={i}
              >
                <InputNew
                  id={tag.fe_id}
                  type={tag.fe_type}
                  label={tag.fe_label}
                  element={tag.fe_element}
                  onInput={inputHandler}
                  initialValue={newValidationInitialValueHandler(tag)}
                  clean_options={optionException(tag)}
                  onRecord={() => {}}
                  tableData={[]}
                  form="Validation"
                  indicator={validationId.split("+")[0]}
                  version={validationId.split("+")[1]}
                  sequence={0}
                  userId={display_name}
                  validationId={data?.validation_id}
                  setScrollTop={setScrollTop}
                  pageElement={pageElement}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <div style={{ padding: "0.8rem" }}>
                <FileLoad
                  uploadHandler={uploadFileHandler}
                  removeHandler={removeFileHandler}
                  newDocument={newDocument}
                  form="model"
                  onInput={inputHandler}
                  fileChangeHandler={fileChangeHandler}
                  fileOption={fileOption}
                />
              </div>
            </Grid>
            {(!tag || isLoading) && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                <Button
                  onClick={() => handleClickOpen("publish")}
                  variant="outlined"
                  className="editButton"
                  disabled={access.includes("Update") ? false : true}
                >
                  Submit
                </Button>

                {
                  <Button
                    onClick={() => handleClickOpen("save")}
                    variant="outlined"
                    className="editButton"
                    disabled={access.includes("Update") ? false : true}
                  >
                    Save
                  </Button>
                }
              </div>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );

  return <Fragment>{element}</Fragment>;
};

export default NewValidation;
