import TemplatePage from "../../components/CustomComponents/GeneralPageTemplate";

import "./Model.css";

const Model = (props) => {
  if (props?.record?.includes("undefined")) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
          paddingTop: "10rem",
        }}
      >
        <h2 style={{ color: "#444791" }}>Please select a model</h2>
      </div>
    );
  }
  return (
    <TemplatePage
      tag={props.tag}
      record={props.record}
      modelData={props.modelData}
      isTreeChanged={props.isTreeChanged}
      form="model"
      table="Model"
      setScrollTop={props.setScrollTop}
      pageElement={props.pageElement}
    />
  );
};

export default Model;
