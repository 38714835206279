import { useState, useEffect } from "react";

// material-ui
import { Box, Grid, Stack, Typography } from "@mui/material";
import LazyLoad from "react-lazy-load";

// project import
import { OrdersTable } from "./OrdersTable";
import { HorizontalBarChart } from "./H-BarChart";
import { VerticalBarChart } from "./V-BarChart";
import LineChart from "./LineChart";
import PieChart from "./PieChart";
import DonutChart from "./DonutChart";
import MainCard from "./MainCard";
import CardChart from "./CardChart";

// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardLayout = (props) => {
  return (
    <Box
      component="main"
      sx={{
        width: "100%",
        flexGrow: 0,
        p: { xs: 0, sm: 0 },
      }}
    >
      <Grid container rowSpacing={3} columnSpacing={3}>
        {/* row 1 */}
        <Grid item xs={12} sx={{ mb: -2.25 }}>
          <Typography variant="h5"></Typography>
        </Grid>

        {props.layoutData?.grid
          ?.sort((a, b) => a.order - b.order)
          ?.map((chart, i) => {
            const chartMap = {
              card: (
                <CardChart
                  title={chart.title}
                  count={chart?.data?.[chart?.axis?.[1]]?.toString()}
                  status={chart?.data?.[chart?.axis?.[0]]}
                  axis={chart.axis}
                  percentage={chart?.data?.Percentage}
                  isLoss
                  color="warning"
                  extra="$20,395"
                />
              ),
              "v-bar": (
                <VerticalBarChart
                  chartTitle={chart.subtitle}
                  chartData={chart?.data?.data}
                  chartLabels={chart?.data?.label}
                  chartHeight={0.7 * chart.height}
                  axis={chart.axis}
                  chartAxisLabel={chart?.label}
                />
              ),
              "h-bar": (
                <HorizontalBarChart
                  chartTitle={chart.subtitle}
                  chartData={chart?.data?.data}
                  chartLabels={chart?.data?.label}
                  chartHeight={0.7 * chart.height}
                  axis={chart.axis}
                  chartAxisLabel={chart?.label}
                />
              ),
              line: (
                <LineChart
                  chartTitle={chart.subtitle}
                  chartData={
                    Array.isArray(chart?.data)
                      ? chart?.data?.map((i) => i[chart?.axis[1]])
                      : []
                  }
                  chartLabels={
                    Array.isArray(chart?.data)
                      ? chart?.data?.map((i) => i[chart?.axis[0]])
                      : []
                  }
                  chartHeight={0.8 * chart.height}
                  chartAxisLabel={chart?.label}
                />
              ),
              pie: (
                <PieChart
                  chartTitle={chart.subtitle}
                  chartData={chart?.data?.data}
                  chartLabels={chart?.data?.label}
                  chartHeight={0.9 * chart.height}
                />
              ),
              donut: (
                <DonutChart
                  chartTitle={chart.subtitle}
                  chartData={chart?.data?.data}
                  chartLabels={chart?.data?.label}
                  chartHeight={0.9 * chart.height}
                />
              ),

              table: (
                <OrdersTable
                  chartHeader={chart.axis}
                  chartData={chart?.data}
                  chartHeight={0.8 * chart.height}
                  chartTitle={chart.subtitle}
                />
              ),
            };

            if (
              Array.isArray(Object.keys(chart?.data)) &&
              Object.keys(chart?.data)?.length === 0
            ) {
              return;
            }

            if (chart.chart_type === "card")
              return (
                <Grid item xs={12} md={6} lg={Number(chart.width)} key={i}>
                  <LazyLoad height={140} offsetVertical={500}>
                    {chartMap[chart.chart_type]}
                  </LazyLoad>
                </Grid>
              );

            return (
              <Grid
                item
                xs={12}
                md={6}
                lg={Number(chart.width) <= 1 ? 1 : Number(chart.width)}
                key={i}
              >
                <Grid>
                  <Grid item>
                    <Typography variant="h5">{chart.title}</Typography>
                  </Grid>
                </Grid>
                <MainCard
                  sx={{ mt: 1.75, overflowY: "auto" }}
                  height={chart.height}
                >
                  <Box sx={{ p: 1, pb: 0 }}>
                    <Stack spacing={1.5}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        sx={{ display: "flex", justifyContent: "center" }}
                      >
                        {chart.subtitle}
                      </Typography>
                      {chart.chart_type === "stack-bar" && (
                        <Typography variant="h3">{chart.total}</Typography>
                      )}
                    </Stack>
                  </Box>
                  <LazyLoad height={200} offsetVertical={500}>
                    {chartMap[chart.chart_type]}
                  </LazyLoad>
                  {/*chart.chartType === "stack-bar" && <TasksTable />*/}
                </MainCard>
              </Grid>
            );
          })}

        {/* row 4 */}

        {/*<Grid item xs={12} md={5} lg={4}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item>
              <Typography variant="h5">Transaction History</Typography>
            </Grid>
            <Grid item />
          </Grid>
          <MainCard sx={{ mt: 2 }} content={false}>
            <List
              component="nav"
              sx={{
                px: 0,
                py: 0,
                "& .MuiListItemButton-root": {
                  py: 1.5,
                  "& .MuiAvatar-root": avatarSX,
                  "& .MuiListItemSecondaryAction-root": {
                    ...actionSX,
                    position: "relative",
                  },
                },
              }}
            >
              <ListItemButton divider>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: "success.main",
                      bgcolor: "success.lighter",
                    }}
                  >
                    <GiftOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1">Order #002434</Typography>
                  }
                  secondary="Today, 2:00 AM"
                />
                <ListItemSecondaryAction>
                  <Stack alignItems="flex-end">
                    <Typography variant="subtitle1" noWrap>
                      + $1,430
                    </Typography>
                    <Typography variant="h6" color="secondary" noWrap>
                      78%
                    </Typography>
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
              <ListItemButton divider>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: "primary.main",
                      bgcolor: "primary.lighter",
                    }}
                  >
                    <MessageOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1">Order #984947</Typography>
                  }
                  secondary="5 August, 1:45 PM"
                />
                <ListItemSecondaryAction>
                  <Stack alignItems="flex-end">
                    <Typography variant="subtitle1" noWrap>
                      + $302
                    </Typography>
                    <Typography variant="h6" color="secondary" noWrap>
                      8%
                    </Typography>
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{
                      color: "error.main",
                      bgcolor: "error.lighter",
                    }}
                  >
                    <SettingOutlined />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1">Order #988784</Typography>
                  }
                  secondary="7 hours ago"
                />
                <ListItemSecondaryAction>
                  <Stack alignItems="flex-end">
                    <Typography variant="subtitle1" noWrap>
                      + $682
                    </Typography>
                    <Typography variant="h6" color="secondary" noWrap>
                      16%
                    </Typography>
                  </Stack>
                </ListItemSecondaryAction>
              </ListItemButton>
            </List>
          </MainCard>
          <MainCard sx={{ mt: 2 }}>
            <Stack spacing={3}>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Stack>
                    <Typography variant="h5" noWrap>
                      Help & Support Chat
                    </Typography>
                    <Typography variant="caption" color="secondary" noWrap>
                      Typical replay within 5 min
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item>
                  <AvatarGroup
                    sx={{ "& .MuiAvatar-root": { width: 32, height: 32 } }}
                  >
                    <Avatar alt="Remy Sharp" src={avatar1} />
                    <Avatar alt="Travis Howard" src={avatar2} />
                    <Avatar alt="Cindy Baker" src={avatar3} />
                    <Avatar alt="Agnes Walker" src={avatar4} />
                  </AvatarGroup>
                </Grid>
              </Grid>
              <Button
                size="small"
                variant="contained"
                sx={{ textTransform: "capitalize" }}
              >
                Need Help?
              </Button>
            </Stack>
          </MainCard>
                </Grid>*/}
      </Grid>
    </Box>
  );
};

export default DashboardLayout;
