import { useContext, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import {
  Toolbar,
  Container,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  List,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuPage from "./MenuPage";

import { UserContext } from "../../../context/user-context";
import { clearIndexDB } from "../../../components/CustomComponents/IndexedDBTemplate";

import "./NavLinks.css";

const NavLinks = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const {
    user,
    tabNameOutToInHandler,
    tabNameInToOutHandler,
    isTempPage,
    dataChangedArr,
    handleDialogClickOpen,
    setNavigationHandler,
    clearChangedArrHandler,
    record,
  } = useContext(UserContext);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  let pathname2;
  if (pathname) {
    pathname2 = pathname.split("/")?.[1];
  }

  let formList;
  let documentPermission = false;
  if (user) {
    formList = Object.values(user["menu access"])[0].map((form) => form[0]);
    documentPermission = user["document permission"];
  }

  const linkStyle = { pointerEvents: "none", color: "grey" };
  const activeStyle = {
    background: "#FF9100",
    borderColor: "#292929",
    fontColor: "#292929",
    borderRadius: "6px",
  };
  const notSavedStyle = {
    background: "#CB3694",
    borderColor: "#292929",
    fontColor: "black",
    borderRadius: "6px",
  };

  const conditionalNavigationHandler = (e, path) => {
    e.preventDefault();
    if (pathname.includes("/new")) {
      if (dataChangedArr.some((obj) => "New" in obj)) {
        setNavigationHandler(path);
        handleDialogClickOpen();
      } else {
        history.push(path);
      }
    } else {
      history.push(path);
    }
  };

  const changePageNavigationHandler = (e) => {
    e.preventDefault();
    if (pathname !== "/change") {
      if (
        window.confirm(
          "This is only for material changes. Do you want to continue?"
        ) === true
      ) {
        if (pathname.includes("/new")) {
          if (dataChangedArr.some((obj) => "New" in obj)) {
            setNavigationHandler("/change");
            handleDialogClickOpen();
          } else {
            history.push("/change");
          }
        } else {
          history.push("/change");
        }
      }
    }
  };

  const changeToNonModelRelatedPageHandler = (e, path) => {
    e.preventDefault();
    if (dataChangedArr.length > 0) {
      setNavigationHandler(path);
      handleDialogClickOpen();
    } else {
      clearChangedArrHandler();
      clearIndexDB();
      history.push(path);
    }
  };

  return (
    <Container sx={{ p: { xs: 0, md: 0, lg: 0 }, pr: { xs: 0, md: 0, lg: 1 } }}>
      <Toolbar disableGutters>
        <Box sx={{ display: { xs: "none", md: "none", lg: "flex" } }}>
          <ul className="nav-links">
            <li style={pathname === "/model/new" ? activeStyle : {}}>
              <NavLink
                to="/model/new"
                onClick={(e) =>
                  changeToNonModelRelatedPageHandler(e, "/model/new")
                }
                style={formList.includes("New") ? {} : linkStyle}
                exact
              >
                MD Submission
              </NavLink>
            </li>

            <li
              style={
                dataChangedArr.some(
                  (element) => typeof element === "object" && "model" in element
                )
                  ? notSavedStyle
                  : pathname !== "/model/new" && pathname2 === "model"
                  ? activeStyle
                  : {}
              }
            >
              <NavLink
                to={isTempPage ? "/temp" : "/model"}
                onClick={(e) =>
                  conditionalNavigationHandler(
                    e,
                    isTempPage ? "/temp" : "/model"
                  )
                }
                style={
                  formList.includes("Model") &&
                  pathname.split("/")?.[1] !== "temp" &&
                  pathname.split("/")?.[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                Model Information
              </NavLink>
            </li>

            <li
              style={
                dataChangedArr.some(
                  (element) =>
                    typeof element === "object" && "change" in element
                )
                  ? notSavedStyle
                  : pathname2 === "change"
                  ? activeStyle
                  : {}
              }
            >
              <NavLink
                to="/change"
                onClick={(e) => changePageNavigationHandler(e, "/change")}
                style={
                  formList.includes("Change") &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                Model Change
              </NavLink>
            </li>

            <li
              style={
                dataChangedArr.some(
                  (element) =>
                    typeof element === "object" && "validation" in element
                )
                  ? notSavedStyle
                  : pathname2 === "validation"
                  ? activeStyle
                  : {}
              }
            >
              <NavLink
                to="/validation"
                onClick={(e) => conditionalNavigationHandler(e, "/validation")}
                style={
                  formList.includes("Validation") &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                MRM Activities
              </NavLink>
            </li>

            <li
              style={
                dataChangedArr.some(
                  (element) => typeof element === "object" && "issue" in element
                )
                  ? notSavedStyle
                  : pathname2 === "issue"
                  ? activeStyle
                  : {}
              }
            >
              <NavLink
                to="/issue"
                onClick={(e) => conditionalNavigationHandler(e, "/issue")}
                style={
                  formList.includes("Issue") &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
              >
                Issue Management
              </NavLink>
            </li>

            {
              <li
                style={
                  dataChangedArr.some(
                    (element) => typeof element === "object" && "opm" in element
                  )
                    ? notSavedStyle
                    : pathname2 === "opm"
                    ? activeStyle
                    : {}
                }
              >
                <NavLink
                  to="/opm"
                  onClick={(e) => conditionalNavigationHandler(e, "/opm")}
                  style={
                    formList.includes("OPM") &&
                    pathname.split("/")[1] !== "temp" &&
                    pathname.split("/")[1] !== "mac" &&
                    record?.split("+")?.[0] !== "MAC"
                      ? {}
                      : linkStyle
                  }
                >
                  Ongoing Monitoring
                </NavLink>
              </li>
            }

            <li style={pathname2 === "assignment" ? activeStyle : {}}>
              <NavLink
                to="/assignment"
                onClick={(e) => conditionalNavigationHandler(e, "/assignment")}
                style={
                  formList.includes("Assignment") &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                Assignment
              </NavLink>
            </li>
            <li style={pathname2 === "documents" ? activeStyle : {}}>
              <NavLink
                to="/documents"
                onClick={(e) => conditionalNavigationHandler(e, "/documents")}
                style={
                  formList.includes(tabNameInToOutHandler("Document")) &&
                  documentPermission
                    ? {}
                    : linkStyle
                }
                exact
              >
                Document
              </NavLink>
            </li>

            <li style={pathname2 === "report" ? activeStyle : {}}>
              <NavLink
                to="/report"
                onClick={(e) =>
                  changeToNonModelRelatedPageHandler(e, "/report")
                }
                style={
                  formList.includes(tabNameInToOutHandler("Report")) &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                Report
              </NavLink>
            </li>
          </ul>

          <MenuPage
            isDisabled={formList.includes("Admin") ? false : true}
            conditionalNavigationHandler={conditionalNavigationHandler}
          />
        </Box>
        <Box
          sx={{
            p: 0,
            m: 0,
            height: "3rem",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            display: { xs: "flex", md: "flex", lg: "none" },
          }}
        >
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "block", lg: "none" },
              "& .MuiMenu-list": {
                p: "4px 0",
                "& .MuiMenuItem-root a": {
                  textDecoration: "none",
                  color: "black",
                },
              },
              "& .MuiMenuItem-root": {
                "& .MuiSvgIcon-root": {
                  fontSize: 18,
                  marginRight: 1.5,
                },
                "&:active": {
                  backgroundColor: "white",
                },
                "&:hover": {
                  backgroundColor: "rgb(255, 145, 0) !important",
                },
              },
            }}
          >
            <MenuItem style={pathname === "/model/new" ? activeStyle : {}}>
              <NavLink
                to="/model/new"
                onClick={(e) =>
                  changeToNonModelRelatedPageHandler(e, "/model/new")
                }
                style={formList.includes("New") ? {} : linkStyle}
                exact
              >
                <ListItemText primary="MD Submission" />
              </NavLink>
            </MenuItem>

            <MenuItem
              style={
                dataChangedArr.some(
                  (element) => typeof element === "object" && "model" in element
                )
                  ? notSavedStyle
                  : pathname !== "/model/new" && pathname2 === "model"
                  ? activeStyle
                  : {}
              }
            >
              <NavLink
                to={isTempPage ? "/temp" : "/model"}
                onClick={(e) =>
                  conditionalNavigationHandler(
                    e,
                    isTempPage ? "/temp" : "/model"
                  )
                }
                style={
                  formList.includes("Model") &&
                  pathname.split("/")?.[1] !== "temp" &&
                  pathname.split("/")?.[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                <ListItemText primary="Model Information" />
              </NavLink>
            </MenuItem>

            <MenuItem
              style={
                dataChangedArr.some(
                  (element) =>
                    typeof element === "object" && "change" in element
                )
                  ? notSavedStyle
                  : pathname2 === "change"
                  ? activeStyle
                  : {}
              }
            >
              <NavLink
                to="/change"
                onClick={(e) => changePageNavigationHandler(e, "/change")}
                style={
                  formList.includes("Change") &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                <ListItemText primary="Model Change" />
              </NavLink>
            </MenuItem>

            <MenuItem
              style={
                dataChangedArr.some(
                  (element) =>
                    typeof element === "object" && "validation" in element
                )
                  ? notSavedStyle
                  : pathname2 === "validation"
                  ? activeStyle
                  : {}
              }
            >
              <NavLink
                to="/validation"
                onClick={(e) => conditionalNavigationHandler(e, "/validation")}
                style={
                  formList.includes("Validation") &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                <ListItemText primary="MRM Activities" />
              </NavLink>
            </MenuItem>

            <MenuItem
              style={
                dataChangedArr.some(
                  (element) => typeof element === "object" && "issue" in element
                )
                  ? notSavedStyle
                  : pathname2 === "issue"
                  ? activeStyle
                  : {}
              }
            >
              <NavLink
                to="/issue"
                onClick={(e) => conditionalNavigationHandler(e, "/issue")}
                style={
                  formList.includes("Issue") &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
              >
                <ListItemText primary="Issue Management" />
              </NavLink>
            </MenuItem>

            {
              <MenuItem
                style={
                  dataChangedArr.some(
                    (element) => typeof element === "object" && "opm" in element
                  )
                    ? notSavedStyle
                    : pathname2 === "opm"
                    ? activeStyle
                    : {}
                }
              >
                <NavLink
                  to="/opm"
                  onClick={(e) => conditionalNavigationHandler(e, "/opm")}
                  style={
                    formList.includes("OPM") &&
                    pathname.split("/")[1] !== "temp" &&
                    pathname.split("/")[1] !== "mac" &&
                    record?.split("+")?.[0] !== "MAC"
                      ? {}
                      : linkStyle
                  }
                >
                  <ListItemText primary="Ongoing Monitoring" />
                </NavLink>
              </MenuItem>
            }

            <MenuItem style={pathname2 === "assignment" ? activeStyle : {}}>
              <NavLink
                to="/assignment"
                onClick={(e) => conditionalNavigationHandler(e, "/assignment")}
                style={
                  formList.includes("Assignment") &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                <ListItemText primary="Assignment" />
              </NavLink>
            </MenuItem>
            <MenuItem style={pathname2 === "documents" ? activeStyle : {}}>
              <NavLink
                to="/documents"
                onClick={(e) => conditionalNavigationHandler(e, "/documents")}
                style={
                  formList.includes(tabNameInToOutHandler("Document")) &&
                  documentPermission
                    ? {}
                    : linkStyle
                }
                exact
              >
                <ListItemText primary="Document" />
              </NavLink>
            </MenuItem>

            <MenuItem style={pathname2 === "report" ? activeStyle : {}}>
              <NavLink
                to="/report"
                onClick={(e) =>
                  changeToNonModelRelatedPageHandler(e, "/report")
                }
                style={
                  formList.includes(tabNameInToOutHandler("Report")) &&
                  pathname.split("/")[1] !== "temp" &&
                  pathname.split("/")[1] !== "mac" &&
                  record?.split("+")?.[0] !== "MAC"
                    ? {}
                    : linkStyle
                }
                exact
              >
                <ListItemText primary="Report" />
              </NavLink>
            </MenuItem>
            <MenuItem
              sx={{
                "& .MuiBox-root": {
                  height: "100%",
                  "& .MuiButton-root": {
                    color: "black",
                    p: 0,
                    ml: 0,
                  },
                },
              }}
            >
              <div style={{ fontSize: "16px", fontColor: "#444444" }}>
                <MenuPage
                  isDisabled={formList.includes("Admin") ? false : true}
                  conditionalNavigationHandler={conditionalNavigationHandler}
                />
              </div>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </Container>
  );
};

export default NavLinks;
