import React, { Fragment, useState, useEffect } from "react";
import { gridClasses } from "@mui/x-data-grid";
import {
  Menu,
  MenuItem,
  CircularProgress,
  Box,
  TableContainer,
  Button,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";

import dayjs from "dayjs";

import WrapTextTable, {
  renderCellExpand,
  CustomToolbar,
} from "../../components/CustomComponents/WrapTextTable";

export const OrdersTable = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [pageSize, setPageSize] = useState(50);
  const formattedDate = dayjs().format("YYYYMMDD");

  useEffect(() => {
    setRows(props.chartData.map((row, index) => ({ ...row, id: index + 1 })));
    setIsLoading(false);
  }, [props.chartData]);

  const columns = props.chartHeader.map((header) => ({
    field: header,
    headerName: header,
    headerAlign: "center",
    renderCell: renderCellExpand,
    align: "center",
    flex: 1,
  }));

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertToUppercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toUpperCase(),
          email: row.email.toUpperCase(),
          title: row.title.toUpperCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const convertToLowercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toLowerCase(),
          email: row.email.toLowerCase(),
          title: row.title.toLowerCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  // different color in even line and hover effect
  const datagridSx = {
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "rgb(250,250,250)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgb(221,225,255)",
      },
    },
  };

  return (
    <Box>
      <TableContainer
        sx={{
          width: "100%",
          overflowX: "auto",
          position: "relative",
          display: "block",
          maxWidth: "100%",
          pb: 2,
          "& td, & th": { whiteSpace: "nowrap" },
        }}
      >
        <div>
          <div style={{ width: "100%" }}>
            <WrapTextTable
              autoHeight
              rowHeight={30}
              sx={datagridSx}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              columns={columns}
              rows={rows}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              components={{ Toolbar: () => CustomToolbar(`${props.chartTitle}_${formattedDate}`) }}
              density="compact"
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 20]}
              pagination
            />
          </div>
        </div>
      </TableContainer>
    </Box>
  );
};
