import React, { Fragment, useState, useEffect, useContext } from "react";
import { gridClasses } from "@mui/x-data-grid";
import { Menu, MenuItem, Button, CircularProgress, Box } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import _ from "lodash";
import { UserContext } from "../../../context/user-context";
import WrapTextTable, {
  renderCellExpand,
} from "../../../components/CustomComponents/WrapTextTable";
import DashboardLayout from "../../DashboardPage/DashboardLayout";
import "./ValidationTable.css";

const ValidationTable = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const { dataChangedArr } = useContext(UserContext);

  useEffect(() => {
    setRows(
      props.validation?.map((item) => ({
        id: item.validation_id,
        ...item,
      }))
    );
    setIsLoading(false);
  }, [props.id, props.validation]);

  const columns = [
    {
      field: "validation_id",
      headerName: "MRM Activity ID",
      headerAlign: "center",
      renderCell: (params) => (
        <Link to={`/validation/${props.id}+${params.row.validation_id}`}>
          {params.value}
        </Link>
      ),
      flex: 0.7,
      align: "center",
    },
    {
      field: "review",
      headerName: "MRM Activity Type",
      align: "center",
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      field: "rating",
      headerName: "Approval Status",
      align: "center",
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      field: "approver",
      headerName: "Model Approver",
      align: "center",
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      field: "validation_status",
      headerName: "Validation Status",
      align: "center",
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      field: "validator_charge",
      headerName: "Activity Leader",
      align: "center",
      headerAlign: "center",
      renderCell: renderCellExpand,
      flex: 1,
    },
    {
      field: "certification_date",
      headerName: "Approval Date",
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertToUppercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toUpperCase(),
          email: row.email.toUpperCase(),
          title: row.title.toUpperCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const convertToLowercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toLowerCase(),
          email: row.email.toLowerCase(),
          title: row.title.toLowerCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };
  // different color in even line and hover effect
  const datagridSx = {
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "rgb(250,250,250)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgb(221,225,255)",
      },
    },
    [`& .${gridClasses.row}.not--saved`]: {
      backgroundColor: "#CB3694",
      color: "white",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgb(221,225,255)",
        color: "black",
      },
     
    },
    "& .Red": {
      backgroundColor: "#F4B084",
      color: "black",
    },
    "& .Green": {
      backgroundColor: "#C6E0B4",
      color: "black",
    },
    "& .Yellow": {
      backgroundColor: "#FFF2CC",
      color: "black",
    },
    "& .Blue": {
     
    },
  };

  return (
    <div style={{ margin: "0 8rem 2rem 8rem" }}>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <div>
          <div style={{ marginTop: "20px" }}>
            <DashboardLayout layoutData={props.layoutData} />
          </div>

          <div
            style={{ width: "100%", marginTop: "20px", marginBottom: "30px" }}
          >
            <WrapTextTable
              autoHeight
              rowHeight={30}
              sx={datagridSx}
              getRowClassName={(params) => {
                if (
                  Array.isArray(dataChangedArr) &&
                  dataChangedArr.some((item) =>
                    _.isEqual(item, { validation: params.id })
                  )
                ) {
                  return "not--saved";
                } else {
                  return params.indexRelativeToCurrentPage % 2 === 0
                    ? "even"
                    : "odd";
                }
              }}
              getCellClassName={(params) => {
                if (
                  params.field === "validation_status" &&
                  params.value === "Completed"
                ) {
                  return "Green";
                }

                if (params.field === "validation_status") {
                  return "Blue";
                }
                return "";
              }}
              columns={columns}
              rows={rows}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              components={{
                Footer: () => (
                  <div
                    className="MuiDataGrid-footerContainer css-17jjc08-MuiDataGrid-footerContainer"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box sx={{ p: 1, display: "flex", justifyContent: "end" }}>
                      Total MRM Activities: {rows.length}
                    </Box>
                  </div>
                ),
              }}
              showCellRightBorder={true}
            />

            <Menu
              open={contextMenu !== null}
              onClose={handleClose}
              anchorReference="anchorPosition"
              anchorPosition={
                contextMenu !== null
                  ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                  : undefined
              }
              componentsProps={{
                root: {
                  onContextMenu: (e) => {
                    e.preventDefault();
                    handleClose();
                  },
                },
              }}
            >
              <MenuItem onClick={convertToUppercase}>UPPERCASE</MenuItem>
              <MenuItem onClick={convertToLowercase}>lowercase</MenuItem>
            </Menu>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              variant="outlined"
              className="editButton"
              onClick={() => {
                history.push({
                  pathname: `/validation/${props.id}/new`,
                  maxValidationId: props?.validation
                    ?.sort(
                      (a, b) =>
                        ~b.validation_id.slice(3) - ~a.validation_id.slice(3)
                    )
                    ?.slice(-1)?.[0]?.validation_id,
                });
              }}
            >
              New
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ValidationTable;
