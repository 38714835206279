import { useEffect, useState } from "react";

// material-ui
import dayjs from "dayjs";

// third-party
import ReactApexChart from "react-apexcharts";

// ==============================|| MONTHLY BAR CHART ||============================== //

export const HorizontalBarChart = (props) => {
  const formattedDate = dayjs().format("YYYYMMDD");

  const [series, setSeries] = useState([]);

  useEffect(() => {
    if (props?.axis?.length === 2) {
      setSeries([{ name: props.chartAxisLabel, data: props.chartData }]);
    } else {
      setSeries(props.chartData);
    }
  }, [props.chartData, props.chartAxisLabel]);

  const options = {
    chart: {
      toolbar: {
        show: true,
        offsetX: 1,
        offsetY: 1,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: `${props.chartTitle}_${formattedDate}`,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: `${props.chartTitle}_${formattedDate}`,
          },
          png: {
            filename: `${props.chartTitle}_${formattedDate}`,
          },
        },
        autoSelected: "zoom",
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 2,
        horizontal: true,
        dataLabels: {
          // textAnchor: "end",
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      //   textAnchor: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        fontSize: "11px",
        colors: ["black"],
      },
    },
  
    yaxis: {
      categories: props.chartLabels,
      labels: {
        align: "left",
      },
    },
    xaxis: {
      title: {
        text: props.chartAxisLabel,
        align: "medium",
      },
      categories: props.chartLabels,
    },
    grid: {
      show: true,
      xaxis: { lines: { show: true } },
      yaxis: { lines: { show: false } },
    },
  };

  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={props.chartHeight}
      />
    </div>
  );
};
