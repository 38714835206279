import { createContext } from "react";

export const UserContext = createContext({
  tag: [],
  user: {user: {}},
  token: null,
  userGroup: [],
  fileType: "",
  formMapping: [],
  tabNameOutToInHandler: (outName) => {},
  tabNameInToOutHandler: (inName) => {},
});
