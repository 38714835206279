import { useState, useEffect, Fragment, useContext } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import "./NewOpm.css";

import InputNew from "../../../components/Content/FormElements/InputNew";
import FileLoad from "../../../components/CustomComponents/FileLoad";
import PopUpTable from "./PopUpTable";
import Toast from "../../../components/Utils/Toast";
import { isTwoJsonValueEqual } from "../../../components/CustomComponents/GeneralPageTemplate";

import {
  CircularProgress,
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import { useForm } from "../../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../../components/Utils/hooks/http-hook";
import { UserContext } from "../../../context/user-context";

import { fileTypeCheck, emptyFieldCheck } from "./NewOpmFieldCheck";
import OpmScore from "../OpmScore/OpmScore";

export const MetricCheck = (
  metric,
  checkType,
  isFourColsShow,
  scale,
  isSubmit
) => {
  if (metric && Array.isArray(metric) && checkType === "Quantitative") {
    if (isFourColsShow) {
      for (let i = 0; i < metric.length; i++) {
        for (let j = 1; j < 5; j++) {
          if (
            metric[i]?.[`value${j}`] <
              scale(metric[i]?.statistics)?.lower_bound ||
            metric[i]?.[`value${j}`] > scale(metric[i]?.statistics)?.upper_bound
          ) {
            scale(metric[i]?.statistics)?.lower_bound !== Infinity &&
              Toast(
                "error",
                `${metric[i]?.statistics} must be in the range of the [${
                  scale(metric[i]?.statistics)?.lower_bound
                }, ${scale(metric[i]?.statistics)?.upper_bound}]`
              );
            return true;
          }

          if (
            !isSubmit &&
            [null, undefined, ""].includes(metric[i]?.[`value${j}`])
          ) {
            return true;
          }
        }
      }
    } else {
      for (let i = 0; i < metric.length; i++) {
        if (
          metric[i]?.value4 < scale(metric[i]?.statistics).lower_bound ||
          metric[i]?.value4 > scale(metric[i]?.statistics).upper_bound
        ) {
          scale(metric[i]?.statistics)?.lower_bound !== Infinity &&
            Toast(
              "error",
              `${metric[i].statistics} must be in the range of the [${
                scale(metric[i]?.statistics).lower_bound
              }, ${scale(metric[i]?.statistics).upper_bound}]`
            );
          return true;
        }
        if (!isSubmit && [null, undefined, ""].includes(metric[i]?.value4)) {
          return true;
        }
      }
    }
    return false;
  }

  if (metric && Array.isArray(metric) && checkType === "Qualitative") {
    if (isFourColsShow) {
      for (let i = 0; i < metric.length; i++) {
        for (let j = 1; j < 5; j++) {
          if (
            !isSubmit &&
            [null, undefined, ""].includes(metric[i][`attestation${j}`])
          ) {
            return true;
          }
        }
      }
    } else {
      for (let i = 0; i < metric.length; i++) {
        if (
          !isSubmit &&
          [null, undefined, ""].includes(metric[i]?.attestation4)
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

const NewOpm = ({ tag, setScrollTop, pageElement }) => {
  const { opmId } = useParams();
  let { originDynamicMetricData, monitorData, colorReference, gaugeData } =
    useLocation();
  const {
    user,
    token,
    fileTypeInToOutHandler,
    isPageDataChangedHandler,
    isPageDataNotChangedHandler,
  } = useContext(UserContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [files, setFiles] = useState([]);
  const [newDocument, setNewDocument] = useState([]);
  const [metric, setMetric] = useState([]);
  const [open, setOpen] = useState(false);
  const [formDataObj, setFormDataObj] = useState({});
  const [tableData, setTableData] = useState([]);
  const [dynamicMetricData, setDynamicMetricData] = useState([]);
  const [quantitativeMetric, setQuantitativeMetric] = useState([]);
  const [qualitativeMetric, setQualitativeMetric] = useState([]);
  const [score, setScore] = useState(0);
  const [trafficLight, setTrafficLight] = useState("");
  const [isCalculating, setIsCalculating] = useState(false);
  const [buttonOperation, setButtonOperation] = useState("");
  const [quantitativeColor, setQuantitativeColor] = useState([]);
  const [qualitativeColor, setQualitativeColor] = useState([]);
  const [formState, inputHandler] = useForm(
    {
      issue_id: {
        value: "",
        isValid: true,
      },
      version: {
        value: "",
        isValid: true,
      },
      framework: {
        value: "",
        isValid: true,
      },
    },
    false
  );
  const { display_name } = user || {};
  const buttonAccess = user["menu access"] || {};
  const status_option = Object.values(user["group access"]) || [];

  const qualitativeOption = (stat) => {
    if (originDynamicMetricData && stat) {
      const opt = originDynamicMetricData.find(
        (i) => i.statistics_type === "Qualitative" && i.statistics === stat
      )?.option;
      return opt;
    } else return [];
  };

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  let tag2, fileOption;
  if (tag) {
    tag2 = tag.filter((item) => item.db_tablename === "opm");
    fileOption = tag.filter(
      (item) => item.db_tablename === "documents" && item.fe_id === "file_type"
    );
  } else {
    tag2 = [];
    fileOption = [];
  }

  if (tag) {
    tag = tag.filter(
      (item) => item.db_tablename === "opm" && item.fe_isrender === true
    );
  } else {
    tag = [];
  }

  const quantitative_scale = (stats) => {
    const scale = formState.inputs?.quantitative_metric?.value.map(
      (metric) => ({
        statistics: metric.statistics,
        lower_bound: Math.min(
          ...colorReference
            ?.filter(
              (i) =>
                i.framework === formState.inputs?.framework?.value &&
                i.statistics_type === "Quantitative" &&
                i.statistics === metric?.statistics
            )
            ?.map((j) => j.lower_bound)
        ),
        upper_bound: Math.max(
          ...colorReference
            ?.filter(
              (i) =>
                i.framework === formState.inputs?.framework?.value &&
                i.statistics_type === "Quantitative" &&
                i.statistics === metric?.statistics
            )
            ?.map((j) => j.upper_bound)
        ),
      })
    );

    return scale.find((i) => i.statistics === stats);
  };

  if (gaugeData && Array.isArray(gaugeData)) {
    gaugeData = gaugeData.filter(
      (i) => i.framework === formState.inputs?.framework?.value
    );
  }

  const history = useHistory();

  const access = Object.values(buttonAccess)[0]
    .filter((item) => item[0] === "OPM")
    .map((access) => access[1]);

  useEffect(() => {
    originDynamicMetricData &&
      setQuantitativeMetric(
        originDynamicMetricData
          .filter((data) => data.statistics_type === "Quantitative")
          ?.map((item) => ({
            ...item,
            isFourColsShow: colorReference
              .filter(({ statistics }) => statistics === item.statistics)
              ?.map((i) => i.opm_rule)
              ?.includes("No"),
          }))
      );
    originDynamicMetricData &&
      setQualitativeMetric(
        originDynamicMetricData
          .filter((data) => data.statistics_type === "Qualitative")
          ?.map((item) => ({
            ...item,
            isFourColsShow: colorReference
              .filter(({ statistics }) => statistics === item.statistics)
              ?.map((i) => i.opm_rule)
              ?.includes("No"),
          }))
      );
  }, [originDynamicMetricData, colorReference?.[0]]);

  useEffect(() => {
    const fetchCalculateMetric = async () => {
      setIsCalculating(true);
      try {
        const responseData = await sendRequest(
          `${
            process.env.REACT_APP_BACKEND_URL
          }/opm/metric/calculate?framework=${
            formState.inputs?.framework?.value
          }&code=${opmId.split("+")[0]}&version=${
            formState.inputs.version.value
          }`,
          "POST",
          JSON.stringify([
            ...formState.inputs.quantitative_metric.value.map((data) => {
              return {
                code: opmId.split("+")[0],
                version: formState.inputs.version?.value || "",
                statistics_type: data.statistics_type,
                statistics: data.statistics,
                attestation1: "",
                attestation2: "",
                attestation3: "",
                attestation4: "",
                value1:
                  data.value1 !== undefined && data.value1 !== null
                    ? data.value1
                    : 0,
                value2:
                  data.value2 !== undefined && data.value2 !== null
                    ? data.value2
                    : 0,
                value3:
                  data.value3 !== undefined && data.value3 !== null
                    ? data.value3
                    : 0,
                value4:
                  data.value4 !== undefined && data.value4 !== null
                    ? data.value4
                    : 0,
                user_id: display_name,
                update_time: currentDate,
              };
            }),
            ...formState.inputs.qualitative_metric.value.map((data) => {
              return {
                code: opmId.split("+")[0],
                version: formState.inputs.version?.value || "",
                statistics_type: data.statistics_type,
                statistics: data.statistics,
                attestation1:
                  data.attestation1 !== undefined ? data.attestation1 : "",
                attestation2:
                  data.attestation2 !== undefined ? data.attestation2 : "",
                attestation3:
                  data.attestation3 !== undefined ? data.attestation3 : "",
                attestation4:
                  data.attestation4 !== undefined ? data.attestation4 : "",
                value1: null,
                value2: null,
                value3: null,
                value4: null,
                user_id: display_name,
                update_time: currentDate,
              };
            }),
          ]),
          {
            "Content-Type": "application/json",
          }
        );
        setIsCalculating(false);
        responseData &&
          responseData["metric result"] &&
          setScore(responseData["metric result"]["weighted sum"]);
        responseData &&
          responseData["metric result"] &&
          setTrafficLight(responseData["metric result"]["traffic light"]);
        responseData &&
          responseData["dynamic metric"] &&
          setQuantitativeColor(
            responseData["dynamic metric"].filter(
              (i) => i.statistics_type === "Quantitative"
            )
          );
        responseData &&
          responseData["dynamic metric"] &&
          setQualitativeColor(
            responseData["dynamic metric"].filter(
              (i) => i.statistics_type === "Qualitative"
            )
          );
      } catch {}
    };

    if (
      !MetricCheck(
        formState.inputs?.quantitative_metric?.value,
        "Quantitative",
        quantitativeMetric.map((i) => i.isFourColsShow).includes(true),
        quantitative_scale
      ) &&
      !MetricCheck(
        formState.inputs?.qualitative_metric?.value,
        "Qualitative",
        qualitativeMetric.map((i) => i.isFourColsShow).includes(true)
      ) &&
      formState.inputs.version?.value !== ""
    ) {
      fetchCalculateMetric();
    }
  }, [
    formState.inputs?.quantitative_metric?.value,
    formState.inputs?.qualitative_metric?.value,
    formState.inputs?.version?.value,
  ]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (operation) => {
    if (fileTypeCheck(newDocument) === false) {
      return;
    }

    if (
      emptyFieldCheck(
        formState.inputs?.version?.value,
        "Target OPM Submission Date"
      ) === false
    ) {
      return;
    }

    if (
      emptyFieldCheck(
        formState.inputs?.opm_status?.value,
        "OPM Workflow Status"
      ) === false
    ) {
      return;
    }

    if (
      emptyFieldCheck(formState.inputs?.opm_fit?.value, "Final Outcome") ===
      false
    ) {
      return;
    }

    if (
      emptyFieldCheck(formState.inputs?.framework?.value, "OPM Framework") ===
      false
    ) {
      return;
    }

    if (
      MetricCheck(
        formState.inputs?.quantitative_metric?.value,
        "Quantitative",
        quantitativeMetric.map((i) => i.isFourColsShow).includes(true),
        quantitative_scale,
        true
      ) ||
      MetricCheck(
        formState.inputs?.qualitative_metric?.value,
        "Qualitative",
        qualitativeMetric.map((i) => i.isFourColsShow).includes(true),
        null,
        true
      )
    ) {
      return;
    }

    setOpen(true);
    const formData = new FormData();
    formData.append("code", opmId.split("+")[0]);
    formData.append("user_id", display_name);

    tag.forEach((item) => {
      if (item.fe_id !== "update_time") {
        formData.append(
          item.fe_id,
          formState.inputs[item.fe_id].value
            ? formState.inputs[item.fe_id].value
            : ""
        );
      }
    });

    let object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    setFormDataObj(formData);
    let arr = [];
    let i = 1;
    for (const [key, value] of Object.entries(object)) {
      arr.push({ id: i, attribute: key, current: value });
      i++;
    }
    setTableData(arr);
    setButtonOperation(operation);
  };

  const opmSubmitHandler = async (event) => {
    event.preventDefault();
    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);
    const traceList = [
      [
        1,
        opmId.split("+")[0],
        formState.inputs.version.value,
        formState.inputs.opm_status.value,
        display_name,
        currentDate,
      ],
    ];

    try {
      const formData = new FormData();
      formData.append("code", opmId.split("+")[0]);
      formData.append("form", "OPM");
      formData.append("user_id", display_name);
      formData.append("update_time", currentDate);
      formData.append("access_log", JSON.stringify(traceList));
      formData.append(
        "dynamic metric",
        JSON.stringify([
          ...formState.inputs.quantitative_metric.value.map((data) => {
            return {
              code: opmId.split("+")[0],
              version: formState.inputs.version.value || "",
              statistics_type: data.statistics_type,
              statistics: data.statistics,
              attestation1: "",
              attestation2: "",
              attestation3: "",
              attestation4: "",
              value1:
                data.value1 !== undefined && data.value1 !== null
                  ? data.value1
                  : 0,
              value2:
                data.value2 !== undefined && data.value2 !== null
                  ? data.value2
                  : 0,
              value3:
                data.value3 !== undefined && data.value3 !== null
                  ? data.value3
                  : 0,
              value4:
                data.value4 !== undefined && data.value4 !== null
                  ? data.value4
                  : 0,
              user_id: display_name,
              update_time: currentDate,
            };
          }),
          ...formState.inputs.qualitative_metric.value.map((data) => {
            return {
              code: opmId.split("+")[0],
              version: formState.inputs.version.value || "",
              statistics_type: data.statistics_type,
              statistics: data.statistics,
              attestation1:
                data.attestation1 !== undefined ? data.attestation1 : "",
              attestation2:
                data.attestation2 !== undefined ? data.attestation2 : "",
              attestation3:
                data.attestation3 !== undefined ? data.attestation3 : "",
              attestation4:
                data.attestation4 !== undefined ? data.attestation4 : "",
              value1: null,
              value2: null,
              value3: null,
              value4: null,
              user_id: display_name,
              update_time: currentDate,
            };
          }),
        ])
      );

      newDocument.length > 0 &&
        formData.append(
          "file_description",
          JSON.stringify(Array.from(newDocument, (x) => x.file_description))
        );

      newDocument.length > 0 &&
        formData.append(
          "file_type",
          JSON.stringify(
            Array.from(newDocument, (x) => fileTypeInToOutHandler(x.file_type))
          )
        );

      tag.forEach((item) => {
        if (
          item.fe_id !== "update_time" &&
          formState.inputs[item.fe_id].value &&
          formState.inputs[item.fe_id].value !== ""
        ) {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id].value
              ? formState.inputs[item.fe_id].value
              : ""
          );
        }
      });

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append(`file${i + 1}`, files[i]);
        }
      }

      let inventoryValue = true;

      if (buttonOperation === "save") {
        inventoryValue = false;
      }

      const res = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/opm/new?inventory=${inventoryValue}`,
        "POST",
        formData,
        headers
      );

      if (res.error) {
        Toast("error", res);
        return;
      }
      //
      Toast("success", "New OPM has been created successfully.");
      isPageDataNotChangedHandler("New", 0);
      //Redirect the user to a different page
      history.push("/opm");
      // } else {
      //   Toast("info", res)
      // }
    } catch (err) {}
  };

  const PopUpModalData = tableData
    .filter(
      (item) =>
        item.attribute !== "update_time" &&
        item.attribute !== "user_id" &&
        item.attribute !== "access_log"
    )
    .map((data) => {
      const label = tag2.find(
        ({ fe_id }) => fe_id === data.attribute
      )?.fe_label;
      return { ...data, attribute: label };
    });

  const fileChangeHandler = (id, value) => {
    setNewDocument(
      newDocument.map((doc, i) => {
        if ("file_" + doc.filename + "_type_" + (i + 1) === id) {
          doc.file_type = value;
        }
        if ("file_description_" + doc.filename === id) {
          doc.file_description = value;
        }
        return doc;
      })
    );
  };

  const uploadFileHandler = (file) => {
    if (Array.isArray(files) && files.map((i) => i.name).includes(file.name)) {
      Toast("error", "File already added.");
      return;
    }

    setFiles([...files, file]);
    setNewDocument([
      ...newDocument,
      {
        file_description: "",
        filename: file.name,
        update_time: new Date(),
        file_owner: display_name,
        files_status: "active",
        file_type: "",
      },
    ]);
  };

  const removeFileHandler = (file) => {
    setFiles(files.filter((doc) => doc.name !== file));
    setNewDocument(newDocument.filter((doc) => doc.filename !== file));
  };

  const optionException = (tag) => {
    if (tag.fe_id === "opm_status") {
      return status_option[0]
        .filter((item) => item[0] === "OPM")
        .map((item) => item[1]);
    } else {
      return undefined;
    }
  };

  const isDataChanged = () => {
    let newTag = tag.filter((i) => i.fe_id !== "validation_id");
    for (let i = 0; i < newTag.length; i++) {
      if (
        !isTwoJsonValueEqual(
          monitorData?.[newTag[i].fe_id] || "",
          formState.inputs?.[newTag[i].fe_id]?.value
        )
      ) {
        // console.log("data changed!");
        // console.log("label:", newTag[i].fe_label);
        // console.log("initial:", "");
        // console.log("input:", formState.inputs?.[newTag[i].fe_id]?.value);
        return true;
      }
    }

    if (
      !isTwoJsonValueEqual(
        quantitativeMetric,
        formState.inputs?.quantitative_metric?.value,
        "quantitative"
      ) ||
      !isTwoJsonValueEqual(
        qualitativeMetric,
        formState.inputs?.qualitative_metric?.value,
        "qualitative"
      )
    ) {
      // console.log("metric data changed");
      // console.log(
      //   qualitativeMetric,
      //   formState.inputs?.quantitative_metric?.value
      // );
      // console.log(
      //   quantitativeMetric,
      //   formState.inputs?.qualitative_metric?.value
      // );
      return true;
    }

    if (newDocument?.length > 0) {
      // console.log("files exist");

      return true;
    }

    return false;
  };

  useEffect(() => {
    if (isDataChanged()) {
      isPageDataChangedHandler("New", 0);
    } else {
      isPageDataNotChangedHandler("New", 0);
    }
  }, [formState.inputs]);

  let element;
  element = (
    <div style={{ marginTop: "1.5rem" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "650px",
            maxWidth: "900px",
            height: "100%",
            padding: "1rem 3rem 1rem 3rem",
          },
        }}
      >
        <DialogTitle>
          <PopUpTable tableData={PopUpModalData} isNew={true} />
        </DialogTitle>
        <DialogActions>
          <Button onClick={opmSubmitHandler}>Confirm</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
          {isLoading && <CircularProgress />}
        </DialogActions>
      </Dialog>
      <form style={{ margin: "0 8rem 2rem 8rem" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={0.5} columnSpacing={5}>
            {tag
              .sort((a, b) => a.fe_order - b.fe_order)
              .map((tag, i) => (
                <Grid
                  item
                  xs={12}
                  md={tag.fe_grid === 12 ? 12 : 6}
                  lg={tag.fe_grid}
                  key={i}
                >
                  <InputNew
                    id={tag.fe_id}
                    type={tag.fe_type}
                    label={tag.fe_label}
                    element={tag.fe_element}
                    onInput={inputHandler}
                    initialValid={true}
                    initialValue={
                      monitorData && monitorData[tag.fe_id]
                        ? monitorData[tag.fe_id]
                        : ""
                    }
                    validators={[]}
                    origin_options={tag}
                    onRecord={() => {}}
                    clean_options={optionException(tag)}
                    disabled={
                      tag.fe_id === "framework" || tag.fe_id === "monitor_level"
                    }
                    setScrollTop={setScrollTop}
                    pageElement={pageElement}
                  />
                </Grid>
              ))}
            <Grid item xs={12}>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div style={{ width: "50%" }}>
                  <div style={{ padding: "0.8rem", marginBottom: "2rem" }}>
                    <InputNew
                      id="quantitative_metric"
                      label="Model Performance Metric (Quantitative Result)"
                      element="opmTable"
                      onInput={inputHandler}
                      initialValue={quantitativeMetric}
                      isAddButtonNotShow
                      colorReference={quantitativeColor}
                      isFourColsShow={quantitativeMetric
                        .map((i) => i.isFourColsShow)
                        .includes(true)}
                    />
                  </div>
                  <div style={{ padding: "0.8rem", marginBottom: "2rem" }}>
                    <InputNew
                      id="qualitative_metric"
                      label="Assessment (Qualitative Result)"
                      element="opmTable"
                      onInput={inputHandler}
                      initialValue={qualitativeMetric}
                      isAddButtonNotShow
                      colorReference={qualitativeColor}
                      qualitativeOption={qualitativeOption}
                      isFourColsShow={qualitativeMetric
                        .map((i) => i.isFourColsShow)
                        .includes(true)}
                    />
                  </div>
                </div>
                <div style={{ width: "50%" }}>
                  {isCalculating && (
                    <h5 style={{ marginBottom: "200px", textAlign: "center" }}>
                      <CircularProgress />{" "}
                    </h5>
                  )}
                  {!isCalculating && (
                    <h5
                      style={{ paddingBottom: "1.5rem", textAlign: "center" }}
                    >
                      Score: {score?.toFixed(2)}
                    </h5>
                  )}
                  {!isCalculating && (
                    <div className="metric-score">
                      <OpmScore
                        value={score?.toFixed(2)}
                        gaugeData={gaugeData}
                      />
                    </div>
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              {/*!isCalculating && (
                    <h5
                      className="metric-score"
                      style={{
                        backgroundColor: `${ColorTransfer(trafficLight)}`,
                      }}
                    >
                    
                      {score?.toFixed(2)}
                    </h5>
                    )*/}
            </Grid>
            <Grid item xs={12}>
              <div style={{ padding: "0.8rem" }}>
                <FileLoad
                  uploadHandler={uploadFileHandler}
                  removeHandler={removeFileHandler}
                  newDocument={newDocument}
                  form="model"
                  onInput={inputHandler}
                  fileChangeHandler={fileChangeHandler}
                  fileOption={fileOption}
                />
              </div>
            </Grid>
            {(!tag || isLoading) && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            )}

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                <Button
                  onClick={() => handleClickOpen("publish")}
                  variant="outlined"
                  className="editButton"
                  disabled={access.includes("Update") ? false : true}
                >
                  Submit
                </Button>

                {
                  <Button
                    onClick={() => handleClickOpen("save")}
                    variant="outlined"
                    className="editButton"
                    disabled={access.includes("Update") ? false : true}
                  >
                    Save
                  </Button>
                }
              </div>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );

  return <Fragment>{element}</Fragment>;
};

export default NewOpm;
