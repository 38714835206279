import { useState, useEffect, useContext, useCallback } from "react";
import Box from "@mui/material/Box";
import { GridCellModes } from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";

import WrapTextTable from "../../components/CustomComponents/WrapTextTable";

export default function FullFeaturedCrudGrid(props) {
  const [rows, setRows] = useState([]);
  const [cellModesModel, setCellModesModel] = useState({});

  useEffect(() => {
    props.BuAccessData &&
      setRows(
        props.BuAccessData.map((data) => ({
          ...data,
          id: data.business_unit,
          isNew: false,
        }))
      );
  }, [props.BuAccessData]);

  const handleCellClick = useCallback((params) => {
    if (params.field === "actions" || params.field === "business_unit") return;
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    props.buAccessDataChanged(rows.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    props.buAccessDataChanged(
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const columns = [
    {
      field: "business_unit",
      headerName: "Business Unit",
      flex: 1,
      editable: false,
      type: "string",

      headerAlign: "center",
    },

    {
      field: "permission",
      headerName: "Permission",
      type: "singleSelect",
      headerAlign: "center",
      valueOptions: ["All", "Internal All", "Internal Limited"],
      flex: 1,
      editable: true,
    },
  ];

  return (
    <Box>
      <WrapTextTable
        headerHeight={40}
        rowHeight={40}
        autoHeight
        rows={rows}
        columns={columns}
        isCellEditable={(params) => true}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        hideFooter={true}
        showCellRightBorder={true}
        sx={{
          boxShadow: "none",
        }}
      />
    </Box>
  );
}
