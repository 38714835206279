import { useState, useContext, useEffect } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import OnlinePredictionIcon from "@mui/icons-material/OnlinePrediction";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import PeopleIcon from "@mui/icons-material/People";

import { UserContext } from "../../context/user-context";

export default function NestedList({ param, form }) {
  const [open, setOpen] = useState(true);
  const [userViewList, setUserViewList] = useState([]);
  const { user, token, userGroup, fileTypeInToOutHandler, socket } =
    useContext(UserContext);

  const handleClick = () => {
    setOpen(!open);
  };

  useEffect(() => {
    socket.on("message", (data) => {
      setUserViewList(JSON.parse(data));
    });
  }, []);

  if (!param?.split("+")[2]) {
    param = param + "+" + "0";
  }

  return (
    <List
      sx={{
        width: "150px",
        maxWidth: 200,
        padding: 0,
        position: "fixed",
        mt: 1,
      }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <ListItemButton onClick={handleClick}>
        <ListItemIcon>
          <PeopleIcon style={{ color: "#2E8B57" }} />
        </ListItemIcon>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          style={{ position: "fixed", backgroundColor: "transparent" }}
          sx={{
            "& .MuiListItemButton-root:hover": {
              backgroundColor: "transparent !important",
            },
          }}
        >
          {userViewList[form]
            ?.filter(
              (i) =>
                i[1] === param.split("+")[0] &&
                i[2] === param.split("+")[1] &&
                i[3] === param.split("+")[2]
            )
            ?.map((j, i) => (
              <ListItemButton
                key={i}
                sx={{
                  pl: "16px",
                }}
              >
                <ListItemIcon style={{ color: "#2E8B57" }}>
                  <OnlinePredictionIcon />
                </ListItemIcon>
                <ListItemText primary={j[0]} />
              </ListItemButton>
            ))}
        </List>
      </Collapse>
    </List>
  );
}
