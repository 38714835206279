import { useParams } from "react-router-dom";

import TemplatePage from "../../../components/CustomComponents/GeneralPageTemplate";

import "./ValidationDetails.css";

const ValidationDetails = ({ tag, record, setScrollTop, pageElement }) => {
  const { validationId } = useParams();
  let validationDetailsId = `${validationId.split("+")[0]}+${validationId.split("+")[1]}+${validationId.split("+")[2]}`;
  return (
    <TemplatePage
      tag={tag}
      form="validation"
      table="Validation"
      issueId={validationDetailsId}
      record={record}
      setScrollTop={setScrollTop}
      pageElement={pageElement}
    />
  );
};
 
export default ValidationDetails;
