import React, { Fragment, useState, useEffect } from "react";
import { gridClasses } from "@mui/x-data-grid";
import { Menu, MenuItem, Button, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

import WrapTextTable, {
  renderCellExpand,
  CustomToolbar,
} from "../../components/CustomComponents/WrapTextTable";

const BatchTable = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [head, setHead] = useState([]);
  const [pageSize, setPageSize] = useState(25);

  useEffect(() => {
    setRows(props.tableData.map((data, i) => ({ id: i, ...data })));
    setIsLoading(false);
    setHead(props.head);
  }, [props.tableData, props.head]);

  const columns = head.map((item) => ({
    field: item,
    headerName: item,
    width: 150,
    headerAlign: "center",
    renderCell: renderCellExpand,
    align: "center",
  }));
  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertToUppercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toUpperCase(),
          email: row.email.toUpperCase(),
          title: row.title.toUpperCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const convertToLowercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toLowerCase(),
          email: row.email.toLowerCase(),
          title: row.title.toLowerCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  // different color in even line and hover effect
  const datagridSx = {
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "rgb(250,250,250)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgb(221,225,255)",
      },
    },
  };

  return (
    <Fragment>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <div>
          <div style={{ width: "100%", paddingTop: "1rem" }}>
            <WrapTextTable
              autoHeight
              rowHeight={30}
              sx={datagridSx}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              density="compact"
              columns={columns}
              rows={rows}
              components={{
                Toolbar: () => CustomToolbar(props.fileName),
              }}
              pagination
              pageSize={pageSize}
              rowsPerPageOptions={[25, 50, 100, 200]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default BatchTable;
