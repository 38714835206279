import React from "react";
import classes from "./Footer.module.css";
import { useEffect, useRef, useState, useContext } from "react";

import { UserContext } from "../../context/user-context";

const Footer = () => {
  const { treeHeightHandler } = useContext(UserContext);
  const FooterRef = useRef(null);

  const [footerHeightInVh, setFooterHeightInVh] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const footer = FooterRef.current;
      const newFooterHeightInVh = ((footer.offsetHeight / window.innerHeight) * 100).toFixed(2);
      setFooterHeightInVh(newFooterHeightInVh);
    };
  
    window.addEventListener('resize', handleResize);
    window.addEventListener('wheel', handleResize);

    // Initial calculation
    if(FooterRef.current) {
      handleResize();
    }

    // Cleanup listeners on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('wheel', handleResize);
    };
  }, []);

  useEffect(() => {
    // This will run whenever footerHeightInVh changes
    treeHeightHandler(((65/window.innerHeight)*100).toFixed(2), footerHeightInVh)
  }, [footerHeightInVh]);
  return (
    <footer className={classes.footer} ref={FooterRef} id="footer">
      <span>Copyright © {new Date().getFullYear()} BBD Insights Inc. All Rights Reserved.</span>
    </footer>
  );
};

export default Footer;
