import { useState, useEffect, useContext } from "react";

import { UserContext } from "../../context/user-context";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
const SuperUser = () => {
  const { token } = useContext(UserContext);
  const { sendRequest } = useHttpClient();
  const [response, setResponse] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      const bearer = token;
      headers.append("Authorization", bearer);
      try {
        const res = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/admin`,
          "GET",
          null,
          headers
        );
        setResponse(JSON.stringify(res));
      } catch (err) {}
    };
    fetchData();
  }, []);

  return (
    <div>
      <p>{response}</p>
    </div>
  );
};

export default SuperUser;
