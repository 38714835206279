import { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";

import { toast } from "react-toastify";
import InputNew from "../../components/Content/FormElements/InputNew";
import FileLoad from "../../components/CustomComponents/FileLoad";
import { UserContext } from "../../context/user-context";
import Toast from "../../components/Utils/Toast";

import { CircularProgress, Button } from "@mui/material";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { useForm } from "../../components/Utils/hooks/form-hook";

import "./NewDocument.css";

const Document = (props) => {
  const { record } = useParams();
  const { tag } = props;
  const {
    user,
    token,
    tabNameOutToInHandler,
    tabNameInToOutHandler,
    fileTypeInToOutHandler,
    macFileTypeInToOutHandler,
    macFileType,
  } = useContext(UserContext);
  const { display_name } = user || {};
  const history = useHistory();
  const { isLoading, sendRequest } = useHttpClient();
  const [files, setFiles] = useState([]);
  const [newDocument, setNewDocument] = useState([]);
  const [issueId, setIssueId] = useState(0);
  const [validationId, setValidationId] = useState(0);
  const [formState, inputHandler] = useForm(
    {
      issue_id: {
        value: 0,
        isValid: false,
      },
      finding_name: {
        value: "",
        isValid: false,
      },
      severity: {
        value: "",
        isValid: false,
      },
      category: {
        value: "",
        isValid: false,
      },
      doc_type: {
        value: "MD Report",
        isValid: false,
      },
    },
    false
  );

  const doc_access = user["document access"] || {};
  let doc_option = [];
  if (
    record?.split("+")[0] === "MAC" &&
    doc_access &&
    Array.isArray(Object.values(doc_access))
  ) {
    if (Object.values(doc_access)?.[0].includes("MAC")) {
      doc_option = ["MAC"];
    } else {
      doc_option = [];
    }
  } else {
    doc_option = Array.isArray(Object.values(doc_access))
      ? Object.values(doc_access)[0]
          ?.filter((i) => i !== "MAC")
          ?.map((access) => tabNameOutToInHandler(access))
      : [];
  }
  let fileOption;
  if (tag) {
    fileOption = tag.filter(
      (item) => item.db_tablename === "documents" && item.fe_id === "file_type"
    );
  } else {
    fileOption = [];
  }

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
  // let docTypeOption;
  // if (tag) {
  //   docTypeOption = tag.filter(
  //     (i) =>
  //       i.db_tablename === "documents" &&
  //       i.fe_label === "Document Type"
  //   )[0]?.group?.split(",")?.map(opt => tabNameOutToInHandler(opt))
  // }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/${
            formState.inputs.doc_type.value === "Issue Management"
              ? "issue"
              : formState.inputs.doc_type.value === "MRM Activities"
              ? "validation"
              : "opm"
          }/${
            formState.inputs.doc_type.value === "Issue Management" ||
            formState.inputs.doc_type.value === "MRM Activities"
              ? props.record
              : props.record.split("+")[0]
          }`
        );
        if (response.state || response.error) {
          setIssueId(0);
        } else {
          if (formState.inputs.doc_type.value === "Issue Management") {
            setIssueId([
              ...new Set(response["issue data"].map((i) => i.issue_id)),
            ]);
          } else if (formState.inputs.doc_type.value === "MRM Activities") {
            setValidationId([
              ...new Set(
                response["validation data"].map((i) => i.validation_id)
              ),
            ]);
          } else {
            setIssueId([
              ...new Set(response["opm data"].map((i) => i.version)),
            ]);
          }
        }
      } catch (err) {}
    };
    if (
      formState.inputs?.doc_type?.value === "Issue Management" ||
      formState.inputs?.doc_type?.value === "Ongoing Monitoring" ||
      formState.inputs?.doc_type?.value === "MRM Activities"
    ) {
      fetchData();
    }
  }, [props.record, sendRequest, formState.inputs?.doc_type?.value]);

  const fileChangeHandler = (id, value) => {
    setNewDocument(
      newDocument.map((doc, i) => {
        if ("file_" + doc.filename + "_type_" + (i + 1) === id) {
          doc.file_type = value;
        }
        if ("file_description_" + doc.filename === id) {
          doc.file_description = value;
        }
        return doc;
      })
    );
  };

  const uploadFileHandler = (file) => {
    if (
      !formState.inputs.doc_type.value ||
      (formState.inputs.doc_type.value &&
        formState.inputs.doc_type.value === "")
    ) {
      Toast("error", "Document Type not selected.");
      return;
    }

    if (
      (formState.inputs.doc_type.value &&
        tabNameInToOutHandler(formState.inputs.doc_type.value) === "Issue" &&
        !formState.inputs.issue_id.value) ||
      (formState.inputs.doc_type.value &&
        tabNameInToOutHandler(formState.inputs.doc_type.value) === "Issue" &&
        formState.inputs.issue_id.value &&
        formState.inputs.issue_id.value === "")
    ) {
      Toast("error", "Issue ID not selected.");
      return;
    }

    if (
      (formState.inputs.doc_type.value &&
        formState.inputs.doc_type.value === "Ongoing Monitoring" &&
        !formState.inputs.issue_id.value) ||
      (formState.inputs.doc_type.value &&
        formState.inputs.doc_type.value === "Ongoing Monitoring" &&
        formState.inputs.issue_id.value &&
        formState.inputs.issue_id.value === "")
    ) {
      Toast("error", "Target OPM Submission Date not selected.");
      return;
    }

    if (Array.isArray(files) && files.map((i) => i.name).includes(file.name)) {
      Toast("error", "File already added.");
      return;
    }

    setFiles([...files, file]);
    setNewDocument([
      ...newDocument,
      {
        file_description: "",
        filename: file.name,
        update_time: new Date(),
        user_id: display_name,
        files_status: "active",
        file_type: "",
      },
    ]);
  };

  const removeFileHandler = (file) => {
    setFiles(files.filter((doc) => doc.name !== file));
    setNewDocument(newDocument.filter((doc) => doc.filename !== file));
  };

  const documentUpdateHandler = async (event) => {
    event.preventDefault();
    if (
      (formState.inputs.doc_type.value &&
        tabNameInToOutHandler(formState.inputs.doc_type.value) === "Issue" &&
        !formState.inputs.issue_id.value) ||
      (formState.inputs.doc_type.value &&
        tabNameInToOutHandler(formState.inputs.doc_type.value) === "Issue" &&
        formState.inputs.issue_id.value &&
        formState.inputs.issue_id.value === "")
    ) {
      Toast("error", "Issue ID not selected.");
      return;
    }

    if (
      (formState.inputs.doc_type.value &&
        tabNameInToOutHandler(formState.inputs.doc_type.value) ===
          "Validation" &&
        !formState.inputs.validation_id.value) ||
      (formState.inputs.doc_type.value &&
        tabNameInToOutHandler(formState.inputs.doc_type.value) ===
          "Validation" &&
        formState.inputs.validation_id.value &&
        formState.inputs.validation_id.value === "")
    ) {
      Toast("error", "Validation ID not selected.");
      return;
    }

    if (
      (formState.inputs.doc_type.value &&
        formState.inputs.doc_type.value === "Ongoing Monitoring" &&
        !formState.inputs.issue_id.value) ||
      (formState.inputs.doc_type.value &&
        formState.inputs.doc_type.value === "Ongoing Monitoring" &&
        formState.inputs.issue_id.value &&
        formState.inputs.issue_id.value === "")
    ) {
      Toast("error", "Target OPM Submission Date not selected.");
      return;
    }

    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);

    if (!props.record.split("+")[1]) {
      Toast("error", "No Full Version ID Selected.");
      return;
    }

    if (
      !formState.inputs.doc_type.value ||
      (formState.inputs.doc_type.value &&
        formState.inputs.doc_type.value === "")
    ) {
      Toast("error", "Document Type not selected.");
      return;
    }

    if (files.length <= 0) {
      Toast("info", "No file uploaded.");
      return;
    }

    if (Array.from(newDocument, (x) => x.file_type).includes("")) {
      Toast("error", "Please specify the File Type.");
      return;
    }

    try {
      const formData = new FormData();
      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append(`file${i + 1}`, files[i]);
        }
      }
      formData.append("code", props.record.split("+")[0]);
      if (formState.inputs.doc_type.value === "Ongoing Monitoring") {
        formData.append("version", formState.inputs.issue_id.value);
        formData.append("issue_id", "0");
        formData.append("validation_id", "0");
      } else if (formState.inputs.doc_type.value === "Issue Management") {
        formData.append("version", props.record.split("+")[1]);
        formData.append("issue_id", formState.inputs.issue_id.value || "0");
        formData.append("validation_id", "0");
      } else if (formState.inputs.doc_type.value === "MRM Activities") {
        formData.append("version", props.record.split("+")[1]);
        formData.append("issue_id", "0");
        formData.append(
          "validation_id",
          formState.inputs.validation_id.value || "0"
        );
      } else {
        formData.append("version", props.record.split("+")[1]);
        formData.append("issue_id", "0");
        formData.append("validation_id", "0");
      }

      formData.append("update_time", currentDate);
      formData.append(
        "form",
        tabNameInToOutHandler(formState.inputs.doc_type.value)
      );
      formData.append("user_id", display_name);
      formData.append(
        "file_description",
        JSON.stringify(Array.from(newDocument, (x) => x.file_description))
      );
      doc_option?.[0] !== "MAC" &&
        formData.append(
          "file_type",
          JSON.stringify(
            Array.from(newDocument, (x) => fileTypeInToOutHandler(x.file_type))
          )
        );
      doc_option?.[0] === "MAC" &&
        formData.append(
          "file_type",
          JSON.stringify(
            Array.from(newDocument, (x) =>
              macFileTypeInToOutHandler(x.file_type)
            )
          )
        );

      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/documents/submit",
        "POST",
        formData,
        headers
      );
      setNewDocument([]);

      if (res.error) {
        Toast("error", res.error);
        return;
      }

      //Redirect the user to a different page
      if (res.document) {
        Toast("success", "New document submitted.");
        history.push("/documents");
      } else {
        Toast("info", "Something wrong happened.");
      }
    } catch (err) {
      alert(err);
      return;
    }
  };
  const initialType = () => {
    if (record?.split("+")?.[0] === "MAC") {
      return "MAC";
    }
  };

  return (
    <div style={{ minHeight: "85vh" }}>
      <form className="parent-document" onSubmit={documentUpdateHandler}>
        <div
          className="div1-document"
          style={{ display: "flex", alignItems: "flex-end" }}
        >
          <InputNew
            id="doc_type"
            label="Document Type"
            element="select"
            onInput={inputHandler}
            initialValid={true}
            validators={[]}
            initialValue=""
            clean_options={doc_option}
          />
          <div>{isLoading && <CircularProgress />}</div>
        </div>
        {formState.inputs.doc_type &&
          !isLoading &&
          tabNameInToOutHandler(formState.inputs.doc_type.value) ===
            "Issue" && (
            <div className="div2-document">
              <InputNew
                id="issue_id"
                label="Issue ID"
                element="select"
                onInput={inputHandler}
                clean_options={issueId}
                initialValid={true}
                validators={[]}
              />
            </div>
          )}

        {formState.inputs.doc_type &&
          !isLoading &&
          tabNameInToOutHandler(formState.inputs.doc_type.value) ===
            "Validation" && (
            <div className="div2-document">
              <InputNew
                id="validation_id"
                label="Validation Activity ID"
                element="select"
                onInput={inputHandler}
                clean_options={validationId}
                initialValid={true}
                validators={[]}
              />
            </div>
          )}

        {formState.inputs.doc_type &&
          !isLoading &&
          tabNameInToOutHandler(formState.inputs.doc_type.value) === "OPM" && (
            <div className="div2-document">
              <InputNew
                id="issue_id"
                label="Target OPM Submission Date"
                element="select"
                onInput={inputHandler}
                clean_options={issueId}
                initialValid={true}
                validators={[]}
              />
            </div>
          )}
        <div className="div4-document">
          <div style={{ padding: "0.8rem" }}>
            <FileLoad
              uploadHandler={uploadFileHandler}
              removeHandler={removeFileHandler}
              newDocument={newDocument}
              form="document"
              onInput={inputHandler}
              fileChangeHandler={fileChangeHandler}
              fileOption={doc_option[0] !== "MAC" ? fileOption : undefined}
              macFileType={doc_option[0] === "MAC" ? macFileType : undefined}
            />
          </div>
        </div>

        <div className="div6-document">
          <Button type="submit" variant="outlined" className="editButton">
            Update document
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Document;
