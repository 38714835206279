import React, { Fragment, useState, useEffect } from "react";
import { gridClasses } from "@mui/x-data-grid";
import { Menu, MenuItem, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import WrapTextTable from "../../components/CustomComponents/WrapTextTable";

import "./TaskTable.css";

const TaskTable = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [pageSize, setPageSize] = useState(3);

  useEffect(() => {
    setRows(props.tableData);
    setIsLoading(false);
  }, [props.tableData]);

  let status;
  if (props.form === "Model") {
    status = "model_status";
  } else if (props.form === "OPM") {
    status = "opm_status";
  } else if (props.form === "Validation") {
    status = "validation_status";
  } else if (props.form === "Issue") {
    status = "issue_status";
  }

  let columns = [];

  if (props.form === "Issue") {
    columns = [
      {
        field: "id",
        headerName: "ID",
        width: 50,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "issue_name",
        headerName: "Name",
        flex: 5,
        headerAlign: "center",
        renderCell: (params) => (
          <Link
            to={`/${props.form ? props.form.toLowerCase() : ""}/${
              params.row.code
            }+${params.row.version}+${params.row.issue_id}+${
              params.row.sequence
            }`}
            className="task-link"
          >
            {params.value}
          </Link>
        ),
      },

      {
        field: status,
        headerName: "Current Status",
        flex: 1,
        headerAlign: "center",
      },
      {
        field: "user_id",
        headerName: "User ID",
        flex: 1,
        headerAlign: "center",
      },
    ];
  } else if (props.form === "Validation") {
    columns = [
      {
        field: "id",
        headerName: "ID",
        width: 50,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Name",
        flex: 5,
        headerAlign: "center",
        renderCell: (params) => (
          <Link
            to={`/${props.form ? props.form.toLowerCase() : ""}/${
              params.row.code
            }+${params.row.version}+${params.row.validation_id}+${
              params.row.sequence
            }`}
            className="task-link"
          >
            {params.value}
          </Link>
        ),
      },

      {
        field: status,
        headerName: "Current Status",
        flex: 1,
        headerAlign: "center",
      },
      {
        field: "user_id",
        headerName: "User ID",
        flex: 1,
        headerAlign: "center",
      },
    ];
  } else if (props.form === "Model") {
    columns = [
      {
        field: "id",
        headerName: "ID",
        width: 50,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Name",
        flex: 5,
        headerAlign: "center",
        renderCell: (params) => (
          <Link
            to={`/${
              params.row?.sequence === "0" || params.row?.sequence === 0
                ? "temp"
                : "model"
            }/${params.row.code}+${params.row.version}+${params.row.sequence}`}
            className="task-link"
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: status,
        headerName: "Current Status",
        flex: 1,
        headerAlign: "center",
      },
      {
        field: "user_id",
        headerName: "User ID",
        flex: 1,
        headerAlign: "center",
      },
    ];
  } else {
    columns = [
      {
        field: "id",
        headerName: "ID",
        width: 50,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "name",
        headerName: "Name",
        flex: 5,
        headerAlign: "center",
        renderCell: (params) => (
          <Link
            to={`/${props.form ? props.form.toLowerCase() : ""}/${
              params.row.code
            }+${params.row.version}+${params.row.sequence}`}
            className="task-link"
          >
            {params.value}
          </Link>
        ),
      },
      {
        field: status,
        headerName: "Current Status",
        flex: 1,
        headerAlign: "center",
      },
      {
        field: "user_id",
        headerName: "User ID",
        flex: 1,
        headerAlign: "center",
      },
    ];
  }

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertToUppercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toUpperCase(),
          email: row.email.toUpperCase(),
          title: row.title.toUpperCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const convertToLowercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toLowerCase(),
          email: row.email.toLowerCase(),
          title: row.title.toLowerCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const datagridSx = {
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "rgb(250,250,250)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgb(221,225,255)",
      },
    },
  };

  return (
    <Fragment>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <div style={{ width: "100%" }}>
          <WrapTextTable
            autoHeight
            rowHeight={30}
            sx={datagridSx}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
            }
            pagination
            columns={columns}
            rows={rows}
            pageSize={pageSize}
            rowsPerPageOptions={[3, 5, 10]}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            componentsProps={{
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: "context-menu" },
              },
            }}
          />

          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            componentsProps={{
              root: {
                onContextMenu: (e) => {
                  e.preventDefault();
                  handleClose();
                },
              },
            }}
          >
            <MenuItem onClick={convertToUppercase}>UPPERCASE</MenuItem>
            <MenuItem onClick={convertToLowercase}>lowercase</MenuItem>
          </Menu>
        </div>
      )}
    </Fragment>
  );
};

export default TaskTable;
