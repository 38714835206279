import { useState, useEffect, useContext, useCallback, useRef } from "react";
import {
  Box,
  Button,
  Popper,
  Paper,
  InputBase,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridCellModes,
  GridActionsCellItem,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";
import clsx from "clsx";
import _ from "lodash";
import { UserContext } from "../../../context/user-context";
import WrapTextTable, {
  renderCellExpand,
} from "../../CustomComponents/WrapTextTable";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const EditTextarea = (props) => {
  const { id, field, value, colDef, rowHeightHandler, hasFocus } = props;
  const [valueState, setValueState] = useState(value);
  const [anchorEl, setAnchorEl] = useState();
  const apiRef = useGridApiContext();

  const handleRef = useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const inputRef = useCallback((el) => {
    if (el && el !== null) {
      try {
        rowHeightHandler([id, el.scrollHeight]);
      } catch (err) {
        console.warning(err);
        rowHeightHandler([id, el.scrollHeight]);
      }
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById("textareaCell");
    if (element && element !== null) {
      rowHeightHandler([id, element.scrollHeight]);
    }
  }, [valueState, field]);

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    },
    [apiRef, field, id]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (
        event.key === "Escape" ||
        (event.key === "Enter" &&
          !event.shiftKey &&
          (event.ctrlKey || event.metaKey))
      ) {
        const params = apiRef.current.getCellParams(id, field);
        apiRef.current.publishEvent("cellKeyDown", params, event);
      }
    },
    [apiRef, id, field]
  );

  return (
    <InputBase
      multiline
      value={valueState}
      sx={{ width: "100%", padding: 0 }}
      onChange={handleChange}
      autoFocus
      inputRef={inputRef}
      id="textareaCell"
    />
  );
};

export default function MetricTable(props) {
  const { tag } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [cellModesModel, setCellModesModel] = useState({});
  const [rowHeight, setRowHeight] = useState(["id", 30]);

  const rowHeightHandler = (height) => {
    setRowHeight(height);
  };

  const [count, setCount] = useState(
    rows.length > 0 ? Math.max(...rows.map((i) => i.key)) + 1 : 1
  );

  const [tableHeight, setTableHeight] = useState("100px");

  useEffect(() => {
    if (rows.length === 0) {
      setTableHeight("100px");
    } else {
      setTableHeight("auto");
    }
  }, [rows]);

  let status_option;
  if (tag) {
    status_option = tag
      .filter(
        (item) =>
          item.db_tablename === "assumption" &&
          item.fe_id === "assumption_status"
      )[0]
      ?.group.split(",");
  } else {
    status_option = [];
  }

  let identifier;
  if (tag) {
    identifier = tag
      .filter(
        (item) =>
          item.db_tablename === "assumption" && item.fe_id === "identifier"
      )[0]
      ?.group.split(",");
  } else {
    identifier = [];
  }

  let frequency_option;
  if (tag) {
    frequency_option = tag
      .filter(
        (item) =>
          item.db_tablename === "assumption" &&
          item.fe_id === "monitoring_frequency"
      )[0]
      ?.group.split(",");
  } else {
    frequency_option = [];
  }

  const multilineColumn = {
    type: "string",
    renderEditCell: (params) => {
      return <EditTextarea {...params} rowHeightHandler={rowHeightHandler} />;
    },
  };

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        key: count,
        assumption_status: "",
        condition: "",
        identifier: "",
        monitoring_frequency: "",
        isNew: true,
      },
    ]);
    props.tableDataChanged([
      ...rows,
      {
        id,
        key: count,
        assumption_status: "",
        condition: "",
        identifier: "",
        monitoring_frequency: "",
        isNew: true,
      },
    ]);

    setCellModesModel((oldModel) => ({
      ...oldModel,
      [id]: {
        condition: { mode: GridCellModes.Edit },
      },
    }));
    setCount(count + 1);
  };

  useEffect(() => {
    if (
      !_.isEqual(
        props.tableData.map((i) => [
          i.condition,
          i.assumption_status,
          i.identifier,
          i.monitoring_frequency,
        ]),
        rows.map((i) => [
          i.condition,
          i.assumption_status,
          i.identifier,
          i.monitoring_frequency,
        ])
      )
    ) {
      props.tableData &&
        setRows(
          props.tableData.map((data, i) => ({
            ...data,
            id: data.id ? data.id : randomId(),
            isNew: false,
            key: i + 1,
            monitoring_frequency:
              data.assumption_status === "Active - Not Track"
                ? ""
                : data.monitoring_frequency,
          }))
        );
    }

    setRowHeight(["id", 30]);
  }, [props.tableData]);

  useEffect(() => {
    setCount(rows.length > 0 ? Math.max(...rows.map((i) => i.key)) + 1 : 1);
  }, [rows.length]);

  const handleCellClick = useCallback((params) => {
    if (!params.isEditable) {
      return;
    }
    if (params.field === "actions") return;
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    props.tableDataChanged(rows.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = {
      ...newRow,
      isNew: false,
      monitoring_frequency:
        newRow.assumption_status === "Active - Not Track"
          ? ""
          : newRow.monitoring_frequency,
    };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    props.tableDataChanged(
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const columns = [
    {
      field: "key",
      headerName: "ID",
      flex: 0.5,
      editable: false,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "condition",
      headerName: "Description",
      flex: 5,
      editable: true,
      renderCell: (params) => {
        return renderCellExpand(params);
      },
      headerAlign: "center",
      ...multilineColumn,
    },

    {
      field: "assumption_status",
      headerName: "Status",
      type: "singleSelect",
      valueOptions: status_option,
      flex: 2,
      headerAlign: "center",
      editable: true,
    },
    {
      field: "identifier",
      headerName: "Identified By",
      type: "singleSelect",
      valueOptions: identifier,
      flex: 2,
      headerAlign: "center",
      editable: true,
    },
    {
      field: "monitoring_frequency",
      headerName: "Monitoring Frequency",
      type: "singleSelect",
      flex: 2,
      headerAlign: "center",
      editable: true,
      valueOptions: (params) => {
        if (params.row.assumption_status === "Active - Not Track") {
          return [];
        } else {
          return frequency_option;
        }
      },
      valueGetter: (params) => {
        if (params.row.assumption_status === "Active - Not Track") {
          return "";
        } else {
          return params.row.monitoring_frequency;
        }
      },
      valueSetter: (params) => {
        const [monitoring_frequency] = params.value.toString().split(" ");
        if (params.row.assumption_status === "Active - Not Track") {
          return { ...params.row, monitoring_frequency: "" };
        } else {
          return { ...params.row, monitoring_frequency };
        }
      },

      renderCell: (params) => {
        if (params.row.assumption_status === "Active - Not Track") {
          return <div></div>;
        } else {
          return <div>{params.value}</div>;
        }
      },
      cellClassName: (params) => {
        if (params.row.assumption_status === "Active - Not Track") {
          return clsx("MuiDataGrid-cell--", {
            "non--editable": true,
          });
        } else {
          return "";
        }
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  return (
    <Box
      sx={{
        "& .MuiDataGrid-cell.non--editable": {
          backgroundColor: "#f2f2f2",
        },
      }}
    >
      <Accordion
        sx={{
          color: "inherit",
          borderRadius: "0 !important",
          borderTop: "none",
          borderRight: "none",
          borderLeft: "none",
          borderBottom: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="document-management"
          id="document-management"
          sx={{
            width: "30%",
            ml: 1,
            p: 0,
            "& .MuiAccordionSummary-content": {
              justifyContent: "space-between",
            },
          }}
        >
          <Typography sx={{ fontWeight: 600, fontSize: 16 }}>
            {props.label}
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: 16, ml: 10, color: "#CB3694" }}
          >
            ({rows.length})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Button
            variant="outlined"
            className="editButton"
            color="primary"
            startIcon={<AddIcon />}
            onClick={handleClick}
          >
            Add record
          </Button>
          <WrapTextTable
            headerHeight={40}
            getRowHeight={(params) => {
              if (params.id === rowHeight[0]) {
                return rowHeight[1];
              } else {
                return 30;
              }
            }}
            getEstimatedRowHeight={() => 200}
            autoHeight
            rows={rows}
            columns={columns}
            isCellEditable={(params) => !props.disabled}
            processRowUpdate={processRowUpdate}
            experimentalFeatures={{ newEditingApi: true }}
            cellModesModel={cellModesModel}
            onCellModesModelChange={handleCellModesModelChange}
            onCellClick={handleCellClick}
            hideFooter={true}
            showCellRightBorder={true}
            sx={{
              boxShadow: "none",
            }}
            onCellKeyDown={(params, event) => {
              if (event.key === "Enter") {
                event.stopPropagation();
              }
            }}
            style={{ height: tableHeight }}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
