import { useState, useContext } from "react";

import { NavLink, useLocation, useHistory } from "react-router-dom";

import {
  Button,
  ListItem,
  ListItemText,
  styled,
  Menu,
  Box,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AssignmentTurnedInOutlinedIcon from "@mui/icons-material/AssignmentTurnedInOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import SimCardDownloadOutlinedIcon from "@mui/icons-material/SimCardDownloadOutlined";
import MediationOutlinedIcon from "@mui/icons-material/MediationOutlined";
import Groups3OutlinedIcon from "@mui/icons-material/Groups3Outlined";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import TerminalOutlinedIcon from "@mui/icons-material/TerminalOutlined";
import { UserContext } from "../../../context/user-context";

import "./MenuPage.css";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: "black",

    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "white",
      },
    },
  },
}));

const colors = {
  eqyellow: "#FFCB05",
  eqorange: "#FF9100",
  eqblack: "#333333",
  eqpink: "#CB3694",
  eqcharcoal: "#444444",
  bbdlightblue: "#58A6F1",
  bbdseagreen: "#2E8B57",
};

export default function CustomizedMenus(props) {
  const { pathname } = useLocation();
  const history = useHistory();
  const {
    user,
    tabNameOutToInHandler,
    tabNameInToOutHandler,
    isTempPage,
    dataChangedArr,
    handleDialogClickOpen,
    setNavigationHandler,
  } = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeToNonModelRelatedPageHandler = (e, path) => {
    e.preventDefault();
    setAnchorEl(null);
    if (dataChangedArr.length > 0) {
      setNavigationHandler(path);
      handleDialogClickOpen();
    } else {
      history.push(path);
    }
  };

  return (
    <Box
      sx={{
        p: 0,
        m: 0,
        height: "3rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        color: "#333333 !important",
      }}
    >
      <Button
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={props.isDisabled}
        sx={{
          p: 0.1,
          pl: 0.7,
          pr: 0.7,
          ml: 0.5,
          color: props.isDisabled ? "grey !important" : "white",
          background: "transparent",
          fontFamily: "inherit",
          fontSize: "inherit",
          fontWeight: "inherit",
          "&:hover": {
            background: "#FF9100",
            borderColor: "#292929",
            color: "#292929",
          },
          "& .active": {
            background: "#FF9100",
            borderColor: "#292929",
            color: "#292929",
          },
          "& .MuiButton-endIcon": {
            ml: 0,
          },
        }}
      >
        Admin
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className="drop_down_menu"
        sx={{ "& .MuiListItem-root": { paddingLeft: "5px", color: "#444444" } }}
      >
        {/*<MenuItem onClick={handleClose} disableRipple>
          <NavLink to="/init" exact>
            System Initiation
          </NavLink>
      </MenuItem>*/}
        <ListItem
          button
          to="/config"
          component={NavLink}
          onClick={(e) => changeToNonModelRelatedPageHandler(e, "/config")}
        >
          <TuneOutlinedIcon sx={{ color: colors.eqyellow }} />
          <ListItemText primary="Option Config" />
        </ListItem>
        <ListItem
          button
          to="/user"
          component={NavLink}
          onClick={(e) => changeToNonModelRelatedPageHandler(e, "/user")}
        >
          <ManageAccountsOutlinedIcon sx={{ color: colors.eqorange }} />
          <ListItemText primary="User" />
        </ListItem>
        <ListItem
          button
          to="/notification"
          component={NavLink}
          onClick={(e) =>
            changeToNonModelRelatedPageHandler(e, "/notification")
          }
        >
          <MarkEmailReadOutlinedIcon sx={{ color: colors.eqpink }} />
          <ListItemText primary="Notification" />
        </ListItem>
        <ListItem
          button
          to="/history"
          component={NavLink}
          onClick={(e) => changeToNonModelRelatedPageHandler(e, "/history")}
        >
          <HistoryOutlinedIcon sx={{ color: colors.bbdlightblue }} />
          <ListItemText primary="History Log" />
        </ListItem>
        <ListItem
          button
          to="/task"
          component={NavLink}
          onClick={(e) => changeToNonModelRelatedPageHandler(e, "/task")}
          exact
        >
          <AssignmentOutlinedIcon sx={{ color: colors.eqblack }} />
          <ListItemText primary="Task List" />
        </ListItem>
        <ListItem
          button
          to="/task/config"
          component={NavLink}
          onClick={(e) => changeToNonModelRelatedPageHandler(e, "/task/config")}
          exact
        >
          <AssignmentTurnedInOutlinedIcon sx={{ color: colors.eqcharcoal }} />
          <ListItemText primary="Task Config" />
        </ListItem>
        <ListItem
          button
          to="/access"
          component={NavLink}
          onClick={(e) => changeToNonModelRelatedPageHandler(e, "/access")}
        >
          <LockPersonOutlinedIcon sx={{ color: colors.bbdseagreen }} />
          <ListItemText primary="Access Config" />
        </ListItem>
        <ListItem
          button
          to="/batch/upload"
          component={NavLink}
          onClick={(e) =>
            changeToNonModelRelatedPageHandler(e, "/batch/upload")
          }
        >
          <UploadFileOutlinedIcon sx={{ color: colors.eqyellow }} />
          <ListItemText primary="Batch Upload" />
        </ListItem>
        <ListItem
          button
          to="/batch/download"
          component={NavLink}
          onClick={(e) =>
            changeToNonModelRelatedPageHandler(e, "/batch/download")
          }
        >
          <SimCardDownloadOutlinedIcon sx={{ color: colors.eqorange }} />
          <ListItemText primary="Batch Download" />
        </ListItem>

        <ListItem
          button
          to="/schema"
          component={NavLink}
          onClick={(e) => changeToNonModelRelatedPageHandler(e, "/schema")}
        >
          <MediationOutlinedIcon sx={{ color: colors.eqpink }} />
          <ListItemText primary="OPM Framework" />
        </ListItem>

        <ListItem
          button
          to="/attendance"
          component={NavLink}
          onClick={(e) => changeToNonModelRelatedPageHandler(e, "/attendance")}
        >
          <Groups3OutlinedIcon sx={{ color: colors.bbdlightblue }} />
          <ListItemText primary="MAC Attendance" />
        </ListItem>

        <ListItem
          button
          to="/dashboard/config"
          component={NavLink}
          onClick={(e) =>
            changeToNonModelRelatedPageHandler(e, "/dashboard/config")
          }
        >
          <DashboardCustomizeOutlinedIcon sx={{ color: colors.eqblack }} />
          <ListItemText primary="Dashboard Config" />
        </ListItem>

        <ListItem
          button
          to="/query"
          component={NavLink}
          onClick={(e) => changeToNonModelRelatedPageHandler(e, "/query")}
        >
          <TerminalOutlinedIcon sx={{ color: colors.eqcharcoal }} />
          <ListItemText primary="Query List" />
        </ListItem>
      </StyledMenu>
    </Box>
  );
}
