import { useEffect, useState } from "react";

import { Box, Button, CircularProgress } from "@mui/material";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";

import InputNew from "../../components/Content/FormElements/InputNew";
import Toast from "../../components/Utils/Toast";

const Executor = () => {
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const [formState, inputHandler] = useForm({}, false);

  const handleClick = async (e) => {
    e.preventDefault();
    if (!formState.inputs?.executor?.value) {
      Toast("error", "Please enter executor");
      return;
    }

    try {
      const response = await sendRequest(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/executor`,
        "POST",
        formState.inputs?.executor?.value
      );
      if (response.error) {
        Toast("error", response.error.toString().slice(0,200));
      } else {
        Toast(
          "info",
          typeof response === "object" ? Object.values(response)?.[0] : response
        );
      }
    } catch (err) {}
  };
  return (
    <Box sx={{ mt: 10, ml: 30, mr: 30, mb: 20 }}>
      <InputNew
        id="executor"
        element="textEditor"
        label="Executor"
        initialValue=""
        onInput={inputHandler}
        height={300}
        isAutoSize
      />
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant="outlined" className="editButton" onClick={handleClick}>
          Execute
        </Button>
        {isLoading && <CircularProgress />}
      </Box>
    </Box>
  );
};

export default Executor;
