import { useState, useEffect, useContext, useCallback } from "react";
import {
  Button,
  Box,
  Autocomplete,
  TextField,
  Popper,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  useGridApiRef,
  GridActionsCellItem,
  GridCellModes,
  useGridApiContext,
} from "@mui/x-data-grid-pro";
import { randomId } from "@mui/x-data-grid-generator";
import WrapTextTable from "../../components/CustomComponents/WrapTextTable";

import { UserContext } from "../../context/user-context";

const EditAutocomplete = (props) => {
  const {
    id,
    field,
    value,
    row,
    colDef,
    tabNameOutToInHandler,
    groupOption,
    rowHeightHandler,
    hasFocus,
  } = props;
  const [valueState, setValueState] = useState(value);
  const [anchorEl, setAnchorEl] = useState();
  const apiRef = useGridApiContext();

  const handleRef = useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const inputRef = useCallback((el) => {
    if (el === null) return;
    const element = document.getElementById("autoCompleteCell");
    if (element && element !== null) {
      try {
        rowHeightHandler([id, element.scrollHeight]);
      } catch (err) {
        console.warning(err);
        rowHeightHandler([id, element.scrollHeight]);
      }
    }
  }, []);

  useEffect(() => {
    const element = document.getElementById("autoCompleteCell");
    if (element && element !== null) {
      rowHeightHandler([id, element.scrollHeight]);
    }
  }, [valueState, field]);

  useEffect(() => {
    if (!hasFocus) {
      rowHeightHandler([id, 40]);
    }
  }, [hasFocus]);

  const handleChange = useCallback(
    (event, value) => {
      const newValue = value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event
      );
    },
    [apiRef, field, id]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (
        event.key === "Escape" ||
        (event.key === "Enter" &&
          !event.shiftKey &&
          (event.ctrlKey || event.metaKey))
      ) {
        const params = apiRef.current.getCellParams(id, field);
        apiRef.current.publishEvent("cellKeyDown", params, event);
      }
    },
    [apiRef, id, field]
  );

  return (
    <div style={{ position: "relative", alignSelf: "flex-start" }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: "block",
          position: "absolute",
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom-start">
          <Paper
            elevation={5}
            sx={{ p: 0, minWidth: colDef.computedWidth }}
            id="autoCompleteCell"
          >
            <Autocomplete
              multiple
              options={
                field === "user_group"
                  ? groupOption
                  : Object.entries(props.groupOperation)?.find((option) => {
                      if (tabNameOutToInHandler(option[0]) === row.form) {
                        return option[1];
                      } else return "";
                    })?.[1] || []
              }
              disableCloseOnSelect
              value={valueState}
              sx={{ width: colDef.computedWidth, p: 0, m: 0 }}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              renderInput={(params) => (
                <TextField {...params} variant="standard" size="small" />
              )}
              ref={inputRef}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
};

export default function FullFeaturedCrudGrid(props) {
  const { tag, tabNameOutToInHandler } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [cellModesModel, setCellModesModel] = useState({});
  const [rowHeight, setRowHeight] = useState(["id", 40]);

  const rowHeightHandler = (height) => {
    setRowHeight(height);
  };

  let tag2 = {};
  if (tag) {
    tag
      .filter((i) => i.db_tablename === "menu access")
      .map((item) => (tag2[item.fe_id] = item.group.split(",")));
  }

  const autoCompleteColumn = {
    type: "string",
    renderEditCell: (params) => (
      <EditAutocomplete
        {...params}
        tag2={tag2}
        tabNameOutToInHandler={tabNameOutToInHandler}
        groupOption={props.groupOption || []}
        groupOperation={props.groupOperation || {}}
        rowHeightHandler={rowHeightHandler}
      />
    ),
  };

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        isNew: true,
        form: "",
        user_group: [],
        operation: [],
      },
    ]);

    setCellModesModel((oldModel) => ({
      ...oldModel,
    }));
  };

  useEffect(() => {
    props.groupAccessData &&
      setRows(
        props.groupAccessData.map((data) => ({
          ...data,
          form: tabNameOutToInHandler(data.form),
          id: data.id ? data.id : randomId(),
          access: data.access.toString(),
          isNew: false,
        }))
      );
  }, [props.groupAccessData]);

  const handleCellClick = useCallback((params) => {
    if (params.field === "actions") return;
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    props.groupAccessDataChanged(rows.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    props.groupAccessDataChanged(
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  const columns = [
    {
      field: "form",
      headerName: "Task",
      flex: 1,
      editable: true,
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: Object.keys(props.groupOperation).map((op) =>
        tabNameOutToInHandler(op)
      ),
    },

    {
      field: "user_group",
      headerName: "User Group",
      headerAlign: "center",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return (
          <div>
            {Array.isArray(params.value) ? params.value.join("; ") : ""}
          </div>
        );
      },
      ...autoCompleteColumn,
    },
    {
      field: "operation",
      headerName: "Operation",
      headerAlign: "center",
      flex: 1,
      editable: true,
      renderCell: (params) => {
        return (
          <div>
            {Array.isArray(params.value) ? params.value.join("; ") : ""}
          </div>
        );
      },
      ...autoCompleteColumn,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.5,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ];
      },
    },
  ];

  return (
    <Box>
      <Button
        variant="outlined"
        className="editButton"
        color="primary"
        startIcon={<AddIcon />}
        onClick={handleClick}
      >
        Add
      </Button>
      <WrapTextTable
        headerHeight={40}
        getRowHeight={(params) => {
          if (params.id === rowHeight[0]) {
            return rowHeight[1] + 5;
          } else {
            return 40;
          }
        }}
        autoHeight
        rows={rows}
        columns={columns}
        isCellEditable={(params) => true}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        hideFooter={true}
        showCellRightBorder={true}
        sx={{
          boxShadow: "none",
        }}
      />
    </Box>
  );
}
