import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { CircularProgress, Box, Grid, Button } from "@mui/material";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { UserContext } from "../../context/user-context";
import { Popconfirm } from "antd";

import InputNew from "../../components/Content/FormElements/InputNew";
import Toast from "../../components/Utils/Toast";
import { weightSchemaValueCheck } from "./GlobalSchemaCheck";

const isSchemaIncludesNonNumber = (list) => {
  if (Array.isArray(list)) {
    if (
      list.filter(
        (item) =>
          typeof item.lower_bound !== "number" ||
          typeof item.upper_bound !== "number"
      ).length > 0
    ) {
      return true;
    } else return false;
  }
  return true;
};

const GlobalSchema = (props) => {
  const { tag } = props;
  const { user } = useContext(UserContext);
  const [formState, inputHandler] = useForm(
    {
      framework: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [schemaData, setSchemaData] = useState([]);
  const [weightSchema, setWeightSchema] = useState([]);
  const [performanceMeasurement, setPerformanceMeasurement] = useState([]);
  const [attestation, setAttestation] = useState([]);
  const [scoreSchema, setScoreSchema] = useState([]);
  const [isBaseChanged, setIsBaseChanged] = useState(false);
  const [frameworkSelected, setFrameworkSelected] = useState("");

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const { display_name } = user || {};

  const colorList = tag
    ?.filter(
      (item) => item.db_tablename === "score schema" && item.fe_id === "color"
    )[0]
    ?.group?.split(",");

  const framework_option = tag?.filter(
    (item) =>
      item.db_tablename === "static metric" && item.fe_id === "framework"
  )[0];

  useEffect(() => {
    const fetchGlobalSchema = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/opm/schema`
        );
        setSchemaData(response);
      } catch (err) {}
    };
    fetchGlobalSchema();
  }, [sendRequest, isBaseChanged]);

  useEffect(() => {
    if (
      formState.inputs.framework_new &&
      formState.inputs.framework_new.value
    ) {
      setFrameworkSelected(formState.inputs.framework_new?.value);
      setWeightSchema(
        schemaData["weight schema"]?.filter(
          (item) => item.framework === formState.inputs.framework_new.value
        )
      );
      setPerformanceMeasurement(
        schemaData["static metric"]?.filter(
          (item) =>
            item.framework === formState.inputs.framework_new.value &&
            item.statistics_type === "Quantitative"
        )
      );
      setAttestation(
        schemaData["static metric"]?.filter(
          (item) =>
            item.framework === formState.inputs.framework_new.value &&
            item.statistics_type === "Qualitative"
        )
      );
      setScoreSchema(
        schemaData["score schema"]?.filter(
          (item) => item.framework === formState.inputs.framework_new.value
        )
      );
    }
  }, [formState.inputs.framework_new?.value, isBaseChanged, schemaData]);

  const updateFrameworkHandler = async (e) => {
    if (
      !formState.inputs.framework_new ||
      !formState.inputs.framework_new.value ||
      formState.inputs.framework_new.value === ""
    ) {
      Toast("error", "Please select Framework Name.");
      return;
    }

    if (weightSchemaValueCheck(formState.inputs?.weight_schema?.value)) {
      Toast("error", "Please enter valid number for weight.");
      return;
    }

    // if (
    //   isSchemaIncludesNonNumber(
    //     formState.inputs.performance_measurement?.value
    //   ) ||
    //   isSchemaIncludesNonNumber(formState.inputs.score_schema?.value)
    // ) {
    //   Toast("error", "Please enter valid number for lower and upper bound");
    //   return;
    // }
    e.preventDefault();
    const scoreSchemaBody = {
      "score schema":
        Array.isArray(formState.inputs.score_schema?.value) &&
        formState.inputs.score_schema?.value?.map((score) => ({
          color: score.color ? score.color : "",
          framework: formState.inputs.framework_new?.value
            ? formState.inputs.framework_new?.value
            : "",
          lower_bound:
            score.lower_bound !== undefined &&
            score.lower_bound !== "" &&
            score.lower_bound !== null
              ? +score.lower_bound
              : null,
          update_time: currentDate,
          upper_bound:
            score.upper_bound !== undefined &&
            score.upper_bound !== "" &&
            score.upper_bound !== null
              ? +score.upper_bound
              : null,
          user_id: display_name,
          value:
            score.value !== undefined &&
            score.value !== "" &&
            score.value !== null
              ? +score.value
              : null,
          default_color: score.default_color ? score.default_color : "",
        })),
    };

    let qualitativeMetric;
    if (
      formState.inputs.attestation.value &&
      Array.isArray(formState.inputs.attestation.value)
    ) {
      qualitativeMetric = formState.inputs.attestation?.value.map((data) => ({
        attestation: data.attestation,
        color: data.color ? data.color : "",
        framework: formState.inputs.framework_new?.value
          ? formState.inputs.framework_new?.value
          : "",
        lower_bound: null,
        statistics: data.statistics ? data.statistics : "",
        statistics_type: data.statistics_type ? data.statistics_type : "",
        update_time: currentDate,
        upper_bound: null,
        user_id: display_name,
        opm_rule: data.opm_rule !== undefined ? data.opm_rule : "",
        quarterback:
          data.quarterback !== undefined &&
          data.quarterback !== "" &&
          data.quarterback !== null
            ? +data.quarterback
            : null,
        series:
          data.series !== undefined &&
          data.series !== "" &&
          data.series !== null
            ? +data.series
            : null,
        continuity: data.continuity !== undefined ? data.continuity : "",
      }));
    }

    let quantitativeMetric;
    if (
      formState.inputs.performance_measurement.value &&
      Array.isArray(formState.inputs.performance_measurement.value)
    ) {
      quantitativeMetric = formState.inputs.performance_measurement?.value.map(
        (data) => ({
          attestation: "",
          color: data.color ? data.color : "",
          framework: formState.inputs.framework_new?.value
            ? formState.inputs.framework_new?.value
            : "",
          lower_bound:
            data.lower_bound !== undefined &&
            data.lower_bound !== "" &&
            data.lower_bound !== null
              ? +data.lower_bound
              : null,
          statistics: data.statistics ? data.statistics : "",
          statistics_type: data.statistics_type ? data.statistics_type : "",
          update_time: currentDate,
          upper_bound:
            data.upper_bound !== undefined &&
            data.upper_bound !== "" &&
            data.upper_bound !== null
              ? +data.upper_bound
              : null,
          user_id: display_name,
          opm_rule: data.opm_rule ? data.opm_rule : "",
          quarterback:
            data.quarterback !== undefined &&
            data.quarterback !== "" &&
            data.quarterback !== null
              ? +data.quarterback
              : null,
          series:
            data.series !== undefined &&
            data.series !== "" &&
            data.series !== null
              ? +data.series
              : null,
          continuity: data.continuity !== undefined ? data.continuity : "",
        })
      );
    }

    const staticMetricBody = {
      "static metric": [...qualitativeMetric, ...quantitativeMetric],
    };

    const weightSchemaBody = {
      "weight schema": formState.inputs.weight_schema?.value?.map((weight) => ({
        framework: formState.inputs.framework_new?.value
          ? formState.inputs.framework_new?.value
          : "",
        statistics: weight.statistics ? weight.statistics : "",
        weight:
          weight.weight !== undefined &&
          weight.weight !== "" &&
          weight.weight !== null
            ? +weight.weight
            : 0,
      })),
    };
    const opmSubmitBody = {
      ...scoreSchemaBody,
      ...staticMetricBody,
      ...weightSchemaBody,
    };
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/opm/schema?framework=${formState.inputs.framework_new.value}`,
        "POST",
        JSON.stringify(opmSubmitBody),
        {
          "Content-Type": "application/json",
        }
      );
      if (
        response["score schema"] ||
        response["static metric"] ||
        response["weight schema"]
      ) {
        Toast("success", "Framework Updated Successfully.");
        setIsBaseChanged((prev) => !prev);
      } else {
        Toast("error", response, "Database Error.");
      }
    } catch (err) {}
  };

  const clearFrameworkHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/opm/schema?framework=${formState.inputs.framework_new.value}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
        }
      );

      Toast("success", "Framework Cleared.");
      setIsBaseChanged((prev) => !prev);
    } catch (err) {}
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>OPM Framework</h4>
      </div>
      <hr />
      <Box sx={{ flexGrow: 2, mt: 2, ml: 20, mr: 20, mb: 2 }}>
        <Grid container rowSpacing={2} columnSpacing={5}>
          <Grid item xs={6}>
            <InputNew
              id="framework_new"
              type="text"
              label="Input Framework Name"
              element="select"
              onInput={inputHandler}
              initialValue={frameworkSelected}
              onBlur
              origin_options={framework_option}
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "1.2rem" }}>
              <Popconfirm
                title="Are you sure to clear?"
                onConfirm={clearFrameworkHandler}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="outlined" className="editButton">
                  Clear Metric
                </Button>
              </Popconfirm>
            </div>
          </Grid>
        </Grid>
        <div style={{ width: "100%", margin: "1rem auto 2rem auto" }}>
          <InputNew
            id="weight_schema"
            label="Weighting Schema"
            element="opmTable"
            onInput={inputHandler}
            initialValue={weightSchema}
          />
        </div>
        <div style={{ width: "100%", margin: "1rem auto 2rem auto" }}>
          <InputNew
            id="performance_measurement"
            label="Performance Measurement (Quantitative Result)"
            element="opmTable"
            onInput={inputHandler}
            initialValue={performanceMeasurement}
            colorList={colorList}
          />
        </div>
        <div style={{ width: "100%", margin: "1rem auto 2rem auto" }}>
          <InputNew
            id="attestation"
            label="Assessment (Qualitative Result)"
            element="opmTable"
            onInput={inputHandler}
            initialValue={attestation}
            colorList={colorList}
          />
        </div>
        <div style={{ width: "100%", margin: "1rem auto 2rem auto" }}>
          <InputNew
            id="score_schema"
            label="Score Schema"
            element="opmTable"
            onInput={inputHandler}
            initialValue={scoreSchema}
            colorList={colorList}
          />
        </div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={updateFrameworkHandler}
            variant="outlined"
            className="editButton"
          >
            Save Metric
          </Button>
        </div>
      </Box>
    </div>
  );
};
export default GlobalSchema;
