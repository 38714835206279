import { useParams } from "react-router-dom";

import TemplatePage from "../../../components/CustomComponents/GeneralPageTemplate";

import "./IssueDetails.css";

const IssueDetails = ({ tag, record, setScrollTop, pageElement }) => {
  const { issueId } = useParams();
  let issueDetailsId = `${issueId.split("+")[0]}+${issueId.split("+")[1]}+${issueId.split("+")[2]}`;
  return (
    <TemplatePage
      tag={tag}
      form="issue"
      table="Issue"
      issueId={issueDetailsId}
      record={record}
      setScrollTop={setScrollTop}
      pageElement={pageElement}
    />
  );
};
 
export default IssueDetails;
