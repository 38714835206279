import { useState, Fragment, useContext, useEffect } from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import {
  saveDataInIndexDB,
  getDataFromDB,
  deleteDataFromIndexDB,
  db,
} from "../../../components/CustomComponents/IndexedDBTemplate";

import { toast } from "react-toastify";
import Toast from "../../../components/Utils/Toast";

import "./NewIssue.css";

import InputNew from "../../../components/Content/FormElements/InputNew";
import FileLoad from "../../../components/CustomComponents/FileLoad";
import {
  CircularProgress,
  Box,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@mui/material";

import PopUpTable from "./PopUpTable";
import { useForm } from "../../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../../components/Utils/hooks/http-hook";
import { UserContext } from "../../../context/user-context";
import { isTwoJsonValueEqual } from "../../../components/CustomComponents/GeneralPageTemplate";

const raisedByMap = {
  MRM: "MRM",
  "Internal Auditor": "ADT",
  "External Vetters": "EXTV",
  "External Auditor": "EXTA",
  Regulator: "OSFI",
  "RMG(Concentra)": "RMG",
  MAC: "MAC",
};

const NewIssue = ({ tag, isTreeChanged, setScrollTop, pageElement }) => {
  const { pathname, maxIssueId } = useLocation();
  const { issueId } = useParams();
  const {
    user,
    token,
    userGroup,
    fileTypeInToOutHandler,
    isPageDataChangedHandler,
    isPageDataNotChangedHandler,
  } = useContext(UserContext);
  const { isLoading, sendRequest } = useHttpClient();
  const [files, setFiles] = useState([]);
  const [newDocument, setNewDocument] = useState([]);
  const [open, setOpen] = useState(false);
  const [formDataObj, setFormDataObj] = useState({});
  const [tableData, setTableData] = useState([]);
  const [storedData, setStoredData] = useState({});
  const [buttonOperation, setButtonOperation] = useState("");
  const [formState, inputHandler] = useForm(
    {
      issue_id: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const { display_name } = user || {};
  const status_option = Object.values(user["group access"]) || [];

  let tag2, fileOption;
  if (tag) {
    tag2 = tag.filter((item) => item.db_tablename === "issue");
    fileOption = tag.filter(
      (item) => item.db_tablename === "documents" && item.fe_id === "file_type"
    );
  } else {
    tag2 = [];
    fileOption = [];
  }

  if (tag) {
    tag = tag.filter(
      (item) => item.db_tablename === "issue" && item.fe_isrender === true
    );
  } else {
    tag = [];
  }

  const history = useHistory();

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const buttonAccess = user["menu access"] || {};
  const access = Object.values(buttonAccess)[0]
    .filter((item) => item[0] === "Issue")
    .map((access) => access[1]);

  useEffect(() => {
    storedData.file && setFiles(storedData.file);
    storedData.file && setNewDocument(storedData.newDocument);
  }, [storedData]);

  const newIssueInitialValueHandler = (tag) => {
    if (
      tag.fe_id === "record" ||
      tag.fe_id === "date_closed" ||
      tag.fe_id === "approver"
    ) {
      if (
        formState.inputs.issue_status &&
        (formState.inputs.issue_status.value === "Open" ||
          formState.inputs.issue_status.value === "Past Due")
      ) {
        return "";
      }
    }

    if (tag.fe_id === "approver") {
      if (storedData["approver"] === "") {
        return "MRM VP";
      }
    }

    // if (
    //   tag.fe_element === "select" &&
    //   (!storedData[tag.fe_id] || storedData[tag.fe_id] === "") &&
    //   tag.fe_id !== "record" &&
    //   tag.group.split(",")
    // ) {
    //   return tag.group.split(",")[0];
    // }

    if (tag.fe_id === "auditor") {
      if (
        formState.inputs.raise &&
        !(
          formState.inputs.raise.value === "External Auditor" ||
          formState.inputs.raise.value === "External Vetters"
        )
      ) {
        return "";
      }
    }
    if (tag.fe_id === "issue_id") {
      return raisedByMap?.[formState.inputs?.raise?.value]
        ? raisedByMap[formState.inputs?.raise?.value] +
            (~~maxIssueId + 1)?.toString()?.padStart(5, "0")
        : (~~maxIssueId + 1)?.toString()?.padStart(5, "0");
    }

    if (storedData && storedData[tag.fe_id]) {
      return storedData[tag.fe_id];
    }
  };

  const conditionalDisabledHandler = (tag) => {
    if (tag.fe_id === "auditor") {
      if (
        formState.inputs.raise &&
        !(
          formState.inputs.raise.value === "External Auditor" ||
          formState.inputs.raise.value === "External Vetters"
        )
      ) {
        return true;
      }
    }
    if (
      tag.fe_id === "record" ||
      tag.fe_id === "date_closed" ||
      tag.fe_id === "approver"
    ) {
      if (
        formState.inputs.issue_status &&
        (formState.inputs.issue_status.value === "Open" ||
          formState.inputs.issue_status.value === "Past Due")
      ) {
        return true;
      }
    }
    if (tag.fe_id === "issue_id") {
      return true;
    }
    return false;
  };

  const issueDateCheck = () => {
    if (
      !formState.inputs.name.value ||
      formState.inputs.name.value.trim().length === 0
    ) {
      Toast("error", "Please enter a valid Issue Name.");
      return false;
    }

    if (
      formState.inputs.issue_date &&
      formState.inputs.issue_date.value &&
      formState.inputs.issue_date.value !== ""
    ) {
      if (
        formState.inputs.remediation_date &&
        formState.inputs.remediation_date.value &&
        formState.inputs.remediation_date.value !== "" &&
        formState.inputs.issue_date.value >
          formState.inputs.remediation_date.value
      ) {
        Toast(
          "error",
          "Issue Raised Date should be no later than Expected Resolution Date."
        );
        return false;
      }

      if (
        formState.inputs.date_closed &&
        formState.inputs.date_closed.value &&
        formState.inputs.date_closed.value !== ""
      ) {
        if (
          formState.inputs.issue_date.value > formState.inputs.date_closed.value
        ) {
          Toast(
            "error",
            "Issue Raised Date should be no later than Issue Closure Date."
          );
          return false;
        }
      }

      if (
        formState.inputs.revised_remediation_date &&
        formState.inputs.revised_remediation_date.value &&
        formState.inputs.revised_remediation_date.value !== ""
      ) {
        if (
          formState.inputs.issue_date.value >
          formState.inputs.revised_remediation_date.value
        ) {
          Toast(
            "error",
            "Issue Raised Date should be no later than Revised Expected Resolution Date."
          );
          return false;
        }
      }
    }

    if (
      formState.inputs.issue_status &&
      formState.inputs.issue_status.value === "Open"
    ) {
      if (
        (formState.inputs.approver &&
          formState.inputs.approver.value &&
          formState.inputs.approver.value !== "") ||
        (formState.inputs.date_closed &&
          formState.inputs.date_closed.value &&
          formState.inputs.date_closed.value !== "") ||
        (formState.inputs.record &&
          formState.inputs.record.value &&
          formState.inputs.record.value !== "")
      ) {
        Toast(
          "error",
          "For the Open Issues, Issue Closure Record, Issue Closure Approver, and Issue Closure Date should be empty."
        );
        return false;
      }
    }

    return true;
  };

  const fileTypeCheck = () => {
    if (Array.from(newDocument, (x) => x.file_type).includes("")) {
      Toast("error", "Please specify the File Type.");
      return false;
    }
    return true;
  };

  const handleClickOpen = (operation) => {
    if (!issueId.split("+")[0] || !issueId.split("+")[1]) {
      Toast("error", "Please select a model to edit.");
      return;
    }

    if (
      (formState.inputs.name && !formState.inputs.name.value) ||
      (formState.inputs.issue_id && !formState.inputs.issue_id.value) ||
      (formState.inputs.issue_status && !formState.inputs.issue_status.value) ||
      (formState.inputs.raise && !formState.inputs.raise?.value)
    ) {
      Toast("error", "Model Issue ID, Issue Name or Issue Status is empty.");
      return;
    }

    if (issueDateCheck() === false) {
      return;
    }

    if (
      !(
        formState.inputs.raise.value === "External Auditor" ||
        formState.inputs.raise.value === "External Vetters"
      )
    ) {
      if (
        formState.inputs.auditor.value &&
        formState.inputs.auditor.value !== ""
      ) {
        Toast(
          "error",
          "The information of External Auditors/Vetters and Raised by is mismatched."
        );
        return;
      }
    }

    if (fileTypeCheck() === false) {
      return;
    }

    setOpen(true);

    const formData = new FormData();
    formData.append("code", issueId.split("+")[0]);
    formData.append("version", issueId.split("+")[1]);
    formData.append("user_id", display_name);

    tag.forEach((item) => {
      if (item.fe_id !== "update_time") {
        formData.append(
          item.fe_id,
          formState.inputs[item.fe_id].value
            ? formState.inputs[item.fe_id].value
            : ""
        );
      }
    });

    let object = {};
    formData.forEach(function (value, key) {
      object[key] = value;
    });
    setFormDataObj(formData);
    let arr = [];
    let i = 1;
    for (const [key, value] of Object.entries(object)) {
      arr.push({ id: i, attribute: key, current: value });
      i++;
    }
    setTableData(arr);
    setButtonOperation(operation);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const issueSubmitHandler = async (event) => {
    event.preventDefault();
    const headers = new Headers();
    const bearer = token;
    headers.append("Authorization", bearer);
    const traceList = [
      [
        1,
        issueId.split("+")[0],
        issueId.split("+")[1],
        formState.inputs.issue_id.value,
        formState.inputs.issue_status.value,
        display_name,
        currentDate,
      ],
    ];

    try {
      setOpen(false);
      const formData = new FormData();
      formData.append("code", issueId.split("+")[0]);
      formData.append("version", issueId.split("+")[1]);
      formData.append("access_log", JSON.stringify(traceList));
      formData.append("form", "Issue");
      formData.append("user_id", display_name);
      formData.append("update_time", currentDate);
      newDocument.length > 0 &&
        formData.append(
          "file_description",
          JSON.stringify(Array.from(newDocument, (x) => x.file_description))
        );
      newDocument.length > 0 &&
        formData.append(
          "file_type",
          JSON.stringify(
            Array.from(newDocument, (x) => fileTypeInToOutHandler(x.file_type))
          )
        );

      tag.forEach((item) => {
        if (item.fe_element !== "autoFill" && item.fe_id !== "update_time") {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id].value
              ? formState.inputs[item.fe_id].value
              : ""
          );
        }
      });

      tag.forEach((item) => {
        if (item.fe_element === "autoFill") {
          formData.append(
            item.fe_id,
            formState.inputs[item.fe_id].value
              ? JSON.stringify(formState.inputs[item.fe_id].value)
              : JSON.stringify([])
          );
        }
      });

      if (files) {
        for (let i = 0; i < files.length; i++) {
          formData.append(`file${i + 1}`, files[i]);
        }
      }

      let inventoryValue = true;

      if (buttonOperation === "save") {
        inventoryValue = false;
      }

      const res = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/issue/new?inventory=${inventoryValue}`,
        "POST",
        formData,
        headers
      );

      if (res.error) {
        Toast("error", res);
        return;
      }

      Toast("success", "New Issue has been added successfully.");
      deleteDataFromIndexDB("New Issue");
      isPageDataNotChangedHandler("New", 0);
      //Redirect the user to a different page
      history.push("/issue");
    } catch (err) {
      toast(err, {
        autoClose: false,
        position: "top-center",
      });
    }
  };

  const fileChangeHandler = (id, value) => {
    setNewDocument(
      newDocument.map((doc, i) => {
        if ("file_" + doc.filename + "_type_" + (i + 1) === id) {
          doc.file_type = value;
        }
        if ("file_description_" + doc.filename === id) {
          doc.file_description = value;
        }
        return doc;
      })
    );
  };
  const uploadFileHandler = (file) => {
    if (Array.isArray(files) && files.map((i) => i.name).includes(file.name)) {
      Toast("error", "File already added.");
      return;
    }

    setFiles([...files, file]);
    setNewDocument([
      ...newDocument,
      {
        file_description: "",
        filename: file.name,
        update_time: new Date(),
        file_owner: display_name,
        files_status: "active",
        file_type: "",
      },
    ]);
  };

  const removeFileHandler = (file) => {
    setFiles(files.filter((doc) => doc.name !== file));
    setNewDocument(newDocument.filter((doc) => doc.filename !== file));
  };

  const optionException = (tag) => {
    if (tag.fe_id === "issue_status") {
      return status_option[0]
        .filter((item) => item[0] === "Issue")
        .map((item) => item[1]);
    } else {
      return undefined;
    }
  };

  const issueValidatorHandler = (tag) => {
    return [];
  };

  const issueErrorTextHandler = (tag) => {
    return "";
  };

  const issuePlaceHolderHandler = (tag) => {
    if (tag.fe_id === "issue_id") {
      return "OSFI00001 or MRM00001";
    } else {
      return "";
    }
  };
  const PopUpModalData = tableData
    .filter(
      (item) =>
        item.attribute !== "update_time" &&
        item.attribute !== "user_id" &&
        item.attribute !== "access_log"
    )
    .map((data) => {
      const label = tag2.find(
        ({ fe_id }) => fe_id === data.attribute
      )?.fe_label;
      return { ...data, attribute: label };
    });

  const isDataChanged = () => {
    let newTag = tag.filter((i) => i.fe_id !== "issue_id");
    for (let i = 0; i < newTag.length; i++) {
      if (
        !isTwoJsonValueEqual(
          newIssueInitialValueHandler(newTag[i]),
          formState.inputs?.[newTag[i].fe_id]?.value
        )
      ) {
        // console.log("data changed!");
        // console.log("label:", newTag[i].fe_label);
        // console.log("initial:", newIssueInitialValueHandler(newTag[i]));
        // console.log("input:", formState.inputs?.[newTag[i].fe_id]?.value);
        return true;
      }
    }

    if (newDocument?.length > 0) {
      //console.log("files exist");

      return true;
    }

    return false;
  };

  useEffect(() => {
    if (isDataChanged()) {
      isPageDataChangedHandler("New", 0);
    } else {
      isPageDataNotChangedHandler("New", 0);
    }
  }, [formState.inputs]);

  let element;
  element = (
    <div style={{ marginTop: "1.5rem" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            width: "650px",
            maxWidth: "900px",
            height: "100%",
            padding: "1rem 3rem 1rem 3rem",
          },
        }}
      >
        <DialogTitle>
          <PopUpTable tableData={PopUpModalData} isNew={true} />
        </DialogTitle>
        <DialogActions>
          <Button onClick={issueSubmitHandler}>Confirm</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <form style={{ margin: "0 8rem 2rem 8rem" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={0.5} columnSpacing={5}>
            {tag
              .sort((a, b) => a.fe_order - b.fe_order)
              .map((tag, i) => (
                <Grid
                  item
                  xs={12}
                  md={tag.fe_grid === 12 ? 12 : 6}
                  lg={tag.fe_grid}
                  key={i}
                >
                  <InputNew
                    id={tag.fe_id}
                    type={tag.fe_type}
                    label={tag.fe_label}
                    element={tag.fe_element}
                    onInput={inputHandler}
                    initialValid={true}
                    initialValue={newIssueInitialValueHandler(tag)}
                    validators={issueValidatorHandler(tag)}
                    errorText={issueErrorTextHandler(tag)}
                    placeholder={issuePlaceHolderHandler(tag)}
                    onBlur
                    origin_options={tag}
                    onRecord={() => {}}
                    clean_options={optionException(tag)}
                    disabled={conditionalDisabledHandler(tag)}
                    options={
                      userGroup
                        ? userGroup.map((user) => ({
                            label: user,
                            value: user,
                          }))
                        : []
                    }
                    setScrollTop={setScrollTop}
                    pageElement={pageElement}
                  />
                </Grid>
              ))}

            <Grid item xs={12}>
              <div style={{ padding: "0.8rem" }}>
                <FileLoad
                  uploadHandler={uploadFileHandler}
                  removeHandler={removeFileHandler}
                  newDocument={newDocument}
                  form="model"
                  onInput={inputHandler}
                  fileChangeHandler={fileChangeHandler}
                  fileOption={fileOption}
                />
              </div>
            </Grid>
            {(!tag || isLoading) && (
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <CircularProgress />
              </Grid>
            )}
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "2rem",
                }}
              >
                <Button
                  onClick={() => handleClickOpen("publish")}
                  variant="outlined"
                  className="editButton"
                  disabled={access.includes("Update") ? false : true}
                >
                  Submit
                </Button>

                {
                  <Button
                    onClick={() => handleClickOpen("save")}
                    variant="outlined"
                    className="editButton"
                    disabled={access.includes("Update") ? false : true}
                  >
                    Save
                  </Button>
                }
              </div>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  );

  return <Fragment>{element}</Fragment>;
};

export default NewIssue;
