import { useState, useEffect, useContext } from "react";

import TaskTable from "./TaskTable";
import { CircularProgress } from "@mui/material";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { UserContext } from "../../context/user-context";

import { clearIndexDB } from "../../components/CustomComponents/IndexedDBTemplate";

const Task = () => {
  const [taskData, setTaskData] = useState();
  const { isLoading, sendRequest } = useHttpClient();

  const { user } = useContext(UserContext);

  let group;

  //user_group = ['admin', 'user', 'guest']
  //group = "user_group=admin&user_group=user&user_group=guest"

  if (user && user.user_group) {
    group = user.user_group.map((item) => `user_group=${item}`).join("&");
  } else {
    group = "";
  }

  useEffect(() => {
    clearIndexDB();
    if (!user) return <div>NO USER</div>;
    const fetchData = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/task?user_id=${
            user.display_name
          }&${group}&business_unit=${Object.keys(
            user.business_unit
          )}&permission=${Object.values(user.business_unit)}`
        );
        setTaskData(response.task);
      } catch (err) {
      }
    };
    if(user) {
      fetchData();
    }
    
  }, []);

  if (!user) {
    return <div>NO USER</div>;
  }

  if (!taskData || isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (taskData.length === 0) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
 
        }}
      >
        {/*<CircularProgress />*/}
        No Task
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex", gap: "15rem", justifyContent: "center", marginTop: "2rem" }}>
        <h4>Task List Direct</h4>
      </div>
      <hr />
      <div style={{padding: "0 10rem 3rem 10rem"}}>
        {taskData.map((task, i) => (
          <div key={i} style={{ margin: "2rem 5rem 1rem 5rem" }}>
            <h5 style={{ marginBottom: "0.5rem" }}>{`${Object.keys(
              task
            )} Tasks`}</h5>
            <TaskTable
              tableData={Object.values(task)?.[0]?.sort((b, a) => new Date(a.update_time) - new Date(b.update_time))?.map((i, index) => {
                return {
                  ...i,
                  id: index,
                };
              })}
              form={Object.keys(task)[0]}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Task;
