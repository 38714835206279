import React, { Fragment, useState, useEffect } from "react";
import { gridClasses } from "@mui/x-data-grid";
import { Button, CircularProgress } from "@mui/material";
import { useHistory } from "react-router-dom";

import WrapTextTable, {
  renderCellExpand,
  CustomToolbar,
} from "../../components/CustomComponents/WrapTextTable";

const HistoryTable = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [pageSize, setPageSize] = useState(50);

  useEffect(() => {
    setRows(props.tableData);
    setIsLoading(false);
  }, [props.tableData]);

  const columns = props.isDetails
    ? [
        {
          field: "id",
          headerName: "ID",
          headerAlign: "center",
          renderCell: renderCellExpand,
          align: "center",
          flex: 0.3,
        },
        {
          field: "table",
          headerName: "Table",
          headerAlign: "center",
          renderCell: renderCellExpand,
          align: "center",
          flex: 1,
        },
        {
          field: "attribute",
          headerName: "Attribute",
          flex: 1,
          headerAlign: "center",
          renderCell: renderCellExpand,
          align: "center",
        },
        {
          field: "previous",
          headerName: "Previous",
          flex: 1,
          headerAlign: "center",
          renderCell: renderCellExpand,
          align: "center",
        },
        {
          field: "current",
          headerName: "Current",
          flex: 1,
          headerAlign: "center",
          renderCell: renderCellExpand,
          align: "center",
        },
      ]
    : [
        {
          field: "id",
          headerName: "ID",
          headerAlign: "center",
          align: "center",
          flex: 0.3,
        },
        {
          field: "edit",
          headerName: "Details",
          align: "center",
          headerAlign: "center",
          flex: 0.5,
          renderCell: (cellValues) => {
            return (
              <Fragment>
                <Button
                  variant="outlined"
                  onClick={() => {
                    history.push(
                      `/history/${cellValues.row.form}+${
                        cellValues.row.indicator
                      }+${cellValues.row.version}+${
                        cellValues.row.form === "Validation"
                          ? cellValues.row.validation_id
                          : cellValues.row.issue_id
                      }+${cellValues.row.sequence}`
                    );
                  }}
                  style={{ border: "none" }}
                >
                  Details
                </Button>
              </Fragment>
            );
          },
        },

        {
          field: "user_id",
          headerName: "User ID",
          width: 300,
          headerAlign: "center",
          align: "center",
          flex: 1,
        },
        {
          field: "update_time",
          headerName: "Update Time",
          width: 300,
          headerAlign: "center",
          align: "center",
          flex: 1,
        },
        {
          field: "form",
          headerName: "Operation",
          width: 200,
          headerAlign: "center",
          align: "center",
          flex: 1,
        },
      ];

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertToUppercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toUpperCase(),
          email: row.email.toUpperCase(),
          title: row.title.toUpperCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const convertToLowercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toLowerCase(),
          email: row.email.toLowerCase(),
          title: row.title.toLowerCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  // different color in even line and hover effect
  const datagridSx = {
    [`& .${gridClasses.row}.even`]: {
      backgroundColor: "rgb(250,250,250)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgb(221,225,255)",
      },
    },
  };

  return (
    <Fragment>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <div>
          <div style={{ width: "100%" }}>
            <WrapTextTable
              autoHeight
              rowHeight={30}
              sx={datagridSx}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
              }
              columns={columns}
              rows={rows}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              components={{ Toolbar: () => CustomToolbar(props.fileName) }}
              density="compact"
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={props.isDetails ? [1, 2, 3] : [50, 100, 200]}
              pagination
            />
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default HistoryTable;
