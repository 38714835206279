import { useState, useEffect } from "react";

import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import DashboardLayout from "./DashboardLayout";
import {
  CircularProgress,
} from "@mui/material";
const chartLayout = {grid: [
  {
    chart_type: "card",
    title: "Title A",
    chartData: [200, 0.5, 30],
    chartLabels: [["Jan", "Feb", "Mar", "Apr"], ["Jan", "Feb", "Mar", "Apr"], ["Jan", "Feb", "Mar", "Apr"]],
    title: "Title 1",
    order: 1,
    grid: 6,
    height: 500,
  },
  {
    chart_type: "card",
    title: "Title B",
    chartData: [200, 0.5, 30],
    chartLabels: ["Jan", "Feb", "Mar", "Apr"],
    title: "Title 1",
    order: 1,
    grid: 6,
    height: 500,
  },
 
  
  {
    chart_type: "table",
    title: "Analytics Report",
    chartData: [1, 2, 3, 4],
    chartLabels: ["Jan", "Feb", "Mar", "Apr"],
    title: "Title 7",
    order: 7,
    grid: 12,
    height: 500,
  },
]};
// ==============================|| DASHBOARD - DEFAULT ||============================== //

const DashboardDefault = () => {
  const { error, sendRequest, clearError } = useHttpClient();
  const [layoutData, setLayoutData] = useState(chartLayout);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
        try{
            const response = await sendRequest(
                `${process.env.REACT_APP_BACKEND_URL}/summary/default`
            )
            setIsLoading(false);
            //setLayoutData(response)
        } catch (error) {
        }
    };
    fetchData();
  }, [sendRequest]);

  if(isLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center", marginTop: "200px" }}>
      <CircularProgress />
    </div>
    )
  }
  return (
    <DashboardLayout layoutData={[]}/>
  );
};

export default DashboardDefault;
