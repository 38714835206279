import { useState, useEffect, useContext } from "react";

import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Button,
} from "@mui/material";
import Toast from "../../components/Utils/Toast";

import InputNew from "../../components/Content/FormElements/InputNew";

import { UserContext } from "../../context/user-context";
import { CircularProgress } from "@mui/material";

import "./User.css";

const User = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { token } = useContext(UserContext);
  const [formState, inputHandler] = useForm({}, false);
  const [configData, setConfigData] = useState();
  const [selectionData, setSelectionData] = useState();
  const [optionData, setOptionData] = useState([]);
  const [optionSelected, setOptionSelected] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [selectionFiltered, setSelectionFiltered] = useState("");

  const handleSelectionClick = (event, index, id) => {
    setOptionSelected(id);
    setSelectedIndex(id);
  };

  useEffect(() => {
    const fetchData = async () => {
      const headers = new Headers();
      const bearer = token;
      headers.append("Authorization", bearer);
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/graphcall`,
          "GET",
          null,
          headers
        );
        setConfigData(response);

        if (response.user) {
          setSelectionData(response.user);
        } else if (response.error) {
          Toast("error", response.error.message);
        } else {
          setSelectionData([]);
          console.warning(response);
        }
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, token, isChanged]);

  useEffect(() => {
    optionSelected &&
      selectionData &&
      setOptionData(
        selectionData.filter((item) => item.id === optionSelected)[0]
      );
  }, [optionSelected, configData, selectionData]);

  useEffect(() => {
    formState.inputs.filter_option?.value &&
      setSelectionFiltered(formState.inputs.filter_option?.value);
  }, [formState.inputs?.filter_option?.value]);

  const deleteUserHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/user/delete/${optionData?.id}`,
        "DELETE",
        null,
        { "Content-Type": "application/json", Authorization: token }
      );
      if (res.state === "success") {
        alert("User Deleted.");
        setIsChanged((prev) => !prev);
      } else {
        alert("User Deletion Failed.");
      }
    } catch (err) {}
  };

  const saveUserHandler = async (e) => {
    e.preventDefault();
    try {
      const body = {
        id: optionData ? optionData?.id : "",
        business_phones: [
          formState.inputs.userConfig_business_phones.value || "",
        ],
        display_name: formState.inputs.userConfig_display_name.value || "",
        surname: formState.inputs.userConfig_surname.value || "",
        given_name: formState.inputs.userConfig_given_name.value || "",
        job_title: formState.inputs.userConfig_job_title.value || "",
        email: formState.inputs.userConfig_email.value || "",
        mobile_phone: formState.inputs.userConfig_job_title.value || "",
        office_location:
          formState.inputs.userConfig_office_location.value || "",
        preferred_language:
          configData?.language[
            `${formState.inputs.userConfig_preferred_language.value}`
          ] ||
          formState.inputs.userConfig_preferred_language.value ||
          "",
        principal_name: optionData?.principal_name || "",
        validator: formState.inputs.userConfig_validator.value || "",
        user_status: formState.inputs.userConfig_user_status.value || "",
        user_group: formState.inputs.userConfig_user_group.value || "",
        business_unit: formState.inputs.userConfig_business_unit.value || "",
      };
      const res = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + `/user/update`,
        "PATCH",
        JSON.stringify(body),
        { "Content-Type": "application/json", Authorization: token }
      );
      if (res.error) {
        Toast(
          "error",
          res?.error?.details?.[0]?.message || "User Save Failed."
        );
        return;
      } else {
        Toast("success", "User Saved.");
      }
      setIsChanged((prev) => !prev);
    } catch (err) {}
  };

  if (!configData || !selectionData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "85vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>User Configuration</h4>
      </div>
      <hr />
      <div className="option-config" style={{ margin: "2rem 9rem 2rem 9rem" }}>
        <div className="user_user_group">
          <div
            style={{
              fontSize: "1rem",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            Users
          </div>
          <div className="selection">
            <List
              sx={{
                width: "100%",
                position: "relative",
                overflow: "auto",
                height: 550,
                "& ul": { padding: "1rem" },
              }}
            >
              <div
                style={{
                  position: "sticky",
                  zIndex: 999,
                  marginBottom: "0.5rem",
                  top: 0,
                }}
              >
                <InputNew
                  id="filter_option"
                  element="input"
                  validators={[]}
                  onInput={inputHandler}
                  placeholder="Search"
                />
              </div>
              {selectionData &&
                selectionData
                  .sort((a, b) => a.display_name.localeCompare(b.display_name))
                  .map((selection, i) => {
                    if (selectionFiltered && selectionFiltered.length > 0) {
                      if (
                        selection?.display_name
                          .toLowerCase()
                          .includes(selectionFiltered.toLowerCase())
                      ) {
                        return (
                          <ListItem disablePadding key={i}>
                            <ListItemButton
                              selected={selectedIndex === selection?.id}
                              onClick={(event) => {
                                handleSelectionClick(
                                  event,
                                  selection?.display_name,
                                  selection?.id
                                );
                              }}
                            >
                              <ListItemText
                                primary={selection?.display_name}
                                key={i}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      }
                    } else {
                      return (
                        <ListItem disablePadding key={i}>
                          <ListItemButton
                            selected={selectedIndex === selection?.id}
                            onClick={(event) => {
                              handleSelectionClick(
                                event,
                                selection?.display_name,
                                selection?.id
                              );
                            }}
                          >
                            <ListItemText
                              primary={selection?.display_name}
                              key={i}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    }
                  })}
            </List>
          </div>
        </div>
        <div className="user_attribute">
          <div
            style={{
              fontSize: "16px",
              display: "flex",
              justifyContent: "center",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            User Attributes
          </div>
          <div className="selection">
            <List
              sx={{
                width: "100%",
                overflow: "auto",
                height: 550,
                "& ul": { padding: 0 },
              }}
            >
              {" "}
              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              )}
              {!isLoading && configData && optionData && (
                <div>
                  <InputNew
                    id="userConfig_business_phones"
                    label="Business Phones"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.business_phones}
                    disabled
                  />
                  <InputNew
                    id="userConfig_display_name"
                    label="Display Name"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.display_name}
                    disabled
                  />
                  <InputNew
                    id="userConfig_surname"
                    label="Surname"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.surname}
                    disabled
                  />
                  <InputNew
                    id="userConfig_given_name"
                    label="Given Name"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.given_name}
                    disabled
                  />
                  <InputNew
                    id="userConfig_job_title"
                    label="Job Title"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.job_title}
                    disabled
                  />
                  <InputNew
                    id="userConfig_email"
                    label="E-mail"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.email}
                  />
                  <InputNew
                    id="userConfig_office_location"
                    label="Office Location"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.office_location}
                    disabled
                  />
                  <InputNew
                    id="userConfig_preferred_language"
                    label="Preferred Language"
                    element="select"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={Object.keys(configData?.language).find(
                      (key) =>
                        configData?.language[key] ===
                        optionData?.preferred_language
                    )}
                    clean_options={Object.keys(configData?.language)}
                    disabled
                  />
                  <InputNew
                    id="userConfig_principal_name"
                    label="Principal Name"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.principal_name}
                    disabled
                  />
                  <InputNew
                    id="userConfig_validator"
                    label="Validator"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.validator}
                  />
                  <InputNew
                    id="userConfig_user_status"
                    label="User Status"
                    element="select"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.user_status}
                    origin_options={
                      props.tag.filter(
                        (i) =>
                          i.db_tablename === "users" &&
                          i.fe_id === "user_status"
                      )[0]
                    }
                  />
                  <InputNew
                    id="userConfig_user_group"
                    label="User Group"
                    element="select"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.user_group}
                    origin_options={
                      props.tag.filter(
                        (i) =>
                          i.db_tablename === "users" && i.fe_id === "user_group"
                      )[0]
                    }
                  />
                  <InputNew
                    id="userConfig_business_unit"
                    label="Business Unit"
                    element="select"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.business_unit}
                    origin_options={
                      props.tag.filter(
                        (i) =>
                          i.db_tablename === "users" &&
                          i.fe_id === "business_unit"
                      )[0]
                    }
                  />
                  {/*<InputNew
                    id="userConfig_last_login"
                    label="Last Login"
                    element="input"
                    validators={[]}
                    onInput={inputHandler}
                    onBlur
                    initialValue={optionData?.last_login}
                    disabled
                  />*/}
                </div>
              )}
            </List>
          </div>
        </div>
      </div>
      <div className="div4-init">
        <Button
          variant="outlined"
          className="editButton"
          onClick={saveUserHandler}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          className="editButton"
          onClick={deleteUserHandler}
        >
          Delete
        </Button>
      </div>
    </div>
  );
};

export default User;
