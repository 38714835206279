import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/user-context";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import {
  Button,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";

import { toast } from "react-toastify";
import { useForm } from "../../components/Utils/hooks/form-hook";
import InputNew from "../../components/Content/FormElements/InputNew";

import BuAccessTable from "./BuAccessTable2";
import GroupAccessTable from "./GroupAccessTable2";
import NavPermissionTable from "./NavPermissionTable2";
import DocumentPermission from "./DocumentPermission2";

const AccessConfig = (props) => {
  const [buAccessData, setBuAccessData] = useState([]);
  const [groupAccessData, setGroupAccessData] = useState([]);
  const [navPermissionData, setNavPermissionData] = useState([]);
  const [docPermissionData, setDocPermissionData] = useState([]);
  const [groupAccessOperation, setGroupAccessOperation] = useState({});
  const [submitBuAccessData, setSubmitBuAccessData] = useState([]);
  const [submitGroupAccessData, setSubmitGroupAccessData] = useState([]);
  const [submitNavPermissionData, setSubmitNavPermissionData] = useState([]);
  const [submitDocPermissionData, setSubmitDocPermissionData] = useState([]);
  const [groupOption, setGroupOption] = useState([]);
  const [taskListValue, setTaskListValue] = useState([]);
  const [taskPermissionValue, setTaskPermissionValue] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const { sendRequest } = useHttpClient();
  const [formState, inputHandler] = useForm({}, false);
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const { user, tabNameOutToInHandler, tabNameInToOutHandler } =
    useContext(UserContext);
  const { display_name } = user || {};

  const taskPermissionOption = props.tag?.filter(
    (i) => i.db_tablename === "task access" && i.fe_id === "task_permission"
  )[0];
  const taskListOption = props.tag?.filter(
    (i) => i.db_tablename === "task access" && i.fe_id === "task_list"
  )[0];

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsTableLoading(true);
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/access`
        );
        setIsTableLoading(false);
        setBuAccessData(response["bu access"]);
        setSubmitBuAccessData(response["bu access"]);
        setGroupAccessData(response["group access"]);
        setSubmitGroupAccessData(response["group access"]);
        setNavPermissionData(response["menu access"]);
        setSubmitNavPermissionData(response["menu access"]);
        setDocPermissionData(response["document access"]);
        setSubmitDocPermissionData(response["document access"]);
        setGroupAccessOperation(response.operation);
        setGroupOption(response.group);
        setTaskPermissionValue(response["task access"]?.task_permission);
        setTaskListValue(response["task access"]?.task_list);
      } catch (err) {}
    };
    fetchData();
  }, [sendRequest, isChanged]);

  const buAccessChangedHandler = (data) => {
    setSubmitBuAccessData(data);
  };

  const groupAccessDataChangedHandler = (data) => {
    setSubmitGroupAccessData(data);
  };

  const navPermissionDataChangedHandler = (data) => {
    setSubmitNavPermissionData(data);
  };

  const docPermissionDataChangedHandler = (data) => {
    setSubmitDocPermissionData(data);
  };

  const submitAccessConfigHandler = async (e) => {
    e.preventDefault();
    const accessConfigBody = {
      "bu access": submitBuAccessData.map((buData) => ({
        business_unit: buData.business_unit,
        permission: buData.permission,
        update_time: currentDate,
        user_id: display_name,
      })),
      "group access": submitGroupAccessData.map((gaData) => ({
        form: tabNameInToOutHandler(gaData.form),
        user_group: gaData.user_group,
        operation: gaData.operation,
        access: gaData.access,
        user_id: display_name,
        update_time: currentDate,
      })),
      "menu access": submitNavPermissionData.map((navData) => ({
        menu: tabNameInToOutHandler(navData.menu),
        user_group: navData.user_group,
        menu_permission: navData.menu_permission,
      })),
      "document access": submitDocPermissionData.map((docData) => ({
        form: tabNameInToOutHandler(docData.form),
        user_group: docData.user_group,
      })),
      "task access": {
        task_list: formState.inputs?.task_list?.value || "",
        task_permission: formState.inputs?.task_permission?.value || "",
      },
    };
    try {
      setIsLoading(true);
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/access/update`,
        "PUT",
        JSON.stringify(accessConfigBody),
        {
          "Content-Type": "application/json",
        }
      );
      setIsLoading(false);

      if (response.group && response.operation) {
        toast.success("Successfully Updated.", {
          autoClose: true,
          position: "top-center",
        });
        setIsChanged(!isChanged);
        props.isUserChanged();
      } else {
        toast.error(`Failed to Update: ${response}`, {
          autoClose: false,
          position: "top-center",
        });
      }
    } catch (err) {}
  };

  if (isTableLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "10%",
          minHeight: "85vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>Access Configuration</h4>
      </div>
      <hr />
      <div style={{ margin: "2rem 8rem 2rem 8rem" }}>
        <Accordion
          sx={{
            color: "inherit",

            borderRadius: "0 !important",

            borderTop: "none",

            borderRight: "none",

            borderLeft: "none",

            borderBottom: "none",
          }}
        >
          <AccordionSummary
            aria-controls="bu-permission"
            id="bu-permission"
            expandIcon={<ExpandMoreIcon />}
            sx={{
              "& .MuiAccordionSummary-content": {
                flexGrow: 0,
                justifyContent: "space-between",
                width: "20%",
              },
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
              Business Unit Permission
            </Typography>
            <Typography
              sx={{ fontWeight: 600, fontSize: 18, color: "#CB3694" }}
            >
              ({buAccessData.length})
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <BuAccessTable
              BuAccessData={buAccessData || []}
              buAccessDataChanged={buAccessChangedHandler}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            color: "inherit",

            borderRadius: "0 !important",

            borderTop: "none",

            borderRight: "none",

            borderLeft: "none",

            borderBottom: "none",
          }}
        >
          <AccordionSummary
            aria-controls="user-group-permission"
            id="user-group-permission"
            expandIcon={<ExpandMoreIcon />}
            sx={{
              "& .MuiAccordionSummary-content": {
                flexGrow: 0,
                justifyContent: "space-between",
                width: "20%",
              },
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
              User Group Permission
            </Typography>
            <Typography
              sx={{ fontWeight: 600, fontSize: 18, color: "#CB3694" }}
            >
              ({groupAccessData.length})
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <GroupAccessTable
              groupAccessData={groupAccessData || []}
              groupOperation={groupAccessOperation || {}}
              groupOption={groupOption || []}
              groupAccessDataChanged={groupAccessDataChangedHandler}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            color: "inherit",

            borderRadius: "0 !important",

            borderTop: "none",

            borderRight: "none",

            borderLeft: "none",

            borderBottom: "none",
          }}
        >
          <AccordionSummary
            aria-controls="menu-permission"
            id="menu-permission"
            expandIcon={<ExpandMoreIcon />}
            sx={{
              "& .MuiAccordionSummary-content": {
                flexGrow: 0,
                justifyContent: "space-between",
                width: "20%",
              },
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
              Menu Permission
            </Typography>
            <Typography
              sx={{ fontWeight: 600, fontSize: 18, color: "#CB3694" }}
            >
              ({navPermissionData.length})
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <NavPermissionTable
              navPermissionData={navPermissionData || []}
              navPermissionDataChanged={navPermissionDataChangedHandler}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            color: "inherit",

            borderRadius: "0 !important",

            borderTop: "none",

            borderRight: "none",

            borderLeft: "none",

            borderBottom: "none",
          }}
        >
          <AccordionSummary
            aria-controls="document-permission"
            id="document-permission"
            expandIcon={<ExpandMoreIcon />}
            sx={{
              "& .MuiAccordionSummary-content": {
                flexGrow: 0,
                justifyContent: "space-between",
                width: "20%",
              },
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
              Document Permission
            </Typography>
            <Typography
              sx={{ fontWeight: 600, fontSize: 18, color: "#CB3694" }}
            >
              ({docPermissionData.length})
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <DocumentPermission
              docPermissionData={docPermissionData || []}
              docPermissionDataChanged={docPermissionDataChangedHandler}
            />
          </AccordionDetails>
        </Accordion>

        <Accordion
          sx={{
            color: "inherit",

            borderRadius: "0 !important",

            borderTop: "none",

            borderRight: "none",

            borderLeft: "none",

            borderBottom: "none",
          }}
        >
          <AccordionSummary
            aria-controls="task-permission"
            id="task-permission"
            expandIcon={<ExpandMoreIcon />}
            sx={{
              "& .MuiAccordionSummary-content": {
                flexGrow: 0,
                justifyContent: "space-between",
                width: "20%",
              },
            }}
          >
            <Typography sx={{ fontWeight: 600, fontSize: 18 }}>
              Task Permission
            </Typography>
            <Typography
              sx={{ fontWeight: 600, fontSize: 18, color: "#CB3694" }}
            >
              (1)
            </Typography>
          </AccordionSummary>

          <AccordionDetails>
            <div
              style={{
                width: "100%",

                marginTop: "2rem",

                display: "flex",

                justifyContent: "center",
              }}
            >
              <table
                className="table table-bordered"
                style={{
                  tableLayout: "fixed",

                  borderCollapse: "separate",

                  borderSpacing: "0px",
                }}
              >
                <thead className="MuiDataGrid-columnHeaderTitleContainerContent">
                  <tr>
                    <th
                      className="css-1jbbcbn-MuiDataGrid-columnHeaderTitle"
                      style={{
                        fontSize: "14px",

                        border: "1px solid rgba(224, 224, 224, 1)",

                        borderRight: "none",

                        borderTopLeftRadius: "5px",

                        textAlign: "center",
                      }}
                    >
                      Task List
                    </th>

                    <th
                      className="css-1jbbcbn-MuiDataGrid-columnHeaderTitle"
                      style={{
                        fontSize: "14px",

                        border: "1px solid rgba(224, 224, 224, 1)",

                        borderTopRightRadius: "5px",

                        textAlign: "center",
                      }}
                    >
                      Task Permission
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td
                      className="MuiDataGrid-cell"
                      style={{
                        border: "1px solid rgba(224, 224, 224, 1)",

                        borderRight: "none",

                        borderTop: "none",

                        borderBottomLeftRadius: "5px",
                      }}
                    >
                      <div
                        className="ant-select ant-select-borderless ant-select-single ant-select-show-arrow ant-select-show-search"
                        style={{ width: "100%" }}
                      >
                        <InputNew
                          id="task_list"
                          type="text"
                          label=""
                          element="select"
                          onInput={inputHandler}
                          initialValue={taskListValue}
                          validators={[]}
                          onRecord={() => {}}
                          origin_options={taskListOption ? taskListOption : []}
                        />
                      </div>
                    </td>

                    <td
                      style={{
                        border: "1px solid rgba(224, 224, 224, 1)",

                        borderTop: "none",

                        borderBottomRightRadius: "5px",
                      }}
                    >
                      <InputNew
                        id="task_permission"
                        type="text"
                        label=""
                        element="select"
                        onInput={inputHandler}
                        initialValue={taskPermissionValue}
                        validators={[]}
                        onRecord={() => {}}
                        origin_options={
                          taskPermissionOption ? taskPermissionOption : []
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </AccordionDetails>
        </Accordion>

        {(isLoading || !buAccessData) && (
          <div
            style={{
              display: "flex",

              justifyContent: "center",

              alignItems: "center",

              height: "50%",
            }}
          >
            <CircularProgress />
          </div>
        )}

        <div
          style={{
            display: "flex",

            justifyContent: "center",

            marginTop: "1rem",
          }}
        >
          <Button
            variant="outlined"
            className="editButton"
            onClick={submitAccessConfigHandler}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AccessConfig;
