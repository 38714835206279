import { useState, useEffect, forwardRef } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import Routes from "./routes/index";
import UnAuthRoute from "./routes/unAuthRoute";
import Toast from "./components/Utils/Toast";
/*To do:
  Lazy Loading
*/
import TopBar from "./layout/TopBar/TopBar";
import Footer from "./layout/Footer/Footer";

import {
  CircularProgress,
  Typography,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";

import ThemeProvider from "./theme/ThemeProvider";
import "./App.css";

import { UserContext } from "./context/user-context";

import { useHttpClient } from "./components/Utils/hooks/http-hook";

import { BrowserRouter as Router, useLocation } from "react-router-dom";

import { clearIndexDB } from "./components/CustomComponents/IndexedDBTemplate";

import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";

import { loginRequest } from "./authConfig";

import { io } from "socket.io-client";

const socket = io(process.env.REACT_APP_BACKEND_URL, {
  closeOnBeforeunload: false,
});

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function AuthorizedContent() {
  const { isLoading, sendRequest } = useHttpClient();
  const { instance, accounts } = useMsal();
  const [tree, setTree] = useState();
  const [options, setOptions] = useState();
  const [tag, setTag] = useState();
  const [nameConvention, setNameConvention] = useState([]);
  const [record, setRecord] = useState("");
  const [documents, setDocuments] = useState("");
  const [isTreeChanged, setIsTreeChanged] = useState(false);
  const [isUserChanged, setIsUserChanged] = useState(false);
  const [user, setUser] = useState([]);
  // const [graphData, setGraphData] = useState([]);
  const [token, setToken] = useState("");
  const { pathname } = useLocation();
  const history = useHistory();
  const [userGroup, setUserGroup] = useState([]);
  const [formMapping, setFormMapping] = useState([]);
  const [fileTypeMapping, setFileTypeMapping] = useState([]);
  const [macFileTypeMapping, setMacFileTypeMapping] = useState([]);
  const [allModelData, setAllModelData] = useState([]);
  const [currentURL, setCurrentURL] = useState(pathname);
  const [form, setForm] = useState("");
  const [param, setParam] = useState("");

  const [currentPosition, setCurrentPosition] = useState({
    xRate: 500,
    yRate: 100,
  });

  const [open, setOpen] = useState(false);
  const [pendingNode, setPendingNode] = useState(null);
  const [nodeSelected, setNodeSelected] = useState(null);
  const [isSaveExecuted, setIsSaveExecuted] = useState(false);
  const [saveAction, setSaveAction] = useState(false);
  const [dataChangedArr, setDataChangedArr] = useState([]);
  const [isSaveSuspense, setIsSaveSuspense] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [navPage, setNavPage] = useState("");
  const [nextLocation, setNextLocation] = useState("");
  const [isTreeClicked, setIsTreeClicked] = useState(false);
  const [expanded, setExpanded] = useState([]);
  const [treeHeight, setTreeHeight] = useState(0);

  const treeHeightHandler = (headerHeight, footerHeight) => {
    setTreeHeight(100 - headerHeight - footerHeight);
  };

  const nodeExpandHandler = (e, nodes) => {
    setExpanded(nodes);
  };

  const handleDialogClickOpen = () => {
    setOpen(true);
  };

  const handleDialogClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const setNavigationHandler = (path) => {
    setNextLocation(path);
  };

  const setFormHandler = (form) => {
    setForm(form);
  };
  const setParamHandler = (param) => {
    setParam(param);
  };

  const modelRecord = `${record.split("+")[1]}+${record.split("+")[2]}`;

  let fileType;

  if (tag) {
    fileType = tag
      .filter((item) => item.db_tablename === "file type")[0]
      ?.group.split(",")
      .map((i) => "." + i)
      .join(",");
  }

  useEffect(() => {
    const RequestProfileData = async () => {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      const response = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      setToken(response.accessToken);
      const headers = new Headers();
      const bearer = response.accessToken;
      headers.append("Authorization", bearer);
      // Silently acquires an access token which is then attached to a request for MS Graph data
      const res = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/me`,
        "GET",
        null,
        headers
      );
      if (res.error) {
        Toast("error", res.error);
        return;
      }
      if (res.user) {
        setUser(res.user);
      }
    };
    if (pathname !== "/init" && pathname !== "/executor") {
      RequestProfileData();
    }
  }, [accounts, instance, sendRequest, isUserChanged]);

  let group;

  if (user && user.user_group) {
    group = user.user_group.map((item) => `user_group=${item}`).join("&");
  } else {
    group = "user_group=";
  }

  let isTaskPageEnable = false;
  if (user && user["task access"]?.task_list === "Enable") {
    isTaskPageEnable = true;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/init?user_id=${
            user.display_name
          }&${group}&business_unit=${Object.keys(
            user.business_unit
          )}&permission=${Object.values(user.business_unit)}`
        );
        const result = response;
        // (result);
        const optionObj = {};
        result.tag
          .filter((t) => t[3] !== "")
          .forEach((record) => {
            optionObj[record[1]] = record[3];
          });
        setOptions(optionObj);
        setTag(result.tag);
        setTree(result.tree);
        setNameConvention(result["name convention"]);
        setUserGroup(result.user);
        setFormMapping(result.menu);
        setFileTypeMapping(result["file type map"]);
        setMacFileTypeMapping(result["mac file type map"]);
        setAllModelData(result["all model"]);
        // setRecord(
        //   `Model+${result?.tree?.[0].Model?.[0]?.code}+${
        //     result.tree[0]?.Model?.[0].version?.[0]?.[0]
        //   }${result.tree[0]?.Model?.[0].version?.[0]?.[1] === 0 ? "+0" : ""}`
        // );
        // setNodeSelected(
        //   `Model+${result?.tree?.[0].Model?.[0]?.code}+${
        //     result.tree[0]?.Model?.[0].version?.[0]?.[0]
        //   }${result.tree[0]?.Model?.[0].version?.[0]?.[1] === 0 ? "+0" : ""}`
        // );
        // setExpanded([`Model+${result?.tree?.[0]?.Model?.[0]?.code}`, "Model"]);
      } catch (error) {}
    };

    if (
      pathname !== "/init" &&
      pathname !== "/executor" &&
      pathname !== "/user/root"
    ) {
      fetchData();
    }
  }, [isTreeChanged, sendRequest, user?.display_name]);

  useEffect(() => {
    if (
      pathname.split("/")?.[2] &&
      pathname.split("/")?.[2] !== "config" &&
      pathname.split("/")?.[2] !== "upload" &&
      pathname.split("/")?.[2] !== "download" &&
      !pathname.split("/")?.[2]?.includes("report") &&
      !pathname.split("/")?.[2]?.includes("history")
    ) {
      if (pathname.split("/")?.[1] === "opm") {
        setNodeSelected(`Model+${pathname.split("/")?.[2]?.split("+")?.[0]}`);
        // setRecord(`Model+${pathname.split("/")?.[2]}`);
        setExpanded([
          `Model+${pathname.split("/")?.[2]?.split("+")?.[0]}`,
          "Model",
        ]);
      } else if (pathname.split("/")?.[1] === "mac") {
        setRecord(
          `MAC+${pathname.split("/")?.[2]?.split("+")?.[0]}+${
            pathname.split("/")?.[2]?.split("+")?.[1]
          }`
        );
        setExpanded([
          `Model+${pathname.split("/")?.[2]?.split("+")?.[0]}`,
          pathname.split("/")?.[2]?.split("+")?.[0],
        ]);
      } else if (pathname === "/model/new/") {
        // console.log(7777);
        // setNodeSelected(record);
        // setExpanded(["Model"]);
      } else if (
        pathname.split("/")?.[2]?.split("+")?.[0] === "MAC" ||
        pathname.split("/")?.[2]?.split("+")?.[0] === "Model"
      ) {
      } else {
        setNodeSelected(
          `Model+${pathname.split("/")?.[2]?.split("+")?.[0]}+${
            pathname.split("/")?.[2]?.split("+")?.[1]
          }`
        );
        setRecord(
          `Model+${pathname.split("/")?.[2]?.split("+")?.[0]}+${
            pathname.split("/")?.[2]?.split("+")?.[1]
          }`
        );
        setExpanded([
          `Model+${pathname.split("/")?.[2]?.split("+")?.[0]}`,
          "Model",
        ]);
      }
    } else {
      if (pathname === "/opm") {
        //setNodeSelected(`Model+${record.split("+")[1]}`);
        setNodeSelected(
          `Model+${record.split("+")[1]}${
            record.split("+")[2] ? `+${record.split("+")[2]}` : ""
          }`
        );
        setRecord(
          `Model+${record.split("+")[1]}${
            record.split("+")[2] ? `+${record.split("+")[2]}` : ""
          }`
        );
        setExpanded([`Model+${record.split("+")[1]}`, "Model"]);
      } else if (pathname?.split("/")?.[1] === "documents") {
        record.split("+")[0] === "MAC" &&
          setExpanded([
            `${record?.split("+")[1]}${
              record?.split("+")[2] ? `+${record?.split("+")?.[2]}` : ""
            }`,
            `${record.split("+")?.[1]}`,
          ]);
        record.split("+")[0] === "Model" &&
          setExpanded([`Model+${record?.split("+")[1]}`, "Model"]);
        record.split("+")[0] === "Model" &&
          setNodeSelected(
            `Model+${record?.split("+")[1]}${
              record?.split("+")[2] ? `+${record?.split("+")?.[2]}` : ""
            }`
          );
      } else {
        setNodeSelected(
          `Model+${record.split("+")[1]}${
            record.split("+")[2] ? `+${record.split("+")[2]}` : ""
          }${record.split("+")[3] ? `+${record.split("+")[3]}` : ""}`
        );
        setExpanded([`Model+${record.split("+")[1]}`, "Model"]);
      }
    }
    if (
      pathname === "/task" ||
      pathname === "/query" ||
      pathname === "/user/root" ||
      pathname === "/executor" ||
      pathname === "/report" ||
      pathname.includes("/report") ||
      pathname === "/dashboard" ||
      pathname === "/dashboard/config" ||
      pathname === "/userlist" ||
      pathname === "/attendance" ||
      pathname === "/init" ||
      pathname === "/config" ||
      pathname === "/user" ||
      pathname === "/notification" ||
      pathname === "/history" ||
      pathname.includes("/history") ||
      pathname === "/task/config" ||
      pathname === "/access" ||
      pathname === "/batch/upload" ||
      pathname === "/batch/download" ||
      pathname === "/schema"
    ) {
      setNodeSelected("");
      setRecord("");
      setExpanded(["Model"]);
    }
  }, [pathname]);

  const tabNameOutToInHandler = (outName) => {
    let inName;
    if (outName && formMapping.filter((name) => name[0] === outName)[0]) {
      inName = formMapping.filter((name) => name[0] === outName)[0][1];
      return inName;
    }
    return outName;
  };

  const tabNameInToOutHandler = (inName) => {
    let outName;
    if (inName && formMapping.filter((name) => name[1] === inName)[0]) {
      outName = formMapping.filter((name) => name[1] === inName)[0][0];
      return outName;
    }
    return inName;
  };

  const fileTypeInToOutHandler = (fileType) => {
    let outFileType;
    if (fileType && fileTypeMapping.filter((name) => name[0] === fileType)[0]) {
      outFileType = fileTypeMapping.filter(
        (name) => name[0] === fileType
      )[0][1];
      return outFileType;
    }
    return fileType;
  };

  const fileTypeOutToInHandler = (fileType) => {
    let inFileType;
    if (fileType && fileTypeMapping.filter((name) => name[1] === fileType)[0]) {
      inFileType = fileTypeMapping.filter((name) => name[1] === fileType)[0][0];
      return inFileType;
    }
    return fileType;
  };

  const macFileTypeInToOutHandler = (fileType) => {
    let outFileType;
    if (
      fileType &&
      macFileTypeMapping.filter((name) => name[0] === fileType)[0]
    ) {
      outFileType = macFileTypeMapping.filter(
        (name) => name[0] === fileType
      )[0][1];
      return outFileType;
    }
    return fileType;
  };

  const macFileTypeOutToInHandler = (fileType) => {
    let inFileType;
    if (
      fileType &&
      macFileTypeMapping.filter((name) => name[1] === fileType)[0]
    ) {
      inFileType = macFileTypeMapping.filter(
        (name) => name[1] === fileType
      )[0][0];
      return inFileType;
    }
    return fileType;
  };

  let modelAccess;

  if (user && user["menu access"] && Object.values(user["menu access"])) {
    modelAccess = Object.values(user["menu access"])[0]
      .map((i) => i[0])
      .includes("Model");
  }

  let tempAccess;
  if (user && user["menu access"] && Object.values(user["menu access"])) {
    tempAccess = Object.values(user["menu access"])[0]
      .map((i) => i[0])
      .includes("New");
  }

  const isPageDataChangedHandler = (form, param) => {
    Array.isArray(dataChangedArr) &&
      setDataChangedArr((prev) => {
        if (!prev.some((item) => _.isEqual(item, { [form]: param }))) {
          return [...prev, { [form]: param }];
        } else {
          return prev;
        }
      });
  };

  const isPageDataNotChangedHandler = (form, param) => {
    Array.isArray(dataChangedArr) &&
      setDataChangedArr((prev) =>
        prev?.filter((item) => !_.isEqual(item, { [form]: param }))
      );
  };

  const setNodeClickedHandler = (node) => {
    setNodeSelected(node);
  };

  const setRecordHandler = (record) => {
    setRecord(record);
  };

  const clearChangedArrHandler = () => {
    setDataChangedArr([]);
  };

  const isTreeChangedHandler = () => {
    setIsTreeChanged(!isTreeChanged);
  };

  const reportDataChangedHandler = (data) => {
    setReportData(data);
  };

  const isUserChangedHandler = () => {
    setIsUserChanged(!isUserChanged);
  };

  const handleSaveSuspense = (bool) => {
    setIsSaveSuspense(bool);
  };

  const setNavPageHandler = (pathname) => {
    setNavPage(pathname);
  };

  const handleNotSaveHandler = (e) => {
    setOpen(false);

    if (nextLocation !== "") {
      history.push(nextLocation);
      if (
        nextLocation === "/report" ||
        nextLocation === "/config" ||
        nextLocation === "/user" ||
        nextLocation === "/notification" ||
        nextLocation === "/history" ||
        nextLocation === "/task" ||
        nextLocation === "/task/config" ||
        nextLocation === "/access" ||
        nextLocation === "/batch/upload" ||
        nextLocation === "/batch/download" ||
        nextLocation === "/schema" ||
        nextLocation === "/attendance" ||
        nextLocation === "/dashboard/config" ||
        nextLocation === "/query" ||
        nextLocation === "/model/new"
      ) {
        setDataChangedArr([]);
        clearIndexDB();
      }
      setDataChangedArr((prev) =>
        prev?.filter((item) => !_.isEqual(item, { New: 0 }))
      );
      setNextLocation("");
    } else {
      treeClickHandler(pendingNode);
      setDataChangedArr([]);
    }
  };

  const handleCancelHandler = (form) => {
    setOpen(false);
    if (form === "New") {
    } else {
      if (pathname.split("/")?.[1] === "opm") {
        setNodeSelected(`${record.split("+")[0]}+${record.split("+")[1]}`);
      } else if (pathname.split("/")?.[1] === "mac") {
        setNodeSelected(`MAC+${record.split("+")[0]}+${record.split("+")[1]}`);
      } else if (
        ["model", "issue", "validation"].includes(pathname.split("/")?.[1])
      ) {
        setNodeSelected(
          `${record.split("+")[0]}+${record.split("+")[1]}+${
            record.split("+")[2]
          }`
        );
      }
    }
    setNextLocation("");
  };

  const treeClickHandler = (nodeId) => {
    if (nodeId.split("+")?.[3] === "0") {
      if (modelAccess) {
        history.push("/temp");
      }
    } else if (nodeId.split("+")[0] !== "Model" && !nodeId.split("+")[1]) {
      history.push(`/mac/${nodeId.split("+")[0]}/new`);
    } else if (nodeId.split("+")[0] === "MAC" && nodeId.split("+")[2]) {
      history.push(
        `/mac/${nodeId.split("+")[1]}${
          nodeId.split("+")[2] ? `+${nodeId.split("+")[2]}` : ""
        }`
      );
    } else if (nodeId.split("+")[0] === "Policy Exception") {
      if (!nodeId.split("+")[1]) {
        history.push("/policy/new");
      } else {
        history.push("/policy");
      }
    } else if (nodeId.split("+")[0] === "Model Change Memo") {
      if (!nodeId.split("+")[1]) {
        history.push("/model-change/new");
      } else {
        history.push("/model-change");
      }
    } else if (nodeId.split("+")[0] === "Attestation") {
      if (!nodeId.split("+")[1]) {
        history.push("/attestation/new");
      } else {
        history.push("/attestation");
      }
    } else if (
      ["opm", "validation", "issue"].includes(pathname.split("/")[1]) &&
      pathname.split("/")[2]
    ) {
      history.push(`/${pathname.split("/")[1]}`);
    } else if (
      nodeId?.split("+")?.[3] !== "0" &&
      pathname?.split("/")?.[1] === "temp"
    ) {
      history.push("/model");
    } else if (
      pathname?.split("/")?.[1] === "mac" &&
      nodeId.includes("Model")
    ) {
      history.push("/model");
    } else if (pathname?.split("/")?.[1] === "model") {
      history.push("/model");
    } else if (
      pathname?.split("/")?.[1] === "documents" &&
      pathname?.split("/")?.[3] === "new"
    ) {
      history.push("/documents");
    }

    setRecord(nodeId);
    clearIndexDB();
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (dataChangedArr.length > 0) {
        event.preventDefault();

        event.returnValue = "";
      } else {
        delete event["returnValue"];
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [dataChangedArr.length]);

  if (pathname === "/executor" || pathname === "/init") {
    return <Routes pathname={pathname} />;
  }

  if (!token || token === null) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          marginTop: "15rem",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (pathname === "/user/root") {
    return (
      <UserContext.Provider value={{ token: token || "" }}>
        <Routes pathname={pathname} />
      </UserContext.Provider>
    );
  }

  if (!tree || isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          marginTop: "15rem",
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <UserContext.Provider
      value={{
        tag: tag,
        user: user,
        token: token,
        userGroup: userGroup,
        fileType: fileType ? fileType : "",
        tabNameOutToInHandler: tabNameOutToInHandler,
        tabNameInToOutHandler: tabNameInToOutHandler,
        fileTypeInToOutHandler: fileTypeInToOutHandler,
        fileTypeOutToInHandler: fileTypeOutToInHandler,
        macFileTypeInToOutHandler: macFileTypeInToOutHandler,
        macFileTypeOutToInHandler: macFileTypeOutToInHandler,
        macFileType: macFileTypeMapping.map((i) => i[0]),
        socket: socket,
        currentURL: currentURL,
        setFormHandler: setFormHandler,
        setParamHandler: setParamHandler,
        form: form,
        param: param,
        isSaveExecuted: isSaveExecuted,
        saveAction: saveAction,
        isPageDataChangedHandler: isPageDataChangedHandler,
        isPageDataNotChangedHandler: isPageDataNotChangedHandler,
        isTempPage: record.split("+")[3] === "0" ? true : false,
        handleSaveSuspense: handleSaveSuspense,
        dataChangedArr: dataChangedArr,
        reportDataChangedHandler: reportDataChangedHandler,
        reportData: reportData,
        setNavPageHandler: setNavPageHandler,
        handleDialogClickOpen: handleDialogClickOpen,
        setNavigationHandler: setNavigationHandler,
        clearChangedArrHandler: clearChangedArrHandler,
        setNodeClickedHandler: setNodeClickedHandler,
        setRecordHandler: setRecordHandler,
        nodeExpandHandler: nodeExpandHandler,
        record: record,
        treeHeightHandler: treeHeightHandler,
      }}
    >
      <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          style: {
            marginTop: 0,
            position: "absolute",
            top: "0",
          },
        }}
      >
        <DialogTitle>
          {
            "You have unsaved changes for the current model, would you like to continue leaving and discard the changes?"
          }
        </DialogTitle>

        <DialogActions>
          <Button onClick={(e) => handleNotSaveHandler(e)}>Continue</Button>
          <Button onClick={handleCancelHandler}>Back</Button>
        </DialogActions>
      </Dialog>
      <TopBar />
      <Routes
        pathname={pathname}
        modelAccess={modelAccess}
        tempAccess={tempAccess}
        modelRecord={modelRecord}
        documents={documents}
        options={options}
        tag={tag}
        tree={tree}
        treeClickHandler={(e, nodeId) => {
          setIsTreeClicked(true);
          if (
            record === nodeId?.[0] &&
            nodeId?.[0]?.split("+")?.[0] !== "MAC"
          ) {
            return;
          } else if (nodeId?.[0]?.split("+")?.[2]) {
            if (dataChangedArr.length === 0) {
              treeClickHandler(nodeId?.[0]);
              setNodeSelected(nodeId?.[0]);
            } else {
              setPendingNode(nodeId?.[0]);
              setNodeSelected(nodeId?.[0]);
              setOpen(true);
            }
          } else if (
            pathname.split("/")[1] === "opm" ||
            pathname.split("/")[1] === "mac" ||
            nodeId?.[0].split("+")[0] !== "Model" ||
            pathname === "/assignment"
          ) {
            if (dataChangedArr.length === 0) {
              treeClickHandler(nodeId?.[0]);
              setNodeSelected(nodeId?.[0]);
            } else {
              setPendingNode(nodeId?.[0]);
              setNodeSelected(nodeId?.[0]);
              setOpen(true);
            }
          }
        }}
        isTreeChangedHandler={isTreeChangedHandler}
        isUserChangedHandler={isUserChangedHandler}
        record={record}
        user={user}
        allModelVersion={tree && tree[0] ? tree[0].Model : []}
        allModelData={allModelData}
        isTaskPageEnable={isTaskPageEnable}
        nodeSelected={nodeSelected}
        expanded={expanded}
        nodeExpandHandler={nodeExpandHandler}
        treeHeight={treeHeight}
      />

      <Footer />
    </UserContext.Provider>
  );
}

const UnauthorizedContent = () => {
  return <UnAuthRoute />;
};

const MainContent = () => {
  return (
    <div>
      <AuthenticatedTemplate>
        <AuthorizedContent />
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <UnauthorizedContent />
      </UnauthenticatedTemplate>
    </div>
  );
};

export default function App() {
  return (
    <ThemeProvider>
      <Router>
        <MainContent />
      </Router>
    </ThemeProvider>
  );
}
