import { useState, useEffect, useContext } from "react";
import ValidationTable from "./ValidationTable/ValidationTable";
import { CircularProgress } from "@mui/material";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { UserContext } from "../../context/user-context";
import "./Validation.css";

const Validation = (props) => {
  const [validation, setValidation] = useState([]);
  const [mrm, setMrm] = useState(props.record.split("+")[0]);
  const [version, setVersion] = useState(props.record.split("+")[1]);
  const [isValidationChanged, setIsValidationChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [layoutData, setLayoutData] = useState([]);
  const { error, sendRequest, clearError } = useHttpClient();
  const { dataChangedArr } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/validation/${mrm}+${version}`
        );

        setValidation(response["validation data"]);
        if (Array.isArray(response?.summary?.grid)) {
          setLayoutData(response?.summary);
        } else {
          setLayoutData([]);
        }

        setIsLoading(false);
      } catch (err) {
        console.warn(err);
      }
    };
    if (mrm && version && version !== "undefined" && mrm !== "undefined") {
      fetchData();
    }
  }, [mrm, version, sendRequest, isValidationChanged]);

  const validationChangedHandler = () => {
    setIsValidationChanged((prev) => !prev);
  };

  useEffect(() => {
    setMrm(props.record.split("+")[0]);
    setVersion(props.record.split("+")[1]);
  }, [props.record]);

  let element;

  if (isLoading) {
    element = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
          paddingTop: "20rem",
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    element = (
      <div style={{ height: "120%" }}>
        <ValidationTable
          validation={validation ? validation : []}
          id={`${mrm}+${version}`}
          layoutData={layoutData || []}
        />
      </div>
    );
  }

  if (!(mrm && version && version !== "undefined" && mrm !== "undefined")) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
          paddingTop: "10rem",
        }}
      >
        <h2 style={{ color: "#444791" }}>Please select a model</h2>
      </div>
    );
  }

  return <div>{element}</div>;
};

export default Validation;
