import { useState, useEffect, useCallback, useContext } from "react";
import { Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import {
  GridCellModes,
  GridActionsCellItem,
} from "@mui/x-data-grid-pro";
import WrapTextTable  from "../../../components/CustomComponents/WrapTextTable";

import { randomId } from "@mui/x-data-grid-generator";
import clsx from "clsx";
import Toast from "../../../components/Utils/Toast";
import { MetricEmptyCheck } from "./MuiMetricCheck";
import { UserContext } from "../../../context/user-context";

export const ColorTransfer = (color) => {
  switch (color?.toLowerCase()) {
    case "red":
      return "#FF0000";
    case "green":
      return "#00B050";
    case "amber":
      return "#FFBF00";
    default:
      return "white";
  }
};

const cellColor = {
  "& .Green": {
    backgroundColor: "#00B050",
    fontWeight: "bold",
  },
  "& .Red": {
    backgroundColor: "#FF0000",
    fontWeight: "bold",
  },
  "& .Amber": {
    backgroundColor: "#FFBF00",
    fontWeight: "bold",
  },
};

export default function FullFeaturedCrudGrid(props) {
  const { tag } = useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [cellModesModel, setCellModesModel] = useState({});

  const singleRuleOptions = tag
    ?.filter(
      (item) =>
        item.db_tablename === "static metric" && item.fe_id === "opm_rule"
    )?.[0]
    ?.group?.split(",");

  const continuityOptions = tag
    ?.filter(
      (item) =>
        item.db_tablename === "static metric" && item.fe_id === "continuity"
    )?.[0]
    ?.group?.split(",");

  const defaultColorOptions = tag
    ?.filter(
      (item) =>
        item.db_tablename === "score schema" && item.fe_id === "default_color"
    )?.[0]
    ?.group?.split(",");

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [
      ...oldRows,
      {
        id,
        isNew: true,
      },
    ]);

    setCellModesModel((oldModel) => ({
      ...oldModel,
      [id]: {
        [props.id === "memo" ? "" : ""]: { mode: GridCellModes.Edit },
      },
    }));
  };

  useEffect(() => {
    props.tableData &&
      setRows(
        props.tableData.map((data, i) => {
          return {
            ...data,
            isNew: false,
            id: data.id ? data.id : randomId(),
          };
        })
      );
  }, [props.tableData]);

  const handleCellClick = useCallback((params) => {
    if (!params.isEditable) {
      return;
    }
    if (
      params.field === "actions" ||
      ((params.field === "color" || params.field === "statistics") &&
        props.id === "quantitative_metric") ||
      ((params.field === "color" || params.field === "statistics") &&
        props.id === "qualitative_metric")
    )
      return;
    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {}
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const handleCellModesModelChange = useCallback((newModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
    props.tableDataChanged(rows.filter((row) => row.id !== id));
  };

  const processRowUpdate = (newRow) => {
    // if (MetricEmptyCheck(props.id, newRow)) {
    //   Toast("info", "Please fill all the fields");
    //   return;
    // }
    const updatedRow = { ...newRow, isNew: false };
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    props.tableDataChanged(
      rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    );
    return updatedRow;
  };

  let columns = [];

  if (props.id === "weight_schema") {
    columns = [
      {
        field: "statistics",
        headerName: "Statistics",
        type: "string",
        headerAlign: "center",
        flex: 4,
        editable: true,
      },
      {
        field: "weight",
        headerName: "Weight",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 4,
        editable: true,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        headerAlign: "center",
        flex: 0.88,
        hide: !props.isShowOnly ? false : true,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      },
    ];
  } else if (props.id === "attestation") {
    columns = [
      {
        field: "statistics",
        headerName: "Statistics",
        type: "string",
        headerAlign: "center",
        align: "left",
        flex: 0.148,
        editable: true,
      },
      {
        field: "opm_rule",
        headerName: "Single Rule",
        type: "singleSelect",
        headerAlign: "center",
        valueOptions: singleRuleOptions,
        align: "center",
        flex: 0.148,
        editable: true,
      },
      {
        field: "continuity",
        headerName: "Consecutive",
        type: "singleSelect",
        valueOptions: continuityOptions,
        headerAlign: "center",
        align: "center",
        flex: 0.148,
        editable: true,
      },
      {
        field: "quarterback",
        headerName: "Lookback",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 0.148,
        editable: true,
      },

      {
        field: "series",
        headerName: "Focus",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 0.127,
        editable: true,
      },
      {
        field: "attestation",
        headerName: "Attestation",
        headerAlign: "center",
        align: "center",
        flex: 0.148,
        editable: true,
      },
      {
        field: "color",
        headerName: "Color",
        headerAlign: "center",
        flex: 0.121,
        editable: true,
        align: "center",
        type: "singleSelect",
        valueOptions: props.colorList,
        cellClassName: (params) => {
          if (params.value) {
            return clsx(params.value);
          }
        },
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 0.11,
        headerAlign: "center",
        hide: !props.isShowOnly ? false : true,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      },
    ];
  } else if (props.id === "performance_measurement") {
    columns = [
      {
        field: "statistics",
        headerName: "Statistics",
        type: "string",
        headerAlign: "center",
        align: "left",
        flex: 0.127,
        editable: true,
        color: "red",
      },
      {
        field: "opm_rule",
        headerName: "Single Rule",
        type: "singleSelect",
        headerAlign: "center",
        valueOptions: singleRuleOptions,
        align: "center",
        flex: 0.127,
        editable: true,
      },
      {
        field: "continuity",
        headerName: "Consecutive",
        type: "singleSelect",
        valueOptions: continuityOptions,
        headerAlign: "center",
        align: "center",
        flex: 0.127,
        editable: true,
      },
      {
        field: "quarterback",
        headerName: "Lookback",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 0.127,
        editable: true,
      },

      {
        field: "series",
        headerName: "Focus",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 0.127,
        editable: true,
      },

      {
        field: "lower_bound",
        headerName: "Lower Threshold",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 0.127,
        editable: true,
      },
      {
        field: "upper_bound",
        headerName: "Upper Threshold",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 0.127,
        editable: true,
      },
      {
        field: "color",
        headerName: "Color",
        flex: 0.127,
        editable: true,
        headerAlign: "center",
        align: "center",
        type: "singleSelect",
        cellClassName: (params) => {
          if (params.value) {
            return clsx(params.value);
          }
        },
        valueOptions: props.colorList,
      },

      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 0.11,
        hide: !props.isShowOnly ? false : true,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      },
    ];
  } else if (props.id === "score_schema") {
    columns = [
      {
        field: "lower_bound",
        headerName: "Lower Score",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 0.178,
        editable: true,
      },
      {
        field: "upper_bound",
        headerName: "Upper Score",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 0.178,
        editable: true,
      },
      {
        field: "value",
        headerName: "Value",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 0.178,
        editable: true,
      },

      {
        field: "default_color",
        headerName: "Default Color",
        flex: 0.178,
        editable: true,
        headerAlign: "center",
        align: "center",
        type: "singleSelect",
        cellClassName: (params) => {
          if (params.value) {
            return clsx(params.value);
          }
        },
        valueOptions: defaultColorOptions,
      },
      {
        field: "color",
        headerName: "Color",
        headerAlign: "center",
        align: "center",
        flex: 0.1,
        editable: true,
        cellClassName: (params) => {
          if (params.value) {
            return clsx(params.value);
          }
        },
        type: "singleSelect",
        valueOptions: props.colorList,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        flex: 0.09,
        hide: !props.isShowOnly ? false : true,
        cellClassName: "actions",
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ];
        },
      },
    ];
  } else if (props.id === "quantitative_metric") {
    columns = [
      {
        field: "statistics",
        headerName: "KPI",
        type: "string",
        headerAlign: "center",
        align: "left",
        flex: 1,
        editable: false,
      },
      {
        field: "value1",
        headerName: "Lead 3",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 1,
        editable: true,
        hide: props.isFourColsShow ? false : true,
      },
      {
        field: "value2",
        headerName: "Lead 2",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 1,
        editable: true,
        hide: props.isFourColsShow ? false : true,
      },
      {
        field: "value3",
        headerName: "Lead 1",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 1,
        editable: true,
        hide: props.isFourColsShow ? false : true,
      },
      {
        field: "value4",
        headerName: props.isFourColsShow ? "Current" : "Value",
        type: "number",
        headerAlign: "center",
        align: "center",
        flex: 1,
        editable: true,
      },
      {
        field: "color",
        headerName: "Color",
        headerAlign: "center",
        align: "center",
        flex: 1,
        editable: false,
        renderCell: (params) => <div></div>,
        cellClassName: (params) => {
          return clsx(
            props.colorReference?.find(
              (i) => i.statistics === params.row.statistics
            )?.color
          );
        },
      },
    ];
  } else if (props.id === "qualitative_metric") {
    columns = [
      {
        field: "statistics",
        headerName: "KPI",
        headerAlign: "center",
        type: "string",
        flex: 1,
        editable: false,
      },
      {
        field: "attestation1",
        headerName: "Lead 3",
        type: "singleSelect",
        headerAlign: "center",
        align: "center",
        flex: 1,
        hide: props.isFourColsShow ? false : true,
        valueOptions: (params) => {
          if (params.row.option) {
            return params.row.option;
          } else {
            return props.qualitativeOption(params.row.statistics);
          }
        },

        editable: true,
      },
      {
        field: "attestation2",
        headerName: "Lead 2",
        type: "singleSelect",
        headerAlign: "center",
        align: "center",
        flex: 1,
        hide: props.isFourColsShow ? false : true,
        valueOptions: (params) => {
          if (params.row.option) {
            return params.row.option;
          } else {
            return props.qualitativeOption(params.row.statistics);
          }
        },

        editable: true,
      },
      {
        field: "attestation3",
        headerName: "Lead 1",
        type: "singleSelect",
        headerAlign: "center",
        align: "center",
        flex: 1,
        hide: props.isFourColsShow ? false : true,
        valueOptions: (params) => {
          if (params.row.option) {
            return params.row.option;
          } else {
            return props.qualitativeOption(params.row.statistics);
          }
        },

        editable: true,
      },
      {
        field: "attestation4",
        headerName: props.isFourColsShow ? "Current" : "Assessment",
        type: "singleSelect",
        headerAlign: "center",
        align: "center",
        flex: 1,
        valueOptions: (params) => {
          if (params.row.option) {
            return params.row.option;
          } else {
            return props.qualitativeOption(params.row.statistics);
          }
        },
        editable: true,
      },
      {
        field: "color",
        headerName: "Color",
        headerAlign: "center",
        align: "center",
        flex: 1,
        editable: false,
        renderCell: (params) => <div></div>,
        cellClassName: (params) => {
          return clsx(
            props.colorReference?.find(
              (i) => i.statistics === params.row.statistics
            )?.color
          );
        },
      },
    ];
  }

  return (
    <Box sx={cellColor}>
      <div style={{ textAlign: "center" }}>
        <div
          style={
            !props.isShowOnly && !props.isAddButtonNotShow
              ? { fontWeight: "bold", color: "#444791", marginBottom: "-3rem" }
              : { fontWeight: "bold", color: "#444791", marginBottom: "0rem" }
          }
        >
          {props.label}
        </div>
      </div>
      {!props.isShowOnly && !props.isAddButtonNotShow && (
        <Button
          variant="outlined"
          className="editButton"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleClick}
        >
          Add record
        </Button>
      )}
      <WrapTextTable
        headerHeight={40}
        rowHeight={30}
        autoHeight
        rows={rows}
        columns={columns}
        isCellEditable={(params) => !props.disabled}
        processRowUpdate={processRowUpdate}
        experimentalFeatures={{ newEditingApi: true }}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        onCellClick={handleCellClick}
        hideFooter={true}
        showCellRightBorder={true}
        sx={{
          boxShadow: "none",
        }}
      />
    </Box>
  );
}
