import { useParams } from "react-router-dom";
import TemplatePage from "../../components/CustomComponents/GeneralPageTemplate";

const MacDetails = (props) => {
  const { record } = useParams();
  return (
    <TemplatePage
      tag={props.tag}
      macParams={record}
      modelData={props.modelData}
      isTreeChanged={props.isTreeChanged}
      form="mac"
      table="Mac"
      setScrollTop={props.setScrollTop}
      pageElement={props.pageElement}
    />
  );
};

export default MacDetails;
