import { useState } from "react";
import { useHistory } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { TreeView, TreeItem } from "@mui/lab";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

function StyledTreeItem(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box
            component={LabelIcon}
            color="primary"
            sx={{ mr: 1, color: "rgb(25, 118, 210)" }}
          />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1, fontSize: "large" }}
          >
            {labelText}
          </Typography>
        </Box>
      }
      {...other}
    />
  );
}

function StyledTreeItem2(props) {
  const {
    bgColor,
    color,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <TreeItem
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0, pr: 0 }}>
          <Box
            component={LabelIcon}
            color="primary"
            sx={{ mr: 1, color: "#757575" }}
          />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1, fontSize: "large" }}
          >
            {labelText}
          </Typography>
        </Box>
      }
      {...other}
    />
  );
}

const ReportTree = ({ treeData, setTreeHandler }) => {
  const [data1, setData1] = useState(treeData);
  let report_tree_expanded = JSON.parse(
    localStorage.getItem("report_tree_expanded")
  );
  let report_selected_node = localStorage.getItem("report_selected_node");
  const history = useHistory();
  const nodeSelectHandler = (e, node) => {
    setTreeHandler(node);
  };
  return (
    <TreeView
      aria-label="report"
      defaultExpanded={report_tree_expanded || []}
      defaultSelected={report_selected_node}
      defaultCollapseIcon={<ArrowForwardIosIcon />}
      defaultExpandIcon={<ArrowForwardIosIcon />}
      defaultEndIcon={<div style={{ width: 100 }} />}
      sx={{ flexGrow: 1, overflowY: "auto", minHeight: 450 }}
      onNodeSelect={nodeSelectHandler}
      onNodeToggle={(_, nodeIds) => {
        report_tree_expanded = nodeIds;
        localStorage.setItem("report_tree_expanded", JSON.stringify(nodeIds));
      }}
    >
      {treeData.map((node, i) => (
        <StyledTreeItem
          nodeId={node}
          labelText={node}
          labelIcon={InsertDriveFileIcon}
          key={node}
          autoFocus={true}
        />
      ))}
    </TreeView>
  );
};

export default ReportTree;
