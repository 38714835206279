import { useParams, useLocation } from "react-router-dom";

import TemplatePage from "../../../components/CustomComponents/GeneralPageTemplate";

import "./OpmDetails.css";

const OpmDetails = ({ tag, setScrollTop, pageElement }) => {
  const { originDynamicMetricData, monitorData, colorReference, gaugeData } =
    useLocation();
  const { opmId } = useParams();

  return (
    <TemplatePage
      tag={tag}
      form="opm"
      table="OPM"
      record={opmId}
      originDynamicMetricData={originDynamicMetricData}
      monitorData={monitorData}
      colorReference={colorReference}
      gaugeData={gaugeData}
      setScrollTop={setScrollTop}
      pageElement={pageElement}
    />
  );
};

export default OpmDetails;
