import { useState, useEffect, useContext } from "react";
import dayjs from "dayjs";
import { CircularProgress, Box, Grid, Button } from "@mui/material";
import { useForm } from "../../components/Utils/hooks/form-hook";
import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import { UserContext } from "../../context/user-context";
import { Popconfirm } from "antd";
import _ from "lodash";

import InputNew from "../../components/Content/FormElements/InputNew";
import Toast from "../../components/Utils/Toast";

export const isAttendanceIncludesNonNumber = (attendance) => {
  if (Array.isArray(attendance)) {
    const arr = Array.from(attendance, (x) => x.attendee)?.flat();
    if (arr.length !== new Set(arr).size) {
      return true;
    }
  }
  return false;
};

const checkTableDataHasEmpty = (dataArray) => {
  if (Array.isArray(dataArray)) {
    const keysToCheck = ["attendee", "business_unit", "stakeholder"];

    for (let item of dataArray) {
      for (let key of keysToCheck) {
        if (_.isEmpty(item[key])) {
          return true;
        }
      }
    }

    return false;
  } else {
    return true;
  }
};

const MacAttendance = (props) => {
  const { tag } = props;
  const { user, userGroup } = useContext(UserContext);
  const [formState, inputHandler] = useForm(
    {
      framework: {
        value: "",
        isValid: false,
      },
    },
    false
  );
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [allAttendance, setAllAttendance] = useState([]);
  const [attendanceData, setAttendanceData] = useState([]);
  const [isBaseChanged, setIsBaseChanged] = useState(false);
  const [macNameSelected, setMacNameSelected] = useState("");
  const [isAllDataLoading, setIsAllDataLoading] = useState(false);

  const currentDate = dayjs().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");

  const { display_name } = user || {};

  const mac_name_option = tag?.filter(
    (item) => item.db_tablename === "mac role" && item.fe_id === "mac_name"
  )[0];

  useEffect(() => {
    const fetchMacAttendance = async () => {
      try {
        setIsAllDataLoading(true);
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/mac/role`
        );
        setIsAllDataLoading(false);
        setAllAttendance(response);
      } catch (err) {}
    };
    fetchMacAttendance();
  }, [sendRequest, isBaseChanged]);

  useEffect(() => {
    if (formState.inputs.mac_name && formState.inputs.mac_name.value) {
      setMacNameSelected(formState.inputs.mac_name?.value);
      setAttendanceData(
        allAttendance["mac role"]?.filter(
          (item) => item.mac_name === formState.inputs.mac_name.value
        )
      );
    }
  }, [formState.inputs.mac_name?.value, allAttendance]);

  const updateFrameworkHandler = async (e) => {
    if (
      !formState.inputs.mac_name ||
      !formState.inputs.mac_name.value ||
      formState.inputs.mac_name.value === ""
    ) {
      Toast("error", "Please select Mac Name.");
      return;
    }

    if (
      isAttendanceIncludesNonNumber(formState.inputs?.mac_attendance?.value)
    ) {
      Toast("error", "Please do not input duplicate Attendee.");
      return;
    }

    if (checkTableDataHasEmpty(formState.inputs.mac_attendance?.value)) {
      Toast("error", "Please fill in all the fields");
      return;
    }

    e.preventDefault();
    const attendanceBody =
      Array.isArray(formState.inputs.mac_attendance?.value) &&
      formState.inputs.mac_attendance?.value?.map((item) => ({
        mac_name: formState.inputs.mac_name?.value,
        attendee: item.attendee,
        stakeholder: item.stakeholder,
        business_unit: item.business_unit,
        placeholder1: "",
        placeholder2: "",
        placeholder3: "",
        placeholder4: "",
        placeholder5: "",
        user_id: display_name,
        update_time: currentDate,
      }));

    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/mac/role?mac_name=${formState.inputs.mac_name?.value}`,
        "POST",
        JSON.stringify(attendanceBody),
        {
          "Content-Type": "application/json",
        }
      );
      if (response["mac role"]) {
        Toast("success", "Attendance Updated Successfully.");
        setIsBaseChanged((prev) => !prev);
      } else {
        Toast("error", response, "Database Error.");
      }
    } catch (err) {}
  };

  const clearFrameworkHandler = async (e) => {
    e.preventDefault();
    try {
      const response = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/mac/role?mac_name=${formState.inputs.mac_name.value}`,
        "DELETE",
        null,
        {
          "Content-Type": "application/json",
        }
      );

      Toast("success", "Attendance Cleared.");
      setIsBaseChanged((prev) => !prev);
    } catch (err) {}
  };

  if (isAllDataLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "5rem",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "15rem",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <h4>MAC Attendance</h4>
      </div>
      <hr />
      <Box sx={{ flexGrow: 2, mt: 2, ml: 20, mr: 20, mb: 2 }}>
        <Grid container rowSpacing={2} columnSpacing={5}>
          <Grid item xs={6}>
            <InputNew
              id="mac_name"
              type="text"
              label="Mac Name"
              element="select"
              onInput={inputHandler}
              initialValue={macNameSelected}
              onBlur
              origin_options={mac_name_option}
            />
          </Grid>
          <Grid item xs={6}>
            <div style={{ marginTop: "1.2rem" }}>
              <Popconfirm
                title="Are you sure to clear?"
                onConfirm={clearFrameworkHandler}
                okText="Yes"
                cancelText="No"
              >
                <Button variant="outlined" className="editButton">
                  Clear Attendance
                </Button>
              </Popconfirm>
            </div>
          </Grid>
        </Grid>
        <div style={{ width: "100%", margin: "3rem auto 2rem auto" }}>
          <InputNew
            id="mac_attendance"
            label="Mac Attendance"
            element="macTable"
            onInput={inputHandler}
            initialValue={attendanceData}
            userGroup={userGroup}
          />
        </div>

        {isLoading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={updateFrameworkHandler}
            variant="outlined"
            className="editButton"
          >
            Save Attendance
          </Button>
        </div>
      </Box>
    </div>
  );
};
export default MacAttendance;
