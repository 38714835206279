import React, { Fragment, useState, useEffect, useContext } from "react";
import { gridClasses } from "@mui/x-data-grid";
import WrapTextTable from "../../../components/CustomComponents/WrapTextTable";
import { Button, CircularProgress, Box } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import { UserContext } from "../../../context/user-context";
import _ from "lodash";
import "./OpmTable.css";

const finalOutcomeColorConfig = (opt) => {
  const colorConfig = {
    "Fit for use": "Green", //#00B050
    "Use with condition": "Amber", //#FFBF00
    Reject: "Red", //#FF0000
  };
  return colorConfig[opt];
};

// different color in even line and hover effect
const datagridSx = {
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: "rgb(250,250,250)",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgb(221,225,255)",
    },
  },
  [`& .${gridClasses.row}.not--saved`]: {
    backgroundColor: "#CB3694",
    color: "white",
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgb(221,225,255)",
      color: "black",
    },
  },
  "& .Green": {
    backgroundColor: "#C6E0B4",
  },
  "& .Amber": {
    backgroundColor: "#FFF2CC",
  },
  "& .Red": {
    backgroundColor: "#F4B084",
  },
};

const OpmTable = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const { dataChangedArr } = useContext(UserContext);

  useEffect(() => {
    setRows(
      props.tableData.map((data) => ({
        id: data.version,
        code: data.code,
        submission_date: data.submission_date,
        version: data.version,
        opm_status: data.opm_status,
        opm_rating: data.opm_rating,
        opm_fit: data.opm_fit,
        reviewer: data.reviewer,
      }))
    );
    setIsLoading(false);
  }, [props.tableData]);

  const columns = [
    {
      field: "version",
      headerName: "Target OPM Submission Date",
      flex: 2.5,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Link
          to={{
            pathname: `/opm/${params.row.code}+${params.row.version}`,
            originDynamicMetricData: props.dynamicMetricData,
            monitorData: props.monitorData,
            colorReference: props.colorReference,
            gaugeData: props.gaugeData,

          }}
         
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "submission_date",
      headerName: "Actual OPM Submission Date",
      flex: 3,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "opm_status",
      headerName: "OPM Workflow Status",
      flex: 2.5,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "opm_rating",
      headerName: "OPM Approval",
      flex: 2,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "opm_fit",
      headerName: "Final Outcome",
      flex: 2,
      headerAlign: "center",
      align: "left",
    },
    {
      field: "reviewer",
      headerName: "OPM Reviewer",
      flex: 2,
      headerAlign: "center",
      align: "left",
    },
  ];

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const convertToUppercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toUpperCase(),
          email: row.email.toUpperCase(),
          title: row.title.toUpperCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  const convertToLowercase = () => {
    const newRows = rows.map((row) => {
      if (row.id === selectedRow) {
        return {
          ...row,
          name: row.name.toLowerCase(),
          email: row.email.toLowerCase(),
          title: row.title.toLowerCase(),
        };
      }
      return row;
    });
    setRows(newRows);
    handleClose();
  };

  return (
    <Fragment>
      {isLoading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <CircularProgress />
        </div>
      )}

      {!isLoading && (
        <div>
          <div style={{ width: "100%" }}>
            <WrapTextTable
              autoHeight
              rowHeight={35}
              sx={datagridSx}
              getRowClassName={(params) => {
                if (
                  Array.isArray(dataChangedArr) &&
                  dataChangedArr.some((item) =>
                    _.isEqual(item, { opm: params.id })
                  )
                ) {
                  return "not--saved";
                } else {
                  return params.indexRelativeToCurrentPage % 2 === 0
                    ? "even"
                    : "odd";
                }
              }}
              getCellClassName={(params) => {
                if (
                  Array.isArray(dataChangedArr) &&
                  dataChangedArr.some((item) =>
                    _.isEqual(item, { opm: params.row.id })
                  )
                ) {
                  return "";
                }
                if (params.field === "opm_fit") {
                  return finalOutcomeColorConfig(params.value);
                }
              }}
              columns={columns}
              rows={rows}
              componentsProps={{
                row: {
                  onContextMenu: handleContextMenu,
                  style: { cursor: "context-menu" },
                },
              }}
              components={{
                Footer: () => (
                  <div
                    className="MuiDataGrid-footerContainer css-17jjc08-MuiDataGrid-footerContainer"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <Box sx={{ p: 1, display: "flex", justifyContent: "end" }}>
                      Total Ongoing Monitoring: {rows.length}
                    </Box>
                  </div>
                ),
              }}
              showCellRightBorder={true}
            />
          </div>

          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={() => {
                history.push({
                  pathname: `/opm/${
                    props.opmId ? props.opmId.split("+")[0] : ""
                  }/new`,
                  originDynamicMetricData: props.dynamicMetricData,
                  monitorData: props.monitorData,
                  colorReference: props.colorReference,
                  gaugeData: props.gaugeData,
                });
              }}
              variant="outlined"
              className="editButton"
            >
              New
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default OpmTable;
