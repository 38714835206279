import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import { useHttpClient } from "../../components/Utils/hooks/http-hook";
import DocumentTreeView from "./DocumentTreeView";
import DocumentTable from "./DocumentTable";
import { UserContext } from "../../context/user-context";
import DocumentAccordion from "./DocumentAccordion";

import { CircularProgress, IconButton } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";

import "./Document.css";
import Toast from "../../components/Utils/Toast";
import { object } from "prop-types";

const docDataTransfer = (docData) => {
  try {
    const data = Object.entries(docData).map(([key, value]) => {
      if (key === "Validation") {
        const newObj = {};
        value.forEach((i) => {
          newObj[`${i.validation_id} - ${i.review}`] = value.filter(
            (j) => j.validation_id === i.validation_id
          );
        });
        return { [key]: newObj };
      } else if (key === "Issue") {
        const newObj = {};
        value.forEach((i) => {
          newObj[`${i.issue_id} - ${i.name}`] = value.filter((j) => j.issue_id === i.issue_id);
        });
        return { [key]: newObj };
      } else if (key === "OPM") {
        const newObj = {};
        value.forEach((i) => {
          newObj[i.version] = value.filter((j) => j.version === i.version);
        });
        return { [key]: newObj };
      }
      return { [key]: value };
    });
    return data;
  } catch (err) {
    console.warn(err);
  }
};

const Document = (props) => {
  const history = useHistory();
  const { user, tabNameOutToInHandler, tabNameInToOutHandler } =
    useContext(UserContext);
  const { error, sendRequest, clearError } = useHttpClient();
  const [isDocumentChanged, setIsDocumentChanged] = useState(true);
  const [documentData, setDocumentData] = useState();
  const [documentRecord, setDocumentRecord] = useState();
  const [allDocumentType, setAllDocumentType] = useState([]);
  const [documentType, setDocumentType] = useState("");
  const [treeClick, setTreeClick] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const doc_access = user["document access"] || {};
  const doc_option = Array.isArray(Object.values(doc_access))
    ? Object.values(doc_access)[0].map((access) =>
        tabNameOutToInHandler(access)
      )
    : [];

  let form, param;
  if (props.record) {
    if (props.record.split("+")[0] === "Model") {
      form = "model";
      param = `${props.record.split("+")[1]}+${props.record.split("+")[2]}`;
    } else if (props.record.split("+")[0] === "Policy Exception") {
      form = "policy";
      param = props.record.split("+")[1];
    } else if (props.record.split("+")[0] === "Attestation") {
      form = "in use";
      param = props.record.split("+")[1];
    } else if (props.record.split("+")[0] === "Model Change Memo") {
      form = "model change";
      param = props.record.split("+")[1];
    } else if (props.record.split("+")[0] === "MAC") {
      form = "mac";
      param = `${props.record.split("+")[1]}+${props.record.split("+")[2]}`;
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/documents/${form}/${param}`
        );
        setIsLoading(false);
        if (response.state || response.error) {
          setDocumentData([]);
          setAllDocumentType([]);
          setDocumentRecord([]);
        } else {
          setDocumentData(response);
          setAllDocumentType(Object.keys(response));
          setTreeClick(
            treeClick.length > 0 ? treeClick : Object.keys(response)?.[0]
          );
          setDocumentRecord(
            treeClick.length > 0
              ? response[`${treeClick}`]
              : Object.values(response)[0]
          );
          treeClick?.split("+")[0] === "Issue" &&
            treeClick?.split("+")[1] &&
            setDocumentRecord(
              response[`${treeClick.split("+")[0]}`].filter(
                (i) => i.issue_id === treeClick.split("+")[1]
              )
            );
          treeClick?.split("+")[0] === "OPM" &&
            treeClick?.split("+")[1] &&
            setDocumentRecord(
              response[`${treeClick.split("+")[0]}`].filter(
                (i) => i.version === treeClick.split("+")[1]
              )
            );
        }
      } catch (err) {}
    };
    if (
      form &&
      param &&
      !form.includes("undefined") &&
      !param.includes("undefined")
    ) {
      fetchData();
    }
  }, [props.record, sendRequest, isDocumentChanged]);

  const updatePageHandler = () => {
    setIsDocumentChanged((prev) => !prev);
  };

  if (!documentData) {
    setDocumentData([]);
  }

  const newDocumentHandler = () => {
    if (
      (["Model", "MAC"].includes(props.record.split("+")[0]) &&
        props.record.split("+")[2]) ||
      (props.record.split("+")[0] !== "Model" && props.record.split("+")[1])
    ) {
      history.push(`/documents/${props.record}/new`);
    } else {
      Toast("info", "Please select a Full Version ID to upload documents.");
    }
  };

  if (
    !(
      form &&
      param &&
      !form.includes("undefined") &&
      !param.includes("undefined")
    )
  ) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
          paddingTop: "10rem",
        }}
      >
        {form !== "mac" && (
          <h2 style={{ color: "#444791" }}>Please select a model</h2>
        )}
        {form === "mac" && (
          <h2 style={{ color: "#444791" }}>Please select a MAC record</h2>
        )}
      </div>
    );
  }

  if (isLoading || !documentData)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50%",
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <div style={{ margin: "1.5rem 8rem 2rem 8rem", minHeight: "80vh" }}>
      <IconButton
        aria-label="add"
        size="small"
        onClick={newDocumentHandler}
        style={{ marginBottom: "1rem", color: "#444791" }}
      >
        <PostAddIcon></PostAddIcon>
      </IconButton>
      {Array.isArray(documentData) && documentData.length === 0 && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50%",
          }}
        >
          <h2 style={{ color: "#444791" }}>No document found</h2>
        </div>
      )}

      {!(Array.isArray(documentData) && documentData.length === 0) && (
        <div className="Document-page">
          <DocumentAccordion
            data={docDataTransfer(documentData)}
            updatePageHandler={updatePageHandler}
          />
        </div>
      )}
    </div>
  );
};
export default Document;
