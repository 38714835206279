const VALIDATOR_TYPE_REQUIRE = 'REQUIRE';
const VALIDATOR_TYPE_MINLENGTH = 'MINLENGTH';
const VALIDATOR_TYPE_MAXLENGTH = 'MAXLENGTH';
const VALIDATOR_TYPE_MIN = 'MIN';
const VALIDATOR_TYPE_MAX = 'MAX';
const VALIDATOR_TYPE_EMAIL = 'EMAIL';
const VALIDATOR_TYPE_FILE = 'FILE';
const VALIDATOR_TYPE_CODE = 'CODE';
const VALIDATOR_TYPE_VALIDATION_ID  = 'VALIDATION_ID';
const VALIDATOR_TYPE_MODEL_VERSION_ID = 'MODEL_VERSION_ID';
const VALIDATOR_TYPE_VALIDATION_ACTIVITY_ID = 'VALIDATION_ACTIVITY_ID';
const VALIDATOR_TYPE_NUMBER = 'NUMBER';

export const VALIDATOR_REQUIRE = () => ({ type: VALIDATOR_TYPE_REQUIRE });
export const VALIDATOR_FILE = () => ({ type: VALIDATOR_TYPE_FILE });
export const VALIDATOR_MINLENGTH = val => ({
  type: VALIDATOR_TYPE_MINLENGTH,
  val: val
});
export const VALIDATOR_MAXLENGTH = val => ({
  type: VALIDATOR_TYPE_MAXLENGTH,
  val: val
});
export const VALIDATOR_MIN = val => ({ type: VALIDATOR_TYPE_MIN, val: val });
export const VALIDATOR_MAX = val => ({ type: VALIDATOR_TYPE_MAX, val: val });
export const VALIDATOR_EMAIL = () => ({ type: VALIDATOR_TYPE_EMAIL });
export const VALIDATOR_CODE = () => ({ type: VALIDATOR_TYPE_CODE });
export const VALIDATOR_VALIDATION_ID = () => ({ type: VALIDATOR_TYPE_VALIDATION_ID });
export const VALIDATOR_MODEL_VERSION_ID = () => ({ type: VALIDATOR_TYPE_MODEL_VERSION_ID });
export const VALIDATOR_VALIDATION_ACTIVITY_ID = () => ({ type: VALIDATOR_TYPE_VALIDATION_ACTIVITY_ID });
export const VALIDATOR_NUMBER = () => ({ type: VALIDATOR_NUMBER });

export const validate = (value, validators) => {
  let isValid = true;
  for (const validator of validators) {
    if (validator.type === VALIDATOR_TYPE_REQUIRE) {
      isValid = isValid && value.trim().length > 0;
    }
    if (validator.type === VALIDATOR_TYPE_MINLENGTH) {
      isValid = isValid && value.trim().length >= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MAXLENGTH) {
      isValid = isValid && value.trim().length <= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MIN) {
      isValid = isValid && +value >= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_MAX) {
      isValid = isValid && +value <= validator.val;
    }
    if (validator.type === VALIDATOR_TYPE_EMAIL) {
      isValid = isValid && /^\S+@\S+\.\S+$/.test(value);
    }
    if (validator.type === VALIDATOR_TYPE_CODE) {
      isValid = isValid && /[A-Z][A-Z][A-Z]+\.\d\.\d\d\.\d\d\.V\d\d/.test(value) && value.length === 15;
    }
    if (validator.type === VALIDATOR_TYPE_VALIDATION_ID) {
      isValid = isValid && /ATV\d\d\d\d/.test(value) && value.length === 7;
    }
    if (validator.type === VALIDATOR_TYPE_MODEL_VERSION_ID) {
      isValid = isValid && /V\d\dCH\d\d\d/.test(value) && value.length === 8;
    }
    if (validator.type === VALIDATOR_TYPE_VALIDATION_ACTIVITY_ID) {
      isValid = isValid && ((/MRM\d\d\d\d\d/.test(value) && value.length === 8) ||
      (/OSFI\d\d\d\d\d/.test(value) && value.length === 9)
      );
    }

  }
  return isValid;
};
