import { toast } from "react-toastify";

const Toast = (type, response, message) => {
  let msg;
  if (typeof response === "string") {
    if (response.length > 200) {
      msg = message;
    } else {
      msg = response;
    }
  }
  if (typeof response === "object") {
    msg = Object.values(response)[0];
  }

  if (type === "success") {
    return toast[type](msg, {
      autoClose: 5000,
      position: "top-center",
      toastId: msg,
    });
  } else if (type === "error" || type === "warning") {
    return toast[type](msg, {
      autoClose: false,
      position: "top-center",
      toastId: msg,
    });
  } else
    return toast[type](msg, {
      autoClose: false,
      position: "top-center",
      toastId: msg,
    });
};

export default Toast;
